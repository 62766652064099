import { Typography, Button, Container, Stack, Divider } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid } from '@mui/x-data-grid';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

export default function Permissions(props) {
    const onRowClick = (role_id) => {
        alert(role_id);
    };

    const onCancelRole = (role_id) => {
        alert('Deactivate Role' + role_id);
    };

    const columns = [
        { field: 'role_id', width: 300, headerName: 'Role Id' },
        { field: 'role_name', width: 300, headerName: 'Role Name' },
        { field: 'disp_ord', width: 300, headerName: 'Display Order' },
        {
            field: 'action',
            headerName: 'Action',
            width: 300,
            renderCell: params =>
                <>
                    <Button onClick={e => (onRowClick(params.row.role_id))}>
                        <EditIcon />
                    </Button>
                    <Button onClick={e => onCancelRole(params.row.role_id)} style={{ color: 'red' }}>
                        <CancelIcon />
                    </Button>
                </>
        }
    ];

    const rows = [
        { role_id: 1, role_name: 'Snow', disp_ord: 'Jon', age: 35 },
        { role_id: 2, role_name: 'Lannister', disp_ord: 'Cersei', age: 42 },
        { role_id: 3, role_name: 'Lannister', disp_ord: 'Jaime', age: 45 },
        { role_id: 4, role_name: 'Stark', disp_ord: 'Arya', age: 16 },
        { role_id: 5, role_name: 'Targaryen', disp_ord: 'Daenerys', age: null },
        { role_id: 6, role_name: 'Melisandre', disp_ord: null, age: 150 },
        { role_id: 7, role_name: 'Clifford', disp_ord: 'Ferrara', age: 44 },
        { role_id: 8, role_name: 'Frances', disp_ord: 'Rossini', age: 36 },
        { role_id: 9, role_name: 'Roxie', disp_ord: 'Harvey', age: 65 },
    ];
    return <Stack>
        <Container>
            <Typography variant="h5">
                <Link to="/carboncompete/configure">
                    <Button>
                        <ArrowBackIcon />
                    </Button>
                </Link>
                Manage Permissions
            </Typography>
        </Container>

        <Divider />
        
        <Container>
            <Button style={{ float: 'right' }} onClick={e => alert('create role')}>
                <AddIcon /> Create New Permissions
            </Button>
        </Container>
        <Container>
            <DataGrid
                rowSelection={false}
                checkboxSelection={false}
                getRowId={(row) => row.role_id}
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 15 },
                    },
                }}
                pageSizeOptions={[5, 10, 15]}
            />
        </Container>
    </Stack>;
}