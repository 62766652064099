import React, { useEffect, useState } from "react";
import {
  Grid,
  Container,
  Typography,
  Button
} from "@mui/material";
import { useFormik } from "formik";
import ad_img from "../Assets2/ad_img.png";
import { makeStyles } from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import CarbonScoreChart from "./charts/PieChart";
import { getSupplierWiseEmissions } from "../../services/analytics";
import { ProgressBarSupplier } from "./charts/ProgressBar";
import { useNavigate } from "react-router-dom";
import Scores from "./charts/ScoreData";
import { APP_KEYS } from "../../utils/constants/AppKeyConstants";
import TypewriterComponent from "typewriter-effect";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 20px',
  },
  confidenceContainer: {
    height: '100%',
    paddingTop: '25px',
    background: '#fff',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.1)',
    borderRadius: '24px',
    border: '0.5px solid #D8D8D8',
    boxShadow: '0px 30px 60px -30px rgba(0, 0, 0, 0.30), 0px 50px 100px -20px rgba(50, 50, 93, 0.25)',
    display: 'flex',
    gap: '80px',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  container: {
    height: '100%',
    paddingTop: '25px',
    background: '#fff',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.1)',
    borderRadius: '24px',
    border: '0.5px solid #D8D8D8',
    boxShadow: '0px 30px 60px -30px rgba(0, 0, 0, 0.30), 0px 50px 100px -20px rgba(50, 50, 93, 0.25)'
  },
  adContainer: {
    borderRadius: '10px',
    background: '#F4F4F4',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
    height: '17rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  title: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    fontSize: '20px',
    marginBottom: theme.spacing(1),
    color: '#333',
  },
  adImg: {
    width: '40%',
    height: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100% !important',
    },
  },
  titleText: {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: '17px',
  },
  link: {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  linkText: {
    textDecoration: 'none',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#149BA1',
  },
  messageContainer: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Poppins',
  },
}));

export default function PremiumDashboard(props) {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));
  const classes = useStyles();
  const [timeOfDay, setTimeOfDay] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [productData, setProductData] = useState([])

  const formik = useFormik({
    initialValues: {
      scope: "Yogurt",
      duration: "daily",
    },
  });

  const [highestScoreProduct, setHighestScoreProduct] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));
    const email = user?.userEmail;

    async function fetchBrandsData() {
      try {
        const response = await getSupplierWiseEmissions(email);
        if (response.success) {
          setProductData(response.data);

          const products = response.data[0]?.products;
          if (products && products.length > 0) {
            const highestScoreProduct = products.reduce((prev, current) =>
              prev.stage_carbon_score > current.stage_carbon_score ? prev : current
            );
            setHighestScoreProduct(highestScoreProduct);
          }
        } else {
          console.error('Error fetching products data:', response.msg);
        }
      } catch (error) {
        console.error('Error fetching products data:', error);
      }
    }
    fetchBrandsData();
  }, []);


  useEffect(() => {
    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
    }, 1000);
  }, [formik.values.scope]);

  // Function : show time of day and greeting
  const updateGreeting = () => {
    const currentHour = new Date().getHours();
    let greeting = "";

    if (currentHour >= 5 && currentHour < 12) {
      greeting = "Good morning";
    } else if (currentHour >= 12 && currentHour < 17) {
      greeting = "Good afternoon";
    } else {
      greeting = "Good evening";
    }

    setTimeOfDay(greeting);
  };

  useEffect(() => {
    updateGreeting();

    const interval = setInterval(updateGreeting, 60000);
    return () => clearInterval(interval);
  }, []);

  const isProductAvailable = highestScoreProduct && highestScoreProduct.productId;

  return (
    <div className={classes.root}>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={8}>
          <Container className={classes.confidenceContainer}>
            <Grid>
              <ProgressBarSupplier highestScoreProduct={highestScoreProduct} />
            </Grid>
            <Grid xs className={classes.messageContainer}>
              <div style={{ marginTop: '20px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'sp', border: '1px solid #ccc', borderRadius: '10px', padding: '10px', fontFamily: 'Poppins', fontWeight: 'bold', gap: '10px' }}>
                  <Typography
                    sx={{
                      color: '#222',
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: '600',
                      textAlign: 'center',
                    }}
                    variant="body1"
                    color="textSecondary"
                    component="div"
                  >
                    {highestScoreProduct
                      ? (
                        <>
                          Your most surprising Product
                          of highest carbon <br></br> emissions ,
                          remarkable , is
                        </>
                      )
                      : "Start your carbon journey by adding your products"}
                  </Typography>

                  {isProductAvailable ? (
                    <>
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginTop: '10px', borderRadius: '20px', fontFamily: 'Poppins', fontWeight: 'bold' }}
                      >
                        {highestScoreProduct.productName.slice(0, 34)}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Typography sx={{ color: '#149BA1', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'bold', }}>Start the carbon journey at your stage.</Typography>
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginTop: '10px', borderRadius: '20px', fontFamily: 'Poppins', fontWeight: 'bold' }}
                        onClick={() => navigate(`/carboncompete/supplychainconfig`)}
                      >
                        Your catalogue
                      </Button>
                    </>
                  )}
                </div>
                {highestScoreProduct && (
                  <div>
                    <div style={{ marginTop: '10px', border: '1px solid #ccc', borderRadius: '10px', padding: '10px', fontFamily: 'Poppins', fontWeight: 'bold' }}>
                      <Typography sx={{ color: '#000', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }} variant="body1" color="textSecondary">
                        This carbon emission is equal to  {highestScoreProduct && (
                          <span style={{ color: '#a81111' }}>
                            <TypewriterComponent
                              options={{
                                strings: [
                                  `${parseFloat(highestScoreProduct.stage_carbon_score / 0.3889).toFixed(0)} Miles driven by gasoline-powered vehicle.`,
                                  `${parseFloat(highestScoreProduct.stage_carbon_score * 0.098214).toFixed(1)} Gallons of diesel consumed.`,
                                  `${parseFloat(highestScoreProduct.stage_carbon_score / 0.0082).toFixed(0)} Number of smartphones charged.`,
                                ],
                                autoStart: true,
                                loop: true,
                                deleteSpeed: 50,
                              }}
                            />
                          </span>
                        )}
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            </Grid>
          </Container>
        </Grid>
        <Grid item xs={12} md={4}>
          <Container className={classes.confidenceContainer}>
            <CarbonScoreChart productData={productData} />
          </Container>
        </Grid>
        <Grid item xs={12} md={12}>
          <Container className={classes.container} maxWidth="xl">
            <Scores productData={productData} />
          </Container>
        </Grid>

      </Grid>
    </div>
  );
}



// why this is not taking full width of the screen?
