import React, { useEffect, useState } from 'react';
import {
  Dialog,
  Typography,
  Container,
  Grid,
  IconButton,
  TextField,
  MenuItem,
  Button,
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { useFormik } from 'formik';
import Iconify from '../../../components/iconify';
import { editClient } from '../../../services/clients';

const EditUser = ({ open, setOpen, roles, userDetails, handleFetchUser }) => {
  const [confirmSubmission, setConfirmSubmission] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showAlert, setShowAlert] = useState('');

  const formik = useFormik({
    initialValues: {
      userId: userDetails.id,
      userName: userDetails.name,
      userEmail: userDetails.email,
      userMobile: userDetails.userMobile,
    },
    onSubmit: () => {
      setConfirmSubmission(true);
    },
  });

  const handleFormSubmission = async () => {
    try {
      setShowLoader(true);

      const response = await editClient({
        userId: formik.values.userId,
        userName: formik.values.userName,
        userEmail: formik.values.userEmail,
        userMobile: formik.values.userMobile,
      });
      setShowLoader(false);
      if (response.success) {
        setShowAlert('user_updated');
        handleFetchUser();
      } else {
        setShowAlert('failed_to_update');
      }
    } catch (err) {
      console.error(err);
      setShowLoader(false);
      setShowAlert('failed_to_update');
    }
  };


  useEffect(() => {
    setTimeout(() => {
      setShowAlert('');
    }, 1500);
  }, [showAlert]);

  return (
    <Dialog open={open} keepMounted maxWidth="md" fullWidth>
      <Container sx={{ p: 1 }}>
        <Container sx={{ pb: 1 }}>
          <Grid container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid item>
              <IconButton onClick={() => setOpen((s) => !s)}>
                <Iconify icon="material-symbols:close" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container py={1}>
            <Grid item>
              <Typography sx={{ m: 'auto', fontSize: '20px' }} fontFamily="Raleway" fontWeight={600}>
                Edit existing user details
              </Typography>
            </Grid>
            <Grid item sx={{ ml: 'auto' }}>
              <IconButton onClick={() => setOpen((s) => !s)}>
                {/* Add your close icon here */}
              </IconButton>
            </Grid>
          </Grid>
        </Container>

        <Container spacing={2}>
          {showAlert === 'user_updated' ? (
            <Alert severity="success" sx={{ width: '100%' }}>
              <strong>User updated successfully</strong>
            </Alert>
          ) : (
            <>
              {showAlert === 'failed_to_update' ? (
                <Alert severity="error" sx={{ width: '100%' }}>
                  <strong>Failed to update user</strong>
                </Alert>
              ) : null}
            </>
          )}

          <form onSubmit={formik.handleSubmit}>
            <Grid container sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <TextField
                  name="userName"
                  value={formik.values.userName}
                  onChange={formik.handleChange}
                  label="Edit user's name"
                  sx={{
                    width: '100%',
                    '.MuiInputLabel-root': {
                      fontFamily: 'Poppins',
                      fontSize: '15px',
                    },
                    '.MuiInputBase-input': {
                      fontFamily: 'Poppins',
                    },
                    mt: 1,
                    color: 'white',
                  }}
                  error={formik.touched.userName && Boolean(formik.errors.userName)}
                  helperText={formik.touched.userName && formik.errors.userName}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <TextField
                  name="userEmail"
                  value={formik.values.userEmail}
                  onChange={formik.handleChange}
                  label="Edit user's email"
                  sx={{
                    width: '100%',
                    '.MuiInputLabel-root': {
                      fontFamily: 'Poppins',
                      fontSize: '15px',
                    },
                    '.MuiInputBase-input': {
                      fontFamily: 'Poppins',
                    },
                    mt: 1,
                    color: 'white',
                  }}
                  error={formik.touched.userEmail && Boolean(formik.errors.userEmail)}
                  helperText={formik.touched.userEmail && formik.errors.userEmail}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }}>
              <TextField
                name="userMobile"
                value={formik.values.userMobile}
                onChange={formik.handleChange}
                label="Edit user's mobile number"
                sx={{
                  width: '100%',
                  '.MuiInputLabel-root': {
                    fontFamily: 'Poppins',
                    fontSize: '15px',
                  },
                  '.MuiInputBase-input': {
                    fontFamily: 'Poppins',
                  },
                  mt: 1,
                  color: 'white',
                }}
                error={formik.touched.userMobile && Boolean(formik.errors.userMobile)}
                helperText={formik.touched.userMobile && formik.errors.userMobile}
              />
            </Grid>

            {confirmSubmission ? (
              <Typography fontFamily="Poppins" textAlign="center" sx={{ mt: 2 }}>
                Are you sure?
              </Typography>
            ) : null}

            <Grid container spacing={1} mt={1}>
              {confirmSubmission ? (
                <>
                  <Grid item xs={6} md={6}>
                    <Button
                      sx={{
                        width: '100%',
                        mt: 1,
                        backgroundColor: 'red',
                        '&:hover': {
                          backgroundColor: 'red',
                        },
                      }}
                      variant="contained"
                      onClick={() => setConfirmSubmission(false)}
                    >
                      <Typography fontFamily="Raleway" fontWeight={600}>
                        No
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Button
                      type="button"
                      onClick={() => handleFormSubmission()}
                      sx={{
                        width: '100%',
                        mt: 1,
                        backgroundColor: 'green',
                        '&:hover': {
                          backgroundColor: 'green',
                        },
                      }}
                      variant="contained"
                    >
                      <Typography fontFamily="Raleway" fontWeight={600}>
                        Yes
                      </Typography>
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid sx={{ mb: 2 }} item xs={6}>
                    <Button
                      sx={{
                        width: '100%',
                        backgroundColor: 'red',
                        mt: 1,
                        '&:hover': {
                          backgroundColor: 'red',
                        },
                      }}
                      variant="contained"
                      onClick={formik.handleReset}
                      disabled={confirmSubmission}
                    >
                      <Typography fontFamily="Raleway" fontWeight={600}>
                        Reset
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid sx={{ mb: 2 }} item xs={6}>
                    <Button
                      type="button"
                      onClick={() => formik.handleSubmit()}
                      sx={{ width: '100%', mt: 1 }}
                      variant="contained"
                    >
                      <Typography fontFamily="Raleway" fontWeight={600}>
                        Submit
                      </Typography>
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        </Container>
      </Container>
    </Dialog>
  );
};

export default EditUser;
