import React, { useEffect, useState } from 'react';
import { ScopeCards } from './Charts/ScopeCards';
import { getAnalyticsData } from '../../services/corporatecanvas';
import { Box, Grid, Typography, createTheme, ThemeProvider, Card } from '@mui/material';
import { ScopeBreakdownPie } from './Charts/ScopeBreakdown';
import { MonthWiseScopeEmissions } from './Charts/MonthlyScopeData';
import { MultipleSelectWithCheckBox } from '../../components/form/selectComponents';
import { APP_KEYS } from '../../utils/constants/AppKeyConstants';

export const CorporateDashboard = () => {
  const userInfo = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));
  const [analyticsData, setAnalyticsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);

  const handleLocationChange = (event) => {
    const newSelectedLocations = event.target.value;
    const filteredLocations = newSelectedLocations.filter(location => location !== "Select All");

    if (newSelectedLocations.includes("Select All")) {
      setSelectedLocations(locations.map(location => location.name)); // Setting all the available locations....
    } else {
      setSelectedLocations(filteredLocations);
    }
  };

  const handleYearChange = (event) => {
    const newSelectedYears = event.target.value;
    const filteredYears = newSelectedYears.filter(year => year !== "Select All");

    if (newSelectedYears.includes("Select All")) {
      setSelectedYears(years); // Setting all the available years..........
    } else {
      setSelectedYears(filteredYears);
    }
  };

  const locations = analyticsData?.locations?.map(location => ({
    id: location.id,
    name: location.name
  })) || [];

  const years = [...new Set(
    analyticsData?.locations?.flatMap(location =>
      location?.yearly_data?.map(data => data.year)
    ) || []
  )].sort((a, b) => a - b);

  console.log(years)

  const isAllLocationSelected = locations.length > 0 && selectedLocations.length === locations.length;
  const isAllYearSelected = years.length > 0 && selectedYears.length === years.length;

  const calculateEmissions = () => {
    const scopeEmissionsByYear = {
      scope1: {},
      scope2: {},
      scope3: {}
    };

    if (selectedLocations.length > 0 && selectedYears.length > 0) {
      selectedLocations.forEach(locationName => {
        const location = analyticsData?.locations?.find(loc => loc.name === locationName);
        if (location) {
          location.yearly_data.forEach(yearData => {
            if (selectedYears.includes(yearData.year)) {
              if (!scopeEmissionsByYear.scope1[yearData.year]) {
                scopeEmissionsByYear.scope1[yearData.year] = 0;
                scopeEmissionsByYear.scope2[yearData.year] = 0;
                scopeEmissionsByYear.scope3[yearData.year] = 0;
              }
              scopeEmissionsByYear.scope1[yearData.year] += yearData.total_scope_emissions.scope1.total_emissions;
              scopeEmissionsByYear.scope2[yearData.year] += yearData.total_scope_emissions.scope2.total_emissions;
              scopeEmissionsByYear.scope3[yearData.year] += yearData.total_scope_emissions.scope3.total_emissions;
            }
          });
        }
      });
    }

    return scopeEmissionsByYear;
  };

  const scopeData = calculateEmissions();

  const calculateScopeEmissions = () => {
    let totalEmissions = 0;
    let totalScopeEmissions = { scope1: { category_breakdown_emissions: {} }, scope2: { category_breakdown_emissions: {} }, scope3: { category_breakdown_emissions: {} } };

    if (selectedLocations.length > 0 && selectedYears.length > 0) {
      selectedLocations.forEach(locationName => {
        const location = analyticsData?.locations?.find(loc => loc.name === locationName);
        if (location) {
          location.yearly_data.forEach(yearData => {
            if (selectedYears.includes(yearData.year)) {
              totalEmissions += yearData.total_emissions;
              Object.keys(totalScopeEmissions).forEach(scope => {
                Object.entries(yearData.total_scope_emissions[scope]?.category_breakdown_emissions || {}).forEach(([category, values]) => {
                  if (!totalScopeEmissions[scope].category_breakdown_emissions[category]) {
                    totalScopeEmissions[scope].category_breakdown_emissions[category] = { total_emissions: 0 };
                  }
                  totalScopeEmissions[scope].category_breakdown_emissions[category].total_emissions += values.total_emissions;
                });
              });
            }
          });
        }
      });
    }

    return { totalEmissions, totalScopeEmissions };
  };

  const { totalScopeEmissions } = calculateScopeEmissions();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAnalyticsData(userInfo?.userId);
        setAnalyticsData(data.data);
  
        // Set default selections after data is fetched
        const defaultLocations = data.data.locations.map(location => location.name);
        const availableYears = [...new Set(
          data.data.locations?.flatMap(location =>
            location?.yearly_data?.map(data => data.year)
          ) || []
        )].sort((a, b) => a - b);
        
        const firstYear = availableYears[0];
        const lastYear = availableYears[availableYears.length - 1];
        
        setSelectedLocations(defaultLocations);
        setSelectedYears([firstYear, lastYear]);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);  

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const theme = createTheme({
    typography: {
      fontFamily: "Poppins !important",
      fontWeight: "bolder !important",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Grid sx={{ padding: 2 }}>
        <Grid
          container
          spacing={2}
          p={2}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Grid item xs={6}>
            <Typography variant="h6" fontWeight={700}>
              Corporate GHG Dashboard
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <MultipleSelectWithCheckBox
              label="Locations"
              id="locations-select"
              value={selectedLocations}
              options={locations.map(location => location.name)}
              onChange={handleLocationChange}
              isAllSelected={isAllLocationSelected}
            />
          </Grid>
          <Grid item xs={3}>
            <MultipleSelectWithCheckBox
              label="Years"
              id="years-select"
              value={selectedYears}
              options={years}
              onChange={handleYearChange}
              isAllSelected={isAllYearSelected}
            />
          </Grid>
        </Grid>
        <ScopeCards scopeData={scopeData} />
        <ScopeBreakdownPie ScopeBreakdown={totalScopeEmissions} />
        <MonthWiseScopeEmissions
          analyticsData={analyticsData}
          selectedLocations={selectedLocations}
          selectedYears={selectedYears}
        />
      </Grid>
    </ThemeProvider>
  );
};
