/* eslint-disable react-hooks/exhaustive-deps */
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactFlow, {
  addEdge,
  Background,
  BackgroundVariant,
  ConnectionMode,
  Controls,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  Panel,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import { NOTIFICATION_INFO } from "../../utils/constants/NotificationConstants";
import CustomNode from "../CarbonCatalogue/TempCanvas/CustomNode";

import "reactflow/dist/style.css";
import "./Flow2.css";
import dagre from "dagre";
import { Backdrop, Button, CircularProgress, Typography } from "@mui/material";
import FlowToolBar from "./FlowElements/FlowToolBar";
import CanvasTemplateModal from "./CanvasTemplateModal";
import CombinedEdge from "./FlowElements/CustomEdge";
import { templateCalculation } from "../../services/external";
import { updateProductAIValues } from "../../services/catalogue/addproduct";

const Flow = ({ data, setData }) => {
 

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const reactFlowWrapper = useRef(null);
  const { getNode } = useReactFlow();
  const {getEdge}=useReactFlow()
  const [showLoader, setShowLoader] = useState(false);
 
  const [editMode, toggleEditMode] = useState(false);
  const [tempProp, setTempProp] = useState(null);
  const [tempProduct, setTempProduct] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [aiValueProduct, setAIValueProduct] = useState({
    productName: "",
    nodes: [],
    edges: [],
  });
  const [updatedData, setUpdatedData] = useState(data || {});

  const [showAIButton, setShowAIButton] = useState(false);
  const [isNewStageChanges,setIssNewStageChanges]=useState(false)
  const [newDataCalculate,setNewDataCalculate]=useState(null)

  useEffect(() => {
    if (data) {
      setAIValueProduct({
        productName: data.productName,
        nodes: data?.supplyChainConfig?.nodes,
        edges: data?.supplyChainConfig?.edges,
      });
    }
  }, []);






  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 172;
  const nodeHeight = 36;

  const getLayoutedElements = async (
    nodes = [],
    edges = [],
    direction = "LR"
  ) => {
    const isHorizontal = direction === "LR";
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = isHorizontal ? "left" : "top";
      node.sourcePosition = isHorizontal ? "right" : "bottom";

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };

      return node;
    });

    return { nodes, edges };
  };

  useEffect(() => {
    let isMounted = true;

    const layoutElements = async () => {
      if (data) {
        setShowLoader(true);
        const { nodes: layoutedNodes, edges: layoutedEdges } =
          await getLayoutedElements(
            data?.supplyChainConfig?.nodes,
            data?.supplyChainConfig?.edges
          );
        if (isMounted) {
          setNodes(layoutedNodes || []);
          setEdges(layoutedEdges || []);
        }
        setShowLoader(false);
      }
    };

    layoutElements();

    return () => {
      isMounted = false;
    };
  }, [data, setEdges, setNodes]);

  const nodeTypes = useMemo(
    () => ({
      custom: CustomNode,
      rootNode: CustomNode,
      customdefault: CustomNode,
    }),
    []
  );

  const edgeTypes = useMemo(
    () => ({
      custom: CombinedEdge,
    }),
    []
  );



  const handleNodeClick = (event, node, productData) => {
    if (node ) {
      setTempProp(node);
      setTempProduct(productData);
      setOpenDialog(true);
      
    }
  
  };

  const editSelectedProduct = () => {
    toggleEditMode(!editMode);
  };
  const onCancel = () => {
    if (editMode) {
      toggleEditMode(!editMode);
      return;
    }

    setNodes([]);
    setEdges([]);
    setData(null);
  };
  const proOptions = { hideAttribution: true };

  const ws = useRef(null);
  const [messages, setMessages] = useState([]);
  
  useEffect(() => {
    ws.current = new WebSocket("wss://socketfilling.azurewebsites.net/ws");

    ws.current.onopen = () => {
      console.log("WebSocket connection established.");
    };

    ws.current.onmessage = async (event) => {
      const message = event.data;
      try {
        // Check if the message is JSON
        const response = JSON.parse(message);

        if (response && response.nodes && response.edges) {
          setNodes(response.nodes || []);
          setEdges(response.edges || []);
          setUpdatedData((prevData) => ({
            ...prevData,
            supplyChainConfig: {
              ...prevData.supplyChainConfig,
              nodes: response.nodes || [],
            },
          }));
          setShowAIButton(true);
          setShowLoader(false); // Set loading to false after updating nodes and edges
        } else {
          console.error("Unexpected response:", response);
          setShowLoader(false);
        }
      } catch (error) {
        // If JSON parsing fails, it might be a plain text status message
        if (message === "Task started") {
          setMessages((prevMessages) => [...prevMessages, message]);
        } else {
          console.error("Error parsing WebSocket message:", error);
          setShowLoader(false);
        }
      }
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error:", error);
      setShowLoader(false);
    };

    ws.current.onclose = () => {
      console.log("WebSocket connection closed.");
      setShowLoader(false);
    };

    

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  const handleAITempVal = async () => {
    try {
      setShowLoader(true);
      ws.current.send(JSON.stringify(aiValueProduct));

      // const response = await generateTemplateAIValues(aiValueProduct);

      //logging ws calls
      try {
        let activities = JSON.parse(sessionStorage.getItem('activity')) || [];
         
        activities.push("wss://socketfilling.azurewebsites.net/ws");
        
        sessionStorage.setItem('activity', JSON.stringify(activities));
      } catch (error) {
        console.error('Error updating activity:', error);
      }

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
    console.log("your use effect is running")
    if (tempProduct){

      let newNodes = updatedData?.supplyChainConfig?.nodes.map((item)=>{

        if (item?.id=== tempProduct.stageID){

          item.data.templateSchemaProp = tempProduct.templateSchemaProp
        }

        return item
      })

    setUpdatedData({
      ...updatedData,
      supplyChainConfig: {
        ...updatedData.supplyChainConfig,
        nodes: newNodes
      }
    }
    )
    }
  },[tempProduct])

  const handleSave = async () => {
    
    try {
      setShowLoader(true);

      const response = await templateCalculation({
        productID: updatedData.productId,
        productName: updatedData.productName,
        nodes: updatedData.supplyChainConfig.nodes,
        edges: updatedData.supplyChainConfig.edges,
      });
      if (response) {
        const res = await updateProductAIValues({
          productId: updatedData.productId,
          data: response,
        });
        if (res.code === 200) {
          window.location.reload();
        }
      }

      setShowLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(async()=>{
  //   if(isNewStageChanges && newDataCalculate){
  //     try {
  //       setShowLoader(true);
  
  //       const response = await templateCalculation({
  //         productID: newDataCalculate.productId,
  //         productName: newDataCalculate.productName,
  //         nodes: newDataCalculate.supplyChainConfig.nodes,
  //         edges: newDataCalculate.supplyChainConfig.edges,
  //       });
  //       if (response) {
  //         const res = await updateProductAIValues({
  //           productId: newDataCalculate.productId,
  //           data: response,
  //         });
  //         if (res.code === 200) {
  //           window.location.reload();
  //         }
  //       }
  
  //       setShowLoader(false);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // },[])
  const [currentTab, setTab] = useState(0);
  useEffect(() => {
    if (openDialog) {
      setTab(0);  // Reset to Activity Inputs tab
    }
  }, [openDialog]);

  
  function shouldNodeBeRemoved(node) {
    if (node.type !== 'custom') {
      return true;
    }
  
    return false;
  }

  function shouldEdgeBeRemoved(edge){
    if(edge.type!=="custom"){
      return true
    }
    return false
  }
    function handleNodesChange(changes) {
      const nextChanges = changes.reduce((acc, change) => {
      // if this change is supposed to remove a node we want to validate it first
        if (change.type === 'remove') {
          const node = getNode(change.id)
  
          // if the node can be removed, keep the change, otherwise we skip the change and keep the node
          if (shouldNodeBeRemoved(node)) {
            return [...acc, change];
          }
   
          // change is skipped, node is kept
          return acc;
        }
  
        // all other change types are just put into the next changes arr
        return [...acc, change];
      }, [])
  
    // apply the changes we kept
      onNodesChange(nextChanges);
    }

    function handleEdgesChange(changes){
      const nextChanges=changes.reduce((acc,change)=>{
        if(change.type==="remove"){
          const edge=getEdge(change.id)

          if(shouldEdgeBeRemoved(edge)){
            return [...acc,change]
          }
        }
        return acc
      },[])

      onEdgesChange(nextChanges)
    }

  return (
    <div
      className="dndflow wrapper simple-floatingedges"
      style={{ width: "100%" }}
      ref={reactFlowWrapper}
    >
      {tempProp && (
        <CanvasTemplateModal
          tempProduct={tempProduct}
          setTempProduct={setTempProduct}
          tempProp={tempProp}
          data={data}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          currentTab={currentTab}
          setTab={setTab}
        />
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodeDoubleClick={(event, node) =>
          handleNodeClick(event, node?.data?.templateSchemaProp, node?.data,node?.data?.Analytics)
        }
        onNodesChange={handleNodesChange}
        onEdgesChange={handleEdgesChange}
        // onConnect={onConnect}
        // onConnectStart={onConnectStart}
        // onConnectEnd={onConnectEnd}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        // onNodesDelete={onNodesDelete}
        connectionMode={ConnectionMode.Loose}
        proOptions={proOptions}
        fitView
        nodeOrigin={[0.5, 0]}
        fitViewOptions={{
          padding: 2,
        }}
      >
        {/* <Background variant={BackgroundVariant.Cross} gap={50} /> */}

        {
          <Panel position="top-right">
            <FlowToolBar
              changeEditMode={editSelectedProduct}
              onCancel={onCancel}
              editMode={editMode}
            />
            {data.aiValues?(
                 <Button
                 sx={{ paddingX: "15px", paddingY: "9px" }}
                 style={{
                   marginRight: "10px",
                   marginTop: "20px",
                   background:
                     "linear-gradient(to right,#E4897B 0%,#149BA1 100%)",
 
                   borderRadius: "8px",
                 }}
                 onClick={() => {
                   handleSave();
                 }}
               >
                 <Typography sx={{ color: "white" }}>Save</Typography>
               </Button>
            ):(showAIButton ? (
              <Button
                sx={{ paddingX: "15px", paddingY: "9px" }}
                style={{
                  marginRight: "10px",
                  marginTop: "20px",
                  background:
                    "linear-gradient(to right,#E4897B 0%,#149BA1 100%)",

                  borderRadius: "8px",
                }}
                onClick={() => {
                  handleSave();
                }}
              >
                <Typography sx={{ color: "white" }}>Save</Typography>
              </Button>
            ) : (
              <Button
                sx={{ paddingX: "15px", paddingY: "9px" }}
                style={{
                  marginRight: "10px",
                  marginTop: "20px",
                  background:
                    "linear-gradient(to right,#E4897B 0%,#149BA1 100%)",

                  borderRadius: "8px",
                }}
                onClick={() => {
                  handleAITempVal();
                }}
              >
                <img src={"/assets/buttonAI.svg"} alt="" />

                <Typography sx={{ color: "white", marginLeft: "3px" }}>
                  Generate AI Values
                </Typography>
              </Button>
            ))}
          </Panel>
        }

        <Controls />
      </ReactFlow>
    </div>
  );
};

export default function CanvasFlow({ data, setData }) {
  return (
    <ReactFlowProvider>
      {data ? (
        <Flow data={data} setData={setData} />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Adjust height as needed
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          Drag or Drop a Product
        </div>
      )}
    </ReactFlowProvider>
  );
}