// Add user component
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  Typography,
  Container,
  Grid,
  IconButton,
  Slide,
  TextField,
  MenuItem,
  Button,
  Alert,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Iconify from '../../components/iconify/Iconify';
import { addNewCorporateSupplier } from '../../services/corporatesuppliers';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const AddNewCorporateSupplierDialog = ({ open, setOpen }) => {
  const [showAlert, setShowAlert] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmSubmission, setConfirmSubmission] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const validationSchema = yup.object({
    userName: yup.string().required('Please enter a userName'),
    userEmail: yup.string().required('Please enter userEmail'),
    userMobile: yup.string().required('Please enter userMobile'),
  });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      userName: '',
      userEmail: '',
      userMobile: '',
    },
    onSubmit: async () => {
      setConfirmSubmission(true);
    },
  });

  console.log( validationSchema, "adida sir")

  const handleFormSubmission = async () => {
    try {
      if (confirmSubmission) {
        setShowLoader(true);
        const response = await addNewCorporateSupplier({ ...formik.values });

        if (response && response.success) {
          // Check if registration was successful
          if (response.success === true && response.statusCode === '195101') {
            setShowAlert('supplier_added');
            // Trigger the refresh of the supplier list
          } else {
            setShowAlert('failed_to_add');
          }
        } else {
          setShowAlert('unknown_error');
        }
        setTimeout(() => {
          setShowLoader(false);
          formik.resetForm();
          setConfirmSubmission(false);
          setOpen(false);
        }, 2000);
      }
    } catch (err) {
      console.error('Error:', err);
      setShowAlert('unknown_error');
    }
  };


  useEffect(() => {
    setTimeout(() => {
      setShowAlert('');
    }, 1500);
  }, [showAlert]);

  return (
    <>
      <Dialog open={open} TransitionComponent={Transition} keepMounted maxWidth="md" fullWidth>
        <Container sx={{ p: 1 }}>
          <Container sx={{ pb: 1 }}>
            <Grid container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Grid item>
                <IconButton onClick={() => setOpen((s) => !s)}>
                  <Iconify icon="material-symbols:close" />
                </IconButton>
              </Grid>
            </Grid>

            <Typography sx={{ textAlign: 'center', fontSize: '20px' }} fontFamily="Raleway" fontWeight={600}>
              Add New User Details
            </Typography>
          </Container>

          <Container spacing={2}>
            {showAlert === 'supplier_added' ? (
              <Alert severity="success" sx={{ width: '100%' }}>
                <strong>supplier added successfully</strong>
              </Alert>
            ) : (
              <>
                {showAlert === 'failed_to_add' ? (
                  <Alert severity="error" sx={{ width: '100%' }}>
                    <strong>Failed to add supplier</strong>
                  </Alert>
                ) : null}
              </>
            )}

            <form onSubmit={formik.handleSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    name="userName"
                    value={formik.values.userName}
                    onChange={formik.handleChange}
                    label="Enter user's name"
                    sx={{
                      width: '100%',
                      '.MuiInputLabel-root': {
                        fontFamily: 'Poppins',
                        fontSize: '15px',
                      },
                      '.MuiInputBase-input': {
                        fontFamily: 'Poppins',
                      },
                      mt: 1,
                      color: 'white',
                    }}
                    error={formik.touched.userName && Boolean(formik.errors.userName)}
                    helperText={formik.touched.userName && formik.errors.userName}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <TextField
                    name="userEmail"
                    value={formik.values.userEmail}
                    onChange={formik.handleChange}
                    label="Enter user's email"
                    sx={{
                      width: '100%',
                      '.MuiInputLabel-root': {
                        fontFamily: 'Poppins',
                        fontSize: '15px',
                      },
                      '.MuiInputBase-input': {
                        fontFamily: 'Poppins',
                      },
                      mt: 1,
                      color: 'white',
                    }}
                    error={formik.touched.userEmail && Boolean(formik.errors.userEmail)}
                    helperText={formik.touched.userEmail && formik.errors.userEmail}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="userMobile"
                    value={formik.values.userMobile}
                    onChange={formik.handleChange}
                    label="Enter user's contact number"
                    sx={{
                      width: '100%',
                      '.MuiInputLabel-root': {
                        fontFamily: 'Poppins',
                        fontSize: '15px',
                      },
                      '.MuiInputBase-input': {
                        fontFamily: 'Poppins',
                      },
                      mt: 1,
                      color: 'white',
                    }}
                    error={formik.touched.userMobile && Boolean(formik.errors.userMobile)}
                    helperText={formik.touched.userMobile && formik.errors.userMobile}
                  />
                </Grid>
              </Grid>

              {confirmSubmission ? (
                <Typography fontFamily="Poppins" textAlign="center" sx={{ mt: 2 }}>
                  Are you sure?
                </Typography>
              ) : null}

              <Grid container spacing={1} mt={1}>
                {confirmSubmission ? (
                  <>
                    <Grid item xs={6} md={6}>
                      <Button
                        sx={{
                          width: '100%',
                          mt: 1,
                          backgroundColor: 'red',
                          '&:hover': {
                            backgroundColor: 'red',
                          },
                        }}
                        variant="contained"
                        onClick={() => setConfirmSubmission(false)}
                      >
                        <Typography fontFamily="Raleway" fontWeight={600}>
                          No
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Button
                        type="button"
                        onClick={() => handleFormSubmission()}
                        sx={{
                          width: '100%',
                          mt: 1,
                          backgroundColor: 'green',
                          '&:hover': {
                            backgroundColor: 'green',
                          },
                        }}
                        variant="contained"
                        startIcon={showLoader ? <CircularProgress size={20} style={{ color: 'white' }} /> : null}
                      >
                        <Typography fontFamily="Raleway" fontWeight={600}>
                          Yes
                        </Typography>
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid sx={{ mb: 2 }} item xs={6}>
                      <Button
                        sx={{
                          width: '100%',
                          backgroundColor: 'red',
                          mt: 1,
                          '&:hover': {
                            backgroundColor: 'red',
                          },
                        }}
                        variant="contained"
                        onClick={formik.handleReset}
                        disabled={confirmSubmission}
                      >
                        <Typography fontFamily="Raleway" fontWeight={600}>
                          Reset
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid sx={{ mb: 2 }} item xs={6}>
                      <Button
                        type="submit"
                        onClick={() => formik.handleSubmit()}
                        sx={{ width: '100%', mt: 1 }}
                        variant="contained"
                      >
                        <Typography fontFamily="Raleway" fontWeight={600}>
                          Submit
                        </Typography>
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          </Container>
        </Container>
      </Dialog>
    </>
  );
};

export default AddNewCorporateSupplierDialog;