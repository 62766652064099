import { Typography, Button, Container, Stack, Divider } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid } from '@mui/x-data-grid';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from "react";
import * as rolesService from '../../../services/rolesAndPermissions';

export default function Pages(props) {
    const [roles, setRoles] = useState([]);
    const onRowClick = (roleId) => {
        alert(roleId);
    };

    const onCancelRole = (roleId) => {
        alert('Deactivate Role' + roleId);
    };

    useEffect(() => {
        getAllRoles();
    }, []);

    const getAllRoles = () => {
        rolesService.getRole()
            .then(rolesList => {
                setRoles(rolesList);
            })
    };

    const columns = [
        { field: 'roleId', width: 300, headerName: 'Role Id' },
        { field: 'roleName', width: 300, headerName: 'Role Name' },
        { field: 'displayOrder', width: 300, headerName: 'Display Order' },
        // {
        //     field: 'action',
        //     headerName: 'Action',
        //     width: 300,
        //     renderCell: params =>
        //         <>
        //             {/* <Button onClick={e => (onRowClick(params.row.roleId))}>
        //                 <EditIcon />
        //             </Button> */}
        //             <Button onClick={e => onCancelRole(params.row.roleId)} style={{ color: 'red' }}>
        //                 <CancelIcon />
        //             </Button>
        //         </>
        // }
    ];

    return <Stack>
        <Container>
            <Typography variant="h5">
                <Link to="/carboncompete/configure">
                    <Button>
                        <ArrowBackIcon />
                    </Button>
                </Link>
                Manage Role
            </Typography>
        </Container>

      <Divider />

        {/* <Container>
            <Button style={{ float: 'right' }} onClick={e => alert('create role')}>
                <AddIcon /> Create New Role
            </Button>
        </Container> */}
        <Container>
            <DataGrid
                rowSelection={false}
                checkboxSelection={false}
                getRowId={(row) => row.roleId}
                rows={roles}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 15 },
                    },
                }}
                pageSizeOptions={[5, 10, 15]}
            />
        </Container>
    </Stack>;
}