import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,

} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { getScenario, postScenarion } from "../../services/scenario/scenario";
import { Box } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

export const ScenarioPlanning = () => {
  const navigate = useNavigate();
  const [forecastStatus, setForecastStatus] = useState('idle');
  const [showPercentageInput, setShowPercentageInput] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [payload, setPayload] = useState(null);
  const [allScenarios, setAllScenarios] = useState(null)
  const locationData = [
    { locationId: "loc1", locationName: "Virugambakkam" },
    { locationId: "loc2", locationName: "Banasvadi" },
    { locationId: "loc3", locationName: "Bangalore Region" },
    { locationId: "loc4", locationName: "Bannerghatta" },
  ];

  const scenarioData = [
    { scenarioId: "sce1", scenarioName: "Net Zero" },
    { scenarioId: "sce2", scenarioName: "Reduction in Emissions by %" },
  ];

  const industryData = [
    { industryId: "ind1", industryName: "Manufacturing" },
    { industryId: "ind2", industryName: "Retail" },
    { industryId: "ind3", industryName: "Packaging" },
    { industryId: "ind4", industryName: "Finance" },
  ];

  const recommendationData = [
    { recommendationId: "rec1", recommendationName: "Operational Changes" },
  ];

  const getAllScenarios = async () => {
    try {
      const response = await getScenario()

      if (response) {
        setAllScenarios(response.data)
      }
    } catch (error) {
      console.log(error)
    }

  }
  useEffect(() => {
    getAllScenarios()
  }, [])
  const ws = useRef(null);
  const latestPayload = useRef(null);
  useEffect(() => {
    ws.current = new WebSocket("https://socketscenario.azurewebsites.net/ws");

    ws.current.onopen = () => {
      console.log("WebSocket connection established.");
    };

    ws.current.onmessage = async (event) => {
      const message = event.data;
      try {
        const response = JSON.parse(message);
        if (response) {
          setResponseData(response);

          try {
            if (latestPayload.current) {
              const combinedPayload = {
                data: response,
                ...latestPayload.current
              };
              const apiResponse = await postScenarion(combinedPayload);

              window.location.reload()
            } else {
              console.log("No payload available");
            }
          } catch (error) {
            console.log("Error posting scenario:", error);
          } finally {
            setForecastStatus('completed');
          }
        } else {
          console.error("Unexpected response:", response);
          setForecastStatus('error');
        }
      } catch (error) {
        if (message === "Forecast started") {
          console.log("Forecasting started");
          setForecastStatus('inProgress');
        } else if (message.includes("Forecasting in progress") ||
          message.includes("Models and data loaded") ||
          message.includes("Calculating recommendations") ||
          message.includes("Recommendations generated") ||
          message.includes("Processing columns")) {
          console.log(message);
          setForecastStatus('inProgress');
        } else {
          console.error("Error parsing WebSocket message:", error);
          setForecastStatus('error');
        }
      }
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error:", error);
      setForecastStatus('error');
    };

    ws.current.onclose = () => {
      console.log("WebSocket connection closed.");
      setForecastStatus('idle');
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);


  const formik = useFormik({
    initialValues: {
      location: "",
      scenario: "",
      industry: "",
      target: 2030,
      recommendation: "",
      emissionReductionPercentage: 30,
    },
    onSubmit: (values) => {
      setForecastStatus('inProgress');

      const newPayload = {
        compId: 147,
        scenarioType: values.scenario,
        industryType: values.industry,
        targetYear: parseInt(values.target),
        recommendationType: values.recommendation,
        location: values.location,
      };

      if (showPercentageInput) {
        newPayload.percentage = parseInt(values.emissionReductionPercentage);
      }

      setPayload(newPayload);
      latestPayload.current = newPayload;
      ws.current.send(JSON.stringify(newPayload));
    },
  });

  const downloadCSV = () => {
    const csvData = allScenarios.map(scenario => ({
      'Scenario ID': scenario.scenarioId,
      'Company ID': scenario.compId,
      'Location': scenario.location,
      'Scenario Type': scenario.scenarioType,
      'Target Year': scenario.targetYear,
      'Reduction Percentage': scenario.reductionPercentage || 'N/A',
      'Recommendation Type': scenario.recommendationType,
    }));
    return csvData;
  };

  const handleLocationChange = (event) => {
    formik.setFieldValue("location", event.target.value);
  };

  const handleScenarioTypeChange = (event) => {
    const selectedScenario = event.target.value;
    formik.setFieldValue("scenario", selectedScenario);
    setShowPercentageInput(selectedScenario === "Reduction in Emissions by %");
  };

  const handleIndustryChange = (event) => {
    formik.setFieldValue("industry", event.target.value);
  };

  const handleTargetChange = (event) => {
    formik.setFieldValue("target", event.target.value);
  };

  const handleRecommendationChange = (event) => {
    formik.setFieldValue("recommendation", event.target.value);
  };

  const handlePercentageChange = (event) => {
    formik.setFieldValue("emissionReductionPercentage", event.target.value);
  };

  const [selectedScenario, setSelectedScenario] = useState(null);

  const handleViewRecommendation = (scenario) => {
    navigate(`/carboncompete/scenarioplanning/analysis/${scenario.scenarioId}`, {
      state: { data: scenario.data, data2: scenario.scenarioId },
    });
  };


  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={forecastStatus === 'inProgress'}
      >
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: '100%', gap: 1 }}>
          <CircularProgress color="inherit" sx={{ textAlign: 'center', alignItems: 'center', display: "flex" }} />
          <Typography>Good things take time...</Typography>
        </Box>
      </Backdrop>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3} padding={3} sx={{ backgroundColor: "#F2FCFD" }}>
          <Grid item xs={12} md={4}>
            <FormControl
              fullWidth
              variant="filled"
              error={formik.touched.location && Boolean(formik.errors.location)}
            >
              <InputLabel id="location-label">Select Location</InputLabel>
              <Select
                labelId="location-label"
                id="location"
                name="location"
                value={formik.values.location}
                onChange={handleLocationChange}
                onBlur={formik.handleBlur}
                label="Select Location"
                sx={{ backgroundColor: "white" }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 180,
                      overflowY: "scroll",
                    },
                  }
                }}
              >
                <MenuItem value="" disabled>
                  Select Location
                </MenuItem>
                {locationData.map((location) => (
                  <MenuItem
                    key={location.locationId}
                    value={location.locationName}
                  >
                    {location.locationName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.touched.location && formik.errors.location}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl
              fullWidth
              variant="filled"
              error={formik.touched.scenario && Boolean(formik.errors.scenario)}
            >
              <InputLabel id="scenario-label">Scenario Type</InputLabel>
              <Select
                labelId="scenario-label"
                id="scenario"
                name="scenario"
                value={formik.values.scenario}
                onChange={handleScenarioTypeChange}
                onBlur={formik.handleBlur}
                label="Scenario Type"
                sx={{ backgroundColor: "white" }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 180,
                      overflowY: "scroll",
                    },
                  },
                }}
              >
                {scenarioData.map((type) => (
                  <MenuItem key={type.scenarioId} value={type.scenarioName}>
                    {type.scenarioName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.touched.scenario && formik.errors.scenario}
              </FormHelperText>
            </FormControl>
          </Grid>

          {showPercentageInput && (
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                id="emissionReductionPercentage"
                name="emissionReductionPercentage"
                label="Emission Reduction Percentage"
                value={formik.values.emissionReductionPercentage}
                onChange={handlePercentageChange}
                onBlur={formik.handleBlur}
                error={formik.touched.emissionReductionPercentage && Boolean(formik.errors.emissionReductionPercentage)}
                helperText={formik.touched.emissionReductionPercentage && formik.errors.emissionReductionPercentage}
                variant="filled"
                type="number"
                sx={{
                  '& .MuiFilledInput-root': { "backgroundColor": "white" },
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </Grid>
          )}

          <Grid item xs={12} md={4}>
            <FormControl
              fullWidth
              variant="filled"
              error={formik.touched.industry && Boolean(formik.errors.industry)}
            >
              <InputLabel id="industry-label">Industry Type</InputLabel>
              <Select
                labelId="industry-label"
                id="industry"
                name="industry"
                value={formik.values.industry}
                onChange={handleIndustryChange}
                onBlur={formik.handleBlur}
                label="Industry Type"
                sx={{ backgroundColor: "white" }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 180,
                      overflowY: "scroll",
                    },
                  },
                }}
              >
                {industryData.map((industry) => (
                  <MenuItem
                    key={industry.industryId}
                    value={industry.industryName}
                  >
                    {industry.industryName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.touched.industry && formik.errors.industry}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              id="target"
              name="target"
              label="Target"
              value={formik.values.target}
              onChange={handleTargetChange}
              onBlur={formik.handleBlur}
              error={formik.touched.target && Boolean(formik.errors.target)}
              helperText={formik.touched.target && formik.errors.target}
              variant="filled"
              type="number"
              sx={{
                '& .MuiFilledInput-root': { "backgroundColor": "white" },
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl
              fullWidth
              variant="filled"
              error={formik.touched.recommendation && Boolean(formik.errors.recommendation)}
            >
              <InputLabel id="recommendation-label">Recommendation Type</InputLabel>
              <Select
                labelId="recommendation-label"
                id="recommendation"
                name="recommendation"
                value={formik.values.recommendation}
                onChange={handleRecommendationChange}
                onBlur={formik.handleBlur}
                label="Recommendation Type"
                sx={{ backgroundColor: "white" }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 180,
                      overflowY: "scroll",
                    },
                  },
                }}
              >
                {recommendationData.map((recommendation) => (
                  <MenuItem
                    key={recommendation.recommendationId}
                    value={recommendation.recommendationName}
                  >
                    {recommendation.recommendationName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.touched.recommendation && formik.errors.recommendation}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                fontFamily: "Poppins",
                marginTop: "8px",
                height: "48px",
                backgroundColor: "#00C9D2",
                "&:hover": { backgroundColor: "#167266" },
              }}
            >
              Generate Analytics
            </Button>
          </Grid>
        </Grid>
      </form>
      {allScenarios && allScenarios.length > 0 && (
        <TableContainer
          component={Paper}
          sx={{
            marginTop: 4,
            padding: 4,
            width: "90%",
            marginLeft: 4,
            alignContent: "center",
            placeItems: "center"
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                {['Location', 'Scenario Type', 'Target Year', 'Reduction Percentage', 'Recommendation Type', 'Action'].map((header) => (
                  <TableCell
                    key={header}
                    sx={{
                      backgroundColor: "offwhite",
                      color: "black",
                      fontWeight: "bold",
                      border: '1px solid white',
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {allScenarios.map((scenario) => (
                <TableRow key={scenario.scenarioId}>
                  {[
                    scenario.location,
                    scenario.scenarioType,
                    scenario.targetYear,
                    scenario.reductionPercentage || 'N/A',
                    scenario.recommendationType
                  ].map((cell, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        border: '1px solid #ddd',
                      }}
                    >
                      {cell}
                    </TableCell>
                  ))}
                  <TableCell
                    sx={{
                      border: '1px solid #ddd',
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleViewRecommendation(scenario)}
                      sx={{
                        backgroundColor: 'white',
                        color: selectedScenario?.scenarioId === scenario.scenarioId ? 'red' : 'black',
                        borderColor: selectedScenario?.scenarioId === scenario.scenarioId ? 'red' : 'black',
                        border: "0.5px solid",
                        fontSize: "12px",
                        '&:hover': {
                          backgroundColor: 'white',
                          borderColor: selectedScenario?.scenarioId === scenario.scenarioId ? 'red' : 'black',
                        },
                      }}
                    >
                      View Recommendation
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
