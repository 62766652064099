import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  Tab,
  Tabs
} from '@mui/material';
import { styled } from '@mui/system';
import { MapContainer, TileLayer, Marker, Popup, useMap, Tooltip } from 'react-leaflet';
import axios from 'axios';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { getLocationData, postLocationData, putLocationData } from '../../services/corporatecanvas';
import { APP_KEYS } from '../../utils/constants/AppKeyConstants';
import markergif from '../Assets2/location.gif';
import { useSnackbar } from 'notistack';
import { debounce } from 'lodash';
import AddLocation from './AddLocation';
// import Configuration from './Configuration';
import { useNavigate } from 'react-router-dom';
import farm from '../../pages/Assets2/farm.png';
import warehouse from '../../pages/Assets2/warehouse.png';
import production from '../../pages/Assets2/production.png';
import packaging from '../../pages/Assets2/packaging.png';
import manufacturing from '../../pages/Assets2/manufacturing.png';
import transport from '../../pages/Assets2/transport.png'
import upstream from '../../pages/Assets2/upstream.png';
import others from '../../pages/Assets2/others.png';
import downstream from '../../pages/Assets2/downstream.png';
import franchise from '../../pages/Assets2/franchise.png'
import { makeStyles } from '@material-ui/core';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const CenterMap = ({ position }) => {
  const map = useMap();
  map.setView(position, 6); // ZOOM LEVEL OF MAP
  return null;
};

const locationTypes = [
  {
    "name": 'Production',
    "icon": production,
  },
  {
    "name": 'Warehouse',
    "icon": warehouse,
  },
  {
    "name": 'Upstream Leased Assets',
    "icon": upstream,
  },
  {
    "name": 'Packaging',
    "icon": packaging,
  },
  {
    "name": 'Transport',
    "icon": transport,
  },
  {
    "name": 'Factory',
    "icon": manufacturing,
  },
  {
    "name": 'Others',
    "icon": others,
  },
  {
    "name": 'Downstream leased assets',
    "icon": downstream,
  },
  {
    "name": 'Franchise',
    "icon": franchise,
  }
];

const getCustomIcon = (type) => {
  const icon = locationTypes.find((icon) => icon.name === type)?.icon || others;
  return L.icon({
    iconUrl: icon,
    iconSize: [52, 52],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });
};

const StyledTabs = styled(Tabs)({
  position: "relative",
  '& .MuiTabs-flexContainer': {
    justifyContent: 'center',
  },
  '& .MuiTab-root': {
    color: '#000',
    '&.Mui-selected': {
      color: '#00C9D2',
      fontWeight: 'bold',
    },
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#00C9D2',
    height: '7px',
    width: '164px !important',
    bottom: '-2px',
    borderRadius: '3.5px',
    zIndex: 10,
  },
  paddingTop: 2,
});

const StyledTab = styled(Tab)({
  textTransform: 'none',
  minWidth: 164,
  marginInline: 28,

  fontFamily: 'Poppins, sans-serif',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#000000',

});

const TabContainer = styled(Box)({
  borderBottom: '1px solid black',
  position: 'relative',
  zIndex: 1
});

export const CorporateCanvas = () => {

  const useStyles = makeStyles((theme) => ({
    customTooltip: {
      backgroundColor: '#149BA1',
      color: '#fff',
      boxShadow: theme.shadows[1],
      fontSize: '13px',
      fontWeight: 'bold',
      padding: '4px 8px',
      borderRadius: '4px',
      fontFamily: 'poppins'
    },
  }));

  const classes = useStyles();
  const userInfo = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));
  const { enqueueSnackbar } = useSnackbar();

  const [locations, setLocations] = useState([]);
  const [center, setCenter] = useState([51.505, -0.09]);
  const [open, setOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [fetchedLocations, setFetchedLocations] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [locationType, setLocationType] = useState()
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    async function fetchLocations() {
      try {
        const response = await getLocationData(userInfo?.compId);
        if (response.success) {
          setFetchedLocations(response.data);
          if (response.data.length > 0 && response.data[0].coordinates) {
            setCenter(response.data[0].coordinates);
          }
        } else {
          console.error("Error fetching location data:", response.msg);
        }
      } catch (error) {
        console.error("Error fetching location data:", error);
      }
    }
    fetchLocations();
  }, [userInfo?.compId]);

  useEffect(() => {
    const newLocations = fetchedLocations
      .filter(location => location.coordinates)
      .map(location => ({
        id: location.locationId,
        name: location.locationName,
        position: location.coordinates,
        type: location.locationType,
        supplierId: location?.supplierId || 0,
        configuredTemplate: location.configuredTemplate
      }));
    setLocations(newLocations);
  }, [fetchedLocations]);


  const handleSearch = debounce(async (searchTerm) => {
    if (!searchTerm) {
      setLocations([]);
      return;
    }

    try {
      const response = await axios.get('https://nominatim.openstreetmap.org/search', {
        params: {
          q: searchTerm,
          format: 'json',
          addressdetails: 1,
          limit: 10, // Limit the number of results to ensure only the most relevant are shown
        },
      });

      let newLocations = response.data.map((result, index) => ({
        id: index,
        name: result.display_name,
        position: [parseFloat(result.lat), parseFloat(result.lon)],
      }));

      // Filter or sort to prioritize exact matches or those starting with the search term
      newLocations = newLocations.filter((location) =>
        location.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setLocations(newLocations);
      if (newLocations.length > 0) {
        setCenter(newLocations[0].position);
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  }, 200); // Keep debounce delay short for responsiveness


  const handleMarkerClick = (location) => {
    setSelectedLocation(location);
    navigate(`/carboncompete/corporatecanva/configpage/${location.id}?name=${encodeURIComponent(location.name)}`, { state: { selectedLocation: location } });
  };

  const handleCloseForm = () => {
    setOpen(false);
    setSelectedLocation(null);
  };

  const handleSaveLocation = async () => {


    if (selectedLocation) {
      // Check if location already exists based on coordinates
      const locationExists = fetchedLocations.some(loc =>
        loc.coordinates[0] === selectedLocation.position[0] &&
        loc.coordinates[1] === selectedLocation.position[1]
      );

      if (locationExists) {
        enqueueSnackbar('Location already exists!', { variant: 'error' });
        setLocations(fetchedLocations.map(location => ({
          id: location.locationId,
          name: location.locationName,
          position: location.coordinates,
        })));
        handleCloseForm();
        return;
      }
      if (!selectedLocation || !locationType) {
        console.error('Location or location type not selected.');
        return;
      }

      const firstWord = selectedLocation.name.split(',')[0].trim();
      const payload = {
        locationName: firstWord,
        shortName: firstWord,
        coordinates: selectedLocation.position,
        isActive: true,
        userId: userInfo?.userId,
        compId: userInfo?.compId,
        locationType: locationType?.name || 'Sending as null',
      };

      try {
        const response = await postLocationData(payload);
        if (response.success) {
          enqueueSnackbar('Location saved successfully!', { variant: 'success' });
          // Fetching the updated locations after saving the location
          const updatedResponse = await getLocationData(userInfo?.compId);
          if (updatedResponse.success) {
            setFetchedLocations(updatedResponse.data);
            setLocations(updatedResponse.data.map(location => ({
              id: location.locationId,
              name: location.locationName,
              position: location.coordinates,
            })));
          }
        } else {
          enqueueSnackbar(`Error: ${response.msg}`, { variant: 'error' });
        }
      } catch (error) {
        console.error("Error saving location:", error);
        enqueueSnackbar('Location already exist', { variant: 'error' });
      }

      handleCloseForm();
    }
  };

  console.log(locationType?.name)

  const isLocationSaved = selectedLocation && fetchedLocations.some(loc =>
    loc.coordinates[0] === selectedLocation.position[0] &&
    loc.coordinates[1] === selectedLocation.position[1]
  );

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1, width: '600px' }}>
        <Autocomplete
          sx={{ width: '300px' }}
          options={locations}
          getOptionLabel={(option) => option.name}
          onInputChange={(event, newInputValue) => {
            setSearchTerm(newInputValue);
            handleSearch(newInputValue);
          }}
          onChange={(event, newValue) => {
            setSelectedLocation(newValue);
            if (newValue) {
              setCenter(newValue.position);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search for a location"
              variant="outlined"
              sx={{ marginRight: 2, flexGrow: 1 }}
            />
          )}
        />

        <Autocomplete
          sx={{ width: '200px', marginLeft: 2 }}
          options={locationTypes}
          getOptionLabel={(option) => option.name}
          onChange={(event, newValue) => {
            setLocationType(newValue);
            console.log('Location Type Selected:', newValue); // Debugging line to check what is selected
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Location Type"
              variant="outlined"
            />
          )}
        />

        <Button
          onClick={handleSaveLocation}
          variant="contained"
          color="primary"
          sx={{ marginLeft: 2 }}
          disabled={!selectedLocation || !locationType} // Enable button only if both location and type are selected
        >
          Save
        </Button>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <MapContainer center={center} zoom={6} style={{ height: '100%', width: '100%' }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {locations.map((location) => (
            <Marker
              key={location.id}
              position={location.position}
              icon={getCustomIcon(location.type)}
              eventHandlers={{
                click: () => handleMarkerClick(location),
              }}
            >
              <Tooltip className={classes.customTooltip}>
                <div>Location : {location.name}</div>
                <div>Type : {(location.type) || 'Not assigned'}</div>
              </Tooltip>
              <Popup>{location.name}</Popup>
            </Marker>
          ))}
          <CenterMap position={center} />
        </MapContainer>
        <style>
          {`
            .moving-line {
              stroke-dasharray: 10, 10;
              stroke-dashoffset: 0;
              animation: dash 1s linear infinite;
            }
            @keyframes dash {
              to {
                stroke-dashoffset: -1000;
              }
            }
          `}
        </style>
      </Box>

      
      <Dialog open={open} onClose={handleCloseForm} maxWidth="md" fullWidth sx={{ '& .MuiDialog-paper': { height: '720px' } }} >
        <TabContainer>
          <StyledTabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="dialog tabs"
            sx={{ paddingX: 4 }}
          >
            <StyledTab label="Add Location" />
            <StyledTab label="Configuration" />
            <StyledTab label="Activity Inputs" />
          </StyledTabs>
        </TabContainer>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            {activeTab === 0 && <AddLocation location={selectedLocation} />}
            {/* {activeTab === 1 && <Configuration />} */}
            {/* {activeTab === 2 && <ActivityInput />} */}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};