import React, { useEffect, useState } from 'react';
import { Box, Grid, Card, CardContent, Typography, CardHeader, CardActionArea } from '@mui/material';
import { styled } from '@mui/system';
import { Grow } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CustomCard = styled(Card)(({ theme }) => ({
  borderRadius: '6px',
  boxShadow: '0 6px 10px 0 rgba(0, 0, 0, 0.06)',
  transition: 'transform 0.2s',
  border: '1px solid #306E91',
  '&:hover': {
    boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.3, 0.5)',
    transform: 'scale(1.02)',
  },
  height: "180px",
}));

const cardHeading = { color: '#306E91', fontSize: '16px', textTransform: 'uppercase', fontWeight: 600 };
const cardYear = { color: 'rgba(0, 0, 0, 0.6)', fontSize: '16px' };
const cardValue = { color: '#000', fontSize: '14px' };

const scopeLabels = {
  scope1: 'Scope 1',
  scope2: 'Scope 2',
  scope3: 'Scope 3'
};

export const ScopeCards = ({ scopeData }) => {
  const [emissionsData, setEmissionsData] = useState(null);
  const [hoveredScope, setHoveredScope] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setEmissionsData(scopeData);
  }, [scopeData]);

  if (!emissionsData) {
    return <Typography>Loading...</Typography>;
  }

  const formatFiscalYear = (year) => {
    const startYear = parseInt(year, 10);
    const endYear = startYear + 1;
    return `FY${startYear}-${endYear.toString().slice(-2)}`;
  };

  const handleCardClick = (scope) => {
    navigate(`/carboncompete/corporatedashboard/scopewiseinfo/${scope}`);
  };

  const renderScopeCard = (scope) => (
    <CustomCard
      elevation={hoveredScope === scope ? 10 : 3}
      onMouseEnter={() => setHoveredScope(scope)}
      onMouseLeave={() => setHoveredScope(null)}
      onClick={() => handleCardClick(scope)}
    >
      <CardActionArea>
        <CardHeader
          title={<Typography sx={cardHeading}>{scopeLabels[scope]} Emissions</Typography>}
          titleTypographyProps={{
            fontSize: 16,
            fontWeight: 600,
            color: 'rgba(105, 135, 255, 1)',
          }}
        />
        <CardContent
          sx={{
            height: '135px',
            overflowY: 'scroll',
            scrollbarWidth: 'thin',
            padding: 0,
          }}
        >
          {Object.entries(emissionsData[scope]).map(([year, value]) => (
            <CardHeader
              key={year}
              sx={{ paddingTop: 0.2 }}
              title={
                <>
                  <Typography sx={cardValue}>
                    {(value).toFixed(2)}
                  </Typography>
                  <Typography sx={cardYear}>
                    {formatFiscalYear(year)}
                  </Typography>
                </>
              }
              titleTypographyProps={{ fontSize: 16, fontWeight: 700 }}
            />
          ))}
        </CardContent>
      </CardActionArea>
    </CustomCard>
  );

  return (
    <Box sx={{ flexGrow: 1, fontFamily: 'Poppins, sans-serif' }}>
      <Grid container spacing={3} justifyContent="center">
        <Grow in={true} timeout={1000}>
          <Grid item xs={12} md={4}>
            {renderScopeCard('scope1')}
          </Grid>
        </Grow>
        <Grow in={true} timeout={1500}>
          <Grid item xs={12} md={4}>
            {renderScopeCard('scope2')}
          </Grid>
        </Grow>
        <Grow in={true} timeout={2000}>
          <Grid item xs={12} md={4}>
            {renderScopeCard('scope3')}
          </Grid>
        </Grow>
      </Grid>
    </Box>
  );
};
