import axios from "./axiosInstance";

const API_URL = process.env.REACT_APP_API_URL;

export const addNewClient = async ({
    userName,
    userEmail,
    userMobile,
    compId
  }) => {
    try {
      const resp = await axios({
        url: `${API_URL}/api/v1/add-user`,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        data:
        {
          userName: userName,
          userEmail: userEmail,
          userPwd: 'Abcd@1234.',
          userMobile: userMobile,
          compId: compId,
          roles: [
            {
              roleId: 2,
              roleName: "Admin"
            }
          ],
          subs: [
            {
              subsId: 3,
              subsName: "Enterprise"
            }
          ],
          "isActive": true
        }
      });
      return resp.data;
    } catch (err) {
      console.log(err);
      return { message: 'error' };
    }
  };

  export const editClient = async ({ userId, userName, userEmail, userMobile }) => {
  try {
    await axios({
      url: `${API_URL}/api/v1/user`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        userId: userId,
        userName: userName,
        userEmail: userEmail,
        userMobile: userMobile,
        compId: 1,
        roles: [
          {
            roleId: 2,
            roleName: "Admin"
          }
        ],
        subs: [
          {
            subsId: 3,
            subsName: 'Enterprise'
          }
        ],
        isActive: true
      },
    });
    return { success: 'supplier_updated' };
  } catch (err) {
    console.log(err);
    return { error: err.message };
  }
};

  export const getAllClients = async () => {
    try {
      const roleId = 2;
      const response = await axios({
        url: `${API_URL}/api/v1/user`,
        method: 'GET',
        headers: {
        },
        params: {
          roleId
        },
      });
      return response.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  
  };