import React, { useEffect, useState } from "react";
import {
    Grid,
    Card,
    Container,
    Typography,
    Button,
    FormControl,
    Select,
    MenuItem,
    CardContent,
    CardHeader, Avatar
} from "@mui/material";

import EChartsReact from "echarts-for-react";

import {
  BarChart,
  LineChart,
  PieChart,
} from 'echarts/charts';

import CheckIcon from '@mui/icons-material/Check';
import { useFormik } from "formik";
import ad_img from "../Assets2/ad_img.png";
import { makeStyles } from "@material-ui/core";
import { Helmet } from "react-helmet-async";

import { getSupplierWiseEmissions } from "../../services/analytics";
import { useNavigate } from "react-router-dom";
import { APP_KEYS } from "../../utils/constants/AppKeyConstants";

import CloseIcon from '@mui/icons-material/Close';
import Iconify from "../../components/iconify";
const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0 20px',
    },
    confidenceContainer: {
        height: '100%',
        paddingTop: '25px',
        background: '#fff',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.1)',
        borderRadius: '24px',
        border: '0.5px solid #D8D8D8',
        boxShadow: '0px 30px 60px -30px rgba(0, 0, 0, 0.30), 0px 50px 100px -20px rgba(50, 50, 93, 0.25)',
        display: 'flex',
        gap: '10px',
        flexDirection: "column",
        // alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap',
        },
    },
    container: {
        height: '100%',
        paddingTop: '25px',
        background: '#fff',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.1)',
        borderRadius: '24px',
        border: '0.5px solid #D8D8D8',
        boxShadow: '0px 30px 60px -30px rgba(0, 0, 0, 0.30), 0px 50px 100px -20px rgba(50, 50, 93, 0.25)'
    },
    adContainer: {
        borderRadius: '10px',
        background: '#F4F4F4',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
        height: '17rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100% !important',
        },
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap',
        },
    },
    title: {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        fontSize: '20px',
        marginBottom: theme.spacing(1),
        color: '#333',
    },
    adImg: {
        width: '40%',
        height: '60%',
        [theme.breakpoints.down('sm')]: {
            width: '100% !important',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100% !important',
        },
    },
    titleText: {
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        fontSize: '17px',
    },
    link: {
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        fontSize: '14px',
    },
    linkText: {
        textDecoration: 'none',
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        fontSize: '14px',
        color: '#149BA1',
    },
    messageContainer: {
        display: 'flex',
        justifyContent: 'center',
        fontFamily: 'Poppins',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        width: "100%",
        alignItems: 'center'
    }
}));

export default function SuperAdminDashboard(props) {
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));
    const classes = useStyles();
    const [timeOfDay, setTimeOfDay] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const productData = [
        {
            products: [
                { productId: 1, productName: 'Product 1' },
                { productId: 2, productName: 'Product 2' },
                { productId: 3, productName: 'Product 3' },
            ],
        },
    ];

    const pieChartData=[
            {value: 205, name: 'Basic Supplier',itemStyle:{color:'#0BDB7B'}},
            { value: 198, name: 'Enterprise Supplier',itemStyle:{color:'#FFA058'} },
            { value: 15, name: 'Enterprise',itemStyle:{color:'#006799'}},
    ]

    const [selectedProduct, setSelectedProduct] = useState(productData[0].products[0].productName);

    const handleChange = (event) => {
        setSelectedProduct(event.target.value);
    };

    // Dummy product data

    const formik = useFormik({
        initialValues: {
            scope: "Yogurt",
            duration: "daily",
        },
    });

    const [highestScoreProduct, setHighestScoreProduct] = useState(null);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));
        const email = user?.userEmail;

        async function fetchBrandsData() {
            try {
                const response = await getSupplierWiseEmissions(email);
                if (response.success) {
                    //   setProductData(response.data);

                    const products = response.data[0]?.products;
                    if (products && products.length > 0) {
                        const highestScoreProduct = products.reduce((prev, current) =>
                            prev.stage_carbon_score > current.stage_carbon_score ? prev : current
                        );
                        setHighestScoreProduct(highestScoreProduct);
                    }
                } else {
                    console.error('Error fetching products data:', response.msg);
                }
            } catch (error) {
                console.error('Error fetching products data:', error);
            }
        }
        fetchBrandsData();
    }, []);


    useEffect(() => {
        setShowLoader(true);
        setTimeout(() => {
            setShowLoader(false);
        }, 1000);
    }, [formik.values.scope]);

    
    // Function : show time of day and greeting
    const updateGreeting = () => {
        const currentHour = new Date().getHours();
        let greeting = "";

        if (currentHour >= 5 && currentHour < 12) {
            greeting = "Good morning";
        } else if (currentHour >= 12 && currentHour < 17) {
            greeting = "Good afternoon";
        } else {
            greeting = "Good evening";
        }

        setTimeOfDay(greeting);
    };

    useEffect(() => {
        updateGreeting();

        const interval = setInterval(updateGreeting, 60000);
        return () => clearInterval(interval);
    }, []);

    const isProductAvailable = highestScoreProduct && highestScoreProduct.productId;

    return (
        <div className={classes.root}>
            <Helmet>
                <title> Super Admin Dashboard </title>
            </Helmet>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} style={{ padding: "20px" }}>
                    <Container className={classes.confidenceContainer} >


                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                            <Typography
                                sx={{
                                    color: '#222',
                                    fontFamily: 'Poppins',
                                    fontSize: '16px',
                                    fontWeight: '600',
                                }}
                                variant="body1"
                                color="textSecondary"
                                component="div"
                            >
                                Demo Requests
                            </Typography>

                            <FormControl>
                                <Select
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 180,
                                                width: '300px',
                                                overflowY: 'scroll',
                                            },
                                        },
                                    }}
                                    style={{
                                        width: '100%', height: '42px', backgroundColor: '#F1F1F1',
                                        borderRadius: '8px',
                                        border: 'none !important',
                                        '&:focus': {
                                            border: 'none',
                                        },
                                    }}
                                    value={selectedProduct} onChange={handleChange}
                                >

                                    {productData[0]?.products.map((product) => (
                                        <MenuItem key={product.productId} value={product.productName}>
                                            {product.productName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>


                        <div
                            style={{
                                overflowY: "scroll",  // Vertical scrollbar
                                overflowX: "hidden", 
                                maxHeight: "370px",
                                scrollbarColor: "#149BA1 #fff",  // The first color is for the thumb, the second one is for the track
                                       // You can use "auto" or "thin" based on your preference
                                gap: "10px",
                            }}
                        >
                            <CardUI />
                            <CardUI />
                            <CardUI />
                            <CardUI />
                        </div>

                    </Container>
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "20px" }}>
                    <Container className={classes.confidenceContainer}>
                        {/* <CarbonScoreChart productData={productData} /> */}
                        <Typography
                                sx={{
                                    color: '#222',
                                    fontFamily: 'Poppins',
                                    fontSize: '16px',
                                    fontWeight: '600',
                                    
                                }}
                                variant="body1"
                                color="textSecondary"
                                component="div"
                            >
                                Concurrent users
                            </Typography>
                            <div style={{display:"flex",gap:"30px"}}>
                                <div style={{flex:1,height:"336px",display:"flex",flexDirection:"column",justifyContent:"space-evenly"}}>
                                    {pieChartData.map((data)=>(
                                        <div style={{padding:"15px",border:`1px solid ${data.itemStyle.color}`,display:"flex",justifyContent:"center",alignItems:"center",gap:"20px",borderRadius:"4px"}}>
                                            <div style={{paddingTop:"17px",paddingBottom:"17px",paddingLeft:"29px",paddingRight:"29px",borderRadius:"4px",borderTop:`2px solid ${data.itemStyle.color}`,borderBottom:`2px solid ${data.itemStyle.color}`}}>
                                                {data.value}
                                            </div>
                                            <h1 style={{fontSize:"14px",fontWeight:"400",fontFamily:"Poppins"}}>{data.name}</h1>
                                        </div>
                                    ))}
                                </div>
                                <div style={{flex:5}}>
                                    <ConcurrentUsersPieCHart data={pieChartData}/>
                                </div>
                            </div>
                            
                    </Container>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} style={{ padding: "20px"}}>
                    <Container className={classes.confidenceContainer} >


                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                            <Typography
                                sx={{
                                    color: '#222',
                                    fontFamily: 'Poppins',
                                    fontSize: '16px',
                                    fontWeight: '600',

                                }}
                                variant="body1"
                                color="textSecondary"
                                component="div"
                            >
                                Support Requests
                            </Typography>

                            <FormControl>
                                <Select
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: "250px",
                                                width: '300px',
                                                overflowY: 'scroll',
                                            },
                                        },
                                    }}
                                    style={{
                                        width: '100%', height: '42px', backgroundColor: '#F1F1F1',
                                        borderRadius: '8px',
                                        border: 'none !important',
                                        '&:focus': {
                                            border: 'none',
                                        },
                                    }}
                                    value={selectedProduct} onChange={handleChange}
                                >

                                    {productData[0]?.products.map((product) => (
                                        <MenuItem key={product.productId} value={product.productName}>
                                            {product.productName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>


                        <div style={{
                                overflowY: "scroll",  // Vertical scrollbar
                                overflowX: "hidden", 
                                maxHeight: "400px",
                                scrollbarColor: "#149BA1 #fff",  // The first color is for the thumb, the second one is for the track
                                       // You can use "auto" or "thin" based on your preference
                                gap: "10px",
                            }}>
                            <CardUI />
                            <CardUI />
                            <CardUI />
                            <CardUI />
                        </div>
                    </Container>
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "20px" }}>
                    <Container className={classes.confidenceContainer}>
                            <Typography
                                sx={{
                                    color: '#222',
                                    fontFamily: 'Poppins',
                                    fontSize: '16px',
                                    fontWeight: '600',

                                }}
                                variant="body1"
                                color="textSecondary"
                                component="div"
                            >
                                New Registration
                            </Typography>
                            <NewRegistrationUi/>
                    </Container>
                </Grid>
            </Grid>
        </div>
    );
}


const CardUI = () => {
    return (
        <Card style={{ display: "flex", padding: "20px",gap:"20px",margin:"20px" }}>
            <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '10px',
                    flex: '1',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                //   avatar={<Avatar src="url-to-avatar-image" />}
            >
                <Avatar src=""/>
                <Typography variant="h5" component="div" style={{ fontSize: "18px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                    Anmol
                </Typography>

            </div>
            <div style={{ flex: '3', display: 'flex', flexDirection: 'column',justifyContent:"space-evenly" }}>
                <Typography variant="h5" component="div" style={{ fontSize:"14px"}}>
                    Anmol@carboncompete.com
                </Typography>
                <Typography color="text.secondary" >
                    9988776655
                </Typography>
             
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px', flex: '2' }}>
                <button  style={{ height: "38px", width: "38px", borderRadius: "50px",display:"flex",alignItems:"center",justifyContent:"center",border:"3px solid #49BC85",cursor:"pointer" }}>
                    <CheckIcon style={{text:"10px",color:"#49BC85",fontWeight:"900"}}/> 
                </button>
                <button  style={{ height: "38px", width: "38px", borderRadius: "50px",display:"flex",alignItems:"center",justifyContent:"center",border:"3px solid #FA3F3F",cursor:"pointer" }}>
                    <CloseIcon style={{text:"10px",color:"#FA3F3F",fontWeight:"900"}}/> 
                </button>
            </div>
            {/* <CardContent style={{display:"flex",flex:'5'}}>
                
            </CardContent> */}
        </Card>
    )
}

const NewRegistrationUi=()=>{
    var option = {
        xAxis: {
          type: 'category',
          data: [1, 2, 3, 5, 8, 10],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            type: 'line',
            data: [2, 5.5, 2, 8.5, 1.5, 5],
            label: {
                show: true,
                position: 'top',
                formatter: '{b}: {c}', // {b} represents the category, {c} represents the data value
            },
            emphasis: {
                label: {
                  show: true,
                  fontSize: 14,
                  fontWeight: 'bold',
                },
              },
          },
          {
            type: 'line',
            data: [1, 3, 4, 6, 8, 10],
            label: {
                show: true,
                position: 'top',
                formatter: '{b}: {c}',
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 14,
                  fontWeight: 'bold',
                },
              },
          },
          {
            type: 'line',
            data: [10,9,8,7,6,5],
            label: {
                show: true,
                position: 'top',
                formatter: '{b}: {c}',
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 14,
                  fontWeight: 'bold',
                },
              },
          },
          
        ],
      };
      
    return(
        <EChartsReact style={{ height: '400px' }} option={option} />
    )
}

const ConcurrentUsersPieCHart=({data})=>{
    const options=
        {
            tooltip: {
              trigger: 'item',
            //   formatter: '{a} <br/>{b} : {c} ({d}%)',
            },
            legend: {
              show: false,
              top: '5%',
              left: 'center',
            },
            color: ['#149BA1', '#FFC950', '#FFA058', '#006799', '#DAF7A6', '#CCCCFF'],
            series: [
              {
                name: 'Stage Carbon Scores',
                type: 'pie',
                radius: ['70%', '40%'],
                avoidLabelOverlap: false,
                itemStyle: {
                  borderRadius: 5,
                  borderColor: '#fff',
                  borderWidth: 3,
                },
                label: {
                    show: false,
                    position: 'center',
                    formatter: ({ dataIndex }) => data[dataIndex]?.name || 'null',
                    color: ({ dataIndex }) => data[dataIndex]?.labelColor || 'red', // Dynamic label color
                  },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: 14,
                    fontWeight: 'bold',
                  },
                },
                labelLine: {
                  show: false,
                },
                data:data,
              },
            ],
    }
    
    
    
    return(
        <EChartsReact style={{ height: '400px' }} option={options} />
    )
}
