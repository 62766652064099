import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import auth_bg from '../../pages/Assets2/auth-bg.png';
import { LoginForm } from './login/LoginForm';
import { RegisterForm } from './register/RegisterForm';
import { makeStyles } from '@material-ui/core';
import { ForgotPassword } from './forgotPass/ForgotPassword';
import { APP_KEYS } from '../../utils/constants/AppKeyConstants';

const useStyles = makeStyles((theme) => ({
    authContainer: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${auth_bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.down('sm')]: {
            background: 'none',
        },
    },
    authSection: {
        width: '34%',
        padding: '50px',
        paddingBottom: '40px',
        backgroundColor: '#fff',
        [theme.breakpoints.down('sm')]: {
            width: '50%',
            padding: theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}));

export default function AuthWrapper() {
    const classes = useStyles();
    const navigate = useNavigate();

    const [authNavigation, setAuthNavigation] = useState(0);
    const [useCase, setUseCase] = useState(null);

    // Redirect to dashboard if user is already logged in
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));
        if (user) {
            navigate('/carboncompete/dashboard');
        }
    }, [navigate]);

    return (
        <div className={classes.authContainer}>
            <div className={classes.authSection}>
                {authNavigation === 0 ? (
                    <LoginForm setUseCase={setUseCase} setAuthNavigation={setAuthNavigation} />
                ) : authNavigation === 1 ? (
                    <RegisterForm useCase={useCase} setAuthNavigation={setAuthNavigation} />
                ) : (
                    <ForgotPassword useCase={useCase} setAuthNavigation={setAuthNavigation} />
                )}
            </div>
        </div>
    );
}
