import React, { memo, useEffect, useState } from "react";
import { Handle } from "reactflow";
import styled from "styled-components";
import {
  Container,
  Typography,
  Stack,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import { ICONLIB } from "./IconLib";

const Node = styled.div`
  padding: 5px 5px;
  height: 110px;
  width: 110px;
  border-radius: 10px;
  border: none;
  box-shadow: 0 0 20px -5px;
  background-color: #159ba1;

  .react-flow__handle {
    width: 8px;
    height: 8px;
    border: none;
    background-color: transparent;
  }
`;

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    height: 300,
    width: 300,
    left: -500,
    marginLeft: "-100px !important",
    paddingLeft: "125px",
    color: "black",
  },
}));

export default memo((node) => {
  const { id, data = {}, isConnectable, selected } = node;

  const [selectMenus, setMenu] = useState(false);
  const [showAddIcon, setShowAddIcon] = useState(false);

  const [factorOptions, setFactorOptions] = useState([]);

  const toggleAddButton = (e) => {
    setShowAddIcon(!showAddIcon);
    e.preventDefault();
  };

  useEffect(() => {
    const { factors = {} } = data;
    const factorOptions = [];

    for (let [key, value] of Object.entries(factors)) {
      factorOptions.push({
        name: key,
        value: value,
      });
    }
    setFactorOptions(factorOptions);
  }, [data]);

  const onNodeOptionClick = (e, optIndex) => {
    console.log(factorOptions[optIndex]?.name);
    setMenu(false);
    e.stopPropagation();
  };

  return (
    <Node
      onMouseEnter={toggleAddButton}
      onClick={() => setMenu(true)}
      onMouseLeave={() => setMenu(false) && setShowAddIcon(false)}
      selected={selectMenus}
    >
      <Stack spacing={1} pt={3} pr={2}>
        <Container>
          <span style={{ background: "#c9e5e9", boxShadow: "0 0 15px -5px" }}>
            {ICONLIB[data?.metaData?.icon] || ICONLIB["default"]}
          </span>
        </Container>

        <Container>
          <Typography mt={3}>{data?.metaData?.name}</Typography>
        </Container>
      </Stack>

      <HtmlTooltip
        open={selectMenus}
        title={
          <React.Fragment>
            <div className="cicleCenter">
              {factorOptions.map((opt, optIndex) => {
                return (
                  <div
                    key={"popup_button_" + optIndex}
                    className={"circle"}
                    style={{
                      transform: `rotate(${350 + 45 - (optIndex * (360 / factorOptions.length))}deg) translate(140px) rotate(-${350 + 45 - (optIndex * (360 / factorOptions.length))}deg)`
                    }}
                    onClick={(e) => onNodeOptionClick(e, optIndex)}
                  >
                    {/* <Category sx={{color: '#fff'}}/> */}
                    <p
                      style={{
                        textAlign: "center",
                        marginTop: "-14px",
                        color: "#000",
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {parseFloat(opt.value).toFixed(0)}
                    </p>

                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      marginTop={"-40px"}
                      className="hideFactorName"
                    >
                      {opt.name}
                    </Typography>
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        }
        placement="right"
      ></HtmlTooltip>

      <Handle
        type="source"
        id="source-top"
        position="top"
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        id="source-bottom"
        position="bottom"
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        id="source-right"
        position="right"
        isConnectable={isConnectable}
      />
    </Node>
  );
});
