import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import auth_bg from '../../../pages/Assets2/auth-bg.png';
import { makeStyles } from '@material-ui/core';
import { ResetForm } from './ResetForm';

const useStyles = makeStyles((theme) => ({
    authContainer: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${auth_bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.down('sm')]: {
            background: 'none',
        },
    },
    authSection: {
        width: '34%',
        padding: '50px',
        paddingBottom: '40px',
        backgroundColor: '#fff',
        [theme.breakpoints.down('sm')]: {
            width: '50%',
            padding: theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}));

export const ResetPassword = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <div className={classes.authContainer}>
            <div className={classes.authSection}>
                <ResetForm/>
            </div>
        </div>
    );
}
