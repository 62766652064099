import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { APP_KEYS } from "../../utils/constants/AppKeyConstants";
import { getEsgData, getMonthlyScopeData } from "../../services/esgdata";
import { ThemeProvider } from "styled-components";
import ScopeCards from "./InternalCharts/ScopeInfoCharts/ScopeCards";
import { createTheme } from "@mui/material";

export default function ScopeInfo() {
  const theme = createTheme({
    typography: {
      fontFamily: "Poppins !important",
      fontWeight: "bolder !important",
    },
  });

  const { scopeName } = useParams();
  const [esgMonthlyData, setEsgMonthlyData] = useState(null);
  const [esgData, setEsgData] = useState(null);
  const userData = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));
  const compId = userData?.userId;

  useEffect(() => {
    const fetchEsgData = async () => {
      try {
        const response = await getMonthlyScopeData(compId);
        if (response.success) {
          setEsgMonthlyData(response.data);
        } else {
          console.log("Error fetching ESG Data", response.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchEsgData();
  }, [compId]);

  useEffect(() => {
    const fetchEsgData = async () => {
      try {
        const response = await getEsgData(compId);
        if (response.success) {
          setEsgData(response.data);
        } else {
          console.log("Error fetching ESG Data", response.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchEsgData();
  }, [compId]);

  return (
    <ThemeProvider theme={theme}>
      {esgMonthlyData && esgData && (
        <ScopeCards
          data={esgMonthlyData}
          scopeName={scopeName}
          esgData={esgData}
        />
      )}
    </ThemeProvider>
  );
}
