import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  TextField,
  Grid,
  createTheme,
  ThemeProvider
} from '@mui/material';
import { putLocationData } from '../../services/corporatecanvas';

// Define the theme
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif',
    h5: {
      fontWeight: 'bold',
      color: '#333',
    },
    subtitle1: {
      fontWeight: 'bold',
      color: '#555',
    },
  }
});

export const Scope3Initial = ({ onNext, location, categorySelections, isNextDisabled, setCategorySelections, setIsNextDisabled, setScopeCategories }) => {
  const scope3Categories = [
    "Purchased Goods and Services",
    "End of life treatment of sold products",
    "Use of Sold Products",
    "Processing of Sold Products",
    "Downstream Transportation distribution",
    "Employee Commute",
    "Upstream Transportation distribution",
    "Business Travel",
    "Waste"
]

  useEffect(() => {
    const allFilled = scope3Categories.every(category => {
      const selection = categorySelections[category];
      return selection && selection.selected && (selection.selected === 'Yes, Data Available' || (selection.selected === 'Yes, Data Not Available' && selection.reason) || (selection.selected === 'Not Applicable' && selection.reason));
    });
    setIsNextDisabled(!allFilled);
  }, [categorySelections]);

  const handleCategoryChange = (category, value) => {
    setCategorySelections(prev => ({
      ...prev,
      [category]: {
        ...prev[category],
        selected: value,
        reason: value === 'Yes, Data Available' ? null : (prev[category]?.reason || '')
      }
    }));
  };

  const handleReasonChange = (category, reason) => {
    setCategorySelections(prev => ({
      ...prev,
      [category]: {
        ...prev[category],
        reason
      }
    }));
  };

  const handleNext = async () => {
    const payload = {
      locationId: location.id,
      scope3Disclosure: categorySelections
    };

    const result = await putLocationData(payload);

    const selectedCategories = Object.entries(categorySelections)
      .filter(([_, value]) => value.selected === 'Yes, Data Available')
      .map(([category, _]) => category);

    setScopeCategories((prev) => {
      Object.keys(prev["Scope 3"]?.categories).forEach((key) => {
        if (!selectedCategories.includes(key)) {
          delete prev["Scope 3"].categories[key];
        }
      });

      return prev;
    });

    onNext(selectedCategories, categorySelections);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        position: 'relative',
        height: '100%',
        overflow: 'auto',
        padding: '0 20px 40px 30px',
        bgcolor: '#f9f9f9'
      }}>
        {/* Floating Next Button */}


        {/* Form Title */}
        <Typography textAlign={'center'} variant="h5" sx={{ mb: 3 }}>
          Please disclose the applicable Scope 3 categories for {location.name}
        </Typography>
        <Box
          sx={{
            position: 'sticky',
            top: 16,
            zIndex: 1000,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={isNextDisabled}
            sx={{
              borderRadius: '20px',
              textTransform: 'none',
              boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
              px: 4,
              py: 1,
              bgcolor: 'rgb(20, 155, 161)',
              '&:hover': {
                bgcolor: '#000',
              },
              '&:disabled': {
                bgcolor: 'rgba(20, 155, 161, 0.5)',
                boxShadow: 'none',
              },
            }}
          >
            Next
          </Button>
        </Box>
        {/* Category Selection Grid */}
        <Grid container spacing={3}>
          {scope3Categories.map((category) => (
            <Grid item xs={12} sm={4} key={category}>
              <Box sx={{ mb: 4 }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  {category}
                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    value={categorySelections[category]?.selected || ''}
                    onChange={(e) => handleCategoryChange(category, e.target.value)}
                  >
                    <FormControlLabel
                      value="Yes, Data Available"
                      control={
                        <Radio
                          sx={{
                            color: 'rgb(20, 155, 161)',
                            '&.Mui-checked': {
                              color: 'rgb(20, 155, 161)',
                            },
                          }}
                        />
                      }
                      label="Yes, Data Available"
                      sx={{ mr: 4 }}
                    />

                    <FormControlLabel
                      value="Yes, Data Not Available"
                      control={
                        <Radio
                          sx={{
                            color: 'rgb(20, 155, 161)',
                            '&.Mui-checked': {
                              color: 'rgb(20, 155, 161)',
                            },
                          }}
                        />
                      }
                      label="Yes, Data Not Available"
                      sx={{ mr: 4 }}
                    />

                    <FormControlLabel
                      value="Not Applicable"
                      control={
                        <Radio
                          sx={{
                            color: 'rgb(20, 155, 161)',
                            '&.Mui-checked': {
                              color: 'rgb(20, 155, 161)',
                            },
                          }}
                        />
                      }
                      label="Not Applicable"
                    />
                  </RadioGroup>

                </FormControl>

                {/* Reason Input */}
                {(categorySelections[category]?.selected === 'Not Applicable' || categorySelections[category]?.selected === 'Yes, Data Not Available') && (
                  <TextField
                    fullWidth
                    label="Reason"
                    variant="outlined"
                    value={categorySelections[category]?.reason || ''}
                    onChange={(e) => handleReasonChange(category, e.target.value)}
                    sx={{ mt: 2 }}
                  />
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </ThemeProvider>
  );
};
