import { useState, useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import {
  Grid,
  Button,
  Container,
  Stack,
  Typography,
  Paper,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import Iconify from "../../components/iconify/Iconify";
//
import Header from "./header";
import Nav from "./nav";
import UserCard from "../../components/subheader/UserCard";

// ----------------------------------------------------------------------

const HEADER_HEIGHT = 64; // Adjust this value based on your actual header height
const NAV_WIDTH = 220; // Adjust this value based on your actual nav width

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100vh",
  overflow: "hidden",
});

const StyledHeader = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  height: HEADER_HEIGHT,
  zIndex: theme.zIndex.appBar,
}));

const StyledNav = styled(Box)(({ theme }) => ({
  width: NAV_WIDTH,
  flexShrink: 0,
  position: "fixed",
  top: HEADER_HEIGHT,
  left: 0,
  bottom: 0,
  overflow: "auto",
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

const StyledMain = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  marginTop: '20px',
  paddingTop: HEADER_HEIGHT,
  paddingLeft: NAV_WIDTH,
  [theme.breakpoints.down("lg")]: {
    paddingLeft: 0,
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [currentComponentIndex, setCurrentComponentIndex] = useState(0);
  const [disableNavigation, setDisableNavigation] = useState("");
  const childcomponents = [
    { name: "Application Health Overview", path: "apphealthoverview", index: 0 },
    { name: "Client Monitoring", path: "clientmonitoring", index: 1 },
    { name: "Device Management", path: "devicemanagement", index: 2 },
    { name: "Recommendations", path: "recommendation", index: 3 },
  ];

  const handleNavigation = (pageChange, key) => {
    try {
      if (location.pathname.indexOf("dashboard") !== -1) {
        const tempLocation = location.pathname.split(["/"])[4];
        const presentLocationIndex = childcomponents.filter(
          (cc) => cc.path === tempLocation
        )[0]?.index;

        if (!pageChange) {
          switch (key) {
            case "previous":
              navigate(
                `/carboncompete/dashboard/sub/${childcomponents[presentLocationIndex - 1].path
                }`
              );
              break;
            case "next":
              navigate(
                `/carboncompete/dashboard/sub/${childcomponents[presentLocationIndex + 1].path
                }`
              );
              break;
            default:
          }
        } else {
          if (presentLocationIndex > 0 && presentLocationIndex < 3) {
            setDisableNavigation("");
          }
          if (presentLocationIndex === 3) {
            setDisableNavigation("next");
          }
          if (presentLocationIndex === 0) {
            setDisableNavigation("previous");
          }
          setCurrentComponentIndex(presentLocationIndex);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleBackNavigation = () => {
    navigate("/carboncompete/dashboard");
  };

  useEffect(() => {
    handleNavigation(true, null);
  }, [location]);

  return (
    <StyledRoot>
      <StyledHeader>
        <Header onOpenNav={() => setOpen(true)} />
      </StyledHeader>
      <StyledNav>
        <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      </StyledNav>
      <StyledMain >
        {location.pathname.indexOf("sub") !== -1 ? (
          <Container>
            <div style={{ display: "flex", marginBottom: "16px" }}>
              <Button
                sx={{
                  bgcolor: "#256D85",
                  color: "white",
                  "&:hover": {
                    bgcolor: "#256D85",
                  },
                }}
                onClick={() => handleBackNavigation()}
                startIcon={<Iconify icon="mdi:arrow-back-circle" width={20} />}
              >
                Back
              </Button>
              <Paper
                elevation={3}
                sx={{
                  width: "100px",
                  marginLeft: "auto",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Tooltip title={<Typography fontFamily="Raleway">{childcomponents[currentComponentIndex - 1]?.name}</Typography>}>
                    <IconButton
                      onClick={() => handleNavigation(false, "previous")}
                      disabled={disableNavigation === "previous"}
                    >
                      <Iconify icon="material-symbols:arrow-circle-left-rounded" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={<Typography fontFamily="Raleway">{childcomponents[currentComponentIndex + 1]?.name}</Typography>}>
                    <IconButton
                      onClick={() => handleNavigation(false, "next")}
                      disabled={disableNavigation === "next"}
                    >
                      <Iconify icon="material-symbols:arrow-circle-right-rounded" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Paper>
            </div>
            <Typography variant="h4" gutterBottom>
              {childcomponents[currentComponentIndex].name}
            </Typography>
          </Container>
        ) : null}
        <Outlet />
      </StyledMain>
    </StyledRoot>
  );
}
