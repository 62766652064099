import React from "react";
import {
  List,
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
  Popover,
  IconButton,
  Typography,
  Paper,
  InputBase,
} from "@mui/material";
import { useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { categoryImage } from "../../pages/CarbonCatalogue/Images/CatalogueImages";

import catalogue from '../../pages/Assets2/catalogue.png';
import { images } from '../../pages/CarbonCatalogue/Images/CatalogueImages';

import { Icon } from "@iconify/react";
import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from 'react-router-dom'

export const CatalogCategoryDrawer = ({
  openCategoryDrawer: open,
  toggleCategorydrawer: handleClose,
  categories = [],
  setCategoryID,
  handleCategory
}) => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [subCategory, setSubCategory] = useState([]);
  const [categoriesList, setCategoryList] = useState([]);

  const handlePopoverOpen = (event, Products) => {
    setAnchorEl(event.currentTarget);
    setSubCategory(Products)
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);

  useEffect(() => {
    setCategoryList(categories);
  }, [categories]);

  const drawerStyles = {
    marginLeft: "128px",
    borderRadius: "10px",
    border: "0.5px solid rgba(0, 0, 0, 0.50)",
    boxShadow:
      "0px 4px 4px 0px rgba(0, 0, 0, 0.08), 0px 4px 35px 2px rgba(0, 0, 0, 0.19)",
  };

  const drawerOptionStyles = {
    borderRadius: "4px",
    border: "0.5px solid rgba(172, 172, 172, 0.50)",
    background: "#FFF",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.08)",
    marginBottom: "22px",
  };

  const handleBothFunc = () =>{
    handlePopoverClose()
    handleClose()
  }

  const handleBothFucntion = (id, index) =>{
    setCategoryID(id)
    handleCategory(index)
    handleBothFunc()
  }

  return (
    <>
      <Drawer
        style={drawerStyles}
        open={open}
        onClose={()=> handleBothFunc()}
        PaperProps={{
          style: {
            position: "absolute",
          },
        }}
        // hideBackdrop={true}
        BackdropProps={{
          sx: {
            backgroundColor: "rgb(43 49 55 / 80%)",
          },
        }}
        // BackdropProps={{ invisible: true }}
        // ModalProps={{
        //   style: {
        //     backgroundColor: 'red'
        //   }
        // }}
        variant="temporary"
        anchor="left"
      >
        {/* <CatalogProductsDrawer
          openProductsDrawer={openProductsDrawer}
          toggleProductsDrawer={()=> toggleProductsDrawer()}
          // categories={displayedCategories}
        /> */}
        <Box component='div' p={2}>
          <Typography variant="h4" p={1} pb={4}>
            Categories
            <IconButton
              aria-label="delete"
              size="small"
              sx={{
                float: "right",
              }}
              onClick={() => handleClose()}
            >
              <CloseIcon htmlColor="#d12f26" />
            </IconButton>
          </Typography>

          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
              // boxShadow: "0px 4px 5px -5px black",
              boxShadow: "0px 0px 20px -10px black",
            }}
          >
            <IconButton
              type="button"
              sx={{
                p: "10px",
                // backgroundColor: "#316ede",
                borderRadius: "5px",
                "&:hover": { backgroundColor: "#000" },
              }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Categories..."
              inputProps={{ "aria-label": "search google maps" }}
            />
          </Paper>
          </Box>
          <Divider light={false} variant="middle" />
          <Box component='div' p={2} onMouseLeave={()=> handlePopoverClose()}>
          <List >
            {categoriesList.map(({ name, categoryId, Products }, index) => (
              <ListItem
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={(event)=>handlePopoverOpen(event, Products)}
                onClick={()=> handleBothFucntion(categoryId, index)}
                // onMouseLeave={handlePopoverClose}

                style={drawerOptionStyles}
                key={categoryId}
                disablePadding
                // onClick={() => toggleProductsDrawer(!openProductsDrawer)}
                // onClick={(e) => openPopper(e, subCategory, categoryId)}
                secondaryAction={
                  <IconButton edge="end" aria-label="delete">
                    <ChevronRightIcon htmlColor="main" />
                  </IconButton>
                }
              >
                <ListItemButton >
                  <ListItemIcon>
                    <Icon
                      fontSize={"40px"}
                      icon={
                        categoryImage.find((cat) => cat.id === categoryId)
                          ?.image || "carbon:category-new-each"
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary={name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Popover
            id="mouse-over-popover"
            sx={{
              marginLeft: '17px',
              pointerEvents: 'none',
            }}
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            disableRestoreFocus
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
          >
            <List 
              sx={{
                width: '349px',
                height: '526px',
                flexShrink: 0,
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                overflow: 'auto', 
                pointerEvents: 'visible'
              }}
              onMouseLeave={handlePopoverClose} 
            >
              {subCategory.map((product, id) => (
                <ListItem 
                  disablePadding 
                  key={id} 
                  sx={{borderRadius: '4px', border: '0.5px solid rgba(172, 172, 172, 0.50)', background: '#FFF', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.08)'}}
                  onClick={()=> navigate(`/carboncompete/carboncatalogue/product/${product.productId}`, { state: { productName: product.productName }} )}
                >
                  <ListItemButton>
                    <ListItemIcon>
                    <img
                    src={
                      images.find((img) => img.id === product.productId)?.image ||
                      catalogue
                    }
                    style={{ height: '30px', width: '30px' }}
                    alt='Product'
                  />
                    </ListItemIcon>
                    <ListItemText primary={product?.productName} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Popover>
        </Box>
      </Drawer>
    </>
  );
};