import { APP_KEYS } from '../utils/constants/AppKeyConstants';
import axios from './axiosInstance';

const API_URL = process.env.REACT_APP_API_URL;

const userInfo = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO) || '{}');
/**
 * @description Get Module Details
 * @param {Object} param
 * @returns 
 */
export const getSubscriptions = async (subsId, subsName) => {
    try {
        const { data: responseData = {} } = await axios({
            url: `${API_URL}/api/v1/subs`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                subsId,
                subsName
            }
        });

        return responseData.data;
    } catch (err) {
        // Throw unknown errors as well
        throw err;
    }
};

export const ChangeSubscription = async ({ userId, userName, userEmail, userMobile, subsId, selectedSubscriptionName }) => {
    try {
        await axios({
            url: `${API_URL}/api/v1/user`,
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                userId: userId,
                userName: userName,
                userEmail: userEmail,
                userMobile: userMobile,
                compId: userInfo?.userId,
                roles: [
                    {
                        roleId: 4,
                        roleName: "Supplier"
                    }
                ],
                subs: [
                    {
                        subsId: subsId,
                        subsName: selectedSubscriptionName
                    }
                ],
                isActive: true
            }
        });

        return { success: 'supplier_updated' };
    } catch (err) {
        console.log(err);
        return { error: err.message };
    }
};