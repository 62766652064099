export const getCategoryTextColor = (category) => {
  switch (category) {
    case "FRESH_WATER":
    case "NON_FV_DUMP":
    case "FV_DUMP":
    case "InBound":
    case "OutBound":
      return "#006799";
    case "EV":
    case "CNG":
      return "#0CDB7B";
    case "RE_POWER":
    case "RE_SHARE":
    case "RE":
    case "TREATED_WATER":
    case "WATER_SAVED":
    case "Treated_Water_Share":
      return "#05D776";
    case "EB_POWER":
    case "Total_Intensity":
    case "IntensityChange":
    case "ENERGY_SAVED":
      return "#FFCE62";
    case "DIESEL":
      return "#FFA058";
    default:
      return "#FFCE62";
  }
};
