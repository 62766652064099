//Navigation
import React, { useEffect, useState } from "react";
import DnDFlow from "./Flow";
import {
  Box,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Helmet } from "react-helmet-async";
import { PRODUCT_IMG_LIB } from "./FlowElements/IconLib";
import { getTotalCarbonData } from "../../services/analytics";

const classes = {
  root: {
    display: "flex",
    height: "100vh",
  },
  aside: {
    backgroundColor: "#fff",
    borderRight: "0.5px solid #F1F1F1",
    padding: "16px",
    borderRadius: "4px",
    width: "230px",
    display: "flex",
    flexDirection: "column",
  },
  subcategory: {
    padding: "8px 0 8px 10px",
    marginTop: "16px",
    fontSize: "16px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "23px",
    color: "#000",
  },
  subcategoryIcon: {
    height: "30px",
    width: "30px",
    "&:hover": {
      backgroundColor: "green",
      color: "#fff",
    },
  },
  content: {
    flex: "1",
    backgroundColor: "#FFF",
    // padding: '16px',
    borderRadius: "4px",
  },
};

export const SupplierCanvas = () => {
  const [search, setSearch] = useState("");
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState(null);

  const onDragStart = (event, nodeType, subCategoryIcon, productObj) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.setData("application/subcategory-icon", subCategoryIcon);
    event.dataTransfer.setData("application/productObj", JSON.stringify(productObj));
    event.dataTransfer.effectAllowed = "move";
  };

  useEffect(() => {
    // getProducts().then((response) => {
    //   setItems(response);
    // });
    getTotalCarbonData().then(({ data = {} }) => {
      setItems(data[0]?.rows[0]);
    });
  }, []);

  const handleSearch = (event) => {
    const searchQuery = event.target.value;
    setSearch(searchQuery);
    const filtered = items.filter((item) =>
      item.productName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredItems(filtered.length > 0 ? filtered : null);
  };

  return (
    <div style={classes.root}>
      <Helmet>
        <title> Carbon Canvas </title>
      </Helmet>
      <aside style={classes.aside}>
        <Box pt={5} px={2}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Stack spacing={1}>
              <TextField
                variant="outlined"
                size="small"
                sx={{ width: "12vw", bgcolor: "#F1F1F1" }}
                fullWidth
                placeholder="Search Product"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                value={search}
                onInput={handleSearch}
              />
            </Stack>
          </Grid>
        </Box>
        <List style={{overflow: 'auto'}}>
          {(filteredItems || items).map((item, index) => (
            <ListItem
              key={item.productId}
              style={classes.subcategory}
              onDragStart={(event) =>
                onDragStart(event, item.productName, item.icon, item)
              } draggable>
              <ListItemAvatar>
                <img
                  style={classes.subcategoryIcon}
                  src={PRODUCT_IMG_LIB[item?.imageFile || (item?.productName || '').replace(/ /g, '').toLowerCase()] || PRODUCT_IMG_LIB['default']}
                  alt={item?.productName}
                />
              </ListItemAvatar>
              <ListItemText primary={item?.productName} />
            </ListItem>
          ))}
        </List>
      </aside>
      <div style={classes.content}>
        <DnDFlow subCategory={filteredItems || items} />
      </div>
    </div>
  );
};

