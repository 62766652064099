import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BlobServiceClient } from "@azure/storage-blob";
import {
  Button,
  Container,
  TextField,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
  Box,
  Grid,
  FormControl,
  InputLabel,
  FormHelperText,
  Tabs,
  Tab,
  Backdrop,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { Helmet } from "react-helmet-async";
import SwipeableViews from "react-swipeable-views";
import Iconify from "../../../components/iconify/Iconify";
import {
  getCategoryData,
  getBrandsData,
  getSubCategoryData,
  getSubCategory2Data,
  addNewProduct,
  getMaxId,
  updateProduct
} from "../../../services/catalogue/addproduct";
import { getCountries } from "../../../services/common";
// import DnDFlow2 from "../TempCanvas/Flow2";
import { prepareReactFlowObjectFromObject } from "../ProductUtil";
import { useSnackbar } from "notistack";
import {
  NOTIFICATION_ERROR,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_WARN,
} from "../../../utils/constants/NotificationConstants";
import dagre from "dagre";
import { useParams } from "react-router-dom";
import { getProductById, getProducts } from "../../../services/product";
import Flow from "../TempCanvas/Flow";
import { postGenerateSupplyChain } from "../../../services/external";
import { useEdgesState, useNodesState } from "reactflow";
import { APP_KEYS } from "../../../utils/constants/AppKeyConstants";
import ConfirmationModal from "../../../components/modal/conformation";
import { useRef } from "react";
import Joyride from "react-joyride";

const joyrideSteps = [{
  target: `body`,
  title: `This page lets you add new products`,
  content: `To see how this page works, let's get started`,
  locale: {
    next: `Start`, skip: `Skip`
  },
  disableBeacon: true,
  placement: `center`
},
{
  target: "#productform",
  content: "Provide all the relevant details needed to add a new product",
  spotlightPadding: 0,
  disableBeacon: true,
},
{
  target: "#continue",
  content: "After entering all the details click here to continue",
  spotlightPadding: 0,
  disableBeacon: true,
},
{
  target: `#supplyChainConfig`,
  content: "You'll be able to configure the supply chain of product here",
  spotlightPadding: 0,
  disableBeacon: true,
}
]

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 36;

const getLayoutedElements = async (nodes = [], edges = [], direction = "LR") => {
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? "left" : "top";
    node.sourcePosition = isHorizontal ? "right" : "bottom";

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};

const AddProduct = () => {
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));
  const [currentTab, setTab] = useState(0);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [backdrop, setBackdrop] = useState(false);
  const [productDetail, setProductDetail] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { productId = "" } = useParams();
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [subCategory2Data, setSubCategory2Data] = useState([]);
  const [brandsData, setBrandsData] = useState([]);
  const [countriesData, setCountriesData] = useState([]);
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false)
  const [exteranlMappingResponse, setExternalMappingResponse] = useState({ productName: "", nodes: [], edges: [] })
  const [payload, setPayload] = useState({})
  const [stageProp, setStageProp] = useState([])
  const [supplierConfirmModal, setSupplierConfirmModal] = useState(false)
  const [runJoyrideAP, setRunJoyrideAP] = useState(false);

  const handleJoyrideCallback = (data) => {
    if (data.action === "close") {
      setRunJoyrideAP(false)
      localStorage.setItem("addproducttour", 'false')
    }

    if ((data.action === "reset")) {
      setRunJoyrideAP(false)
      localStorage.setItem("addproducttour", 'false')
      navigate('/carboncompete/canvas')
    }
  }

  useEffect(() => {

    const guidetourval = localStorage.getItem("addproducttour") === 'false' ? false : true
    setRunJoyrideAP(guidetourval)
  }, []);

  const validationSchema = yup.object({
    productName: yup
      .string()
      .required("Please enter Product name")
      .min(2, "Product name cannot be empty"),
    description: yup.string().required("Please enter product description"),
    category: yup.string().required("Please select a category"),
    country: yup.string().required("Please select a country"),
  });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      productName: "",
      description: "",
      category: "",
      brand: "",
      country: "",
      image: "",
    },
    onSubmit: async () => {
      return switchNextStep();
    },
  });


  const uploadImage = async () => {
    try {
      const blobServiceUrl = "https://productimagescc.blob.core.windows.net/";
      const sasToken = "?sv=2022-11-02&ss=b&srt=sco&sp=rwdlaciytfx&se=2030-01-23T16:10:14Z&st=2024-01-23T08:10:14Z&spr=https&sig=bhUJVCTznfzmG%2B3zY%2BhGgUjDHPLiGkABVPzctcJ2lJU%3D";
      const blobService = new BlobServiceClient(blobServiceUrl + sasToken); // Use concat instead of +
      const containerClient = blobService.getContainerClient("images");

      if (!productId) {
        const result = await getMaxId();
        const blobName = 'p' + result.data[0].productId;
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);
        const contentType = await file.type || 'application/octet-stream';
        const uploadBlobResponse = await blockBlobClient.uploadData(file, { blobHTTPHeaders: { blobContentType: contentType } });
        console.log(uploadBlobResponse);
        const url = blobServiceUrl + "images/" + blobName;
        formik.setFieldValue('image', url);
        setImageUrl(url);
      } else {
        const blobName = 'p' + productId;
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);
        const contentType = await file.type || 'application/octet-stream';
        const uploadBlobResponse = await blockBlobClient.uploadData(file, { blobHTTPHeaders: { blobContentType: contentType } });
        console.log(uploadBlobResponse);
        const url = blobServiceUrl + "images/" + blobName;
        formik.setFieldValue('image', url);
        setImageUrl(url);

      }
    }
    catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (file !== null) {
      uploadImage();
    }
  }, [file]);

  const ws = useRef(null);
  const [messages, setMessages] = useState([]);


  useEffect(() => {
    ws.current = new WebSocket('wss://socketmapping.azurewebsites.net/ws');

    ws.current.onopen = () => {
      console.log("WebSocket connection established.");
    };

    ws.current.onmessage = async (event) => {
      const message = event.data;
      try {
        // Check if the message is JSON
        const response = JSON.parse(message);

        if (response && response.nodes && response.edges) {
          setMessages((prevMessages) => [...prevMessages, "Mapping response received."]);
          setExternalMappingResponse(response)
          const { nodes: layoutedNodes, edges: layoutedEdges } = await getLayoutedElements(response.nodes, response.edges);
          console.log(nodes)
          console.log(edges)
          setNodes(layoutedNodes || []);
          setEdges(layoutedEdges || []);
          setLoading(false); // Set loading to false after updating nodes and edges
        } else {
          console.error("Unexpected response:", response);
          setLoading(false);
        }
      } catch (error) {
        // If JSON parsing fails, it might be a plain text status message
        if (message === "Task started") {
          setMessages((prevMessages) => [...prevMessages, message]);
        } else {
          console.error("Error parsing WebSocket message:", error);
          setLoading(false);
        }
      }
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error:", error);
      setLoading(false);
    };

    ws.current.onclose = () => {
      console.log("WebSocket connection closed.");
      setLoading(false);
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  const externalMappingService = async (productName, productDesc, Location) => {
    setLoading(true);

    const params = {
      productName,
      productDesc,
      Location,
      max_depth: 5,
      relevanceThreshold: 0.001,
    };


    ws.current.send(JSON.stringify(params));

    try {
      let activities = JSON.parse(sessionStorage.getItem('activity')) || [];

      activities.push("wss://socketmapping.azurewebsites.net/ws");

      sessionStorage.setItem('activity', JSON.stringify(activities));
    } catch (error) {
      console.error('Error updating activity:', error);
    }
  };


  const findCountryName = (id) => {
    let filtered = countriesData.filter((country, index) => country.countryId == id)
    if (filtered.length) {
      return filtered[0]
    }
    else {
      return {}
    }
  }

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleChangeIndex = (index) => {
    setTab(index);
  };

  const initPage = async () => {
    try {
      setBackdrop(true);
      await Promise.all([
        fetchCountriesData(),
        fetchCategoryData()
      ]);

      if (productId) {
        const productInfo = await getProductDetails();

        // Bind product detail to form
        formik.setValues({
          productName: productInfo?.productName,
          description: productInfo?.productDesc,
          category: parseInt(productInfo?.categories?.categoryId) || "",
          brand: userInfo?.compId,
          country: productInfo?.countries?.id,
          image: productInfo?.imageFile,
        });

        if (productInfo?.supplyChainConfig) {
          setNodes(productInfo?.supplyChainConfig?.nodes || []);
          setEdges(productInfo?.supplyChainConfig?.edges || []);
        } else {
          const object = prepareReactFlowObjectFromObject(
            productInfo?.stageProp,
            productInfo?.productName,
            productInfo?.imageFile
          );
          setNodes(object.nodes);
          setEdges(object.edges);
        }
      }

      setBackdrop(false);
    } catch (err) {
      setBackdrop(false);
      console.log("Error while fetching the page data. Please try again later");
    }

    formik.validateForm();
  };

  const getProductDetails = async () => {
    const [productInfo] = await getProductById(productId);
    setProductDetail(productInfo);
    return productInfo;
  };


  // Get Page base details from BackEnd
  useEffect(() => {
    initPage();
  }, []);

  const switchNextStep = () => {
    if (formik.isValid) {
      externalMappingService(formik.values.productName, formik.values.description, findCountryName(formik.values.country)?.countryName)
      handleChange(null, 1);
    }
  };


  async function fetchCategoryData() {
    try {
      const response = await getCategoryData();
      if (response.success) {
        setCategoryData(response.data);
      } else {
        console.error("Error fetching category data:", response.msg);
      }
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
    return true;
  }

  async function fetchCountriesData() {
    try {
      const response = await getCountries();
      if (response.success) {
        setCountriesData(response.data);
      } else {
        console.error("Error fetching country data:", response.msg);
      }
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
    return true;
  }

  const handleCategoryChange = async (event) => {
    const categoryId = event.target.value;
    // Change the propery value only its originated from form event
    if (!event?.customEvent) {
      formik.handleChange(event);
    }
    try {
      const response = await getSubCategoryData(categoryId); //{'categoryId' as a parameter}
      if (response.success) {
        setSubCategoryData(response.data);
      } else {
        console.error("Error fetching subcategory data:", response.msg);
      }
    } catch (error) {
      console.error("Error fetching subcategory data:", error);
    }
  };

  const handleSubCategoryChange = async (event) => {
    const subCat1Id = event.target.value;
    formik.handleChange(event);
    //It will Only fetch subCategory2Data when both category and subCategory are selected
    if ((formik.values.category || event.target.categoryId) && subCat1Id) {
      try {
        const response = await getSubCategory2Data(
          formik.values.category || event.target.categoryId, //{'categoryId' & 'subCat1Id' as parameters}
          subCat1Id
        );
        if (response.success) {
          setSubCategory2Data(response.data);
        } else {
          console.error("Error fetching subcategory2 data:", response.msg);
        }
      } catch (error) {
        console.error("Error fetching subcategory2 data:", error);
      }
    }
    return true;
  };


  const handleCheckSupplierConfig = async () => {
    console.log(productId, "chceking prodcut Id")
    if (!productId.length) {
      const updatedExteranlMappingResponse = await schemaConfigure(exteranlMappingResponse);
      setPayload(updatedExteranlMappingResponse)
    }
    if (stageProp.length === nodes.length) {
      if (productId.length) {
        onProductUpdate()
      } else {
        onProductSave();
      }
    } else {
      setSupplierConfirmModal(true);
    }
  };

  useEffect(() => {
    if (nodes.length) {
      setExternalMappingResponse((prev) => ({ ...prev, nodes: nodes }))
    }
  }, [nodes])


  const schemaConfigure = async (schemaProps) => {
    let updatedSchemaProps = schemaProps;

    if (schemaProps.nodes.length) {
      updatedSchemaProps = {
        ...schemaProps,
        // nodes: schemaProps.nodes.map((node, index) => {
        //   let customSchema = updatePreSchema(node.data.stageName, node.data.lifecyclestage);
        //   return {
        //     ...node,
        //     data: {
        //       ...node.data,
        //       templateSchemaProp: checkSchemeRepetition(node?.data?.templateSchemaProp, customSchema),
        //     },
        //   };
        // }),
      };
    }




    return {
      productCode: "PRODUCT_CODE",
      productName: formik.values.productName,
      shortName: "",
      productDesc: formik.values.description,
      categoryId: formik.values.category,
      brandId: 1,
      unitId: 1,
      skuCode: "",
      countryId: formik.values.country,
      userId: userInfo?.userId,
      compId: userInfo?.compId,
      stageProp: stageProp,
      isActive: true,
      imageFile: formik.values.image,
      supplyChainConfig: {
        nodes: updatedSchemaProps.nodes,
        edges: updatedSchemaProps.edges,
      },
      compName: userInfo?.userName
    };
  };

  const onProductSave = async () => {
    try {

      const resp = await addNewProduct(payload);

      // Show warning message if product id is not present in the response
      if (!resp.data[0].productId) {
        enqueueSnackbar("Something Went Wrong", {
          autoHideDuration: 3000,
          variant: NOTIFICATION_WARN,
        });
      }

      enqueueSnackbar("Product Added Successfully", {
        autoHideDuration: 3000,
        variant: NOTIFICATION_SUCCESS,
      });
      setBackdrop(false);

      // goToStages(resp.data[0].productId);
      navigate(`/carboncompete/carboncatalogue/product/${resp.data[0].productId}`);
    } catch (err) {
      enqueueSnackbar(err.msg || err.message || err.Message || "Server Error", {
        autoHideDuration: 3000,
        variant: NOTIFICATION_ERROR,
      });
      setBackdrop(false);
    }
  };

  const onProductUpdate = async () => {
    // prepare stage object
    // const stages = prepareStageFromReactFlowObject(nodes, edges);

    try {
      setBackdrop(true);

      const resp = await updateProduct({
        ...formik.values,
        productId,
        stageProp,
        image: imageUrl,
        nodes,
        edges
      });

      // Show warning message if product id is not present in the repsonse
      if (!resp.data[0].productId) {
        enqueueSnackbar("Something Went Wrong", {
          autoHideDuration: 3000,
          variant: NOTIFICATION_WARN,
        });
      }

      enqueueSnackbar("Product Updated Successfully", {
        autoHideDuration: 3000,
        variant: NOTIFICATION_SUCCESS,
      });
      setBackdrop(false);

      // goToStages(resp.data[0].productId);
      navigate(
        `/carboncompete/carboncatalogue/product/${resp.data[0].productId}`
      );
    } catch (err) {
      enqueueSnackbar('Unable to update a product' || err.message || err.Message || "Server Error", {
        autoHideDuration: 3000,
        variant: NOTIFICATION_ERROR,
      });
      setBackdrop(false);
    }
  };

  return (
    <Box>
      <ConfirmationModal modal={supplierConfirmModal} setModal={setSupplierConfirmModal} heading="Are you sure, you want to continue?" body="you're trying to save the product without cofiguring the supplier for each and every stage." acceptFunction={productId?.length ? onProductUpdate : onProductSave} buttonName="Continue" />

      <Helmet>
        <title>
          {productId
            ? `Edit Product - ${productDetail?.name || productId}`
            : "Add Product"}
        </title>
      </Helmet>

      <Joyride
        steps={joyrideSteps}
        run={runJoyrideAP}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        spotlightClicks="true"
        callback={handleJoyrideCallback}
        styles={{
          options: {
            zIndex: 10000,
          },

          overlay: {
            top: 0,
            left: 0,
            width: "100%",
            height: "1000%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          },
          tooltip: {
            zIndex: 10001,
          },
          spotlight: {
            borderRadius: "inherit",
            padding: 0,
          },
          buttonNext: {
            backgroundColor: "#009999",
            color: "white",
          },
          buttonBack: {
            backgroundColor: "#009999",
            color: "white",
          },
          buttonSkip: {
            backgroundColor: "#009999",
            color: "white",
          },
        }}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 100 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container maxWidth="xl"
        sx={{
          display: "flex", width: "100%", justifyContent: "center",
          alignItems: "center", backgroundColor: "white", paddingY: 2, position: "relative"
        }}>
        <Grid
          // xs={12}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="left"
          spacing={2}
          mb={2}
        >

          <Grid item xs={10}>
            <Typography variant="h4">
              {productId ? "Edit Product" : "Create New Product"}
            </Typography>
          </Grid>

          <Grid item xs={2} justifyContent="space-between">
            <Button
              sx={{
                backgroundColor: "#222",
                "&:hover": { backgroundColor: "#149BA1" },
                color: "#fff",
                marginRight: "20px",
              }}
              onClick={() => navigate(-1)}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Iconify icon="icon-park-outline:back" color="white" />
                <Typography
                  fontFamily="Poppins"
                  style={{ textTransform: "capitalize", marginLeft: "0.5rem" }}
                >
                  Back
                </Typography>
              </div>
            </Button>
            <Button
              sx={{
                backgroundColor: "#149BA1",
                "&:hover": { backgroundColor: "#096969" },
                color: "#fff",
                //   marginLeft: '-100px'
              }}
              onClick={() => productId ? onProductUpdate() : handleCheckSupplierConfig()}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Iconify icon="material-symbols:save" color="white" />
                <Typography
                  fontFamily="Poppins"
                  style={{ textTransform: "capitalize", marginLeft: "0.5rem" }}
                >
                  Save
                </Typography>
              </div>
            </Button>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="xl" sx={{ backgroundColor: '#fff' }} id="productform">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={currentTab}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
            TabIndicatorProps={{ style: { background: "grey" } }}
          >
            <Tab label="Product Details" {...a11yProps(0)} />
            <Tab
              label="Supply Chain Configuration"
              {...a11yProps(1)}
              disabled={!formik.isValid}
              id="supplyChainConfig"
            />
          </Tabs>
        </Box>
        <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
          <SwipeableViews index={currentTab} onChangeIndex={handleChangeIndex}>
            <Box sx={{
              flex: "1",
              backgroundColor: "#FFF",
              // padding: '16px',
              borderRadius: "4px",
              height: "70vh",
              overflowY: "scroll"

            }}
              p={6}>

              <Box>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <InputLabel sx={{ marginBottom: '10px' }}>
                      <Typography fontFamily="Poppins" sx={{ fontWeight: 500 }}>
                        Product name
                      </Typography>
                    </InputLabel>
                    <TextField
                      fullWidth
                      // height={48}
                      
                      name="productName"
                      value={formik.values.productName}
                      onChange={formik.handleChange}
                      error={formik.touched.productName && Boolean(formik.errors.productName)}
                      helperText={formik.touched.productName && formik.errors.productName}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 180,
                            overflowY: 'scroll',
                          },
                        },
                      }}
                      style={{
                        width: '100%',
                        height: '48px',
                        borderRadius: '8px',
                        border: 'none !important',
                        '&:focus': {
                          border: 'none',
                        },
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                      }}
                      inputProps={{
                        style: {
                          height: "15px",
                        },
                      }}

                    />



                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel sx={{ marginBottom: '10px' }}>
                      <Typography fontFamily="Poppins" sx={{ fontWeight: 500 }}>
                        Product Description
                      </Typography>
                    </InputLabel>
                    <TextField
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      fullWidth
                      
                      error={formik.touched.description && Boolean(formik.errors.description)}
                      helperText={formik.touched.description && formik.errors.description}
                      sx={{
                        '& .MuiFilledInput-root:after': {
                          border: '1px solid #149BA1'
                        },
                        '& .MuiFilledInput:after': {
                          borderColor: '#149BA1', 
                        },

                      }}
                      style={{
                        width: '100%',
                        height: '48px',
                        borderRadius: '8px',
                        border: 'none !important',
                        '&:focus': {
                          border: 'none',
                        },
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                      }}
                      inputProps={{
                        style: {
                          height: "15px",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputLabel sx={{ marginBottom: '10px' }}>
                      <Typography fontFamily="Poppins" sx={{ fontWeight: 500 }}>
                        Select Category
                      </Typography>
                    </InputLabel>
                    <FormControl
                      fullWidth
                      sx={{
                        '& .MuiFilledInput-root:after': {
                          border: '1px solid #149BA1'
                        },
                        '& .MuiFilledInput:after': {
                          borderColor: '#149BA1',
                        },

                      }}
                      error={
                        formik.touched.category &&
                        Boolean(formik.errors.category)
                      }
                    >
                      <Select
                        fullWidth
                        defaultValue=""
                        name="category"
                        value={formik.values.category}
                        onChange={handleCategoryChange}
                        onBlur={formik.handleBlur}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 180,
                              overflowY: 'scroll',
                            },
                          },
                        }}
                        style={{
                          width: '100%', height: '48px',
                          borderRadius: '8px',
                          border: 'none !important',
                          '&:focus': {
                            border: 'none',
                          },
                          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                        }}
                      >
                        <MenuItem value="" disabled defaultValue="">
                          Select Category
                        </MenuItem>
                        {categoryData.map((category, index) => (
                          <MenuItem
                            key={"cagtegory_key_" + category.categoryId}
                            value={category.categoryId}
                          >
                            {category.categoryName}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {formik.touched.category && formik.errors.category}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel sx={{ marginBottom: '10px' }}>
                      <Typography fontFamily="Poppins" sx={{ fontWeight: 500 }}>
                        Select country
                      </Typography>
                    </InputLabel>
                    <FormControl
                      fullWidth
                      
                      sx={{
                        '& .MuiFilledInput-root:after': {
                          border: '1px solid #149BA1'
                        },
                        '& .MuiFilledInput:after': {
                          borderColor: '#149BA1', 
                        },

                      }}
                      error={
                        formik.touched.country && Boolean(formik.errors.country)
                      }
                    >
                      <Select
                        defaultValue=""
                        name="country"
                        value={formik.values.country}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 180,
                              overflowY: 'scroll',
                            },
                          },
                        }}
                        style={{
                          width: '100%', height: '48px',
                          //  backgroundColor: '#F1F1F1',
                          borderRadius: '8px',
                          border: 'none !important',
                          '&:focus': {
                            border: 'none',
                          },
                          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                        }}
                        error={
                          formik.touched.country &&
                          Boolean(formik.errors.country)
                        }
                      >
                        <MenuItem value="" disabled defaultValue="" sx={{ fontWeight: 500 }}>
                          Select country
                        </MenuItem>
                        {countriesData.map((country, index) => (
                          <MenuItem
                            key={"country_" + index}
                            value={country.countryId}
                          >
                            {country.countryName}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {formik.touched.country && formik.errors.country}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid sx={{ marginTop: '-8px' }} item xs={12} md={6} >
                    <Box>

                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <div style={{ display: "flex", flexDirection: "column", gap: 10, padding: "10px" }}>
                            <InputLabel >
                              <Typography fontFamily="Poppins" sx={{ fontWeight: 500 }}>
                                Image URL
                              </Typography>
                            </InputLabel>
                            <TextField
                              name="image"
                              value={formik.values.image}
                              onChange={formik.handleChange}
                              fullWidth
                              error={
                                formik.touched.image && Boolean(formik.errors.image)
                              }
                              helperText={formik.touched.image && formik.errors.image}
                              sx={{
                                '& .MuiFilledInput-root:after': {
                                  border: '1px solid #149BA1'
                                },
                                '& .MuiFilledInput:after': {
                                  borderColor: '#149BA1', 
                                },
                              }}
                              style={{
                                width: '100%',
                                height: '48px', // Set the height to 48px
                                // backgroundColor: '#F1F1F1',
                                borderRadius: '8px',
                                border: 'none !important',
                                '&:focus': {
                                  border: 'none',
                                },
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                              }}
                              inputProps={{
                                style: {
                                  height: "15px",
                                },
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid fullWidth item xs={12} md={6}>
                          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", marginTop: '10px' }}>

                            <Typography fontFamily="Poppins" sx={{ fontWeight: 500 }}>
                              <b>or</b> upload your image file
                            </Typography>
                            <Iconify icon="material-symbols:upload" />
                          </div>
                          <label htmlFor="fileInput">
                            <Button
                              component="span"
                              variant="outlined"
                              sx={{
                                backgroundColor: "#fff",
                                "&:hover": { border: "1px solid #149BA1" },
                                color: "#149BA1",
                                marginTop: "10px",
                                gap: "10px",
                                width: '100%',
                                height: '48px',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                              }}

                            >
                              <Iconify icon="material-symbols:save" sx={{ color: "#149BA1" }} />
                              <Typography>Upload Image</Typography>
                            </Button>
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            id="fileInput"
                            onChange={(e) => {
                              e.preventDefault();
                              setFile(e?.target?.files[0]);
                              uploadImage();
                            }}
                            style={{ display: 'none' }}
                          />
                        </Grid>
                      </Grid>





                    </Box>
                  </Grid>

                  <div>

                  </div>
                </Grid>
              </Box>



            </Box>
            <Box
              p={2}
              sx={{
                flex: "1",
                backgroundColor: "#FFF",
                // padding: '16px',
                borderRadius: "4px",
                height: "80vh",

              }}
            >
              {/* <Typography>Supply Chain Configuration</Typography> */}
              {currentTab === 1 && (
                // <DnDFlow2
                //   initData={{ nodes, edges }}
                //   onNodeUpdate={setNodesTest}
                //   onEdgeUpdate={setEdgesTest}
                // />
                <Flow
                  nodes={nodes} setNodes={setNodes} onNodesChange={onNodesChange} edges={edges} setEdges={setEdges} onEdgesChange={onEdgesChange}
                  // initialNodes={nodes} initialEdges={edges}  
                  setExternalMappingResponse={setExternalMappingResponse} exteranlMappingResponse={exteranlMappingResponse}
                  loading={loading} setBackdrop={setBackdrop} stageProps={stageProp} setStageProps={setStageProp} /> // new 
              )}
            </Box>

          </SwipeableViews>
          <Grid container xs={12} justifyContent="space-evenly" sx={{ paddingTop: "10px", paddingBottom: "10px", position: "fixed", bottom: 0, backgroundColor: "white", width: "100%" }}>
            <Button
              sx={{
                backgroundColor: "#FFFFFF",
                border: "1px solid black",
                "&:hover": { border: "1px solid #FA3F3F", color: "#FA3F3F" },
                color: "black",
                marginRight: "20px",
              }}
              onClick={formik.handleReset}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Iconify icon="icon-park-outline:redo" />
                <Typography
                  fontFamily="Poppins"
                  style={{ textTransform: "capitalize", marginLeft: "0.5rem" }}
                >
                  Reset
                </Typography>
              </div>
            </Button>
            {
              currentTab == 1 ? (
                <Button
                  sx={{
                    backgroundColor: "#149BA1",
                    "&:hover": { backgroundColor: "#096969" },
                    color: "#fff",
                    //   marginLeft: '-100px'
                  }}
                  onClick={() => handleCheckSupplierConfig()}
                // onClick={() => productId ? onProductUpdate() : handleCheckSupplierConfig()}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Iconify icon="material-symbols:save" color="white" />
                    <Typography
                      fontFamily="Poppins"
                      style={{ textTransform: "capitalize", marginLeft: "0.5rem" }}
                    >
                      Save
                    </Typography>
                  </div>
                </Button>
              ) : (
                <Button
                  id="continue"
                  sx={{
                    border: "1px solid #149BA1",
                  }}
                  type="submit"
                  onClick={switchNextStep}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      fontFamily="Poppins"
                      style={{ textTransform: "capitalize", marginLeft: "0.5rem" }}
                    >
                      Continue
                    </Typography>
                  </div>
                </Button>
              )
            }
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default AddProduct;