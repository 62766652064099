import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  Container,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import axios from 'axios';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { getLocationData, getSupplierLocationData, postLocationData } from '../../services/corporatecanvas';
import { APP_KEYS } from '../../utils/constants/AppKeyConstants';
import markergif from '../Assets2/location.gif';
import { useSnackbar } from 'notistack';
import { debounce } from 'lodash';
import { format } from 'date-fns';
import farm from '../../pages/Assets2/farm.png';
import warehouse from '../../pages/Assets2/warehouse.png';
import production from '../../pages/Assets2/production.png';
import packaging from '../../pages/Assets2/packaging.png';
import manufacturing from '../../pages/Assets2/manufacturing.png';
import transport from '../../pages/Assets2/transport.png'
import upstream from '../../pages/Assets2/upstream.png';
import others from '../../pages/Assets2/others.png';
import downstream from '../../pages/Assets2/downstream.png';
import franchise from '../../pages/Assets2/franchise.png'
import { ActivityDialog } from './ActivityDialog';
import { useNavigate } from 'react-router-dom';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const CenterMap = ({ position }) => {
  const map = useMap();
  map.setView(position, 6); // ZOOM LEVEL OF MAP
  return null;
};

const locationTypeIcons = [
  {
    name: 'Production',
    icon: production,
  },
  {
    name: 'Warehouse',
    icon: warehouse,
  },
  {
    name: 'Upstream Leased Assets',
    icon: upstream,
  },
  {
    name: 'Packaging',
    icon: packaging,
  },
  {
    name: 'Transport',
    icon: transport,
  },
  {
    name: 'Factory',
    icon: manufacturing,
  },
  {
    name: 'Others',
    icon: others,
  },
  {
    name: 'Downstream leased assets',
    icon: downstream,
  },
  {
    name: 'Franchise',
    icon: franchise,
  }
];

const getCustomIcon = (type) => {
  const icon = locationTypeIcons.find((icon) => icon.name === type)?.icon || manufacturing;
  return L.icon({
    iconUrl: icon,
    iconSize: [52, 52],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });
};

export const SupplierCorporateCanvas = () => {
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));
  const { enqueueSnackbar } = useSnackbar();
  const [locations, setLocations] = useState([]);
  const [center, setCenter] = useState([51.505, -0.09]);
  const [open, setOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [fetchedLocations, setFetchedLocations] = useState([]);

  const openActivityPage = (location) => {
    console.log("activity lcoation", location)
    navigate(`/carboncompete/suppliercorporatecanvas/activity/${location.id}?name=${encodeURIComponent(location.name)}`,
      {
        state: {
          configuredTemplate: location.configuredTemplate
        }
      }
    );
    setSelectedLocation(location);
  };

  console.log(selectedLocation)

  useEffect(() => {
    async function fetchLocations() {
      try {
        const response = await getSupplierLocationData(userInfo?.userId);
        if (response.success) {
          setFetchedLocations(response.data);
          if (response.data.length > 0 && response.data[0].coordinates) {
            setCenter(response.data[0].coordinates);
          }
        } else {
          console.error("Error fetching location data:", response.msg);
        }
      } catch (error) {
        console.error("Error fetching location data:", error);
      }
    }
    fetchLocations();
  }, [userInfo?.compId]);

  useEffect(() => {
    const newLocations = fetchedLocations
      .filter(location => location.coordinates)
      .map(location => ({
        id: location.locationId,
        name: location.locationName,
        position: location.coordinates,
        type: location.locationType, // Include locationType
        configuredTemplate: location.configuredTemplate
      }));
    setLocations(newLocations);
  }, [fetchedLocations]);

  const handleMarkerClick = (location) => {
    setSelectedLocation(location);
    setOpen(true);
  };

  const handleSubmit = () => {
    enqueueSnackbar('Form submitted successfully!', { variant: 'success' });
    setOpen(false);
  };

  const handleCloseForm = () => {
    setOpen(false);
  };

  const handleSaveLocation = async () => {
    if (selectedLocation) {
      const locationExists = fetchedLocations.some(loc =>
        loc.coordinates[0] === selectedLocation.position[0] &&
        loc.coordinates[1] === selectedLocation.position[1]
      );

      if (locationExists) {
        enqueueSnackbar('Location already exists!', { variant: 'error' });
        setLocations(fetchedLocations.map(location => ({
          id: location.locationId,
          name: location.locationName,
          position: location.coordinates,
          type: location.locationType
        })));
        handleCloseForm();
        return;
      }

      const firstWord = selectedLocation.name.split(',')[0].trim();
      const payload = {
        locationName: firstWord,
        shortName: firstWord,
        coordinates: selectedLocation.position,
        isActive: true,
        userId: userInfo?.userId,
        compId: userInfo?.compId,
        templatevalue: null,
      };

      try {
        const response = await postLocationData(payload);
        if (response.success) {
          enqueueSnackbar('Location saved successfully!', { variant: 'success' });
          const updatedResponse = await getLocationData(userInfo?.compId);
          if (updatedResponse.success) {
            setFetchedLocations(updatedResponse.data);
            setLocations(updatedResponse.data.map(location => ({
              id: location.locationId,
              name: location.locationName,
              position: location.coordinates,
              type: location.locationType // Include locationType
            })));
          }
        } else {
          enqueueSnackbar(`Error: ${response.msg}`, { variant: 'error' });
        }
      } catch (error) {
        console.error("Error saving location:", error);
        enqueueSnackbar('Error saving location. Please try again.', { variant: 'error' });
      }
      handleCloseForm();
    }
  };

  const isLocationSaved = selectedLocation && fetchedLocations.some(loc =>
    loc.coordinates[0] === selectedLocation.position[0] &&
    loc.coordinates[1] === selectedLocation.position[1]
  );

  return (
    <Box>
      <Box sx={{ height: '100vh', width: '100%' }}>
        <MapContainer center={center} zoom={13} style={{ height: '100%', width: '100%' }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {locations.map((location) => (
            <Marker
              key={location.id}
              position={location.position}
              icon={getCustomIcon(location.type)}
              eventHandlers={{
                click: () => openActivityPage(location),
              }}
            >
              <Popup>
                <Typography variant="body2">{location.name}</Typography>
              </Popup>
            </Marker>
          ))}
          <CenterMap position={center} />
        </MapContainer>
      </Box>
      <ActivityDialog
        open={open}
        handleClose={handleCloseForm}
        handleSubmit={handleSubmit}
        handleSaveLocation={handleSaveLocation}
        isLocationSaved={isLocationSaved}
        selectedLocation={selectedLocation}
      />
    </Box>
  );
};