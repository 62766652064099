import React, { useEffect, useState,  } from "react";
import { useNavigate } from "react-router-dom";
import CanvasFlow from "./CanvasFlow";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
  ThemeProvider,
  Tooltip,
  createTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Helmet } from "react-helmet-async";
import catalogue from '../Assets2/catalogue.png';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getAllProducts } from "../../services/catalogue/productlist";
import Joyride from "react-joyride";

const joyrideSteps=[{
  target: `body`,
  title: `Dive into the Carbon Canvas!`,
  content: `Visualize and Configure Your Product's Supply Chain`,
  locale: {
    next: `Start`, skip: `Skip`
  },
  disableBeacon: true,
  placement: `center`
},{
  target:"#searchProduct",
  content:"Search for a product to display on the canvas or select one from the list and drag it to the canvas.",
  spotlightPadding:0,
  disableBeacon: true,
  placement:"right"
},{
  target: `#carbonCanvasList`,
  content:"Easily drag and drop a product to see its supply chain configuration.",
  spotlightPadding:0,
  disableBeacon: true,
},
{
  target:"#dragAndDropProduct",
  content:"Use this canvas to see your product's supply chain and activity stages. Drag your product here, then click on a stage to enter activity values manually or use AI to autofill. You can utlize our smart data extraction and access comprehensive analytics for each stage's activity.",
  spotlightPadding:0,
  disableBeacon: true,
}
]

const classes = {
  root: {
    display: "flex",
    height: "86vh",
  },
  aside: {
    backgroundColor: "#fff",
    borderRight: "0.5px solid #F1F1F1",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    overflow: 'auto',
    boxShadow: '0px 0px 15px -5px',
    height: '86vh'
  },
  subcategory: {
    padding: "2px 0 2px 10px",
    marginTop: "16px",
    fontSize: "16px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: "#000",
    borderRadius: "4px",
    border: "2px solid #f1f1f1",
  },
  subcategoryIcon: {
    height: "40px",
    width: "40px",
    "&:hover": {
      backgroundColor: "green",
      color: "#fff",
    },
  },
  content: {
    flex: "1",
    borderRadius: "10px",
  },
};

const Canvas = () => {
  const [search, setSearch] = useState("");
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [draggedItem, setDraggedItem] = useState(null);
  const [showDropNote,setShowDropNote]=useState(false)
  const navigate= useNavigate(null)
  const [runJoyrideCanvas, setRunJoyrideCanvas] = useState(false)

  const handleJoyrideCallback = (data) =>{
    if (data.action==="close"){
      setRunJoyrideCanvas(false)
      localStorage.setItem("canvastour",'false')
    } 

    if ((data.action==="reset")){
      setRunJoyrideCanvas(false)
      localStorage.setItem("canvastour",'false')
      navigate('/carboncompete/suppliers')
    }
  }

  useEffect(() => {

    const guidetourval= localStorage.getItem("canvastour")==='false' ? false:true    
    setRunJoyrideCanvas(guidetourval)
  }, []);

    const fetchAllProducts = () =>{
    setShowLoader(true);
    getAllProducts().then(({ data = {} }) => {
      setItems(data);
      setShowLoader(false);
    }).catch(err => {
      setShowLoader(false);
    });
  }
  useEffect(() => {
    fetchAllProducts();
  }, []);

  const handleSearch = (event) => {
    const searchQuery = event.target.value;
    setSearch(searchQuery);
    const filtered = items.filter((item) =>
      item.productName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredItems(filtered.length > 0 ? filtered : null);
  };

  const theme = createTheme({
    typography: {
      fontFamily: 'Poppins',
    },
  });

  const handleDragStart = (event, product) => {
    setShowDropNote(false)
    setDraggedItem(product);
    event.dataTransfer.setData("application/reactflow", product.productName);
    event.dataTransfer.setData("application/subcategory-icon", product.icon);
    event.dataTransfer.setData("application/productObj", JSON.stringify(product));
    event.dataTransfer.effectAllowed = "move";
  };

 
  return (
    <ThemeProvider theme={theme}>
      <div style={classes.root}>
        <Helmet>
          <title> Carbon Canvas </title>
        </Helmet>
        <Joyride 
        steps={joyrideSteps}
        run={runJoyrideCanvas}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        spotlightClicks="true"
        callback={handleJoyrideCallback}
        styles={{
          options: {
            zIndex: 10000,  
          },
  
            overlay: {
            top: 0,
            left: 0,
            width: '100%',
            height: '1000%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', 
            zIndex: 9999,
          },
          tooltip: {
            zIndex: 10001,  
          },
          spotlight: {
            borderRadius: 'inherit',
            padding: 0,  
          },
          buttonNext: {
            backgroundColor: '#009999', 
            color: 'white',
          },
          buttonBack: {
            backgroundColor: '#009999',  
            color: 'white',
          },
          buttonSkip: {
            backgroundColor: '#009999',  
            color: 'white',
          },
        }}
        />
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showLoader}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <aside style={classes.aside}>
          <Box pt={5} px={2} sx={{ position:"sticky"}}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Stack
                sx={{
                  width: "95%", borderRadius: '8px',
                  border: '1px solid #E3E8F1',
                  background: '#F3FAFA',
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.08)',
                 
                }}
                spacing={1}>
                <TextField
                  id="searchProduct"
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Search Product"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={search}
                  onInput={handleSearch}
                />
              </Stack>
            </Grid>
          </Box>
          <List
          id= "carbonCanvasList" 
          sx={{
            padding: '0 15px', overflow: 'auto',
            '-ms-overflow-style': 'none', /* for Internet Explorer, Edge */
            'scrollbar-width': 'none', /* for Firefox */
            'overflow-y': 'scroll'
          }}>
            {(filteredItems || items || []).map((item) => (
              <Tooltip
                key={item.productId}
                title={
                  <div>
                    <div style={{ textAlign: 'center' }}>{item?.productName}</div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <span>Drag and drop to the canvas</span>
                      <ArrowForwardIcon style={{ marginLeft: '5px' }} />
                    </div>
                  </div>
                }
                arrow
                placement="right"
              >
                <ListItem
                  style={classes.subcategory}
                  onDragStart={(event) =>
                    handleDragStart(event, item)
                  }
                  draggable
                >
                  <ListItemAvatar>
                    <img
                      style={classes.subcategoryIcon}
                      src={item?.imageFile || catalogue}
                      alt={item?.productName}
                    />
                  </ListItemAvatar>

                  <ListItemText primary={item?.productName.slice(0, 17) + '..'} />
                </ListItem>
              </Tooltip>
            ))}
          </List>
        </aside>
        <div style={classes.content} id="dragAndDropProduct">
          <CanvasFlow data={draggedItem} setData={setDraggedItem} />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Canvas;