import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
  createTheme,
  Grid,
  Button,
  CardContent,
  Typography,
  Card,
  CardHeader,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Iconify from "../../../components/iconify";
import { getCategoryTextColor } from "../../../provider/categoryColor";
import { commaNumbers, roundDecimal, sortFiscalYearsDescending } from "../../../utils/formatNumber";
import {
  getUnits,
  stringWithUnderScore,
  stringWithUnderScoreWithUpperCase,
} from "../../../utils/helperFunctions";

const firstYearIntensityChange = {};

export default function EsgStageCards({ Data }) {
  const [selectedLocation, setSelectedLocation] = useState("All");
  const [selectedYear, setSelectedYear] = useState("All");
  const { stageName } = useParams();

  useEffect(() => {
    if (Data) {
      const allYears = Data.map((location) =>
        Object.keys(location.years)
      ).flat();
      const commonYearsSet = new Set(
        allYears.filter((year) =>
          allYears.every((y) =>
            Data.some((location) => Object.keys(location.years).includes(y))
          )
        )
      );

      // Convert Set to array, convert years to numbers, sort in descending order, then convert back to Set
      setCommonYears(Array.from(commonYearsSet).reverse());
    }
  }, [Data]);

  const [commonYears, setCommonYears] = useState([]);

  const locations = ["All", ...Data.map((location) => location.location)];

  const yearsSet = new Set();
  Data.forEach((item) => {
    if (item && item.years) {
      Object.keys(item.years).forEach((year) => {
        yearsSet.add(year);
      });
    }
  });

  const time = ["All", ...yearsSet];


  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value);
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const navigate = useNavigate();

  const selectStyles = {
    width: "100%",
    height: "42px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    fontFamily: "Poppins !important",
    fontWeight: 900,
    border: "none !important",
    "&:focus": {
      border: "none",
    },
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    variant: "menu",
  };

  const theme = createTheme({
    typography: {
      fontFamily: "Poppins !important",
      fontWeight: "bolder !important",
    },
  });
  const useStyles = makeStyles((theme) => ({
    backButton: {
      marginLeft: "20px",
      marginBottom: "10px",
      backgroundColor: "#222",
      "&:hover": { backgroundColor: "#149BA1" },
      color: "#fff",
      display: "flex",
      width: "100px",
      alignItems: "center",
      padding: theme.spacing(1),
      borderRadius: "10px",
    },
  }));
  const classes = useStyles();

  let orders = [];
  if (stageName === "Energy") {
    orders = [
      "DIESEL",
      "EB_POWER",
      "Total_Intensity",
      "RE_POWER",
      "IntensityChange",
      "ENERGY_SAVED",
      "RE",
      "RE_SHARE",
    ];
  } else if (stageName === "Water") {
    orders = [
      "FRESH_WATER",
      "Total_Intensity",
      "IntensityChange",
      "Treated_Water_Share",
      "TREATED_WATER",
      "WATER_SAVED",
    ];
  } else if (stageName === "Food_Travel") {
    orders = [
      "InBound",
      "OutBound",
      "IntensityChange",
      "Total_Intensity",
      "EV",
      "CNG",
    ];
  } else if (stageName === "Waste") {
    orders = ["NON_FV_DUMP", "FV_DUMP", "Total_Intensity", "IntensityChange"];
  }

  const store = {
    IntensityChange: {},
  };
  Data.forEach((loc) => {
    if (selectedLocation === "All" || selectedLocation === loc.location) {
      const yearsToIterate =
        selectedYear === "All" ? Object.keys(loc.years) : [selectedYear];
      yearsToIterate.forEach((year) => {
        const yearData = loc.years?.[year];
        if (yearData) {
          Object.keys(yearData).forEach((stage) => {
            const activityData = yearData[stage]?.yearlyData;

            if (activityData && yearData[stage]?.stageName === stageName) {
              if (stageName === "Waste") {
                const {
                  FV_DUMP = 0,
                  CARTON_BOX = 0,
                  METAL_SCRAP = 0,
                  PLASTIC_CRATE = 0,
                  DRY_WASTE = 0,
                } = activityData.Yearly_Activity_Breakdown || {};

                store["FV_DUMP"] = store["FV_DUMP"] || {};
                store["FV_DUMP"][year] =
                  (store["FV_DUMP"][year] || 0) + FV_DUMP;

                store["NON_FV_DUMP"] = store["NON_FV_DUMP"] || {};
                store["NON_FV_DUMP"][year] =
                  (store["NON_FV_DUMP"][year] || 0) +
                  (CARTON_BOX + METAL_SCRAP + PLASTIC_CRATE + DRY_WASTE);
              } else if (stageName === "Food_Travel") {
                const {
                  DIESEL_FIRST = 0,
                  CNG_FIRST = 0,
                  EV_FIRST = 0,
                  DIESEL_LAST = 0,
                  EV_LAST = 0,
                  CNG_LAST = 0,
                } = activityData.Yearly_Activity_Breakdown || {};

                store["InBound"] = store["InBound"] || {};
                store["InBound"][year] =
                  (store["InBound"][year] || 0) + DIESEL_FIRST + CNG_FIRST;

                store["OutBound"] = store["OutBound"] || {};
                store["OutBound"][year] =
                  (store["OutBound"][year] || 0) + DIESEL_LAST + CNG_LAST;

                store["EV"] = store["EV"] || {};
                store["EV"][year] =
                  (store["EV"][year] || 0) + EV_FIRST + EV_LAST;

                store["CNG"] = store["CNG"] || {};
                store["CNG"][year] =
                  (store["CNG"][year] || 0) + CNG_FIRST + CNG_LAST;
              } else if (stageName === "Water") {
                Object.keys(
                  activityData.Yearly_Activity_Breakdown || {}
                ).forEach((activity) => {
                  store[activity] = store[activity] || {};
                  store[activity][year] =
                    (store[activity][year] || 0) +
                    (activityData.Yearly_Activity_Breakdown[activity] || 0);
                });
              } else if (stageName === "Energy") {
                Object.keys(
                  activityData.Yearly_Activity_Breakdown || {}
                ).forEach((activity) => {
                  store[activity] = store[activity] || {};
                  store[activity][year] =
                    (store[activity][year] || 0) +
                    (activityData.Yearly_Activity_Breakdown[activity] || 0);
                });
              }

              store["Total_Revenue"] = store["Total_Revenue"] || {};
              store["Total_Revenue"][year] =
                (store["Total_Revenue"][year] || 0) +
                (activityData.Total_Yearly_Revenue || 0);

              store["Total_Activity"] = store["Total_Activity"] || {};
              store["Total_Activity"][year] =
                (store["Total_Activity"][year] || 0) +
                (activityData.Total_Yearly_ActivityData || 0);
            }
          });

          if (stageName === "Energy") {
            store["RE_SHARE"] = store["RE_SHARE"] || {};
            store["RE_SHARE"][year] = store["RE_SHARE"][year] || 0;
            if (
              store["RE_POWER"]?.[year] !== undefined &&
              store["EB_POWER"]?.[year] !== undefined &&
              store["DIESEL"]?.[year] !== undefined
            ) {
              store["RE_SHARE"][year] =
                (store["RE_POWER"][year] /
                  (store["EB_POWER"][year] +
                    store["RE_POWER"][year] +
                    store["DIESEL"][year])) *
                100;
            }
          }
          if (stageName === "Water") {
            store["Treated_Water_Share"] = store["Treated_Water_Share"] || {};
            store["Treated_Water_Share"][year] =
              store["Treated_Water_Share"][year] || 0;
            if (
              store["TREATED_WATER"]?.[year] !== undefined &&
              store["FRESH_WATER"]?.[year] !== undefined
            ) {
              store["Treated_Water_Share"][year] =
                (store["TREATED_WATER"][year] /
                  (store["FRESH_WATER"][year] + store["TREATED_WATER"][year])) *
                100;
            }
          }

          store["Total_Intensity"] = store["Total_Intensity"] || {};
          store["Total_Intensity"][year] = store["Total_Intensity"][year] || 0;

          if (
            store["Total_Revenue"] &&
            store["Total_Revenue"][year] !== 0 &&
            store["Total_Activity"] &&
            store["Total_Activity"][year] !== 0
          ) {
            store["Total_Intensity"][year] =
              store["Total_Activity"][year] / store["Total_Revenue"][year];
          } else {
            store["Total_Intensity"][year] = 0;
          }
        }
      });
    }
  });
  let totalRECapacity=0
  if(store["RE_CAPACITY"]){
     totalRECapacity = Object.values(store["RE_CAPACITY"] || {}).reduce(
      (sum, value) => sum + value,
      0
    );
  }

 
  if (stageName == "Energy") {
    if (!store["RE"]) {
      store["RE"] = {};
    }
    if (!store["RE"]["value"]) {
      store["RE"]["value"] = 0;
    }
    store["RE"]["value"] = totalRECapacity;
  }


  if(store["Total_Intensity"]){
    const yearsKey = Object.keys(store.Total_Intensity);

    const yearsSet=new Set()

    yearsKey.forEach((year)=>{
      yearsSet.add(year)
    })

    const yearArray=[...yearsKey]

    const sortedYearsArray=sortFiscalYearsDescending(yearArray)
    // console.log(sortedYearsArray)
  yearsKey.forEach((val) => {
    if (selectedYear !== "All") {
      Object.keys(store.Total_Intensity).forEach((year) => {
        if (year == selectedYear) {
          const lastYearData = store.Total_Intensity[year];
          const firstYearData = Object.values(firstYearIntensityChange)[0];
          const percentChange =
            ((lastYearData - firstYearData) / firstYearData) * 100;
          store.IntensityChange["year"] = store.IntensityChange["year"] || {};
          store.IntensityChange["year"][
            Object.keys(firstYearIntensityChange)[0]
          ] = parseFloat(percentChange);
        }
      });
    } else {
      let firstYearIndex = sortedYearsArray.length - 1;
      let firstYearData = store.Total_Intensity[sortedYearsArray[firstYearIndex]]; 
      while(firstYearData===undefined || isNaN(firstYearData)){
        firstYearIndex-=1
        if(firstYearIndex<=sortedYearsArray.length){
          firstYearData=null
          break;
        }
        firstYearData=store.Total_Intensity[sortedYearsArray[firstYearIndex]]
      }
      let lastYearIndex = 0;
let lastYearData = store.Total_Intensity[sortedYearsArray[lastYearIndex]];

// Loop to find the next valid lastYearData if it is undefined or NaN
while (lastYearData === undefined || isNaN(lastYearData)) {
  lastYearIndex += 1;
  if (lastYearIndex >= sortedYearsArray.length) {
    // If no valid lastYearData is found, handle it appropriately (e.g., break the loop or set a default value)
    lastYearData = null;
    break;
  }
  lastYearData = store.Total_Intensity[sortedYearsArray[lastYearIndex]];
}


      firstYearIntensityChange[sortedYearsArray[firstYearIndex]] = store.Total_Intensity[sortedYearsArray[firstYearIndex]];
      const percentChange =
        ((lastYearData - firstYearData) / firstYearData) * 100;
      store.IntensityChange["year"] = store.IntensityChange["year"] || {};
      store.IntensityChange["year"][sortedYearsArray[firstYearIndex]] = parseFloat(percentChange);
    }
  });
  }


  //We are using this ---> Function to sort dates in descending order
  const sortDatesDescending = (a, b) => {
    return parseInt(b.split("-")[1]) - parseInt(a.split("-")[1]);
  };

  return (
    <div className={classes.container}>
      <ThemeProvider theme={theme}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Button
              className={classes.backButton}
              onClick={() => navigate("/carboncompete/esgdashboard")}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Iconify
                  icon="icon-park-outline:back"
                  color="white"
                  className={classes.backIcon}
                />
                <Typography
                  fontFamily="Poppins"
                  style={{ textTransform: "capitalize" }}
                >
                  Back
                </Typography>
              </div>
            </Button>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="location-select-label">
                Select Location
              </InputLabel>
              <Select
                labelId="location-select-label"
                id="location-select"
                value={selectedLocation}
                label="Select Location"
                onChange={handleLocationChange}
                MenuProps={menuProps}
                style={selectStyles}
              >
                {locations.map((location) => (
                  <MenuItem key={location} value={location}>
                    {location}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="year-select-label">Select Year</InputLabel>
              <Select
                labelId="year-select-label"
                id="year-select"
                value={selectedYear}
                label="Select Year"
                onChange={handleYearChange}
                MenuProps={menuProps}
                style={selectStyles}
              >
                {time.map((year) =>
                  year === "All" ? (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ) : (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid mt={1} mb={2} container spacing={2} padding={2}>
          {orders.map((category) =>
            category === "IntensityChange" ? (
              <Grid key={category} item xs={12} sm={6} md={3} lg={3}>
                <Card
                  sx={{
                    border: `1px solid ${getCategoryTextColor(category)}`,
                    borderRadius: "6px",
                    boxShadow: " 0 6px 10px 0 rgba(0, 0, 0, 0.06) ",
                  }}
                >
                  <CardHeader
                    sx={{ padding: "12px 10px", textTransform: "uppercase" }}
                    title={`% change in ${stageName} Intensity `}
                    titleTypographyProps={{
                      fontSize: 14,
                      fontWeight: 600,
                      color: getCategoryTextColor(category),
                    }}
                  />
                  <CardContent
                    sx={{
                      height: "110px",
                      overflowY: "scroll",
                      scrollbarWidth: "thin",
                      padding: 0,
                    }}
                  >
                    {Object.keys(store[category] || {})
                      .sort(sortDatesDescending)
                      .map((stage, index) => (
                        <React.Fragment key={index}>
                          {Object.keys(store[category][stage]).map(
                            (year, idx) => {
                              const value = store[category][stage][year];
                              if (!isNaN(value) && value !== undefined) {
                                const textColor = value < 0 ? "green" : "red";
                                return (
                                  <div key={idx}>
                                    <CardHeader
                                      sx={{
                                        textAlign: "center",
                                        marginLeft: "70px", // Center align text
                                        color: textColor,
                                      }}
                                      title={
                                        <div
                                          style={{ display: "flex", gap: 10 }}
                                        >
                                          <span>
                                            {commaNumbers(
                                              roundDecimal(value, 2),
                                              2
                                            )}{" "}
                                            %
                                          </span>
                                        </div>
                                      }
                                      titleTypographyProps={{
                                        fontSize: 20,
                                        fontWeight: 900,
                                      }}
                                    />
                                  </div>
                                );
                              }
                              return null;
                            }
                          )}
                        </React.Fragment>
                      ))}
                  </CardContent>
                </Card>
              </Grid>
            ) : category === "RE" ? (
              <Grid key={category} item xs={12} sm={6} md={3} lg={3}>
                <Card
                  sx={{
                    border: `1px solid ${getCategoryTextColor(category)}`,
                    borderRadius: "6px",
                    boxShadow: " 0 6px 10px 0 rgba(0, 0, 0, 0.06) ",
                  }}
                >
                  <CardHeader
                    sx={{
                      padding: "12px 10px",
                    }}
                    title={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {`${stringWithUnderScoreWithUpperCase(
                          category
                        )} CAPACITY ${getUnits({
                          category,
                          stageName,
                        })}`}
                      </div>
                    }
                    titleTypographyProps={{
                      fontSize: 15,
                      fontWeight: 600,
                      color: getCategoryTextColor(category),
                    }}
                  />
                  <CardContent
                    sx={{
                      height: "110px",
                      overflowY: "scroll",
                      scrollbarWidth: "thin",
                      padding: 0,
                    }}
                  >
                    {Object.keys(store[category] || {})
                      .sort(sortDatesDescending)
                      .map((stage, index) => (
                        <React.Fragment key={index}>
                          {(() => {
                            const value = store[category][stage];
                            if (!isNaN(value) && value !== undefined) {
                              return (
                                <div key={index}>
                                  <CardHeader
                                    sx={{
                                      textAlign: "center",
                                      marginLeft: "70px", // Center align text
                                    }}
                                    title={
                                      <div style={{ display: "flex", gap: 10 }}>
                                        <span>
                                          {commaNumbers(
                                            roundDecimal(value, 2),
                                            2
                                          )}
                                        </span>
                                      </div>
                                    }
                                    titleTypographyProps={{
                                      fontSize: 20,
                                      fontWeight: 900,
                                    }}
                                  />
                                </div>
                              );
                            }
                            return null; // Ensure to return null if conditions are not met
                          })()}
                        </React.Fragment>
                      ))}
                  </CardContent>
                </Card>
              </Grid>
            ) : (
              <Grid key={category} item xs={12} sm={6} md={3} lg={3}>
                <Card
                  sx={{
                    border: `1px solid ${getCategoryTextColor(category)}`,
                    borderRadius: "6px",
                    boxShadow: " 0 6px 10px 0 rgba(0, 0, 0, 0.06) ",
                  }}
                >
                  <CardHeader
                    sx={{
                      padding: "12px 10px",
                    }}
                    title={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {`${stringWithUnderScoreWithUpperCase(
                          category
                        )} ${getUnits({
                          category,
                          stageName,
                        })}`}
                      </div>
                    }
                    titleTypographyProps={{
                      fontSize: 15,
                      fontWeight: 600,
                      color: getCategoryTextColor(category),
                    }}
                  />
                  <CardContent
                    sx={{
                      height: "110px",
                      overflowY: "scroll",
                      scrollbarWidth: "thin",
                      padding: 0,
                    }}
                  >
                    {Object.keys(store[category] || {})
                      .sort(sortDatesDescending)
                      .map((year, index) => (
                        <>
                          {!isNaN(store[category][year]) && (
                            <div key={index}>
                              <CardHeader
                                sx={{ paddingTop: 0.5 }}
                                title={
                                  <div
                                    style={{
                                      display: "flex",

                                      gap: 10,
                                    }}
                                  >
                                    <span key={index}>
                                      {commaNumbers(
                                        roundDecimal(store[category][year], 2),
                                        2
                                      )}
                                    </span>
                                  </div>
                                }
                                subheader={<span key={index}>{year}</span>}
                                titleTypographyProps={{
                                  fontSize: 14,
                                  fontWeight: 700,
                                }}
                                subheaderTypographyProps={{
                                  fontSize: 14,
                                  fontWeight: 700,
                                }}
                              />
                            </div>
                          )}
                        </>
                      ))}
                  </CardContent>
                </Card>
              </Grid>
            )
          )}
        </Grid>
      </ThemeProvider>
    </div>
  );
}
