import axios from "axios";

const MappingURL = process.env.REACT_APP_MAPPING_API_URL;
const TemplateURL = process.env.REACT_APP_TEMPLATE_API_URL;
const AIAPI = process.env.REACT_APP_AI_EXTERNAL_API_URL;
const TEMP_URI=process.env.REACT_APP_AI_TEMPLATE_VALUE_URL
const SMART_URL=process.env.REACT_APP_SMART_EXTRACTION_URL
export const postExternalMappingService = async (data) => {
    try {
        const response = await axios({
            url: `${MappingURL}/items`,
            method: 'POST',
            data: data
        });
        return response.data;
    } catch (err) {
        console.log(err);
        return err;
    }
};

export const generateTemplateAIValues=async(data)=>{
    try {
         
       
        const response=await axios({
            url:`${TEMP_URI}/process_activity`,
            method:"POST",
            data:data
        })

        return (response.data)
      
    } catch (error) {
       console.log(error)   
    }
}

export const templateCalculation=async(data)=>{
    try {
        
        const response=await axios({
            url:`${TEMP_URI}/calculate`,
            method:"POST",
            data:data
        })
        console.log(data)
        return response.data
    } catch (error) {
       console.log(error)   
    }
    
}

export const postExternalTemplateService = async (data) => {
    try {
        const response = await axios({
            url: `${TemplateURL}/process`,
            method: 'POST',
            data: data
        });
        return response.data;
    } catch (err) {
        console.log(err);
        return err;
    }
};

export const postGenerateSupplyChain = async (data) => {
    try {
        const response = await axios({
            url: `${AIAPI}/generate`,
            // url: `${AIAPI}/llamagen`,
            method: 'POST',
            data: data
        });
        return response.data;
    } catch (err) {
        console.log(err);
        return err;
    }
};

export const postGenerateStageSchema = async (data) => {
    try {
        const response = await axios({
            url: `${AIAPI}/templateSchemaProp`,
            method: 'POST',
            data: data
        });
        return response.data;
    } catch (err) {
        console.log(err);
        return err;
    }
};

export const postGenerateActivityObj = async (data) => {
    try {
        const response = await axios({
            url: `${AIAPI}/templateObject`,
            method: 'POST',
            data: data
        });
        return response.data;
    } catch (err) {
        console.log(err);
        return err;
    }
};

export const postSmartDataExtraction = async (formData, jsonPayload) => {
    try {
        formData.append('payload', JSON.stringify(jsonPayload));

        const response = await axios({
            url: `${SMART_URL}/smart_extraction`,
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (err) {
        console.log(err);
        return err;
    }
};
