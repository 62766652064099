import numeral from "numeral";

// ----------------------------------------------------------------------

export function fNumber(number) {
  return numeral(number).format();
}

export function fCurrency(number) {
  const format = number ? numeral(number).format("$0,0.00") : "";

  return result(format, ".00");
}

export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format("0.0%") : "";

  return result(format, ".0");
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format("0.00a") : "";

  return result(format, ".00");
}

export function fData(number) {
  const format = number ? numeral(number).format("0.0 b") : "";

  return result(format, ".0");
}

function result(format, key = ".00") {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, "") : format;
}

/**
 * @param {Float} data
 * @param {Integer} round
 * @description A helper function which removes the extra decimal values from the float
 * @returns {String}
 */

export function roundDecimal(data, round) {
  let addon = parseInt("1" + "0".repeat(round));
  return parseFloat((Math.round(data * addon) / addon).toFixed(round));
}

export const sortFiscalYearsDescending=(fiscalYears)=>{
    return fiscalYears.sort((a,b)=>{
      const yearA=parseInt(a.split(" ")[1].split("-")[1],10)
      const yearB=parseInt(b.split(" ")[1].split("-")[1],10)
      return yearB-yearA
    })
}

export const commaNumbers = (number, decimalPlaces) => {
  if (number === null || number === undefined) return "";
  const factor = Math.pow(10, decimalPlaces);
  const roundedNumber = Math.round(number * factor) / factor;
  return roundedNumber.toLocaleString(undefined, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
};

export const transformYearToFiscal = (selectedYears) => {
  if (!Array.isArray(selectedYears)) {
    return "";
  }

  return selectedYears
    .map((year) => {
      const nextYear = parseInt(year) + 1;
      return `FY ${year.slice(2)}-${nextYear.toString().slice(2)}`;
    })
    .join(", ");
};
