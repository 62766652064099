import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import leaf from "../../Assets2/leaf.png";
import { Icon } from "@iconify/react";

const Wrapper = styled.div`
  position: relative;
  height: 240px;
  width: 320px;
  display: flex;
  justify-center: "center";
  align-items: center;
`;

const Progress = styled.svg`
  width: 280px;
  height: 260px;
`;

const TrackAndFill = css`
  fill: rgba(0, 0, 0, 0);
  stroke-width: 30;
  transform: translate(290px, 800px) rotate(-120deg);
`;

const Track = styled.path`
  ${TrackAndFill}
  stroke: #EEEEEE;
`;

const Fill = styled.path`
  ${TrackAndFill}
  stroke: #149BA1;
  stroke-linecap: round;
  stroke-dasharray: 2160;
  stroke-dashoffset: 2160;
  transition: stroke-dashoffset 1s;
  &.blue {
    stroke: rgb(104, 214, 198);
  }
  &.green {
    stroke: rgb(186, 223, 172);
  }
`;

const SmallTrack = styled.path`
  fill: rgba(0, 0, 0, 0);
  stroke-width: 30;
  transform: translate(200px, 800px) rotate(-120deg);
  stroke: #eeeeee;
  width: 100px;
  height: 140px;
`;

const SmallTrack2 = styled.path`
  fill: rgba(0, 0, 0, 0);
  stroke-width: 30;
  transform: translate(200px, 800px) rotate(-120deg);
  stroke: #eeeeee;
  width: 100px;
  height: 140px;
`;

const Line = styled.div`
  width: 2.5cm;
  height: 0.038cm; /* You can adjust the thickness of the line */
  background-color: black; /* Color of the line */
`;

const TextAbove = styled.div`
  font-weight: 'bold,
  margin-bottom: 0cm; /* Adjust the space between the line and text above */
`;

export const ProgressBarSupplier = ({ highestScoreProduct }) => {
  let strokeDashoffset = 0;

  if (highestScoreProduct && highestScoreProduct.stage_confidence_score !== null) {
    strokeDashoffset = (1440 * (100 - highestScoreProduct.stage_confidence_score)) / 100;
  }

  return (
    <Wrapper>
      <div>
        <Progress
          className={`progress blue noselect`}
          data-progress={25}
          x="0px"
          y="0px"
          viewBox="0 0 785 628"
          style={{ transform: "rotate(180deg)", marginLeft: "0x" }}
        >
          <Track
            className="track"
            d="M723 314L543 625.77 183 625.77 3 314 183 2.23 543"
            style={{ strokeWidth: "85px" }}
          />

          <SmallTrack
            d="M723 314L543 625.77 183 625.77 3 314 183 2.23 543"
            style={{
              strokeWidth: "7px",
              stroke: "#505050",
              strokeDasharray: "40,20",
              transform: "translateX(300px) translateY(800px) rotate(-120deg)",
            }}
          />

          <Fill
            className="fill"
            d="M723 314L543 625.77 183 625.77 3 314 183 2.23 543 "
            style={{
              strokeDasharray: "1440",
              strokeDashoffset: strokeDashoffset,
              strokeWidth: "85px",
            }}
          ></Fill>
        </Progress>
      </div>
      <div style={{ marginLeft: "-260px" }}>
        <Progress
          viewBox="0 0 785 628"
          style={{
            transform: "rotate(180deg)",
            marginLeft: "10px",
            height: "200px",
            width: "180px",
          }}
        >
          <SmallTrack2
            d="M723 314L543 625.77 183 625.77 3 314 183 2.23 543"
            style={{
              strokeWidth: "25px",
              stroke: "#FF9240",
            }}
          />
        </Progress>
      </div>

      <div
        style={{
          marginTop: "-22px",
          marginLeft: "50px",
          fontFamily: "poppins",
          textAlign: "center",
        }}
      >
        <img style={{ marginLeft: "67px" }} src={leaf} alt="leaf" />
        <div style={{ fontSize: "16px", fontWeight: "bolder", width: '150px' }}>
          CONFIDENCE SCORE
        </div>
        {highestScoreProduct && (
          <TextAbove style={{ fontSize: "1.3", fontWeight: "bold", fontFamily: 'poppins', }}>
            {highestScoreProduct.stage_confidence_score}%
          </TextAbove>
        )}
      </div>

      <div>
        <Icon
          style={{
            fontSize: "53px",
            marginBottom: "235px",
            marginLeft: "-212px",
            color: "#149BA1",
          }}
          icon="bxs:right-arrow"
        />
      </div>

      <div style={{ marginLeft: "-160px", marginTop: "10px" }}>
        {highestScoreProduct && (
          <p
            style={{
               fontSize: "16px",
              fontWeight: "900",
              marginTop: "-10px",
              marginBottom: "5px",
              marginLeft: "-130px",
              fontFamily: 'poppins'
            }}
          >
            {(highestScoreProduct.stage_carbon_score).toFixed(1)} Kg CO<sub>2</sub>e
          </p>
        )}
        <Line style={{ marginLeft: "-129px", marginTop: '7px' }} />
          <p
            style={{
              fontSize: "15px",
              fontWeight: '900',
              marginTop: "7px",
              marginBottom: "5px",
              marginLeft: "-140px",
              fontFamily: 'poppins'
            }}
          >
            {/* {(highestScoreProduct.stage_carbon_score).toFixed(0)} Co2 eq/Kg */}
            CarbonCompete
          </p>
      </div>
    </Wrapper>
  );
};