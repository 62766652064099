import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import { FormControl, InputLabel, Select, MenuItem, Typography, createTheme, ThemeProvider } from '@mui/material';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  customLegend: {
    position: 'absolute',
    bottom: 10,
    left: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  dot: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    // border: '0.5px solid #ccc',
    // boxShadow: '0px 8px 8px 0px rgba(0, 0, 0, 0.2)',
    marginRight: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const CarbonScoreChart = ({ productData }) => {
  const classes = useStyles();
  const [selectedProduct, setSelectedProduct] = useState('');

  useEffect(() => {
    if (productData[0]?.products.length > 0) {
      setSelectedProduct(productData[0].products[0].productName);
    }
  }, [productData]);

  const handleChange = (event) => {
    setSelectedProduct(event.target.value);
  };

  const selectedProductData = productData[0]?.products.find((product) => product.productName === selectedProduct);

  const chartOptions = {
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)',
    },
    legend: {
      show: false,
      top: '5%',
      left: 'center',
    },
    color: ['#149BA1', '#FFC950', '#FFA058', '#006799', '#DAF7A6', '#CCCCFF'],
    series: [
      {
        name: 'Emission Breakdown',
        type: 'pie',
        radius: ['50%', '90%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 5,
          borderColor: '#fff',
          borderWidth: 3,
        },
        label: {
          show: false,
          position: 'center',
          formatter: '{b}',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 14,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: selectedProductData?.emission_breakdown
          ? Object.keys(selectedProductData.emission_breakdown).map((key) => ({
            name: key,
            value: parseFloat(selectedProductData.emission_breakdown[key]).toFixed(0),
          }))
          : [],
      },
    ],
  };

  const legendItems = Object.keys(productData[0]?.products || {}).map((key) => (
    <div key={key} className={classes.legendItem}>
      <div className={classes.dot}
        style={{
          backgroundColor:
            chartOptions.series[0].data.find((item) => item.name === key)?.itemStyle?.color || 'gray',
        }}
      >
        {key}
      </div>
    </div>
  ));

  const theme = createTheme({
    typography: {
      fontFamily: 'Poppins',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.container}>
        <Typography
          sx={{ fontFamily: 'poppins', fontSize: '15px', fontWeight: 600, marginBottom: '25px', textAlign: 'center' }}
        >
          Emission Breakdowns
        </Typography>
        <FormControl fullWidth>
          <Select
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 180,
                  width: '300px',
                  overflowY: 'scroll',
                },
              },
            }}
            style={{
              width: '100%', height: '42px', backgroundColor: '#F1F1F1',
              borderRadius: '8px',
              fontWeight: 900,
              border: 'none !important',
              '&:focus': {
                border: 'none',
              },
            }}
            value={selectedProduct} onChange={handleChange}>
            {productData[0]?.products.map((product) => (
              <MenuItem key={product.productId} value={product.productName}>
                {product.productName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <ReactECharts option={chartOptions} style={{ height: '230px', width: '300px', marginTop: '10px' }} />
        <div className={classes.customLegend}>{legendItems}</div>
      </div>
    </ThemeProvider>
  );
};

export default CarbonScoreChart;