import React, { useEffect, useState } from "react";
import BarChartArea from "./BarChartArea";
import {
  ThemeProvider,
  createTheme,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { MultipleSelectWithCheckBox } from "../../../components/form/selectComponents";
import { toCamelCase } from "../../../utils/helperFunctions";

export default function IntensityChart({ data, stageId, stageName }) {
  const [monthlyDataStore, setMonthlyDataStore] = useState({});

  const theme = createTheme({
    typography: {
      fontFamily: "Poppins !important",
      fontWeight: "bolder !important",
    },
  });

  useEffect(() => {
    const newDataStore = {};
    data.forEach((entry) => {
      const yearlyData = entry.years;
      const location = entry.location;
      Object.keys(yearlyData).forEach((year) => {
        const monthlyData = yearlyData[year];
        if (!newDataStore[location]) {
          newDataStore[location] = {};
        }
        if (!newDataStore[location][year]) {
          newDataStore[location][year] = {};
        }
        Object.keys(monthlyData).forEach((stageKey) => {
          const stageData = monthlyData[stageKey];
          if (stageData.stageId === parseInt(stageId)) {
            Object.keys(stageData.monthlyData).forEach((month) => {
              if (!newDataStore[location][year]) {
                newDataStore[location][year] = {};
              }
              if (!newDataStore[location][year][month]) {
                newDataStore[location][year][month] =
                  stageData.monthlyData[month].totalMonthlyIntensity;
              }
            });
          }
        });
      });
    });
    setMonthlyDataStore(newDataStore);
  }, [data, stageId]);

  const [selectedLocation, setSelectedLocation] = useState(
    data ? data.map((location) => location.location) : []
  );
  const [selectedYear, setSelectedYear] = useState([]);
  const [commonYears, setCommonYears] = useState([]);

  const locations = data ? data.map((location) => location.location) : [];
  const isAllYearSelected =
    commonYears.length > 0 && selectedYear.length === commonYears.length;
  const isAllLocationSelected =
    locations.length > 0 && selectedLocation.length === locations.length;

  const handleYearChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedYear(
        selectedYear.length === commonYears.length ? [] : commonYears
      );
      return;
    }
    setSelectedYear(value);
  };

  const handleLocationChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedLocation(
        selectedLocation.length === locations.length ? [] : locations
      );
      return;
    }
    setSelectedLocation(value);
  };

  const [isFieldsSelected, setIsFieldsSelected] = useState(true);
  useEffect(() => {
    if (data) {
      const allYears = data
        .map((location) => Object.keys(location.years))
        .flat();
      const commonYearsSet = new Set(
        allYears.filter((year) =>
          allYears.every((y) =>
            data.some((location) => Object.keys(location.years).includes(y))
          )
        )
      );

      const sortedYearsArray = Array.from(commonYearsSet)
        .map((year) => year)
        .sort((a, b) => {
          const [fyAStart, fyAEnd] = a.split("-").map(Number);
          const [fyBStart, fyBEnd] = b.split("-").map(Number);
          return fyBStart - fyAStart || fyBEnd - fyAEnd;
        });

      setCommonYears(sortedYearsArray);
      setSelectedYear([
        sortedYearsArray[0],
        sortedYearsArray[sortedYearsArray.length - 1],
      ]);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const allLocations = data.map((location) => location.location);
      setSelectedLocation(allLocations);
    }
  }, [data]);

  useEffect(() => {
    setIsFieldsSelected(selectedLocation.length > 0 && selectedYear.length > 0);
  }, [selectedLocation, selectedYear]);


  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                  marginTop: "25px",
                  gap: "30px",
                  marginBottom: "30px",
                }}
              >
                <MultipleSelectWithCheckBox
                  label="Select Location"
                  id="location-multi-select"
                  value={selectedLocation}
                  options={locations}
                  onChange={handleLocationChange}
                  isAllSelected={isAllLocationSelected}
                />

                <MultipleSelectWithCheckBox
                  label="Select Year"
                  id="year-multi-select"
                  value={selectedYear}
                  options={commonYears}
                  onChange={handleYearChange}
                  isAllSelected={isAllYearSelected}
                />
              </div>

              {isFieldsSelected && (

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "20px",
                      fontWeight: 900,
                      textAlign: "center",
                      color: "#0BDB7B",
                    }}
                  >
                    {`  ${toCamelCase(stageName)} Intensity ${stageName === "Energy"
                      ? "(GJ/Cr)"
                      : stageName === "Water"
                        ? "(kl/Cr)"
                        : stageName === "Waste"
                          ? "(tons/Cr)"
                          : "(kms/Cr)"
                      }`}
                  </Typography>
                  <BarChartArea
                    propsForAll={monthlyDataStore}
                    selectedLocation={selectedLocation}
                    selectedYear={selectedYear}
                    stageId={stageId}
                    intensity={true}
                  />
                </div>


              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
