/**
 * @description Prepares the savable state object from react flow object
 * @param {Array<Object>} nodes
 * @param {Array<Object>} edges
 * @returns {Array<Object>}
 */
export const prepareStageFromReactFlowObject = (nodes, edges) => {
  const stages = nodes
    .filter((node) => node.id !== "root")
    .map((node) => {
      return {
        stageName: node.data.metaData.name,
        stageId: node.data.metaData.stageId,
        supplierId: node.data.metaData.supplierId,
        id: node.id,
        supplierName: node.data.metaData.supplierName,
        parent_id: "",
        displayOrder: 1,
        meta_prop: node?.data?.metaData || {},
        disp_attr: node?.position || {},
      };
    });
  return stages;
};

/**
 * @description Create nodes and edges from the stage object
 * @param {Array<Object>} stageObject
 * @param {String} productName
 * @param {String} productImage - Prdduct Image
 * @returns
 */
export const prepareReactFlowObjectFromObject = (
  stageObject = [],
  productName = "",
  productImage = ""
) => {
  const nodes = [];
  const edges = [];
 
  stageObject.map((stage,i) => {
    
    nodes.push({
      id: stage?.id,
      type: "customdefault",
      position: {
        x:500-((stageObject.length-i)*350),
        y:500,
      },
      data: {
        metaData: stage?.meta_prop,
      },
    });
  });

  nodes.push({
    id: "root",
    type: "rootNode",
    position: {
      x: 500,
      y: 500,
    },
    data: {
      metaData: {
        name: productName,
        image: productImage,
      },
    },
  });

  
  for (let productIndex = nodes.length-1; productIndex > 0; productIndex--) {
    const edge = {
      id: "dnd_edge_" + productIndex,
      source: nodes[productIndex].id,
      target: nodes[productIndex-1].id,
      sourceHandle: "source-left",
      targetHandle: "target-right",
      type: "smoothstep",
      style: {
        strokeWidth: 20,
      },
    };
    if (nodes[productIndex].type === "transportNode" || nodes[productIndex + 1]?.type === "transportNode" ) {
      edge.animated = true;
      delete edge.style;
    }
    edges.push(edge);
  }
  // setEdges(productEdges);
  // console.log("Edges",productEdges);
  return {nodes, edges};
};

/**

{
    "stageId": 1,
    "stageName": "Farmer",
    "displayOrder": 1,
    "id": 101,
    "parent_id": 102,
    "meta_prop": {},
    "disp_attr": {}
}

{
    "id": "node_r7jhx0krht",
    "type": "customdefault",
    "position": {
        "x": 53,
        "y": 531
    },
    "data": {
        "metaData": {
            "name": "asdf",
            "icon": "transport",
            "stageId": 3,
            "supplierId": 33
        }
    }
}

{
    "id": "rootNode",
    "type": "rootNode",
    "position": {
        "x": 580,
        "y": 470
    },
    "data": {
        "metaData": {
            "name": "asdf",
            "image": "https://api.iconify.design/mdi/fruit-watermelon.svg"
        }
    },
    "width": 110,
    "height": 110,
    "selected": true,
    "positionAbsolute": {
        "x": 580,
        "y": 470
    },
    "dragging": false
}
 */
