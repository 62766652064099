import React, { useState, useEffect, useRef } from 'react';
import { CssBaseline, FormControl, InputLabel, Select, MenuItem, Typography, ThemeProvider, createTheme } from '@mui/material';
import * as echarts from 'echarts';
import { makeStyles } from '@material-ui/core';
import { getProductBatchEmissionData } from '../../../services/analytics';

const useStyles = makeStyles((theme) => ({
  BatchWiseConfidence: {
    maxWidth: '100%',
    height: '400px',
  },
  [theme.breakpoints.down('xs')]: {
    maxWidth: '40% !important',
  },
}));

const BatchWiseConfidence = (props) => {
  const classes = useStyles();
  const [selectedProduct, setSelectedProduct] = useState('');
  const [productOptions, setProductOptions] = useState([]);
  const chartRef = useRef(null);

  useEffect(()=>{
    if (props?.productOptions.length > 0) {
      setProductOptions(props?.productOptions)
      setSelectedProduct(props?.productOptions[0]);
    }
  },[props])

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await getProductBatchEmissionData();
  //       const products = response.data[0].products || {};
  //       const productNames = Object.keys(products);

  //       setProductOptions(productNames);

  //       if (productNames.length > 0) {
  //         setSelectedProduct(productNames[0]);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching product data:', error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  useEffect(() => {
    if (selectedProduct) {
      if (chartRef.current) {
        // Dispose of the previous ECharts instance
        echarts.dispose(chartRef.current);
      }

      const fetchData = async () => {
        try {
          const response = await getProductBatchEmissionData();
          const productData = response.data[0].products[selectedProduct];
          const stages = Object.keys(productData.stages);

          const data = stages.map((stage) => {
            const batches = productData.stages[stage].batches.slice(-3); // Get the last three batches for each stage
            const stageCarbonEmissions = batches.map(
              (batch) => batch.stage_confidence_score
            );

            return {
              name: stage,
              type: 'bar',
              data: stageCarbonEmissions,
            };
          });

          const chartDom = chartRef.current;
          const myChart = echarts.init(chartDom);

          const option = {
            color: ['#FFC950', '#FFA058', '#149BA1', '#006799', '#DAF7A6', '#CCCCFF'],
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow',
              },
              formatter: function (params) {
                let tooltip = '<div style="text-align: left;">';

                params.forEach((param) => {
                  tooltip +=
                    '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;background-color:' +
                    param.color +
                    '"></span>' +
                    param.seriesName +
                    ': ' +
                    param.value.toFixed(2) +
                    '%' +
                    '<br/>';
                });

                tooltip += '</div>';
                return tooltip;
              },
            },
            toolbox: {
              show: true,
              orient: 'vertical',
              left: 'right',
              top: 'center',
              feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: false },
                magicType: { show: true, type: ['line', 'bar', 'stack'] },
                restore: { show: true },
                saveAsImage: { show: true },
              },
            },
            xAxis: [
              {
                type: 'category',
                axisLabel: {
                  color: '#030229',
                  textAlign: 'center',
                  fontFamily: 'Poppins',
                  fontSize: 12,
                },
                axisTick: { show: false },
                data: ['batch 1', 'batch 2', 'batch 3'],
              },
            ],
            yAxis: [
              {
                type: 'value',
                axisLabel: {
                  color: '#030229',
                  textAlign: 'center',
                  fontFamily: 'Poppins',
                  fontSize: 16,
                },
              },
            ],
            itemStyle: {
              borderRadius: [8, 8, 0, 0],
              borderColor: '#fff',
            },
            series: data,
          };

          myChart.setOption(option);
        } catch (error) {
          console.error('Error fetching product data:', error);
        }
      };

      fetchData();
    }
  }, [selectedProduct]);

  const theme = createTheme({
    typography: {
      fontFamily: 'Poppins',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div style={{paddingTop: '20px' }}>
        <CssBaseline />
        <Typography
          sx={{ fontFamily: 'poppins', fontSize: '15px', fontWeight: 600, marginBottom: '25px', textAlign: 'center' }}
        >
          Last 3 Batch Confidence Scores
        </Typography>
        <FormControl fullWidth>
          <Select
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 180,
                  overflowY: 'scroll',
                },
              },
            }}
            style={{
              width: '100%', height: '42px', backgroundColor: '#F1F1F1',
              borderRadius: '8px',
              fontWeight: 900,
              border: 'none !important',
              '&:focus': {
                border: 'none',
              },
            }}
            value={selectedProduct}
            onChange={(event) => setSelectedProduct(event.target.value)}
          >
            {productOptions.map((productName) => (
              <MenuItem key={productName} value={productName}>
                {productName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div id="main" className={classes.BatchWiseConfidence} ref={chartRef} />
      </div>
    </ThemeProvider>
  );
};

export default BatchWiseConfidence;
