import axios from './axiosInstance';
import { APP_KEYS } from "../../src/utils/constants/AppKeyConstants";

const API_URL = process.env.REACT_APP_API_URL;

const userData = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));

export const putEsgData = async (payload) => {
    try {
        const response = await axios({
            url: `${API_URL}/api/v1/esgbatchpdate`,
            method: "PUT",
            data: payload
        });

        return response.data
    } catch (err) {
        console.log(err);
        return err;
    }
};

export const deleteEsgData = async (payload) => {
    try {
        const response = await axios({
            url: `${API_URL}/api/v1/esgbatchdelete`,
            method: "DELETE",
            data: payload
        });

        return response.data
    } catch (err) {
        console.log(err);
        return err;
    }
};

export const postEsgBulkData = async (payload) => {
    try {
        const response = await axios({
            url: `${API_URL}/api/v1/esgbulkupload`,
            method: "POST",
            data: payload
        });

        return response.data
    } catch (err) {
        console.log(err);
        return err;
    }
};


export const postEsgData = async (payload) => {
    try {
        const response = await axios({
            url: `${API_URL}/api/v1/esgdata`,
            method: 'POST',
            data: payload
        });
        return response.data;
    } catch (err) {
        console.log(err);
        return err;
    }
};

export const getEsgData = async () => {
    try {
        const response = await axios({
            url: `${API_URL}/api/v1/esgdata`,
            method: 'GET',
            params: {
                compId: userData.compId
            }
        });
        return response.data;
    } catch (err) {
        console.log(err);
        return err;
    }
};

export const getMonthlyScopeData = async () => {
    try {
        const response = await axios({
            url: `${API_URL}/api/v1/esgmonthlyscope`,
            method: 'GET',
            params: {
               compId: userData.compId
            }
        });
        return response.data;
    } catch (err) {
        console.log(err);
        return err;
    }
};

