import { Backdrop, Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { APP_KEYS } from '../../utils/constants/AppKeyConstants';
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from '../../utils/constants/NotificationConstants';
import { enqueueSnackbar } from 'notistack';
import * as XLSX from 'xlsx';
import { MuiFileInput } from 'mui-file-input';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { postEsgBulkData } from '../../services/esgdata';
import CloseIcon from '@mui/icons-material/Close'


const EsgBulkUpload = () => {

  const userInfo = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO)); //Supplier Info
  const [file, setFile] = useState(null);
  const [loader, setLoader] = useState(false);


  const handleFormSubmission = async () => {
    setLoader(true)
    let formData = new FormData()
    formData.append("file", file)
    formData.append("compId", userInfo?.compId)
    formData.append("companyName", userInfo?.userName)
    formData.append("email", userInfo?.userEmail)

    const response = await postEsgBulkData(formData)
    setLoader(false)
    if (response.success) {
      setFile(null)
      enqueueSnackbar(response.msg, {
        autoHideDuration: 3000,
        variant: NOTIFICATION_SUCCESS
      });
    }
    if(!response.success){
      enqueueSnackbar(response.msg, {
        autoHideDuration: 3000,
        variant: NOTIFICATION_ERROR
      });
    }

  }

  const handleFileChange = (event) => {
    if (event) {
      setFile(event)
      const reader = new FileReader();

      reader.onload = (event) => {
        const binaryString = event.target.result;
        const workbook = XLSX.read(binaryString, { type: 'binary' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const metaData = data[0]
        let requiredMetaData = ["stageId", "stageName",	"location",	"year",	"month"]
        let error = { column: [], lenght: data.length - 1 }

        requiredMetaData.forEach((colName) => {
          if (!metaData.includes(colName)) {
            error.column.push(colName)
          }
        })

        if(error.column.length && !error.lenght){
          setFile(null)
          enqueueSnackbar(`File has no data and ${error.column.join(", ")} columns are missing`, {
            autoHideDuration: 3000,
            variant: NOTIFICATION_ERROR
          });
        }else if(!error.lenght){
          setFile(null)
          enqueueSnackbar(`File has no data`, {
            autoHideDuration: 3000,
            variant: NOTIFICATION_ERROR
          });
        }else if(error.column.length){
          setFile(null)
          enqueueSnackbar(`${error.column.join(", ")} columns are missing`, {
            autoHideDuration: 3000,
            variant: NOTIFICATION_ERROR
          });
        }else{
          enqueueSnackbar(`File can be uploaded`, {
            autoHideDuration: 3000,
            variant: NOTIFICATION_SUCCESS
          });
        }
      }

      reader.readAsArrayBuffer(event)
    }
  }

  return (
    <Box sx={{ bgcolor: "background.paper", width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-around', alignItems: 'center', mt: 5, gap: 8 }}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4">Upload your data on bulk</Typography>
          <Typography variant='body1' sx={{ fontFamily: 'poppins' }}>
            In-order to import catalog from the file. please download the catalog upload template file, add the data and upload the file.
            <a href={'/assets/data/esgBulkUpload.xlsx'} download={"ESG_BulkData_Template.xlsx"} _target="blank">Download Here</a>
          </Typography>
        </Box>

        <Box spacing={2} sx={{ width: '90%' }}>
          <Grid container spacing={5}>
            <Grid item xs={8}>
              <MuiFileInput
                fullWidth
                variant='outlined'
                value={file}
                placeholder='Click or Drag and Drop a file'
                onChange={handleFileChange}
                clearIconButtonProps={{
                  onClick: ()=> setFile(null),
                  title: "Remove",
                  children: <CloseIcon fontSize="small" />
                }}
                InputProps={{
                  sx: {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 3
                  },
                  inputProps: {
                    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  },
                  startAdornment: <UploadFileIcon />,
                }}
                sx={{ cursor: 'pointer' }}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                fullWidth
                // type="submit"
                // sx={{ width: "100%", mt: 1 }}
                onClick={handleFormSubmission}
                disabled={file ? false : true}
                variant="contained"
                size='large'
              >
                <Typography fontFamily="Raleway" fontWeight={600}>
                  Upload Files
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default EsgBulkUpload