export const APP_MODULES = {
  DASHBOARD: "Dashboard",
  SUPER_ADMIN_DASHBOARD: "Super Admin Dashboard",
  CORPORATE_DASHBOARD: "Corporate Dashboard",
  PREMIUM_SUPPLIERS_DASHBOARD: "Premium Dashboard1",
  CARBON_CANVAS: "Carbon Canvas",
  SUPPLIER_CANVAS: "Supplier Carbon Canvas",
  ACTIVITY_INPUTS: "Activity Inputs",
  CLIENT_MANAGEMENT: "Client Management",
  SDASHBOARD: "SDashboard",
  CARBON_CATALOGUE: "Carbon Catalogue",
  SUPPLIERS: "Suppliers",
  CONFIGURE: "Configure",
  HELP_AND_SUPPORT: "Help and Support",
  SUPPLIER_CONFIGURE: "Supplier Configure",
  ESG_DASHBOARD: "Esg Dashboard",
  ESG_DATA: "Esg Data",
  ACTIVITY_HISTORY: "Activity History",
  BULK_UPLOAD: "Bulk Upload",
  CARBON_ADVICE: "Carbon Advice",
  CORPORATE_CANVA: "Corporate Canva",
  SCENARIO_PLANNING: "Scenario Planning",
  CORPORATE_SUPPLIERS: "Corporate Suppliers",
  SUPPLIER_CORPORATE_CANVAS: "Supplier Corporate Canvas"
};
