import React, { useState, useEffect } from "react";
import {
  ThemeProvider,
  createTheme,
  Grid,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { PieChart1 } from "./ExternalCharts/ScopeWiseCharts";
import { EsgCards } from "./ExternalCharts/EsgCards";
import LineAreaChart from "./ExternalCharts/ScopeChart";

import { getEsgData, getMonthlyScopeData } from "../../services/esgdata";
import { APP_KEYS } from "../../utils/constants/AppKeyConstants";
import { MultipleSelectWithCheckBox } from "../../components/form/selectComponents";

const selectStyles = {
  width: "100%",
  height: "42px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  fontFamily: "Poppins !important",
  fontWeight: 900,
  border: "none !important",
  "&:focus": {
    border: "none",
  },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const menuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const theme = createTheme({
  typography: {
    fontFamily: "Poppins !important",
    fontWeight: "bolder !important",
  }
});

export const EsgDashboard = () => {
  const [esgData, setEsgData] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(
    esgData ? esgData.map((location) => location.location) : []
  );
  const [selectedYear, setSelectedYear] = useState([]);
  const [commonYears, setCommonYears] = useState([]);
  const [monthlyScopeData, setMonthlyScopeData] = useState(null);

  const locations = esgData ? esgData.map((location) => location.location) : [];
  const userData = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));
  const compId = userData.userId;

  const isAllYearSelected =
    commonYears.length > 0 && selectedYear.length === commonYears.length;
  const isAllLocationSelected =
    locations.length > 0 && selectedLocation.length === locations.length;

  const handleYearChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedYear(
        selectedYear.length === commonYears.length ? [] : commonYears
      );
      return;
    }
    setSelectedYear(value);
  };

  const handleLocationChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedLocation(
        selectedLocation.length === locations.length ? [] : locations
      );
      return;
    }
    setSelectedLocation(value);
  };

  useEffect(() => {
    const fetchEsgData = async () => {
      try {
        const response = await getEsgData(compId);
        if (response.success) {
          setEsgData(response.data);
        } else {
          console.error("Error fetching ESG data:", response.msg);
        }
      } catch (error) {
        console.error("Error fetching ESG data:", error);
      }
    };

    fetchEsgData();
  }, [compId]);

  useEffect(() => {
    const fetchMonthlyScopeData = async () => {
      try {
        const response = await getMonthlyScopeData(compId);
        if (response.success) {
          setMonthlyScopeData(response.data);
        } else {
          console.error("Error fetching ESG data:", response.msg);
        }
      } catch (error) {
        console.error("Error fetching ESG data:", error);
      }
    };

    fetchMonthlyScopeData();
  }, [compId]);

  useEffect(() => {
    if (esgData) {
      const allYears = esgData
        .map((location) => Object.keys(location.years))
        .flat();
      const commonYearsSet = new Set(
        allYears.filter((year) =>
          allYears.every((y) =>
            esgData.some((location) => Object.keys(location.years).includes(y))
          )
        )
      );

      const sortedYearsArray = Array.from(commonYearsSet)
        .map((year) => year)
        .sort((a, b) => {
          const [fyAStart, fyAEnd] = a.split('-').map(Number);
          const [fyBStart, fyBEnd] = b.split('-').map(Number);
          return fyBStart - fyAStart || fyBEnd - fyAEnd;
        });

      setCommonYears(sortedYearsArray);
      setSelectedYear([
        sortedYearsArray[0],
        sortedYearsArray[sortedYearsArray.length - 1],
      ]);
    }
  }, [esgData]);

  useEffect(() => {
    if (esgData) {
      const allLocations = esgData.map((location) => location.location);
      setSelectedLocation(allLocations);
    }
  }, [esgData]);

  return (
    <ThemeProvider theme={theme}>
      {esgData && (
        <React.Fragment>
          <Card elevation={0}>
            <CardContent>
              <Grid
                container
                spacing={2}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Grid item xs={6}>
                  <Typography variant="h6" fontWeight={700}>
                    ESG Dashboard
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <MultipleSelectWithCheckBox
                    label="Select Location"
                    id="location-multi-select"
                    value={selectedLocation}
                    options={locations}
                    onChange={handleLocationChange}
                    isAllSelected={isAllLocationSelected}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MultipleSelectWithCheckBox
                    label="Select Year"
                    id="year-multi-select"
                    value={selectedYear}
                    options={commonYears}
                    onChange={handleYearChange}
                    isAllSelected={isAllYearSelected}
                  />
                </Grid>
              </Grid>
              <EsgCards
                selectedLocation={selectedLocation}
                selectedYear={selectedYear}
                data={esgData}
                scopeData={monthlyScopeData}
              />
              <PieChart1
                selectedLocation={selectedLocation}
                selectedYear={selectedYear}
                data={esgData}
              />
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              {monthlyScopeData && (
                <LineAreaChart
                  selectStyles={selectStyles}
                  menuProps={menuProps}
                  data={monthlyScopeData}
                  defaultLocations={locations}
                  defaultYears={commonYears}
                />
              )}
            </CardContent>
          </Card>
        </React.Fragment>
      )}
    </ThemeProvider>
  );
};
