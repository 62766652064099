// RESET PASSWORD FORM
import { useNavigate } from 'react-router-dom';
import {
    Stack,
    TextField,
    Typography,
    CircularProgress,
    Box,
    Container,
    Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import Typewriter from 'typewriter-effect';
import { NOTIFICATION_SUCCESS, NOTIFICATION_ERROR, NOTIFICATION_INFO } from '../../../utils/constants/NotificationConstants';
import { makeStyles } from '@material-ui/core';
import cc_small_logo from '../../../pages/Assets2/cc-small-logo-main.png';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
    logo: {
        width: '40.229px',
        height: '60.879px',
    },
    wordAnimation: {
        animation: '$fadeInOut 2s linear infinite',
    },
    '@keyframes fadeInOut': {
        '0%, 100%': {
            opacity: 0,
        },
        '50%': {
            opacity: 1,
        },
    },
    compete: {
        opacity: 1,
    },
    intel: {
        opacity: 0,
    },
    logo_font1: {
        fontSize: '30px',
        color: '#000',
        marginBottom: '-17px',
        fontWeight: 'bold',
        fontFamily: 'poppins',
        letterSpacing: '1.5px'
    },
    logo_font2: {
        fontSize: '30px',
        color: '#000',
        marginTop: '-17px',
        fontWeight: 'bold',
        fontFamily: 'poppins',
        letterSpacing: '1.5px'
    },
    logo_font_container: {
        marginLeft: '-15px !important',
        width: '51.229px',
        height: '70.879px',
    }
}));

export const ResetForm = ({ setAuthNavigation }) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [showLoginLoader, setShowLoginLoader] = useState(false);
    const [token, setToken] = useState('');

    const validationSchema = yup.object({
        newPassword: yup.string().required('Please enter your new password'),
    });

    useEffect(() => {
        // Extract the token from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const tokenParam = urlParams.get('token');

        if (tokenParam) {
            setToken(tokenParam);
        }
    }, []);

    const formik = useFormik({
        validationSchema,
        initialValues: {
            newPassword: '',
        },
        onSubmit: async (values) => {
            setShowLoginLoader(true);
            try {
                // For now Using the same component rather then service file for the --> API request to reset the password
                const response = await axios.post(`${API_URL}/api/v1/reset-pwd`, {
                    newPassword: values.newPassword,
                    token: token,
                });

                if (response.data.success) {
                    enqueueSnackbar('Password reset successful', {
                        autoHideDuration: 3000,
                        variant: NOTIFICATION_SUCCESS,
                    });
                } else {
                    enqueueSnackbar('Password reset failed', {
                        autoHideDuration: 3000,
                        variant: NOTIFICATION_ERROR,
                    });
                }

                setShowLoginLoader(false);
            } catch (err) {
                enqueueSnackbar(err.message || err.msg || 'Error occurred', {
                    autoHideDuration: 3000,
                    variant: NOTIFICATION_ERROR,
                });
                setShowLoginLoader(false);
            }
        },
    });

    const [typewriterCount, setTypewriterCount] = useState(0);

    const handleTypewriterComplete = () => {
        if (typewriterCount < 1) {
            setTypewriterCount(typewriterCount + 1);
        }
    };

    const signIn = () => {
        navigate('/login');
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <img className={classes.logo} src={cc_small_logo} alt='cc_logo' />
                <Container sx={classes.logo_font_container}>
                    <Typography className={classes.logo_font1}>Carbon <br></br></Typography>
                    <Typography className={classes.logo_font2}>
                        {typewriterCount < 2 && (
                            <Typewriter
                                options={{
                                    strings: ["Compete", "Intel"],
                                    autoStart: true,
                                    cursor: ''
                                }}
                                onInit={(typewriter) => {
                                    typewriter
                                        .typeString("Compete")
                                        .pauseFor(1000)
                                        .deleteAll()
                                        .typeString("Intel")
                                        .start()
                                        .callFunction(() => handleTypewriterComplete());
                                }}
                            />
                        )}
                        {typewriterCount === 2 && (
                            <span>Compete</span>
                        )}
                    </Typography>


                </Container>
            </Box>
            <Typography sx={{ mt: 3 }} fontFamily="Poppins" fontSize="20px" gutterBottom>
                <b>Reset Password </b>
            </Typography>
            <Stack sx={{ mt: 3 }} spacing={3}>
                <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                    <TextField
                        label={
                            <Typography fontFamily="Poppins" fontWeight={500}>
                                Enter new desired password
                            </Typography>
                        }
                        sx={{ width: '100%', mt: 1 }}
                        name="newPassword"
                        value={formik.values.newPassword}
                        onChange={formik.handleChange}
                        error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                        helperText={
                            formik.touched.newPassword && formik.errors.newPassword
                        }
                    />
                    <>
                        <LoadingButton
                            sx={{ mt: 2, fontFamily: 'Poppins', backgroundColor: '#149BA1', '&:hover': { bgcolor: '#303030' } }}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            startIcon={showLoginLoader && <CircularProgress size={15} style={{ color: 'white' }} />}
                        >
                            Submit
                        </LoadingButton>
                        <Typography fontFamily="Poppins" style={{ marginTop: '1.5rem' }}>
                            <Button
                                onClick={signIn}
                                style={{ fontWeight: '600', textDecoration: 'underline', cursor: 'pointer', }}
                            >
                                Sign In
                            </Button>
                        </Typography>
                    </>
                </form>
            </Stack>
        </>
    );
};

