import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  Box,
} from "@mui/material";

import { Link } from "react-router-dom";

import CheckIcon from "@mui/icons-material/Check";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function SubscriptionPlans(props) {
  return (
    <>
      <Container sx>
        <Link to={"/carboncompete/configure"}>
          <Button>
            <ArrowBackIcon />
          </Button>
        </Link>
      </Container>

      <Container>
        <Grid container spacing={2}>
          <Grid item xs>
            <Card>
              <CardContent>
                <Stack spacing={7}>
                  <Box textAlign="center">
                    <Button
                      variant="outlined"
                      size="large"
                      sx={{ float: "center", width: "200px", position: "" }}
                    >
                      Basic
                    </Button>
                  </Box>
                  <Typography>
                    Carbocompete brings innovative and critical changes in the
                    product supply.
                  </Typography>

                  <Box textAlign="center">
                    <List>
                      <ListItem>
                        <ListItemAvatar>
                          {/* <Avatar> */}
                          <CheckIcon />
                          {/* </Avatar> */}
                        </ListItemAvatar>
                        <ListItemText primary="Dashboard with carbon score" />
                      </ListItem>

                      <ListItem>
                        <ListItemAvatar>
                          {/* <Avatar> */}
                          <CheckIcon />
                          {/* </Avatar> */}
                        </ListItemAvatar>
                        <ListItemText primary="Template for activity inputs" />
                      </ListItem>
                      <ListItem></ListItem>
                      <ListItem></ListItem>
                      <ListItem></ListItem>
                      <ListItem></ListItem>
                      <ListItem></ListItem>
                      <ListItem></ListItem>
                    </List>
                  </Box>

                  <Button
                    variant="outlined"
                    size="large"
                    sx={{ float: "center", position: "" }}
                  >
                    Generate Email to Supplier
                  </Button>

                  <Divider></Divider>

                  <Container>
                    <Typography>Remaining Credits</Typography>

                    <Typography>
                      Basic 10/25, Premium 06/10. <Link>Click Here</Link> to
                      upgrade more supplier
                    </Typography>
                  </Container>
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item>
            <Divider orientation="vertical" flexItem sx></Divider>
          </Grid>

          <Grid item xs>
              <Card>
                <CardContent>
                  <Stack spacing={7}>
                    <Box textAlign="center">
                      <Button
                        variant="outlined"
                        size="large"
                        sx={{ float: "center", width: "200px", position: "" }}
                      >
                        Premium
                      </Button>
                    </Box>
                    <Typography>
                      Carbocompete brings innovative and critical changes in the
                      product supply.
                    </Typography>

                    <Box textAlign="center">
                      <List>
                        <ListItem>
                          <ListItemAvatar>
                            <CheckIcon />
                          </ListItemAvatar>
                          <ListItemText primary="Dashboard with carbon score" />
                        </ListItem>

                        <ListItem>
                          <ListItemAvatar>
                            <CheckIcon />
                          </ListItemAvatar>
                          <ListItemText primary="Dashboard with carbon score" />
                        </ListItem>

                        <ListItem>
                          <ListItemAvatar>
                            <CheckIcon />
                          </ListItemAvatar>
                          <ListItemText primary="Template for activity inputs" />
                        </ListItem>

                        <ListItem>
                          <ListItemAvatar>
                            <CheckIcon />
                          </ListItemAvatar>
                          <ListItemText primary="Template for activity inputs" />
                        </ListItem>
                      </List>
                    </Box>
                    <Button
                      variant="outlined"
                      size="large"
                      sx={{ float: "center", position: "" }}
                    >
                      Generate Email to Supplier
                    </Button>

                    <Divider></Divider>

                    <Container>
                      <Typography>Remaining Credits</Typography>

                      <Typography>
                        Basic 10/25, Premium 06/10. <Link>Click Here</Link> to
                        upgrade more supplier
                      </Typography>
                    </Container>
                  </Stack>
                </CardContent>
              </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
