// CarbonCatalogue component
import React, { useState, useEffect } from "react";
import { Container, Typography, Button, Backdrop, CircularProgress, Tooltip } from "@mui/material";
// import yogurt from "../Assets2/yogurt.png";
import { Icon } from '@iconify/react';
import { Link, useNavigate, createSearchParams } from "react-router-dom";

//ServicesE:\Carbon Compete\repos\zerot\frontend\carbon-intel-fe\carbon-intel-fe\src\services\SuuplyChainConfig
import { getProductsBySupplier } from "../../services/SuuplyChainConfig/supplychainconfig";
import { APP_KEYS } from "../../utils/constants/AppKeyConstants";
// import Iconify from "../../components/iconify/Iconify";
import Divider from "@mui/material/Divider"
import { images } from "../CarbonCatalogue/Images/CatalogueImages";
import catalogue from '../Assets2/catalogue.png'

const colors = ['#06666A', '#FF9240', '#5555C2', '#05D776', '#FFC950']

const ProductBoxStyle = {
  padding: "15px",
  // border: "1px solid #e0e0e0",
  borderRadius: "10px",
  boxShadow: "4px 4px 4px 0px rgba(0, 0, 0, 0.16);",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: '20px',
  justifyContent: 'center',
  textAlign: "center",
  maxHeight: "300px",
  // transition: "transform 0.3s, box-shadow 0.3s",
  cursor: "pointer",
  backgroundColor: "#fff",
  overflow: "hidden",
  borderTop: '0.1px solid #D7D7D7',
};

const ProductOuterBoxStyle = (bg) => {
  return {
    padding: '0px 0px 4px 0px',
    borderRadius: '10px',
    backgroundColor: bg,
    boxShaodw: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
  }
};

const subcategoryIcon = {
  height: "60px",
  width: "80px",
  borderRadius: "20px",
  border: "2px solid #fff",
  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    backgroundColor: "green",
    color: "#fff",
  },

}

const HoveredProductBoxStyle = {
  // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.15)",
  // transform: "scale(1.05)",
  // backgroundColor: "#F5F5F5",
};

const GridContainerStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
  gap: "30px",
  justifyContent: "center",
};


const topMenu = {
  display: "grid",
  gridTemplateColumns: "1fr auto auto",
  gap: "20px",
  alignItems: "center",
  marginBottom: "20px",
};

const searchBarStyle = {
  width: "100%",
  maxWidth: "300px",
  cursor: "pointer",
};

const buttonStyles = {
  backgroundColor: "#149BA1",
  padding: "10px 20px",
  minWidth: "100px",
  color: "#fff",
};

const entryButton = {
  color: "#000",
  borderRadius: "8px",
  display: 'inline-flex',
  padding: '10px 32px',
  justifyContent: 'center',
  gap: '10px',
  background: 'rgba(49, 110, 222, 0.10)',
};

export default function SupplyChainConfig() {
  // const ProductsData = getProductsBySupplier().data;

  const [pData, setpData] = useState([]);

  useEffect(() => {
    async function fetchpData() {
      try {
        const suppId = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO)).userId;
        const response = await getProductsBySupplier(suppId);
        if (response.success) {
          setpData(response.data);
        } else {
          console.error("Error fetching p data:", response.msg);
        }
      } catch (error) {
        console.error("Error fetching p data:", error);
      }
    }
    fetchpData();
  }, []);

  const useNavigateSearch = () => {
    const navigate = useNavigate();
    return (pathname, params) =>
      navigate(`${pathname}?${createSearchParams(params)}`);
  };

  const navigateSearch = useNavigateSearch();

  const goToHistory = (pid) => {
    // console.log("history" + pid);
    // navigate("/carboncompete/supplychainconfig/productloghistory");
    navigateSearch("/carboncompete/supplychainconfig/productloghistory", { product: pid });
  };

  return (
    <Container sx={{ margin: "30px 0px" }}>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={pData.length === 0 ? true : 0}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography sx={{ color: '#000', textAlign: 'start', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 600, margin: '0px 0px 25px 25px' }}>Select Your Product to Add Entry</Typography>
      <Container sx={GridContainerStyle}>
        {pData.map((product, index) => (
          <Link
            key={"catalog_link_" + index}
            style={{ textDecoration: "none" }}
            onClick={() => goToHistory(product.productId)}
          >
            {/* Wrapping Tooltip around the product information */}
            <Tooltip
              key={product.productId}
              title={
                <div>
                  <div style={{ textAlign: 'center', fontFamily: 'poppins' }}>{product?.productName}</div>
                </div>
              }
              arrow
              placement="top"
            >
              <div style={ProductOuterBoxStyle(colors[index % colors.length])}>
                <Container sx={{
                  ...ProductBoxStyle,
                  "&:hover": HoveredProductBoxStyle,
                }}
                >
                  <Typography sx={{ fontFamily: 'Poppins', color: '#063039' }}>{product.productName.slice(0, 23)}</Typography>
                  <Divider component='body' sx={{ backgroundColor: colors[index % colors.length] }} />
                  <img
                    style={subcategoryIcon}
                    src={
                      product?.imageFile ||
                      catalogue
                    }
                    alt="Product"
                  />
                  <Button sx={entryButton}>
                    Add Entry
                  </Button>
                </Container>
              </div>
            </Tooltip>
          </Link>
        ))}
      </Container>
    </Container>

  );
}