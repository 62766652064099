/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { getTemplateSchema } from "../../services/SuuplyChainConfig/supplychainconfig";
import {
  Alert,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { APP_KEYS } from "../../utils/constants/AppKeyConstants";
import { makeStyles } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { getStages } from "../../services/stage";
import { enqueueSnackbar } from "notistack";
import {
  NOTIFICATION_ERROR,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_WARN,
} from "../../utils/constants/NotificationConstants";
import { postEsgData } from "../../services/esgdata";
import {
  financialYearConversion,
  stringWithUnderScore,
} from "../../utils/helperFunctions";
import StageTemplateProps from "./StageTemplateProps";

const useStyles = makeStyles((theme) => ({
  activeTab: {
    borderBottom: "2px solid",
    borderColor: "#149BA1",
    color: "black", // This makes the text color black
    fontWeight: "bold",
  },
  backButton: {
    alignItems: "start",
  },
  backIcon: {
    marginRight: "0.5rem",
  },
  
  buttonGroup: {
    width: "92%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    padding: "10px 0",
    position: "fixed",
    bottom: 0,
    backgroundColor: "#f6f8fe",
  },
  inputLabel: {
    color: "#000",
    fontWeight: "500",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "normal",
  },
  helperLabel: {
    marginTop: "5px",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontStyle: "normal",
    lineHeight: "normal",
    textDecoration: "underline",
  },
}));

const ResetButton = styled(Button)({
  textTransform: "none",
  boxShadow: "none",
  fontSize: 16,
  fontWeight: "bolder",
  width: "15%",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#fff",
  borderColor: "#FA3F3F",
  color: "#FA3F3F",
  fontFamily: "Poppins",
  alignItems: "center",
  justifyContent: "space-evenly",
  "&:hover": {
    backgroundColor: "#fff",
    borderColor: "#FA3F3F",
    boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)",
  },
});

const SaveButton = styled(Button)({
  textTransform: "none",
  boxShadow: "none",
  fontSize: 16,
  fontWeight: "bolder",
  width: "15%",
  border: "1px solid #149BA1",
  lineHeight: 1.5,
  backgroundColor: "#E2E2E2",
  color: "#030229",
  fontFamily: "Poppins",
  alignItems: "center",
  justifyContent: "space-evenly",
  "&:hover": {
    backgroundColor: "#E2E2E2",
    borderColor: "#149BA1",
    boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const EsgData = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);
  const [dateValue, setDateValue] = useState(null);
  const [stageId, setStageId] = useState("");
  const [stageNodeId, setStageNodeId] = useState("");
  const [stageName, setStageName] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [tempData, setTempData] = useState([]);
  const [validationSchema, setValidationSchema] = useState(null);
  const [showAlert, setShowAlert] = useState({
    status: false,
    type: "",
    msg: "",
  });
  const [confirmSubmission, setConfirmSubmission] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [stageData, setStageData] = useState("");
  const supplierData = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO)); //Supplier Info

  const formik = useFormik({
    validationSchema,
    initialValues: {},
    onSubmit: (values) => {
      setConfirmSubmission(true);
    },
  });

  const handleFormReset = async () => {
    setDateValue(null);
    formik.handleReset();
  };

  const handleFormSubmission = async () => {
    try {
      // if (confirmSubmission) {
      setShowLoader(true);
      const templateValueProp = tempData.map((item) => {
        let value;

        if (item.ctrlName === "stagename") {
          value =
            tempData[3] &&
            tempData[3].ctrlData &&
            tempData[3].ctrlData[0] &&
            tempData[3].ctrlData[0].name;
        } else if (item.ctrlName === "stagetype") {
          value =
            tempData[2] &&
            tempData[2].ctrlData &&
            tempData[2].ctrlData[0] &&
            tempData[2].ctrlData[0].name;
        } else if (item.ctrlName === "Name") {
          value = supplierData.userName;
        } else if (item.ctrlName === "Email") {
          value = supplierData.userEmail;
        } else if (item.ctrlName === "Factor") {
          value = formik.values.dynamic_fields;
        } else {
          // these conversions added in the backend
          // if (item.ctrlName === "EB_POWER" || item.ctrlName === "RE_POWER") {
          //   value = (formik.values[item.ctrlName] || "") * 0.0036; //converting Kwh to Gj
          // } else if (item.ctrlName === "DIESEL") {
          //   value = (formik.values[item.ctrlName] || "") * 40.1976279848; //converting KL to Gj
          // } else {
          //   value = formik.values[item.ctrlName] || "";
          // }
          value = formik.values[item.ctrlName] || "";
        }

        return {
          category: item.category,
          ctrlName: item.ctrlName,
          ctrlDisplayName: item.ctrlDisplayName,
          ctrlType: item.ctrlType,
          scope: item.scope,
          ctrlData:
            item.ctrlName === "Location" && item.ctrlDropdown
              ? [{ id: formik.values["LocationId"], name: value }]
              : [{ id: supplierData.compId, name: value }],
          ctrlApi: item.ctrlApi,
        };
      });

      // console.log("something", supplierData)

      const requestData = {
        stageName: stageName,
        // productId: selectedProductId,
        stageId: stageId,
        // templateSchemaId: templateId,
        compId: supplierData.compId,
        companyName: supplierData.userName,
        stageData: templateValueProp,
        isActive: true,
      };

      const response = await postEsgData(requestData);

      if (
        response &&
        response.success === true &&
        response.statusCode === "135101"
      ) {
        enqueueSnackbar(response.msg, {
          autoHideDuration: 5000,
          variant: NOTIFICATION_SUCCESS,
        });
        setTimeout(() => {
          setDateValue(null);
          formik.resetForm();
          setShowLoader(false);
          setConfirmSubmission(false);
        }, 1000);
      } else {
        setShowLoader(false);
        setConfirmSubmission(false);
        enqueueSnackbar(response.msg, {
          autoHideDuration: 5000,
          variant: NOTIFICATION_WARN,
        });
      }
    } catch (error) {
      // console.log("this is from catch block", error)
      enqueueSnackbar("Something Went Wrong", {
        autoHideDuration: 3000,
        variant: NOTIFICATION_ERROR,
      });
      // setShowAlert({ status: true, type: NOTIFICATION_ERROR, msg: "Something went wrong" });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowAlert("");
    }, 1500);
  }, [showAlert]);

  useEffect(() => {
    async function fetchStageData() {
      try {
        const response = await getStages([12, 13, 14, 15, 16, 17, 18, 19]);
        if (response.success) {
          setStageData(response.data);
          // setStageData(response.data.filter(stage => [12, 13, 14, 15, 16, 17, 18].includes(stage.stageId)));
          setTempData(response.data[0].templateSchema.templateSchemaProp);
          setStageName(response.data[0].stageName);
          setStageId(response.data[0].stageId);
        } else {
          console.error("Error fetching stage data:", response.msg);
        }
      } catch (error) {
        console.error("Error fetching stage data:", error);
      }
    }
    fetchStageData();
  }, []);

  // useEffect(() => {
  //   if (selectedStage) {
  //     async function fetchTemplateSchema() {
  //       try {
  //         const response = await getTemplateSchema(selectedStage.templateSchemaId);
  //         if (response.success && response.data.length > 0) {
  //           // Handle template schema data
  //         } else {
  //           console.error('Error fetching template schema:', response.msg);
  //         }
  //       } catch (error) {
  //         console.error('Error fetching template schema:', error);
  //       }
  //     }
  //     fetchTemplateSchema();
  //   }
  // }, [selectedStage]);

  const handleDropdownChange = (event, itemData) => {
    const { value } = event.target;
    let locationName = itemData.filter((item) => item.id === value)[0].name;

    formik.setFieldValue("LocationId", value);
    formik.setFieldValue("Location", locationName);
  };

  // set selected stage in the current state
  const SelectStage = async (stage, i) => {
    if (stage.id === stageNodeId) {
      return;
    }

    setStageId(stage.stageId);
    setStageNodeId(stage.id);
    setStageName(stage.stageName);

    try {
      const response = await getTemplateSchema(stage.templateSchemaId);
      if (response.success && response.data.length > 0) {
        setTempData(response.data[0].templateSchema.templateSchemaProp);
        // settemplateSchemaInfo(response.data[0]);

        // Set the templateId based on the selected stage
        setTemplateId(stage.templateSchemaId);

        // If current stage consist of dynamic stage then append the default factors
        if (
          response?.data[0]?.templateSchemaName === "DYNAMIC_TEMPLATE_SCHEMA"
        ) {
          formik.setFieldValue("dynamic_fields", [
            {
              factor: "",
              quantity: "",
            },
          ]);
        }

        const schema = Yup.object().shape({
          month: Yup.string().required("Month is required"),
          year: Yup.number().required("Year is required"),
        });

        response.data[0].templateSchemaProp.forEach((item) => {
          const validationRule =
            item.ctrlType === "textbox"
              ? Yup.string().required(`${item.ctrlDisplayName} is required`)
              : Yup.string().required(`${item.ctrlDisplayName} is required`);

          schema.shape({ [item.ctrlName]: validationRule });
        });

        setValidationSchema(schema);
      } else {
        console.error("Error fetching data:", response.msg);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDatePicker = (event, item) => {
    if (event) {
      setDateValue(event);
      formik.values["month"] = event["$d"].toString().split(" ")[1];
      formik.values["year"] = financialYearConversion(event);
    } else {
      setDateValue(null);
      formik.values["month"] = "";
      formik.values["year"] = "";
    }
  };

  const handleChangeTab = (event, newValue) => {
    formik.resetForm();
    setDateValue(null);
    setTabValue(newValue);
    setTempData(stageData[newValue].templateSchema.templateSchemaProp);
    setStageName(stageData[newValue].stageName);
    setStageId(stageData[newValue].stageId);
  };

  return (
    <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
      <Tabs
        value={tabValue}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="inherit"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        {(stageData || [])
          // .filter(stage => [12, 13, 14, 15, 16, 17, 18].includes(stage.stageId))
          .map((stage, i) => (
            <Tab
              key={stage?.stageId}
              label={stage?.stageName}
              {...a11yProps(i)}
              onClick={(e) => SelectStage(stage, i)}
              // className={stage.id === stageNodeId ? classes.activeTab : ''}
            />
          ))}
      </Tabs>

      {(stageData || []).map(({ templateSchema, stageId }, i) => (
        <TabPanel
          key={stageId}
          value={tabValue}
          index={i}
          dir={theme.direction}
        >
          <FormikProvider value={formik}>
            {templateSchema?.templateSchemaProp.length === 0 ? (
              <StageTemplateProps stageId={stageId} />
            ) : (
              <Grid container spacing={6} mb={10} sx={{ width: "100%" }}>
                {templateSchema?.templateSchemaProp
                  .filter(
                    (item) =>
                      item.ctrlName !== "month" &&
                      item.category !== "confidence" &&
                      item.category !== "stage" &&
                      item.category !== "normal" &&
                      item.category !== "dynamic"
                  )
                  .map((item) => (
                    <Grid item xs={12} sm={6} key={item.ctrlName}>
                      <>
                        {item.ctrlName === "year" && (
                          <div>
                            <Typography className={classes.inputLabel}>
                              {item.ctrlDisplayName?.split("(")[0] ||
                                item.ctrlDisplayName}
                            </Typography>
                            <FormControl
                              fullWidth
                              variant="outlined"
                              size="small"
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  id="date"
                                  value={dateValue}
                                  onChange={(event) =>
                                    handleDatePicker(event, item)
                                  }
                                  views={["month", "year"]}
                                  sx={{
                                    boxShadow:
                                      "0px 4px 30px 0px rgba(0, 0, 0, 0.15)",
                                    borderRadius: 1,
                                  }}
                                  slotProps={{
                                    textField: {
                                      placeholder: "Month & Year",
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </FormControl>
                          </div>
                        )}
                        {item.ctrlType === "dropdown" && (
                          <div>
                            <Typography className={classes.inputLabel}>
                              {item.ctrlDisplayName?.split("(")[0] ||
                                item.ctrlDisplayName}
                            </Typography>
                            <FormControl fullWidth variant="outlined">
                              <TextField
                                select
                                variant="outlined"
                                sx={{
                                  boxShadow:
                                    "0px 4px 30px 0px rgba(0, 0, 0, 0.15)",
                                  borderRadius: 1,
                                }}
                                labelId={`${item.ctrlName}-label`}
                                id={item.ctrlName}
                                name={item.ctrlName}
                                value={formik.values["LocationId"] || ""}
                                onChange={(event) =>
                                  handleDropdownChange(event, item.ctrlDropdown)
                                }
                              >
                                {item.ctrlDropdown.map((locationObj) => (
                                  <MenuItem
                                    key={locationObj.id}
                                    value={locationObj.id}
                                  >
                                    {locationObj.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </FormControl>
                          </div>
                        )}
                        {item.ctrlType === "textbox" &&
                          item.ctrlName !== "year" && (
                            <div
                              style={{
                                alignItems: "start",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {item.ctrlDisplayName.includes("Food travel") ? (
                                <Typography className={classes.inputLabel}>
                                  {item.ctrlDisplayName
                                    ?.split(" ")
                                    .slice(0, 5)
                                    .join(" ") || item.ctrlDisplayName}
                                </Typography>
                              ) : (
                                <Typography className={classes.inputLabel}>
                                  {item.ctrlDisplayName?.split("(")[0] ||
                                    item.ctrlDisplayName}
                                </Typography>
                              )}
                              <FormControl fullWidth variant="outlined">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  id={item.ctrlName}
                                  name={item.ctrlName}
                                  value={formik.values[item.ctrlName] || ""}
                                  onChange={formik.handleChange}
                                  sx={{
                                    boxShadow:
                                      "0px 4px 30px 0px rgba(0, 0, 0, 0.15)",
                                    borderRadius: 1,
                                  }}
                                  onBlur={formik.handleBlur}
                                  error={
                                    formik.touched[item.ctrlName] &&
                                    Boolean(formik.errors[item.ctrlName])
                                  }
                                />
                                <Typography className={classes.helperLabel}>
                                  {item.ctrlDisplayName
                                    ?.split("(")[1]
                                    ?.split(")")[0]
                                    ?.split(" ")[1] || ""}
                                </Typography>
                              </FormControl>
                            </div>
                          )}
                      </>
                    </Grid>
                  ))}
              </Grid>
            )}
          </FormikProvider>
        </TabPanel>
      ))}

      {stageId != "19" && (
        <Box key={stageId} maxWidth="90%" sx={classes.buttonGroup}>
          {confirmSubmission ? (
            <div className={classes.buttonGroup}>
              <ResetButton
                variant="contained"
                onClick={() => setConfirmSubmission(false)}
              >
                <Typography fontFamily="Raleway" fontWeight={600}>
                  No
                </Typography>
              </ResetButton>
              <SaveButton
                type="button"
                onClick={() => handleFormSubmission()}
                variant="contained"
                startIcon={
                  showLoader ? (
                    <CircularProgress size={20} style={{ color: "white" }} />
                  ) : null
                }
              >
                <Typography fontFamily="Raleway" fontWeight={600}>
                  Yes
                </Typography>
              </SaveButton>
            </div>
          ) : (
            <div className={classes.buttonGroup}>
              <ResetButton
                variant="outlined"
                onClick={handleFormReset}
                disabled={confirmSubmission}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  viewBox="0 0 21 24"
                  fill="none"
                >
                  <path
                    d="M9.09375 23.125C6.53125 22.9167 4.375 21.8646 2.625 19.9688C0.875 18.0729 0 15.8229 0 13.2188C0 11.6146 0.369792 10.1042 1.10938 8.6875C1.84896 7.27083 2.88542 6.11458 4.21875 5.21875L5.5625 6.5625C4.39583 7.25 3.48958 8.19271 2.84375 9.39062C2.19792 10.5885 1.875 11.8646 1.875 13.2188C1.875 15.3021 2.5625 17.1042 3.9375 18.625C5.3125 20.1458 7.03125 21.0208 9.09375 21.25V23.125ZM10.9688 23.125V21.25C13.0521 21 14.7708 20.1198 16.125 18.6094C17.4792 17.099 18.1562 15.3021 18.1562 13.2188C18.1562 10.9479 17.3698 9.02604 15.7969 7.45312C14.224 5.88021 12.3021 5.09375 10.0312 5.09375H9.40625L11.2812 6.96875L9.9375 8.3125L5.78125 4.15625L9.9375 0L11.2812 1.34375L9.40625 3.21875H10.0312C12.8229 3.21875 15.1875 4.19271 17.125 6.14062C19.0625 8.08854 20.0312 10.4479 20.0312 13.2188C20.0312 15.8229 19.1615 18.0729 17.4219 19.9688C15.6823 21.8646 13.5312 22.9167 10.9688 23.125Z"
                    fill="#FA3F3F"
                  />
                </svg>
                <Typography fontFamily="Raleway" fontWeight={600}>
                  Reset
                </Typography>
              </ResetButton>
              <SaveButton
                type="submit"
                onClick={() => formik.handleSubmit()}
                variant="contained"
              >
                <Typography fontFamily="Raleway" fontWeight={600}>
                  Submit
                </Typography>
              </SaveButton>
            </div>
          )}
        </Box>
      )}
    </Box>
  );
};
