import React, { useEffect, useRef } from "react";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import * as echarts from "echarts";
import { monthsSet } from "../../../provider/EsgDataFilteration";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export default function LineChartArea({
  propsForAll,
  locations,
  years,
  activities,
  stageId,
}) {
  const chartRef = useRef(null);

  const filteredLocationForAll = {};

  locations.forEach((loc) => {
    if (propsForAll[loc]) {
      filteredLocationForAll[loc] = propsForAll[loc];
    }
  });

  const filteredData = {};

  years.forEach((year) => {
    Object.keys(filteredLocationForAll).forEach((loc) => {
      if (filteredLocationForAll[loc][year]) {
        Object.keys(filteredLocationForAll[loc][year]).forEach((month) => {
          if (
            filteredLocationForAll[loc][year][month][activities] !== undefined
          ) {
            if (!filteredData[loc]) {
              filteredData[loc] = {};
            }
            if (!filteredData[loc][year]) {
              filteredData[loc][year] = {};
            }
            if (!filteredData[loc][year][month]) {
              filteredData[loc][year][month] = {};
            }

            filteredData[loc][year][month][activities] =
              filteredLocationForAll[loc][year][month][activities];
          }
        });
      }
    });
  });

  useEffect(() => {
    const chart = echarts.init(chartRef.current);
    const aggregatedDataByYear = {};
    Object.keys(filteredData).forEach((loc) => {
      Object.keys(filteredData[loc]).forEach((year) => {
        Object.keys(filteredData[loc][year]).forEach((month) => {
          if (!aggregatedDataByYear[year]) {
            aggregatedDataByYear[year] = {};
          }
          if (!aggregatedDataByYear[year][month]) {
            aggregatedDataByYear[year][month] = 0;
          }
          aggregatedDataByYear[year][month] +=
            filteredData[loc][year][month][activities];
        });
      });
    });

    const monthDataArray = Array.from(monthsSet)
      .map((month) => {
        const monthData = {
          month: month,
          data: {},
        };

        years.forEach((year) => {
          const dataValue = aggregatedDataByYear[year]?.[month] || 0;
          if (dataValue !== 0) {
            monthData.data[year] = dataValue.toFixed(2);
          }
        });

        if (Object.keys(monthData.data).length > 0) {
          return monthData;
        } else {
          return null;
        }
      })
      .filter((item) => item !== null);

    // Define an array of colors
    const colors = [
      "#0033BB",
      "#5100D9",
      "#9700C7",
      "#E1004F",
      "#BD1100",
      "#EE4900",
      "#EF9100",
      "#A87E00",
      "#44A600",
      "#00B0FA",
      "#02FEE0",
      "#A772FF",
      "#69DBFF",
      "#37877D",
      "#230455",
      "#FD5308",
      "#FB9902",
      "#F9BC02",
      "#66B132",
      "#0291CD",
      "#0247FE",
      "#3E01A4",
      "#8601B0",
      "#A7194B",
      "#FE2712",
    ];

    const legendData = years.map((year, index) => ({
      name: year,
    }));
    const option = {
      xAxis: {
        type: "category",
        data: monthDataArray.map((item) => item.month),
      },
      yAxis: {
        type: "value",
      },
      legend: {
        data: legendData,
        left: "center",
        top: "top",
        textStyle: {
          color: "#000000",
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: (params) => {
          return params
            .map((param) => {
              if (param.value !== 0) {
                const color = param.color;
                if (stageId === "15") {
                  return `<span style="color:${color};">${param.seriesName}: ${param.value} GJ</span>`;
                } else if (stageId === "12") {
                  return `<span style="color:${color};">${param.seriesName}: ${param.value} kL</span>`;
                } else if (stageId === "13") {
                  return `<span style="color:${color};">${param.seriesName}: ${param.value} Tons</span>`;
                } else {
                  return `<span style="color:${color};">${param.seriesName}: ${param.value} kms</span>`;
                }
              } else {
                return "";
              }
            })
            .filter(Boolean)
            .join("<br/>");
        },
      },
      toolbox: {
        show: true,
        orient: "vertical",
        left: "right",
        top: "center",
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ["line", "bar", "stack"] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      series: years
        .filter((year, index) =>
          monthDataArray.some(
            (item) => item.data[year] !== undefined && item.data[year] !== 0
          )
        )
        .map((year, index) => {
          return {
            name: year,
            type: "bar",
            barWidth: "20%",
            barCategoryGap: "30%",
            barGap: "20%",

            itemStyle: {
              color: colors[index % colors.length],
              borderRadius: [5, 5, 0, 0],
            },

            data: monthDataArray.map((item) => item.data[year] || 0),
          };
        }),
    };

    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [filteredData, activities, years, stageId]);

  const handleDownloadCSV = () => {
    const aggregatedDataByYear = {};
    Object.keys(filteredData).forEach((loc) => {
      Object.keys(filteredData[loc]).forEach((year) => {
        Object.keys(filteredData[loc][year]).forEach((month) => {
          if (!aggregatedDataByYear[year]) {
            aggregatedDataByYear[year] = {};
          }
          if (!aggregatedDataByYear[year][month]) {
            aggregatedDataByYear[year][month] = 0;
          }
          aggregatedDataByYear[year][month] +=
            filteredData[loc][year][month][activities];
        });
      });
    });

    let csvContent = "data:text/csv;charset=utf-8,";

    csvContent += `Month,${years.join(",")}\n`;

    Object.keys(aggregatedDataByYear).forEach((year) => {
      Object.keys(aggregatedDataByYear[year]).forEach((month) => {
        csvContent += `${month},${Object.values(aggregatedDataByYear[year]).join(",")}\n`;
      });
    });

    const dataBlob = new Blob([csvContent], { type: "text/csv" });
    const downloadLink = window.URL.createObjectURL(dataBlob);
    const link = document.createElement("a");
    link.href = downloadLink;
    link.download = "chart_data.csv";
    link.click();
  };

  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Button
              sx={{
                backgroundColor: "#149ba1",
                "&:hover": {
                  backgroundColor: "#000000",
                },
              }}
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={handleDownloadCSV}
            >
              Download Data
            </Button>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 900,
                textAlign: "center",
                color: "#00000",
              }}
            >
              {activities.replace("_", " ")}
            </Typography>
            <div ref={chartRef} style={{ width: "100%", height: "400px" }} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
