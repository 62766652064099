import axios from './axiosInstance';
import { APP_KEYS } from "../../src/utils/constants/AppKeyConstants";

const API_URL = process.env.REACT_APP_API_URL;

const userData = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));


export const postLocationData = async (payload) => {
  console.log('postLocationData called with payload:', payload);
  try {
    const response = await axios.post(`${API_URL}/api/v1/location`, payload);
    console.log('API response:', response);
    return response.data;
  } catch (error) {
    console.error('Error in postLocationData:', error);
    throw error;
  }
};

export const putLocationData = async (payload) => {
  console.log('putLocationData called with payload:', payload);
  try {
    const response = await axios.put(`${API_URL}/api/v1/location`,{
      ...payload,
      userId: userData.userId,
      compId: userData.compId,
    });
    console.log('API response:', response);
    return response.data;
  } catch (error) {
    console.error('Error in postLocationData:', error);
    throw error;
  }
};


export const getLocationData = async (compId) => {

  try {
    const response = await axios({
      url: `${API_URL}/api/v1/location`,
      method: 'GET',
      params: {
        compId
      }
    });
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getLocationDataByLocationId = async (locationId) => {

  try {
    const response = await axios({
      url: `${API_URL}/api/v1/location`,
      method: 'GET',
      params: {
        locationId
      }
    });
    return response.data.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getSupplierLocationData = async () => {
  const userInfo = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));

  try {
    const response = await axios({
      url: `${API_URL}/api/v1/location`,
      method: 'GET',
      params: {
        supplierId:5
      }
    });
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getScopeCategoryData = async () => {
  try {
    const response = await axios({
      url: `${API_URL}/api/v1/scopecategory`,
      method: 'GET',
    });
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getAnalyticsData = async (userId) => {

  try {
    const response = await axios({
      url: `${API_URL}/api/v1//corporate-batch/analytics`,
      method: 'GET',
      params: {
        userId 
      }
    });
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};