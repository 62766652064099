import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { DialogTitle, makeStyles } from "@material-ui/core";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Grid,
  Button,
  Typography,
  Container,
  Stack,
  tooltipClasses,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
// import ComparisonChart from "./Charts/ComparisonChart";
import Iconify from "../../components/iconify/Iconify";
// import { getProductScore } from "../../services/product";
import { deleteProduct, getProductByProductId } from "../../services/catalogue/addproduct";
// import { getProductBatchEmissionData2, getTotalCarbonData } from "../../services/analytics";
// import BatchWiseConfidence from "./Charts/LineChart";
import catalogue from "../Assets2/catalogue.png";
import { ProgressBar } from "../../pages/ConfidenceGraph/ConfidenceGraph";
import QRCode from "react-qr-code";
import { styled } from "@mui/material/styles";
import RadarGraph from "./Charts/ContributionChart";
import { getProductsById } from "../../services/catalogue/productlist";
import { enqueueSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 20px",
  },
  backButton: {
    marginLeft: "20px",
    marginBottom: "10px",
    backgroundColor: "#222",
    "&:hover": { backgroundColor: "#149BA1" },
    color: "#fff",
    display: "flex",
    width: "100px",
    alignItems: "center",
    padding: theme.spacing(1),
    borderRadius: "10px",
  },
  backIcon: {
    marginRight: "0.5rem",
  },
  productInfoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    width: "220px",
    border: "1px solid #ccc",
    borderRadius: "10px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    textAlign: "center",
  },
  productImage: {
    height: "200px",
    width: "200px",
    borderRadius: "50%",
  },
  productCO2: {
    color: "#333",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "bold",
  },
  productName: {
    color: "#149BA1",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 500,
  },
  productId: {
    color: "#149BA1",
    fontFamily: "Poppins",
    fontSize: "14px",
    border: "1px solid #149BA1",
    padding: "5px 10px",
    borderRadius: "5px",
    marginTop: "5px",
  },
  chartContainer: {
    border: "1px solid #ccc",
    borderRadius: "10px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
  },
  ConfidenceChart: {
    height: "100%",
    background: "#fff",

    borderRadius: "24px",
    border: "0.5px solid #D8D8D8",
    boxShadow:
      "0px 30px 60px -30px rgba(0, 0, 0, 0.30), 0px 50px 100px -20px rgba(50, 50, 93, 0.25)",
    display: "flex",
    gap: "30px",
    alignItems: "center",
  },
  container: {
    height: "100%",
    background: "#fff",

    borderRadius: "24px",
    border: "0.5px solid #D8D8D8",
    boxShadow:
      "0px 30px 60px -30px rgba(0, 0, 0, 0.30), 0px 50px 100px -20px rgba(50, 50, 93, 0.25)",
  },
  deleteButton: {
    backgroundColor: "#f44336",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#d32f2f",
    },
    fontFamily: "Poppins",
    borderRadius: "8px",
    fontWeight: "bold",
  },
  editProduct: {
    backgroundColor: "#d2b72e",
    color: "#000",
    "&:hover": {
      backgroundColor: "#cdd22e",
    },
    fontFamily: "Poppins",
    borderRadius: "8px",
    fontWeight: "bold",
  },
}));

export const ProductInfo = () => {
  const [selectedStage, setSelectedStage] = useState(""); // for confidence inputs

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { productId } = useParams();
  const location = useLocation();
  const { productName } = location.state || {};
  const navigate = useNavigate();
  const progress = 50;
  const classes = useStyles();

  const [productInfo, setProductInfo] = useState({});
  const [productScore, setProductScore] = useState({});
  const [productBatchEmissionData, setProductBatchEmissionData] = useState({});
  const [productCarbonScore, setProductCarbonScore] = useState({});
  const [selectedProduct, setSelectedProduct] = useState("");
  const [confidenceData, setConfidenceData] = useState("");
  const [productById, setProductById] = useState([]);

  ProductInfo.propTypes = {
    productName: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
  };

  const getProductDetail = () => {
    getProductByProductId(productId).then((response) => {
      setProductInfo(response);
    });
  };

  useEffect(() => {
    async function fetchAllProductsById() {
      try {
        const response = await getProductsById(productId);
        if (response.success) {
          setProductById(response.data);
        } else {
          console.error("Error fetching country data:", response.msg);
        }
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    }
    fetchAllProductsById();
  }, []);

  const handleStageChange = (event) => {
    setSelectedStage(event.target.value);
  };

  /**
   * Fetch Data once component has loaded
   */
  useEffect(() => {
    getProductDetail();
    // getProductCarbonScore();
    // getTotalCarbonEmission();
    // fetchData();

    // Toggle btw two img's
    const imageInterval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? 1 : 0));
    }, 2000);

    return () => {
      clearInterval(imageInterval);
    };
  }, []);

  const openProductEditPage = (productId) => {
    navigate(`/carboncompete/carboncatalogue/product/${productId}/edit`);
    // navigate('/carboncompete/carboncatalogue')
  };

  const inputStyle = {
    width: "100%",
    height: "42px",
    backgroundColor: "#F1F1F1",
    borderRadius: "8px",
    border: "none !important",
    "&:focus": {
      border: "none",
    },
  };

  const inputTypo = {
    fontWeight: 900,
    fontFamily: "Poppins",
    textTransform: "capitalize",
  };

  const mapDataSourceToColor = (dataSource) => {
    // Define colors for each data source
    const colorMap = {
      Sensor: "#4CAF50", // Green
      ERP: "#2196F3", // Blue
      Invoice: "#FFC107", // Yellow
      Documentation: "#FF9800", // Orange
      Third_party: "#F44336", // Red
    };

    return colorMap[dataSource] || "#000"; // Default color if data source is not recognized
  };

  const CarbonScore = productById[0]?.analytics?.CarbonScore ?? 0;

  const mapDataFrequencyToColor = (dataFrequency) => {
    const colorMap = {
      Hourly: "#4CAF50",
      Daily: "#2196F3",
      Weekly: "#FFC107",
      Monthly: "#FF9800",
      Yearly: "#F44336",
    };

    return colorMap[dataFrequency] || "#000";
  };

  const mapDataCompletenessToColor = (dataCompleteness) => {
    const colorMap = {
      High: "#4CAF50",
      Medium: "#2196F3",
      Average: "#FFC107",
      Low: "#FF9800",
    };

    return colorMap[dataCompleteness] || "#000";
  };

  const mapDataAuditedToColor = (dataAudited) => {
    const colorMap = {
      Yes: "#4CAF50",
      No: "#F44336",
    };

    return colorMap[dataAudited] || "#000";
  };

  useEffect(() => {
    if (
      selectedProduct &&
      selectedStage &&
      productBatchEmissionData[selectedProduct]
    ) {
      const selectedProductData = productBatchEmissionData[selectedProduct];
      const selectedStageData = selectedProductData.stages[selectedStage];

      const emissions = selectedStageData.batches.map(
        (batch) => batch.confidence_inputs[0]
      );
      setConfidenceData(emissions);
    }
  }, [selectedProduct, selectedStage, productBatchEmissionData]);


  if (!Array.isArray(confidenceData)) {
    console.error("confidenceData is not an array:", confidenceData);
    setConfidenceData([]);
  }

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
      fontWeight: "bold",
    },
  }));

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleOpenDeleteDialog = () => setOpenDeleteDialog(true);
  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const handleConfirmDelete = async () => {
    try {
      await deleteProduct(productId);
      enqueueSnackbar('Product deleted successfully', { variant: 'success', autoHideDuration: 3000 });
      navigate('/carboncompete/carboncatalogue');
    } catch (error) {
      console.log(error);
    }
    handleCloseDeleteDialog();
  };

  return (
    <div className={classes.root}>
      <Button
        className={classes.backButton}
        onClick={() => navigate("/carboncompete/carboncatalogue")}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Iconify
            icon="icon-park-outline:back"
            color="white"
            className={classes.backIcon}
          />
          <Typography
            fontFamily="Poppins"
            style={{ textTransform: "capitalize" }}
          >
            Back
          </Typography>
        </div>
      </Button>
      <Grid container spacing={2}>
        {/* 1st container  */}
        <Grid item xs={12} md={6}>
          <Container sx={{ padding: 2 }} className={classes.container}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} justifyContent={"center"}>
                <Stack spacing={4} alignItems="center">
                  <img
                    src={productInfo?.imageFile || catalogue}
                    style={{
                      height: "230px",
                      width: "250px",
                      borderRadius: "30px",
                    }}
                    alt="Product"
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={3}>
                  <Typography
                    sx={{ fontFamily: "poppins", textTransform: "capitalize" }}
                    variant="h5"
                    color={"primary"}
                  >
                    {productInfo?.productName || ""}
                  </Typography>
                  <Button variant="outlined" fullWidth>
                    Product ID: {productInfo?.productId}
                  </Button>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <QRCode
                      style={{ height: "60px", width: "60px" }}
                      value={`${productInfo?.productId}-${productInfo?.productName}`}
                    />
                  </div>
                  <Button
                    className={classes.editProduct}
                    fullWidth
                    onClick={(e) => openProductEditPage(productInfo?.productId)}
                  >
                    Edit Product
                  </Button>
                  <Button
                    className={classes.deleteButton}
                    fullWidth
                    onClick={handleOpenDeleteDialog}
                  >
                    Delete Product
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Grid>

        <Grid item xs={12} md={6}>
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className={classes.container}
          >
            <div style={{ marginLeft: "-100px" }}>
              {CarbonScore !== 0 ? (
                <ProgressBar progress={70} carbonEmission={CarbonScore || 0} />
              ) : (
                <ProgressBar progress={0} carbonEmission={CarbonScore || 0} />
              )}
            </div>
          </Container>
        </Grid>

        <Grid item xs={12} md={6}>
          <Container sx={{ paddingTop: 2 }} className={classes.container}>
            <RadarGraph productById={productById} />
          </Container>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <Container sx={{ paddingTop: 2 }} className={classes.container}>
            {productInfo?.productName && (
              <ComparisonChart productId={productId} />
            )}
          </Container>
        </Grid>

        <Grid item xs={12} md={6}>
          <Container className={classes.container} sx={{ padding: 3, borderRadius: '12px', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}>
            <Grid container spacing={1} sx={{ display: "flex", flexDirection: 'column', gap: 2, paddingX: 3 }}>
              <Typography
                sx={{ fontFamily: 'poppins', fontSize: '18px', fontWeight: 600, textAlign: 'center' }}
              >
                Select the stage to check the confidence inputs
              </Typography>
              <FormControl fullWidth>
                <Select
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 180,
                        overflowY: 'scroll',
                      },
                    },
                  }}
                  sx={inputStyle}
                  value={selectedStage}
                  onChange={handleStageChange}
                >
                  {selectedProduct &&
                    productBatchEmissionData[selectedProduct] &&
                    Object.keys(productBatchEmissionData[selectedProduct].stages || {}).map((stageName) => (
                      <MenuItem key={stageName} value={stageName} sx={{ fontFamily: "Poppins" }}>
                        <Typography sx={inputTypo}> {stageName} </Typography>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <Box sx={{ width: '100%', height: 'max', padding: '20px', border: '1px solid #ccc', borderRadius: '8px', boxSizing: 'border-box', }}>
                {confidenceData && confidenceData.length > 0 ? (
                  confidenceData.map((data, index) => {
                    return (
                      <Box key={index}>
                        {Object.keys(data).map((dataKey, i) => {
                          let dataColor;

                          switch (dataKey) {
                            case "Data Source":
                              dataColor = mapDataSourceToColor(data[dataKey]);
                              break;
                            case "Data Frequency":
                              dataColor = mapDataFrequencyToColor(data[dataKey]);
                              break;
                            case "Data Completeness":
                              dataColor = mapDataCompletenessToColor(data[dataKey]);
                              break;
                            case "Data Audited":
                              dataColor = mapDataAuditedToColor(data[dataKey]);
                              break;
                            default:
                              dataColor = "#000";
                          }

                          return (
                            <Box key={i}>
                              <Grid container spacing={2} sx={{ height: '80px' }}>
                                <Grid item xs={6}>
                                  <Typography fontFamily="Poppins" fontWeight={700}>
                                    {dataKey}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontFamily="Poppins" style={{ color: dataColor, cursor: 'pointer' }}>
                                    {data[dataKey]}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          );
                        })}
                        {index === confidenceData.length - 1 ? "" : <Divider variant="middle" sx={{ margin: 2 }}></Divider>}
                      </Box>
                    );
                  })
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    No confidence data available.
                  </Typography>
                )}
              </Box>
            </Grid>
          </Container>
        </Grid> */}
      </Grid>
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Product</DialogTitle>
        <DialogContent>
          <Typography variant="body1" style={{ fontFamily: 'Poppins' }}>
            Are you sure you want to delete this product?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button className={classes.deleteButton} onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
