import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

export const AnalyticsTable = ({ data }) => {
  if (!data || typeof data !== "object") return null;

  const locationKey = Object.keys(data)[0];
  if (!locationKey || !data[locationKey][0]) return null;

  const analyticsData = data[locationKey][0].analytics;
  const years = Object.keys(analyticsData).sort((a, b) => b - a);

  // Filter years to only include those with non-empty values
  const filteredYears = years.filter(year => {
    const yearData = analyticsData[year];
    return yearData.minEmission !== "" && yearData.maxEmission !== "" && yearData.avgEmission !== "";
  });

  return (
    <div style={{ display: 'flex', justifyContent: 'center', height: '376px' }}>
      <Paper elevation={3} style={{ width: '100%' }}>
        <Typography variant="h6" align="center" style={{ fontFamily: 'poppins', fontSize: '20px', fontWeight: 600, padding: '16px' }}>
          Emissions Analytics
        </Typography>
        <TableContainer style={{ maxHeight: '280px', overflow: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow style={{ backgroundColor: '#e0e0e0' }}>
                <TableCell align="center"><strong>Year</strong></TableCell>
                <TableCell align="center"><strong>Min Emission</strong></TableCell>
                <TableCell align="center"><strong>Max Emission</strong></TableCell>
                <TableCell align="center"><strong>Avg Emission</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredYears.length > 0 ? (
                filteredYears.map((year) => {
                  const yearData = analyticsData[year];
                  return (
                    <TableRow key={year} hover>
                      <TableCell align="center">{year}</TableCell>
                      <TableCell align="center">{yearData.minEmission || '-'}</TableCell>
                      <TableCell align="center">{yearData.maxEmission || '-'}</TableCell>
                      <TableCell align="center">{yearData.avgEmission || '-'}</TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">No data available</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};
