import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { MultipleSelectWithCheckBox } from "../../../components/form/selectComponents";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { monthsSet } from "../../../provider/EsgDataFilteration";
import ChartArea from "./ChartArea";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const LineAreaChart = ({ data, defaultLocations, defaultYears }) => {
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedYear, setSelectedYear] = useState([]);
  const [commonYears, setCommonYears] = useState([]);
  const [commonLocations, setCommonLocations] = useState([]);
  const [selectedScope, setSelectedScope] = useState("scope1");
  const [isFieldsSelected,setIsFieldsSelected]=useState(true)

  const scopes = ["scope1", "scope2", "scope3"];

  const isAllYearSelected =
    commonYears.length > 0 && selectedYear.length === commonYears.length;
  const isAllLocationSelected =
    commonLocations.length > 0 &&
    selectedLocation.length === commonLocations.length;


  const handleYearChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedYear(
        selectedYear.length === commonYears.length ? [] : commonYears
      );
      return;
    }
    setSelectedYear(value);
  };

  const handleLocationChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedLocation(
        selectedLocation.length === commonLocations.length
          ? []
          : commonLocations
      );
      return;
    }
    setSelectedLocation(value);
  };

  const handleScopeChange = (event) => {
    setSelectedScope(event.target.value);
  };

  const sliceScope = (str) => {
    return str.slice(0, 5) + " " + str.slice(5);
  };
  const scopeMapping = {};
  scopes.forEach((scope) => {
    scopeMapping[scope] = sliceScope(scope);
  });

  useEffect(() => {
    if (defaultLocations) {
      const filteredLocations = defaultLocations.filter(
        (loc) => loc !== "Others"
      );
      setCommonLocations(filteredLocations);
      setSelectedLocation(filteredLocations);
    }
    if (defaultYears) {
      setCommonYears(Array.from(defaultYears));
      setSelectedYear([defaultYears[0], defaultYears[defaultYears.length - 1]]);
    }
  }, [defaultLocations, defaultYears]);

  useEffect(() => {
    setIsFieldsSelected(selectedLocation.length > 0 && selectedYear.length > 0);
  }, [selectedLocation, selectedYear]);

  const aggregateData = (location, years, scope) => {
    let aggregatedData = {};

    data.forEach((locationObj) => {
      if (location.includes(locationObj?.location)) {
        years.forEach((year) => {
          const monthlyData = locationObj?.years[year]?.monthlyData;
          if (monthlyData) {
            if (!aggregatedData[year]) {
              aggregatedData[year] = {
                Jan: 0,
                Feb: 0,
                Mar: 0,
                Apr: 0,
                May: 0,
                Jun: 0,
                Jul: 0,
                Aug: 0,
                Sep: 0,
                Oct: 0,
                Nov: 0,
                Dec: 0,
              };
            }
            Object.keys(monthlyData).forEach((month) => {
              const emissionData = monthlyData[month][scope]?.emissionData;
              if (emissionData) {
                Object.values(emissionData).forEach((value) => {
                  aggregatedData[year][month] += parseFloat(value) || 0;
                });
              }
            });
          }
        });
      }
    });

    return aggregatedData;
  };

  const aggregatedDataForScope = aggregateData(
    selectedLocation,
    selectedYear,
    selectedScope
  );

  const monthDataArray = Array.from(monthsSet)
    .map((month) => {
      const monthData = {
        month: month,
        data: {},
      };

      selectedYear.forEach((year) => {
        const dataValue = aggregatedDataForScope[year]?.[month] || 0;
        if (dataValue !== 0 && dataValue !== null) {
          monthData.data[year] = dataValue.toFixed(2);
        }
      });

      if (Object.keys(monthData.data).length > 0) {
        return monthData;
      } else {
        return null;
      }
    })
    .filter((item) => item !== null);



 

  const handleDownloadCSV = () => {
    // Filter data based on selected locations, years, and scope
    const filteredData = data.filter((locationObj) =>
      selectedLocation.includes(locationObj.location)
    ).map((locationObj) => {
      const yearsData = {};
      selectedYear.forEach((year) => {
        const monthlyData = locationObj.years[year]?.monthlyData || {};
        const scopeEmissions = Object.entries(monthlyData).reduce((acc, [month, data]) => {
          const scopeData = data[selectedScope]?.emissionData || {};
          const totalScopeEmissions = Object.values(scopeData).reduce((total, value) => total + parseFloat(value), 0);
          acc[month] = totalScopeEmissions.toFixed(2);
          return acc;
        }, {});
        yearsData[year] = scopeEmissions;
      });
      return {
        location: locationObj.location,
        years: yearsData,
      };
    });

    // Convert filtered data to CSV format
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += "Location,Year,Month,Emissions (tCO2e)\n";
    filteredData.forEach((locationData) => {
      Object.entries(locationData.years).forEach(([year, monthlyData]) => {
        Object.entries(monthlyData).forEach(([month, emissions]) => {
          csvContent += `${locationData.location},${year},${month},${emissions}\n`;
        });
      });
    });

    // Trigger the download of the CSV file
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "emissions_data.csv");
    document.body.appendChild(link); // Required for FF
    link.click();
  };


  return (
    <Card elevation={5}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography variant="h6" fontWeight={700}>
              Total GHG Emissions (Scope 1, 2 & 3) (tCO2e)
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <MultipleSelectWithCheckBox
              label="Select Location"
              id="location-multi-select"
              value={selectedLocation}
              options={commonLocations}
              onChange={handleLocationChange}
              isAllSelected={isAllLocationSelected}
            />
          </Grid>
          <Grid item xs={3}>
            <MultipleSelectWithCheckBox
              label="Select Year"
              id="year-multi-select"
              value={selectedYear}
              options={commonYears}
              onChange={handleYearChange}
              isAllSelected={isAllYearSelected}
            />
          </Grid>
          <Grid item xs={3}>
            <Select
              labelId="scope-select-label"
              id="scope-select"
              label="Select Scope"
              value={selectedScope}
              onChange={handleScopeChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                  },
                },
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
                variant: "menu",
              }}
              style={{
                width: "100%",
                height: "42px",
                backgroundColor: "#fff",
                borderRadius: "8px",
                fontFamily: "Poppins !important",
                fontWeight: 500,
                border: "none !important",
                "&:focus": {
                  border: "none",
                },
              }}
            >
              {scopes.map((scope) => (
                <MenuItem value={scope} key={scope}>
                  <Typography sx={{ textTransform: "capitalize" }}>
                    {scopeMapping[scope]}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {isFieldsSelected && (
            <>
             <Grid item xs={3}>
             <Button
                sx={{
                 backgroundColor: "#149ba1",
                 "&:hover": {
                   backgroundColor: "#000000",
                 },
               }}
               startIcon={<FileDownloadIcon />}
               onClick={handleDownloadCSV}
               variant="contained"
               color="primary"
             >
               Download CSV
             </Button>
           </Grid>
           <ChartArea data={monthDataArray} selectedYear={selectedYear}/>
           </>
          )}
         
        </Grid>
       
      </CardContent>
    </Card>
  );
};

export default LineAreaChart;
