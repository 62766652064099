import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import subheader_bg from "../../pages/Assets2/subheader_bg.svg.svg";
import { APP_KEYS } from "../../utils/constants/AppKeyConstants";

const UserCard = () => {
  const userInfo = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));

  const name = userInfo?.userName || "User";
  const company = userInfo?.company || "Your Company";

  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        width: "100%",
        margin: "8px",
        background: 'transparent',
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          padding: "0 !important",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "28px",
            height: "28px",
            borderRadius: "6px",
            border: "0.5px solid #D4D4D4",
            marginRight: "16px",
          }}
        >
          <BusinessIcon sx={{ fontSize: 20, color: '#fff' }} />
        </Box>
          <Typography variant="h6" sx={{ fontWeight: "bold", fontFamily: 'poppins', color: "#fff" }}>
            Hello, {name}
          </Typography>
      </CardContent>
    </Card>
  );
};

export default UserCard;
