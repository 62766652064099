import React, { useEffect, useState } from "react";
import {
  Grid,
  Container,
  Typography,
  Button,
} from "@mui/material";
import ComparisonChart from "./charts/ComparisonChart";
import { makeStyles } from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import CarbonScoreChart from "./charts/PieChart";
import BatchWiseConfidence from "./charts/BatchWiseConfidence";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "./charts/ConfidenceChart/ProgressBar";
import { getProductBatchEmissionData, getTotalCarbonData } from "../../services/analytics";
import TypewriterComponent from "typewriter-effect";
import { APP_KEYS } from "../../utils/constants/AppKeyConstants";
import { ComparisonChart2 } from "./charts/ComparisonChart2";
import PipeUI from "./charts/ScopeBars";
import { getAllProducts } from "../../services/catalogue/productlist";
import { LifeCycleStages } from "./charts/NewAnalytics/LifeCycleStages";
import { all } from "axios";
import PipeUI2 from "./charts/NewAnalytics/ScopeBars";
import { ComparisonChart21 } from "./charts/NewAnalytics/ComparisonChart21";
import { ConfidenceBar } from "./charts/NewAnalytics/ConfidenceChart";
import { ScopeBreakDown } from "./charts/NewAnalytics/ScopeBreakDownChart";
import Joyride from "react-joyride"
import UserCard from "../../components/subheader/UserCard";

const joyrideSteps=[{
  target: `body`,
  title: `Welcome to Your Dashboard!
Let's Get Started!`,
  content: ``,
  locale: {
    next: `Start`, skip: `Skip`
  },
  disableBeacon: true,
  placement: `center`
},{
  target:"#confidenceContainer",
  content:"Explore the CO2 equivalent and comprehensive analytics of your leading carbon score product",
  spotlightPadding:0,
  disableBeacon: true,
},
{
  target: `#stageWiseCarbon`,
  content:" View the stagewise carbon emissions of your products here.",
  spotlightPadding:0,
  disableBeacon: true,
},
{  
    target: `#highestCarbon`,
    content:"The top 4 products with the highest carbon emissions and see their impact on your overall carbon score.",
    spotlightPadding:0,
    placement:"right",
    disableBeacon: true,
},
{
  target: `#carbonScoreComparision`,
  content:"Compare the carbon scores of your products in this section.",
  spotlightPadding:0,
  placement:"left",
  disableBeacon: true,
},
{
  
    target: `#scopewise`,
    content:"See a detailed scope-wise breakdown of carbon emissions of your selected product in this section.",
    placement:"top",
    disableBeacon: true,
}
]

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 20px',
  },
  container: {
    height: '100%',
    background: '#fff',
    // boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.1)',
    borderRadius: '24px',
    border: '0.5px solid #D8D8D8',
    boxShadow: '0px 30px 60px -30px rgba(0, 0, 0, 0.30), 0px 50px 100px -20px rgba(50, 50, 93, 0.25)'
  },

  confidenceContainer: {
    padding: 20,
    marginBottom: theme.spacing(2),
    height: '100%',
    background: '#fff',
    // boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.1)',
    borderRadius: '24px',
    border: '0.5px solid #D8D8D8',
    boxShadow: '0px 30px 60px -30px rgba(0, 0, 0, 0.30), 0px 50px 100px -20px rgba(50, 50, 93, 0.25)',
    display: 'flex',
    gap: '30px',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  messageContainer: {
    width: '360px',
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Poppins',
  },
  pipeContainer: {
    paddingTop: '10px',
    width: '100%',
    borderRadius: '10px',
    height: '17rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  adContainer: {
    borderRadius: '10px',
    background: '#F4F4F4',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
    height: '17rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  adImg: {
    width: '40%',
    height: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100% !important',
    },
  },
  pipeContainer: {
    textAlign: 'center',
  },
  title: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    fontSize: '20px',
    marginBottom: theme.spacing(1),
    color: '#333',
  },
  emissionValue: {
    fontFamily: 'Arial, sans-serif',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  link: {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: '14px',
    transition: 'color 0.3s',
    '&:hover': {
      color: '#222',
    },
  },
  linkText: {
    fontFamily: 'Poppins',
    textDecoration: 'none',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#149BA1'
  },
  analyticsButton: {
    marginTop: '10px',
    borderRadius: '20px',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    background: '#149BA1',
    color: '#fff',
    '&:hover': {
      background: 'linear-gradient(45deg, #149BA1 30%, #FFC950 95%)',
    },
  },  
}));

export default function Dashboard(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [timeOfDay, setTimeOfDay] = useState("");
  // state for charts - piechart,
  const [productData, setProductData] = useState({}); 
  const [top5Products, setTop5Products] = useState([]);
  const [top5ProductsData, setTop5ProductsData] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [allProducts, setAllProducts] = useState([]); 
  // const [highestCarbonScoreProduct, setHighestCarbonScoreProduct] = useState(null);

  // For highest carbon_score product
  const [highestCarbonProduct, setHighestCarbonProduct] = useState(null);

  // const user = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));
  // const [showLoader, setShowLoader] = useState(false);
  const [runJoyride, setRunJoyride]= useState(false)

  const handleJoyrideCallback = (data) =>{
    if (data.action==="close"){
      setRunJoyride(false)
      localStorage.setItem("dashboardtour",'false')
    }

    if (data.action==="reset"){
      setRunJoyride(false)
      localStorage.setItem("dashboardtour",'false')
      navigate('/carboncompete/carboncatalogue')
    }
  }

  useEffect(() => {

    const guidetourval= localStorage.getItem("dashboardtour")==='false' ? false:true    
    setRunJoyride(guidetourval)
  }, []);

  // Function : show time of day and greeting
  const updateGreeting = () => {
    const currentHour = new Date().getHours();
    let greeting = "";

    if (currentHour >= 5 && currentHour < 12) {
      greeting = "Good morning";
    } else if (currentHour >= 12 && currentHour < 17) {
      greeting = "Good afternoon";
    } else {
      greeting = "Good evening";
    }

    setTimeOfDay(greeting);
  };

  useEffect(() => {
    updateGreeting();

    const interval = setInterval(updateGreeting, 60000);
    return () => clearInterval(interval);
  }, []);

  console.log(highestCarbonProduct)

  useEffect(() => {
    getTotalCarbonData({page: 1, limit: 4})
      .then(({data}) => {
        
        if (data && data[0].rows?.length > 0) {
          setHighestCarbonProduct(data[0].rows[0]);
          setTop5ProductsData(data[0]?.rows)
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(()=>{
    getProductBatchEmissionData().then(({data})=>{
      console.log(data, "data from product batch")
      if(data.length > 0){
        const products = data[0].products || {};
        setProductData(products);
        const productsArray = Object.entries(products);
        productsArray.sort(([, productA], [, productB]) => {
          return productB.products_carbon_score - productA.products_carbon_score;
        });
        const top5 = productsArray.slice(0, 5).map(([productName]) => productName);
        setTop5Products(top5);
        
        // for BatchWiseConfidence component
        const productNames = Object.keys(products);
        setProductOptions(productNames);
      }
    }).catch((error)=> {
      console.error("Error fetching data: ", error)
    })
  },[])

useEffect(() => {
    async function fetchAllProducts() {
        try {
            const response = await getAllProducts();
            if (response.success) {
                setAllProducts(response.data);

                // Find product with highest CarbonScore
                let highestScore = -Infinity;
                let highestProduct = null;

                response.data.forEach(product => {
                    if (product?.analytics?.CarbonScore > highestScore) {
                        highestScore = product?.analytics?.CarbonScore;
                        highestProduct = product;
                    }
                });

                // Set state with highest CarbonScore product
                if (highestProduct !== null) {
                    setHighestCarbonProduct({
                        productName: highestProduct?.productName,
                        carbonScore: highestProduct?.analytics?.CarbonScore,
                        productId: highestProduct?.productId
                    });
                }
            } else {
                console.error('Error fetching product data:', response.msg);
            }
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    }

    fetchAllProducts();
}, []);

  return (
      <div className={classes.root}>
        <Helmet>
          <title> Dashboard </title>
        </Helmet>
        <Joyride 
        steps={joyrideSteps}
        run={runJoyride}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        spotlightClicks="true"
        callback={handleJoyrideCallback}
        styles={{
          options: {
            zIndex: 10000,  
          },
  
            overlay: {
            top: 0,
            left: 0,
            width: '100%',
            height: '1000%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', 
            zIndex: 9999,
          },
          tooltip: {
            zIndex: 10001,  
          },
          spotlight: {
            borderRadius: 'inherit',
            padding: 0,  
          },
          buttonNext: {
            backgroundColor: '#009999', 
            color: 'white',
          },
          buttonBack: {
            backgroundColor: '#009999',  
            color: 'white',
          },
          buttonSkip: {
            backgroundColor: '#009999',  
            color: 'white',
          },
        }}
        />
        <Grid container spacing={2.5}>
          <Grid item xs={16} md={8}>  
            <Container className={classes.confidenceContainer} id="confidenceContainer" >
              <Grid xs>
                {/* <ProgressBar highestCarbonProduct={highestCarbonProduct} />  */}
                <ConfidenceBar highestCarbonProduct={highestCarbonProduct} /> 
              </Grid>
              <Grid xs className={classes.messageContainer}>
                <div style={{ }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: '', border: '1px solid #ccc', borderRadius: '10px', padding: '10px', fontFamily: 'Poppins', fontWeight: 'bold', gap: '10px' }}>
                    <Typography
                      sx={{
                        color: '#222',
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontWeight: '600',
                        textAlign: 'center',
                      }}
                      variant="body1"
                      color="textSecondary"
                      component="div"
                    >
                      {highestCarbonProduct
                        ? (
                          <>
                            Delve into your highest Carbon Score Product →

                          </>
                        )
                        : "Start your carbon journey by adding your products"}
                    </Typography>

                    {highestCarbonProduct && (
                      <div style={{ color: '#149BA1', fontSize: '16px', fontWeight: 700, textTransform: 'uppercase', textAlign: 'center' }}>
                        {highestCarbonProduct.productName}
                      </div>
                    )}
                    <Button
                      variant="outlined"
                      className={classes.analyticsButton}
                      onClick={() => {
                        if (highestCarbonProduct) {
                          navigate(`/carboncompete/carboncatalogue/product/${highestCarbonProduct.productId}`);
                        } else {
                          navigate(`/carboncompete/carboncatalogue`);
                        }
                      }}
                    >
                      {highestCarbonProduct ? "Click for Analytics" : "Add products"}
                    </Button>
                  </div>
                  {highestCarbonProduct && (
                    <div>
                      <div style={{minHeight: "100px", marginTop: '10px', border: '1px solid #ccc', borderRadius: '10px', fontFamily: 'Poppins', fontWeight: 'bold', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection:'column', padding: '8px', textAlign: 'center' }}>
                      This carbon emission equates to  
                        <Typography sx={{ color: '#000', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }} variant="body1" color="textSecondary">
                          {highestCarbonProduct && (
                            <span style={{ color: '#a81111' }}>
                              <TypewriterComponent
                                options={{
                                  strings: [
                                    `${parseFloat(highestCarbonProduct?.carbonScore / 0.3889).toFixed(0)} Miles driven by gasoline-powered vehicle.`,
                                    `${parseFloat(highestCarbonProduct?.carbonScore * 0.098214).toFixed(0)} Gallons of diesel consumed.`,
                                    `${parseFloat(highestCarbonProduct?.carbonScore / 0.0082).toFixed(0)} Number of smartphones charged.`,
                                  ],
                                  autoStart: true,
                                  loop: true,
                                  deleteSpeed: 50,
                                }}
                              />
                            </span>
                          )}
                        </Typography>
                      </div>
                    </div>
                  )}
                </div>
              </Grid>
            </Container>
          </Grid>
          {/* New Analytics */}
          <Grid item xs={12} md={4}>
            <Container className={classes.container} id="stageWiseCarbon">
              <LifeCycleStages allProducts={allProducts}/>
            </Container>
          </Grid>
      
           <Grid item xs={12} md={6}>
            <Container className={classes.container} id="highestCarbon">
              <PipeUI2 allProducts={allProducts}/>
            </Container>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container className={classes.container} id="carbonScoreComparision">
              <ComparisonChart21 allProducts={allProducts}/>
            </Container>
          </Grid>
          <Grid maxWidth item xs={12} sx={{marginBottom:"20px"}} >
             <Container className={classes.container} id="scopewise">
             <ScopeBreakDown allProducts={allProducts}/>
             </Container>
             
            
          </Grid>

          {/* Old Analytics */}
          {/* <Grid item xs={12} md={4}>
            <Container className={classes.container}>
              <CarbonScoreChart productData={productData} top5Products={top5Products}/>
            </Container>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container className={classes.container}>
              <PipeUI allProducts={allProducts}/>
            </Container>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container className={classes.container}>
              <BatchWiseConfidence productOptions={productOptions}/>
            </Container>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container className={classes.container}>
              <ComparisonChart2 allProducts={allProducts}/>
            </Container>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container className={classes.container}>
              <ComparisonChart productData={productData}/>
            </Container>
          </Grid> */}
        </Grid>
      </div>
  );
}

// make a null check


