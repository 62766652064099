import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// components
import Iconify from '../../../components/iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import cc_small_logo from '../../../pages/Assets2/cc-small-logo-main.png';
import carbon_intel_logo from '../../../pages/Assets2/carbonintel.png';
import UserCard from '../../../components/subheader/UserCard';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 50;

const HEADER_DESKTOP = 68;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  background: "black",
  boxShadow: 'none',
  zIndex: theme.zIndex.drawer + 1, // Ensures header stays on top
  [theme.breakpoints.up('lg')]: {
    // width: `calc(100% - ${NAV_WIDTH + 1}px)`,
    // width: '100%',
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    // padding: theme.spacing(0, 1),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'white',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Box
          sx={{
            display: { xs: 'none', lg: 'flex' },
            alignItems: 'center',
            mr: 2,
          }}
        >
          <Box component="img" src={cc_small_logo} alt="CC Logo" sx={{ height: 30, mr: 1 }} />
          <Box component="img" src={carbon_intel_logo} alt="Carbon Intel Logo" sx={{ height: 30, width: 150 }} />
        </Box>

        <Box sx={{ flexGrow: 1 }} />
        <UserCard />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}

