/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { MultipleSelectWithCheckBox } from "../../../components/form/selectComponents";
import { monthsSet } from "../../../provider/EsgDataFilteration";
import { toCamelCase } from "../../../utils/helperFunctions";
import ChartArea from "./ChartArea";

const EmissionChart = ({ data, defaultLocations, defaultYears, scope }) => {
  const [commonYears, setCommonYears] = useState([]);
  const [commonLocations, setCommonLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedYear, setSelectedYear] = useState([]);
  const [stageName, setStageName] = useState("Waste");
  const [isFieldsSelected,setIsFieldsSelected]=useState(true)
  const stageNames = [
    "Waste",
    "Water",
    "Food_Travel",
    "Business_Travel",
    "Employee_Commute",
  ];
  const isAllYearSelected =
    commonYears.length > 0 && selectedYear.length === commonYears.length;
  const isAllLocationSelected =
    commonLocations.length > 0 &&
    selectedLocation.length === commonLocations.length;

  const handleYearChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedYear(
        selectedYear.length === commonYears.length ? [] : commonYears
      );
      return;
    }
    setSelectedYear(value);
  };

  const handleLocationChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedLocation(
        selectedLocation.length === commonLocations.length
          ? []
          : commonLocations
      );
      return;
    }
    setSelectedLocation(value);
  };
  useEffect(() => {
    if (defaultLocations) {
      const filteredLocations = defaultLocations.filter(
        (location) => location !== "Others"
      );
      setCommonLocations(filteredLocations);
      setSelectedLocation(filteredLocations);
    }
    if (defaultYears) {
      setCommonYears(Array.from(defaultYears));
      setSelectedYear([defaultYears[0], defaultYears[defaultYears.length - 1]]);
    }
  }, [defaultLocations, defaultYears]);

  useEffect(() => {
    setIsFieldsSelected(selectedLocation.length > 0 && selectedYear.length > 0);
  }, [selectedLocation, selectedYear]);
  const aggregatedData = {};

  data.forEach((locationObj) => {
    if (selectedLocation.includes(locationObj?.location)) {
      Object.keys(locationObj?.years).forEach((year) => {
        if (selectedYear.includes(year)) {
          const monthlyData = locationObj?.years[year].monthly_stage_emission;
          if (monthlyData) {
            if (!aggregatedData[year]) {
              aggregatedData[year] = {
                Jan: 0,
                Feb: 0,
                Mar: 0,
                Apr: 0,
                May: 0,
                Jun: 0,
                Jul: 0,
                Aug: 0,
                Sep: 0,
                Oct: 0,
                Nov: 0,
                Dec: 0,
              };
            }
            if (scope == "scope1") {
              Object.keys(monthlyData).forEach((stage) => {
                if (stage == "Energy") {
                  Object.keys(monthlyData[stage]).forEach((month) => {
                    if (!aggregatedData[year]) {
                      aggregatedData[year] = {};
                    }
                    if (!aggregatedData[year][month]) {
                      aggregatedData[year][month] = 0;
                    }
                    aggregatedData[year][month] +=
                      monthlyData[stage][month].scope1.totalEmission;
                  });
                }
              });
            } else if (scope == "scope2") {
              Object.keys(monthlyData).forEach((stage) => {
                if (stage == "Energy") {
                  Object.keys(monthlyData[stage]).forEach((month) => {
                    if (!aggregatedData[year]) {
                      aggregatedData[year] = {};
                    }
                    if (!aggregatedData[year][month]) {
                      aggregatedData[year][month] = 0;
                    }
                    aggregatedData[year][month] +=
                      monthlyData[stage][month].scope2.totalEmission;
                  });
                }
              });
            } else if (scope == "scope3") {
              Object.keys(monthlyData).forEach((stage) => {
                if (
                  stage != "Energy" &&
                  stage != "Goods_&_Services" &&
                  stage == stageName
                ) {
                  Object.keys(monthlyData[stage]).forEach((month) => {
                    if (!aggregatedData[year]) {
                      aggregatedData[year] = {};
                    }
                    if (!aggregatedData[year][month]) {
                      aggregatedData[year][month] = 0;
                    }
                    aggregatedData[year][month] +=
                      monthlyData[stage][month].scope3.totalEmission;
                  });
                }
              });
            }
          }
        }
      });
    }
  });
  // console.log(aggregatedData);

  const monthDataArray = Array.from(monthsSet)
    .map((month) => {
      const monthData = {
        month: month,
        data: {},
      };

      selectedYear.forEach((year) => {
        const dataValue = aggregatedData[year]?.[month] || 0;
        if (dataValue !== 0 && dataValue !== null) {
          monthData.data[year] = dataValue.toFixed(2);
        }
      });

      if (Object.keys(monthData.data).length > 0) {
        return monthData;
      } else {
        return null;
      }
    })
    .filter((item) => item !== null);



  const sliceScope = (str) => {
    return str.slice(0, 5) + " " + str.slice(5);
  };

  const selectStyles = {
    width: "100%",
    height: "42px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    fontWeight: 900,
    border: "none !important",
    "&:focus": {
      border: "none",
    },
  };

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 180,
        overflowY: "scroll",
      },
    },
  };
  const handleStageChange = (e) => {
    setStageName(e.target.value);
  };

  const stageMapping = {};
  stageNames?.forEach((act) => {
    stageMapping[act] = toCamelCase(act);
  });

  const generateCSV = () => {
    const headers = ["Year", "Month", "Emission (tCO2e)"];
    const rows = [];

    Object.keys(aggregatedData).forEach((year) => {
      monthsSet.forEach((month) => {
        if (aggregatedData[year][month]) {
          rows.push([year, month, aggregatedData[year][month]]);
        }
      });
    });

    const csvContent =
      "data:text/csv;charset=utf-8," +
      [headers.join(","), ...rows.map((row) => row.join(","))].join("\n");

    return encodeURI(csvContent);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.setAttribute("href", generateCSV());
    link.setAttribute("download", "emissions_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card
            elevation={4}
            sx={{
              border: `1px solid rgba(102, 177, 50, 1)`,
              borderRadius: "6px",
              boxShadow: " 0 6px 10px 0 rgba(0, 0, 0, 0.06) ",
            }}
          >
            <CardContent>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
              >
                <Grid item xs={3}>
                  <Typography
                    variant="h6"
                    fontWeight={700}
                    style={{ textTransform: "capitalize" }}
                  >
                    {sliceScope(scope)} monthly emissions
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <MultipleSelectWithCheckBox
                    label="Select Location"
                    id="location-multi-select"
                    value={selectedLocation}
                    options={commonLocations}
                    onChange={handleLocationChange}
                    isAllSelected={isAllLocationSelected}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MultipleSelectWithCheckBox
                    label="Select Year"
                    id="year-multi-select"
                    value={selectedYear}
                    options={commonYears}
                    onChange={handleYearChange}
                    isAllSelected={isAllYearSelected}
                  />
                </Grid>
                {scope == "scope3" && (
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <InputLabel id="year-select-label">
                        Select Activity
                      </InputLabel>
                      <Select
                        labelId="year-select-label"
                        id="year-select"
                        value={stageName}
                        onChange={handleStageChange}
                        MenuProps={menuProps}
                        style={selectStyles}
                      >
                        {stageName &&
                          stageNames.map((act) => (
                            <MenuItem value={act} key={act}>
                              {stageMapping[act]}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                
              </Grid>
              {isFieldsSelected && (
            <>
             <Grid item xs={3}>
             <Button
                sx={{
                 backgroundColor: "#149ba1",
                 "&:hover": {
                   backgroundColor: "#000000",
                 },
               }}
               startIcon={<FileDownloadIcon />}
               onClick={handleDownload}
               variant="contained"
               color="primary"
             >
               Download CSV
             </Button>
           </Grid>
           <ChartArea data={monthDataArray} selectedYear={selectedYear}/>
           </>
          )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EmissionChart;
