import { useNavigate } from 'react-router-dom';
// @mui
import {
    Stack,
    TextField,
    Typography,
    CircularProgress,
    Box,
    Container
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useFormik } from 'formik';
import * as yup from 'yup';
import { forgotPassword } from '../../../services/auth';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import Typewriter from 'typewriter-effect';
import { NOTIFICATION_SUCCESS, NOTIFICATION_ERROR, NOTIFICATION_INFO } from '../../../utils/constants/NotificationConstants'
import { makeStyles } from '@material-ui/core';
import cc_small_logo from '../../../pages/Assets2/cc-small-logo-main.png'

const useStyles = makeStyles((theme) => ({
    logo: {
        width: '40.229px',
        height: '60.879px',
    },
    wordAnimation: {
        animation: '$fadeInOut 2s linear infinite',
    },
    '@keyframes fadeInOut': {
        '0%, 100%': {
            opacity: 0,
        },
        '50%': {
            opacity: 1,
        },
    },
    compete: {
        opacity: 1,
    },
    intel: {
        opacity: 0,
    },
    logo_font1: {
        fontSize: '30px',
        color: '#000',
        marginBottom: '-17px',
        fontWeight: 'bold',
        fontFamily: 'poppins',
        letterSpacing: '1.5px'
    },
    logo_font2: {
        fontSize: '30px',
        color: '#000',
        marginTop: '-17px',
        fontWeight: 'bold',
        fontFamily: 'poppins',
        letterSpacing: '1.5px'
    },
    logo_font_container: {
        marginLeft: '-15px !important',
        width: '51.229px',
        height: '70.879px',
    }
}));

export const ForgotPassword = ({ setAuthNavigation }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [showLoginLoader, setShowLoginLoader] = useState(false);

    const validationSchema = yup.object({
        userEmail: yup.string().required('Please enter your userEmail'),
    });

    const formik = useFormik({
        validationSchema,
        initialValues: {
            userEmail: '',
        },
        onSubmit: async () => {
            setShowLoginLoader(true);
            try {
                await forgotPassword(formik.values);

                // show success message to user
                enqueueSnackbar('Password change link sent successfully', {
                    autoHideDuration: 3000,
                    variant: NOTIFICATION_SUCCESS
                });
                setShowLoginLoader(false);
            } catch (err) {

                // Add Error to notify user
                enqueueSnackbar(err.message || err.msg || 'Error occured', {
                    autoHideDuration: 3000,
                    variant: NOTIFICATION_ERROR
                });

                setShowLoginLoader(false);
            }
        },
    });

    const [typewriterCount, setTypewriterCount] = useState(0);

    const handleTypewriterComplete = () => {
      if (typewriterCount < 1) {
        setTypewriterCount(typewriterCount + 1);
      }
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <img className={classes.logo} src={cc_small_logo} alt='cc_logo' />
                <Container sx={classes.logo_font_container}>
                    <Typography className={classes.logo_font1}>Carbon <br></br></Typography>
                    <Typography className={classes.logo_font2}>
                        {typewriterCount < 2 && (
                            <Typewriter
                                options={{
                                    strings: ["Compete", "Intel"],
                                    autoStart: true,
                                    cursor: ''
                                }}
                                onInit={(typewriter) => {
                                    typewriter
                                        .typeString("Compete")
                                        .pauseFor(1000)
                                        .deleteAll()
                                        .typeString("Intel")
                                        .start()
                                        .callFunction(() => handleTypewriterComplete());
                                }}
                            />
                        )}
                        {typewriterCount === 2 && (
                            <span>Compete</span>
                        )}
                    </Typography>


                </Container>
            </Box>
            <Typography sx={{ mt: 3 }} fontFamily="Poppins" fontSize="20px" gutterBottom>
                <b>Change Password </b>
            </Typography>
            <Stack sx={{ mt: 3 }} spacing={3}>
                <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                    <TextField
                        label={
                            <Typography fontFamily="Poppins" fontWeight={500}>
                                Enter your email to request password change
                            </Typography>
                        }
                        sx={{ width: '100%', mt: 1 }}
                        name="userEmail"
                        value={formik.values.userEmail}
                        onChange={formik.handleChange}
                        error={formik.touched.userEmail && Boolean(formik.errors.userEmail)}
                        helperText={
                            formik.touched.userEmail && formik.errors.userEmail
                        }
                    />

                    <>
                        <LoadingButton
                            sx={{ mt: 2, fontFamily: 'Poppins', backgroundColor: '#149BA1', '&:hover': { bgcolor: '#303030' } }}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            startIcon={showLoginLoader && <CircularProgress size={15} style={{ color: 'white' }} />}
                        >
                            Submit
                        </LoadingButton>
                        <Typography fontFamily="Poppins" style={{ marginTop: '1.5rem' }}>
                            <span
                                onClick={() => setAuthNavigation(0)}
                                style={{ fontWeight: '600', textDecoration: 'underline', cursor: 'pointer', }}
                            >
                                Sign In
                            </span>
                        </Typography>
                    </>
                </form>
            </Stack>
        </>
    );
};
