import React from "react";
import ReactECharts from "echarts-for-react";
import scopeImage from "../../Assets2/pie_center.png";
import { toCamelCase } from "../../../utils/helperFunctions";
import { Button } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export default function ChartArea2({ propsForAll, location, year, stageId }) {
  const colors = [
    "#FD5308",
    "#FB9902",
    "#36454F",
    "#66B132",
    "#0291CD",
    "#0247FE",
    "#3E01A4",
    "#8601B0",
    "#A7194B",
  ];
  const filteredPropsForAll = {};

  Object.keys(propsForAll).forEach((location) =>
    Object.keys(propsForAll[location]).forEach((id) => {
      if (id === stageId) {
        filteredPropsForAll[location] = propsForAll[location][id];
      }
    })
  );

  let filteredDataById = {};

  if (stageId === "15") {
    Object.keys(filteredPropsForAll).forEach((loc) => {
      if (!filteredDataById[loc]) {
        filteredDataById[loc] = {};
      }
      Object.keys(filteredPropsForAll[loc]).forEach((year) => {
        if (!filteredDataById[loc][year]) {
          filteredDataById[loc][year] = {};
        }
        const { DIESEL, EB_POWER, RE_POWER } = filteredPropsForAll[loc][year];
        filteredDataById[loc][year] = { DIESEL, EB_POWER, RE_POWER };
      });
    });
  } else if (stageId === "12") {
    Object.keys(filteredPropsForAll).forEach((loc) => {
      if (!filteredDataById[loc]) {
        filteredDataById[loc] = {};
      }
      Object.keys(filteredPropsForAll[loc]).forEach((year) => {
        if (!filteredDataById[loc][year]) {
          filteredDataById[loc][year] = {};
        }
        const { FRESH_WATER, TREATED_WATER } = filteredPropsForAll[loc][year];
        filteredDataById[loc][year] = { FRESH_WATER, TREATED_WATER };
      });
    });
  } else {
    filteredDataById = filteredPropsForAll;
  }

  let filteredLocationForAll = {};
  location.forEach((loc) => {
    if (filteredDataById[loc]) {
      filteredLocationForAll[loc] = filteredDataById[loc];
    }
  });

  let updatedData = {};
  let percentages = {};
  year.forEach((y) => {
    Object.keys(filteredDataById).forEach((loc) => {
      if (filteredDataById[loc][y]) {
        Object.keys(filteredDataById[loc][y]).forEach((category) => {
          if (!updatedData[category]) {
            updatedData[category] = 0;
          }

          updatedData[category] += filteredDataById[loc][y][category];
        });
      }
    });
  });

  const totalSum = Object.values(updatedData).reduce(
    (acc, val) => acc + val,
    0
  );

  Object.keys(updatedData).forEach((category) => {
    const percent = (updatedData[category] / totalSum) * 100;

    percentages[category] = {
      percentage: percent.toFixed(2),
      data: updatedData[category].toFixed(2),
    };
  });

  const generateChartOption = () => {
    const chartData = [];
    Object.keys(percentages).forEach((category, index) => {
      chartData.push({
        value: percentages[category].percentage,
        name: toCamelCase(category),
        data: percentages[category].data,
        itemStyle: {
          color: colors[index % colors.length],
        },
      });
    });

    const option = {
      tooltip: {
        trigger: "item",
        formatter: (params) => {
          const { name, data } = params;
          if (stageId === "15") {
            return `${name}<br/>Value: ${data?.data} GJ`;
          } else if (stageId === "12") {
            return `${name}<br/>Value: ${data?.data} kL`;
          } else if (stageId === "13") {
            return `${name}<br/>Value: ${data?.data} Tons`;
          } else {
            return `${name}<br/>Value: ${data?.data} kms`;
          }
        },
      },
      toolbox: {
        show: true,
        orient: "vertical",
        left: "right",
        top: "center",
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ["line", "bar", "stack"] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      legend: {
        data: chartData.map((data) => data.name),
        left: "center",
        top: "top",
        // orient: "vertical",
        // left: 10,
        textStyle: {
          color: "#000000",
        },
      },
      graphic: [
        {
          type: "image",
          left: "center",
          top: "center",
          z: 10,
          style: {
            image: scopeImage,
            width: 128,
            height: 128,
          },
        },
      ],
      series: [
        {
          name: "Category",
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          padAngle: 5,

          itemStyle: {
            borderRadius: 10,
            borderWidth: 10,
            borderColor: "white",
          },
          label: {
            show: true,
            formatter: "{c}%",
            position: "outside",
            fontFamily: "Poppins",
            fontWeight: "bold",
            fontSize: 14,
            edgeDistance: 10,
            color: "#000000",
            alignTo: "labelLine",
          },
          labelLine: {
            show: false,
            length: 10,
          },
          data: chartData,
        },
      ],
    };

    return option;
  };

  const handleDownloadCSV = () => {
    // Prepare CSV data and initiate download
    const csvContent = "data:text/csv;charset=utf-8,"
      + Object.keys(updatedData).map((key) => `"${toCamelCase(key)}","${updatedData[key]}"`).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "chart_data.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
  };

  return (
    <div>
      <Button
        sx={{
          backgroundColor: "#149ba1",
          "&:hover": {
            backgroundColor: "#000000",
          },
        }}
        variant="contained"
        color="primary"
        startIcon={<FileDownloadIcon />}
        onClick={handleDownloadCSV}
        style={{ marginTop: "10px" }}
      >
      </Button>
      <ReactECharts option={generateChartOption()} style={{ height: "400px" }} />
    </div>
    );
}
