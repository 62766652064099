import React, { useState, useEffect } from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  Typography,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import ReactEcharts from 'echarts-for-react';

const useStyles = makeStyles((theme) => ({
  customLegend: {
    position: 'absolute',
    bottom: 10,
    left: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
}));

export const LifeCycleStages = ({ allProducts }) => {
  const classes = useStyles();
  const [selectedProduct, setSelectedProduct] = useState(
    allProducts && allProducts.length > 0 ? allProducts[0] : null
  );

  console.log(allProducts)  
  useEffect(() => {
    if (allProducts && allProducts.length > 0) {
      setSelectedProduct(allProducts[0]);
    }
  }, [allProducts]);

  const handleChange = (event) => {
    const productName = event.target.value;
    const selected = allProducts.find((product) => product.productName === productName);
    setSelectedProduct(selected);
  };

 

  const getChartOptions = (lifeCycleStages) => {
    if (!lifeCycleStages || lifeCycleStages.length === 0) {
      return {
        title: {
          text: 'No Data Available',
          left: 'center',
        },
        series: [],
      };
    }

    return {
      title: {
        left: 'center',
        textStyle: {
          fontSize: 20,
          fontWeight: 'bold',
        },
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
      },
      // legend: {
      //   show: false,
      //   top: '5%',
      //   left: 'center',
      // },
      color: ['#149BA1', '#FFC950', '#FFA058', '#006799', '#DAF7A6', '#CCCCFF'],
      series: [
        {
          name: 'Stage Carbon Scores',
          type: 'pie',
          radius: ['50%', '90%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 5,
            borderColor: '#fff',
            borderWidth: 3,
          },
          data: lifeCycleStages.map((stage) => ({
            value: stage.sumContribution.toFixed(2),
            name: stage.name,
          })),
          label: {
            show: false,
            position: 'center',
            formatter: '{b}',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 14,
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          color: ['#149BA1', '#FFC950', '#FFA058', '#006799', '#DAF7A6', '#CCCCFF'],
        },
      ],
    };
  };

  const theme = createTheme({
    typography: {
      fontFamily: 'Poppins',
    },
  });

  if (!allProducts || !Array.isArray(allProducts)) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'fit-content', padding: '20px 0' }}>
        <div style={{ width: '100%', display: "flex", flexDirection: 'column', gap: 10 }}>
          <Typography
            sx={{ fontFamily: 'poppins', fontSize: '15px', fontWeight: 600, textAlign: 'center' }}
          >
            Life Cycle Stages Contribution
          </Typography>
          <FormControl fullWidth>
            <Select
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 180,
                    overflowY: 'scroll',
                  },
                },
              }}
              size='small'
              style={{
                width: '100%',
                backgroundColor: '#F1F1F1',
                borderRadius: '8px',
                fontWeight: 900,
                border: 'none !important',
                '&:focus': {
                  border: 'none',
                },
              }}
              value={selectedProduct ? selectedProduct.productName : ''}
              onChange={handleChange}
            >
              {allProducts.map((product) => (
                <MenuItem sx={{ fontSize: '12px' }} key={product.productId} value={product.productName}>
                  {product.productName.length > 13 ? `${product.productName.slice(0, 27)}` : product.productName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        

        {selectedProduct && selectedProduct.analytics.LifeCycleStages && (
          <div>
            <ReactEcharts option={getChartOptions(selectedProduct.analytics.LifeCycleStages)} style={{ height: '230px', width: '300px', marginTop: '10px' }} />
            {/* <div className={classes.customLegend}>
              {selectedProduct.analytics.LifeCycleStages.map((stage, index) => (
                <div key={stage.name} className={classes.legendItem}>
                  <div style={{ width: 20, height: 20, backgroundColor: getChartOptions(selectedProduct.analytics.LifeCycleStages).series[0].color[index] }}></div>
                  <Typography>{stage.name}</Typography>
                </div>
              ))}
            </div> */}
          </div>
        )}

        {!selectedProduct && (
          <div>
            <p>Please select a product.</p>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};
