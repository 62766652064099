import React from 'react';
import ReactECharts from 'echarts-for-react';
import { Box, Card, Typography } from '@mui/material';

const SubCategoryEmissions = ({ subCategoryData, categoryName }) => {
    console.log("SubCategoryData:", subCategoryData); // Debugging line

    if (!subCategoryData || Object.keys(subCategoryData).length === 0) {
        return <div>No data available</div>; // Fallback UI if no data
    }

    const years = Object.keys(subCategoryData);
    const subCategories = new Set();
    years.forEach(year => {
        Object.keys(subCategoryData[year]).forEach(subCategory => subCategories.add(subCategory));
    });

    const subCategoryArray = Array.from(subCategories);
    const series = subCategoryArray.map(subCategory => {
        return years.map(year => subCategoryData[year][subCategory]?.total_emissions || 0);
    });

    const colors = [
        "#FD5308", "#FB9902", "#36454F", "#66B132", "#0291CD", 
        "#0247FE", "#3E01A4", "#8601B0", "#A7194B"
    ];

    const options = {
        color: colors,
        xAxis: {
            type: 'category',
            data: years,
            axisLine: {
                lineStyle: {
                    color: '#000000',
                },
            },
        },
        yAxis: {
            type: 'value',
            name: 'Total Emissions',
            axisLine: {
                lineStyle: {
                    color: '#000000',
                },
            },
            splitLine: {
                lineStyle: {
                    color: '#e0e0e0',
                },
            },
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
            formatter: function (params) {
                let tooltip = '<div style="text-align: left;">';
                params.forEach((param) => {
                    const value = param.value !== undefined && param.value !== null ? param.value.toFixed(2) : '0.00';
                    tooltip +=
                        '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;background-color:' +
                        param.color +
                        '"></span>' +
                        param.seriesName +
                        ": " +
                        value +
                        " (tCO2e)" +
                        "<br/>";
                });
                tooltip += "</div>";
                return tooltip;
            },
        },
        legend: {
            data: subCategoryArray,
            left: 'center',
            top: 'top',
            textStyle: {
                color: '#000000',
            },
        },
        series: subCategoryArray.map((subCategory, index) => ({
            name: subCategory,
            type: 'bar',
            stack: 'total',
            data: series[index],
            itemStyle: {
                color: colors[index % colors.length],
            },
            barWidth: '20%',
        })),
        toolbox: {
            show: true,
            orient: 'vertical',
            left: 'right',
            top: 'center',
            feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: false },
                magicType: { show: true, type: ['line', 'bar', 'stack'] },
                restore: { show: true },
                saveAsImage: { show: true },
            },
        },
    };

    return (
        <Card elevation={3} sx={{ p: 3, mt: 4 }}>
            <Typography pb={2} textAlign={'center'} variant="h6" fontWeight={700}>
                Emissions Breakdown for {categoryName}
            </Typography>
            <ReactECharts option={options} style={{ height: 400 }} />
        </Card>
    );
};

export default SubCategoryEmissions;