import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  IconButton,
  TextField,
  Button,
  Alert,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import Iconify from "../../components/iconify/Iconify";
import { changePassword } from "../../services/auth";

export const ChangePassword = ({ open, setOpen }) => {
  const [showAlert, setShowAlert] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const validationSchema = yup.object({
    userEmail: yup.string().required("Please enter User Email"),
    oldPassword: yup.string().required("Please enter old password"),
    newPassword: yup.string().required("Please enter your new password"),
  });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      userEmail: "",
      oldPassword: "",
      newPassword: "",
    },
    onSubmit: async (values) => {
      try {
        setShowLoader(true);
        const response = await changePassword({ ...values });
        console.log("Response:", response);

        if (response && response.success) {
          if (response.statusCode === 200) {
            setShowAlert("password_changed");
            setTimeout(() => {
              setShowLoader(false);
              setOpen(false);
            }, 2000);
          } else if (response.statusCode === 400) {
            setShowAlert("failed_to_change");
          } else {
            setShowAlert("unknown_error");
          }
        } else {
          setShowAlert("unknown_error");
        }

        setTimeout(() => {
          setShowLoader(false);
        }, 2000);
      } catch (err) {
        console.error("Error:", err);
        setShowAlert("unknown_error");
      }
    },
  });

  useEffect(() => {
    setTimeout(() => {
      setShowAlert("");
    }, 1500);
  }, [showAlert]);

  return (
    <Container
      sx={{
        p: 10,
        backgroundColor: "#fff",
        boxShadow: "0 0px 15px -10px black",
        borderRadius: "8px",
        width:"990px"
      }}
    >
      <Container sx={{ mb: 2 }}>
        <Typography
          sx={{ fontSize: "24px", fontFamily: "Poppins", fontWeight: 600, color: "#159ba1", mb:10 }}
        >
          Change Password
        </Typography>
      </Container>

      <form onSubmit={formik.handleSubmit}>
        {showAlert === "password_changed" ? (
          <Alert severity="success" sx={{ width: "100%", mb: 2 }}>
            <strong>Password changed successfully</strong>
          </Alert>
        ) : showAlert === "failed_to_change" ? (
          <Alert severity="error" sx={{ width: "100%", mb: 2 }}>
            <strong>Failed to change password. Please check your information.</strong>
          </Alert>
        ) : showAlert === "unknown_error" ? (
          <Alert severity="error" sx={{ width: "100%", mb: 2 }}>
            <strong>Enter correct email or password</strong>
          </Alert>
        ) : null}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="userEmail"
              value={formik.values.userEmail}
              onChange={formik.handleChange}
              label="Enter your registered email"
              fullWidth
              sx={{
                ".MuiInputLabel-root": {
                  fontFamily: "Poppins",
                  fontSize: "15px",
                },
                ".MuiInputBase-input": {
                  fontFamily: "Poppins",
                },
              }}
              error={formik.touched.userEmail && Boolean(formik.errors.userEmail)}
              helperText={formik.touched.userEmail && formik.errors.userEmail}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="oldPassword"
              value={formik.values.oldPassword}
              onChange={formik.handleChange}
              label="Enter your old password"
              type={showOldPassword ? "text" : "password"}
              fullWidth
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => setShowOldPassword((s) => !s)}>
                    <Iconify icon={showOldPassword ? "fa-solid:eye-slash" : "fa-solid:eye"} />
                  </IconButton>
                ),
              }}
              sx={{
                ".MuiInputLabel-root": {
                  fontFamily: "Poppins",
                  fontSize: "15px",
                },
                ".MuiInputBase-input": {
                  fontFamily: "Poppins",
                },
              }}
              error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
              helperText={formik.touched.oldPassword && formik.errors.oldPassword}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="newPassword"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              label="Enter new password"
              type={showNewPassword ? "text" : "password"}
              fullWidth
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => setShowNewPassword((s) => !s)}>
                    <Iconify icon={showNewPassword ? "fa-solid:eye-slash" : "fa-solid:eye"} />
                  </IconButton>
                ),
              }}
              sx={{
                ".MuiInputLabel-root": {
                  fontFamily: "Poppins",
                  fontSize: "15px",
                },
                ".MuiInputBase-input": {
                  fontFamily: "Poppins",
                },
              }}
              error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
              helperText={formik.touched.newPassword && formik.errors.newPassword}
            />
          </Grid>
        </Grid>

        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <Button
            type="submit"
            sx={{
              width: "30%",
              backgroundColor: "black",
              "&:hover": {
                backgroundColor: "#107374",
                left:'0'
              },
            }}
            variant="contained"
            startIcon={showLoader ? <CircularProgress size={20} style={{ color: "white" }} /> : null}
          >
            <Typography fontFamily="Poppins" fontWeight={600}>
              Save Password
            </Typography>
          </Button>
        </Container>
      </form>
    </Container>
  );
};


