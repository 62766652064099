import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Slide,
  IconButton,
  Backdrop,
  CircularProgress,
  Grid,
  Paper,
  InputBase,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { getAllProductsByCategory } from "../../services/catalogue/productlist";
import { Products } from "./Products";
import { useNavigate } from "react-router-dom";
import { APP_KEYS } from "../../utils/constants/AppKeyConstants";
import { categoryImage } from "./Images/CatalogueImages";
import UploadProductDialog from "./UploadProduct";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { CatalogCategoryDrawer } from "../../components/drawer/catalogCategory";
import UploadTemplateValueDialog from './UploadTemplateValue';
import Joyride from "react-joyride";


const joyrideSteps=[{
  target: `body`,
  title: `Explore Your Carbon Catalog! `,
  content: `Check all your existing products and categories, and seamlessly add new products with just a few clicks.`,
  locale: {
    next: `Start`, skip: `Skip`
  },
  disableBeacon: true,
  placement: `center`
},{
  target:"#Menu",
  content:"Click on the Menu to see a list of your existing categories",
  spotlightPadding:0,
  disableBeacon: true,
},{
  target: `#search`,
  content:"Find your products easily here.",
  spotlightPadding:0,
  disableBeacon: true,
},
  {
    target: `#addProducts`,
    content:"Click here to add a new product and configure its supply chain",
    spotlightPadding:0,
    disableBeacon: true,
},
{
  target: `#uploadProduct`,
  content:"Click here to upload new a product directly using our catalog template",
  spotlightPadding:0,
  disableBeacon: true,
},
{
  target: `#uploadTemplateValue`,
  content:"Click here to upload new template values.",
  spotlightPadding:0,
  disableBeacon: true,
},
{
  target: `#categories`,
  content:"This section displays the list of your existing categories.",
  spotlightPadding:0,
  disableBeacon: true,
},
{
    target: `#Products`,
    content: (
      <div>
        Products in the selected category are shown below. Click "Generate Report" to get the
<b> Uncertified</b> Carbon Equivalent Footprint Report. You can also click on a product to edit it or view its analytics.
      </div>
    ),
    spotlightPadding:0,
    disableBeacon: true,
  
}
]

const searchBoxStyles = {
  maxWidth: "399px",
  marginLeft: "40px",
  height: "50px",
};

const buttonStyles = {
  backgroundColor: "#149BA1",
  padding: "10px 20px",
  minWidth: "100px",
  color: "#fff",
  boxShadow: "0px 0px 20px -10px black",
};

export const CarbonCatalogue = () => {

  const [categoryId, setCategoryID] = useState("")
  const [start, setStart] = useState(0);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [activeButton, setActiveButton] = useState(0); // 1st category
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [uploadDialog, toggleUploadDialog] = useState(false);
  const [uploadTemplateDialog, toggleUploadTemplateDialog] = useState(false);
  const itemsPerPage = 8;
  const [runJoyrideCC, setRunJoyrideCC] = useState(true)
  
  const handleJoyrideCallback = (data) =>{
    if (data.action==="close"){
      setRunJoyrideCC(false)
      localStorage.setItem("carboncatalogtour",'false')
    } 

    if (data.action==="reset"){
      setRunJoyrideCC(false)
      localStorage.setItem("carboncatalogtour",'false')
      navigate("/carboncompete/carboncatalogue/addproduct")
    }
  }

  useEffect(() => {

    const guidetourval= localStorage.getItem("carboncatalogtour")==='false' ? false:true    
    setRunJoyrideCC(guidetourval)
  }, []);

  const [openCategoryDrawer, toggleCategoryDrawer] = useState(false);

  const handleAddProduct = () => {
    navigate("/carboncompete/carboncatalogue/addproduct");
  };

  const handleImportProduct = () => {
    toggleUploadDialog(true);
  };

  const handleImportTemplateValue = () => {
    toggleUploadTemplateDialog(true);
  };

  const closeImportDialog = (data) => {
    // Fetch product agan
    fetchAllCategory();
    toggleUploadDialog(data);
  };

  async function fetchAllCategory() {
    try {
      setShowLoader(true);
      const userObj = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));
      const response = await getAllProductsByCategory(userObj);
      if (response.success) {
        setCategories(response.data);
        if (response.data.length > 0) {
          setSelectedCategory(response.data[0]);
        }
      } else {
        console.error("Error fetching products:", response.msg);
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.error("Error fetching products:", error);
    }
  }

  useEffect(() => {
    setSelectedCategory(() => categories.filter((category) => category.categoryId === categoryId)[0])
  }, [categoryId])

  useEffect(() => {
    fetchAllCategory();
  }, []);

  const handleNext = () => {
    if (start + 1 <= categories.length - itemsPerPage) {
      setStart(start + 1);
    }
  };

  const handlePrev = () => {
    if (start > 0) {
      setStart(start - 1);
    }
  };

  const handleClick = (index) => {
    setActiveButton(index);
    setSelectedCategory(categories[index]);
  };

  const displayedCategories = categories.slice(start, start + itemsPerPage);

  return (
    <div
      style={{
        marginBottom: "20px",
        backgroundColor: "#f6f8fe",
      }}
    >
    <Joyride 
        steps={joyrideSteps}
        run={runJoyrideCC}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        spotlightClicks="true"
        callback={handleJoyrideCallback}
        styles={{
          options: {
            zIndex: 10000,  
          },
  
            overlay: {
            top: 0,
            left: 0,
            width: '100%',
            height: '1000%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', 
            zIndex: 9999,
          },
          tooltip: {
            zIndex: 10001,  
          },
          spotlight: {
            borderRadius: 'inherit',
            padding: 0,  
          },
          buttonNext: {
            backgroundColor: '#009999', 
            color: 'white',
          },
          buttonBack: {
            backgroundColor: '#009999',  
            color: 'white',
          },
          buttonSkip: {
            backgroundColor: '#009999',  
            color: 'white',
          },
        }}
        />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid
        container
        my={3}
        mx={6}
        xs={12}
      >
        <Grid item xs={4} md={2} display='flex' alignItems='center' justifyContent='start'>
        <Button
  id="Menu"
  size="large"
  variant="contained"
  onClick={() => toggleCategoryDrawer((prev) => !prev)}
  sx={{
    backgroundColor: "#F2FCFD",
    color: "black",
    "&:hover": { backgroundColor: "#b6b6b6" },
    boxShadow: "0px 0px 20px -10px black",
    width: "38px",
    height: "36.8px",
    borderRadius: "3.51px",
    border: "0.88px solid #00C9D2",
  }}
>
<MenuIcon />
</Button>

        </Grid>
        <Grid item xs={4} md={4} alignItems='center' justifyContent='center' display='flex'>
        <Paper
      id="search"
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 400,
        boxShadow: "0px 0px 20px -10px black",
        backgroundColor: "#F2FCFD",
      }}
    >
      <InputBase
        sx={{
          ml: 1,
          flex: 1,
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 400,
          fontSize: '10.52px',
          lineHeight: '15.77px',
          color: '#000000',
          '::placeholder': {
            color: '#000000',
          }
        }}
        placeholder="Search Your Product Here..."
        inputProps={{ "aria-label": "search google maps" }}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <IconButton
        type="button"
        sx={{
          p: "10px",
          backgroundColor: "#00C9D2",
          borderRadius: "5px",
          "&:hover": { backgroundColor: "#000" },
        }}
        aria-label="search"
      >
        <SearchIcon htmlColor="white" />
      </IconButton>
    </Paper>
        </Grid>
        <Grid item xs={4} md={6} textAlign={"center"} display='flex' alignItems='center' justifyContent='start' >
          <Button
          id="addProducts"
            onClick={handleAddProduct}
            startIcon={<span style={{ fontSize: '16px', fontWeight: 'bold', marginRight:"12px" }}>+</span>}
            sx={{
              ...buttonStyles,
              backgroundColor:"#F2FCFD",
              width:'170px',
              height:"38px",
              "&:hover": { backgroundColor: "#000" },
              marginX: "5px",
              marginLeft:"80px",
              color: "#00C9D2",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 400,
              fontSize: "10.52px",
              lineHeight: "15.77px",
              border: "1px solid #00C9D2", 
            }}
          >
            Add Product
          </Button>
          <Button
          id="uploadProduct"
            onClick={handleImportProduct}
            sx={{
              ...buttonStyles,
              backgroundColor:"#F2FCFD",
              width:'170px',
              height:"38px",
              "&:hover": { backgroundColor: "#000" },
              marginX: "5px",
              color: "#00C9D2",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "15.77px",
              border: "1px solid #00C9D2",
            }}
            startIcon={<UploadOutlinedIcon />}
          >
            Upload Product
          </Button>
          {/* <Button
          id="uploadTemplateValue"
            onClick={handleImportTemplateValue}
            sx={{ ...buttonStyles, marginRight: '10px', '&:hover': { backgroundColor: '#000' }, }}
            startIcon={<UploadOutlinedIcon />}
          >

            Upload Template Value
          </Button> */}
        </Grid>
      </Grid>

      {
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            marginLeft: '3px'
          }}
        >
          <IconButton
            sx={{
              borderRadius: "50%",
              padding: "15px",
              "&:hover": {
                backgroundColor: "#000",
              },
              backgroundColor: start === 0 ? "#CECECE" : "#00C9D2",
              color: start === 0 ? "#149BA1" : "#fff",
            }}
            onClick={handlePrev}
            disabled={start === 0}
          >
            <Icon style={{ fontSize: "30px" }} icon="ep:back" />
          </IconButton>
          <div
            id="categories"
            style={{
              display: "flex",
              transition: "transform 0.5s",
              transform: `translateX(-${start * (110 + 16)}px)`,
            }}
          >
            {displayedCategories.map((category, index) => (
              <Slide key={index} direction="right" in={true} timeout={500}>
                <Button
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    width: "110px",
                    height: "100px",
                    borderRadius: "5px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    borderBottom: "1px solid",
                    borderTop: "1px solid",
                    borderColor:
                      activeButton === index ? "#00C9D2" : "primary.main",
                    padding: "8px",
                    textAlign: "center",
                    position: "relative",
                    marginRight: "16px",
                    backgroundColor:
                      activeButton === index ? "#00C9D2" : "transparent",
                    color: activeButton === index ? "#fff" : "inherit",
                    "&:hover": {
                      backgroundColor:
                        activeButton === index ? "#149BA1" : "transparent",
                      color: activeButton === index ? "#fff" : "inherit",
                    },
                  }}
                  onClick={() => handleClick(index)}
                >
                  <Icon
                    fontSize={"32px"}
                    icon={
                      categoryImage.find(
                        (cat) => cat.id === category.categoryId
                      )?.image || "carbon:category-new-each"
                    }
                  />
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontFamily: "poppins",
                      fontWeight: 400,
                    }}
                  >
                    {category.name}
                  </Typography>
                </Button>
              </Slide>
            ))}
          </div>
          <IconButton
            sx={{
              borderRadius: "50%",
              padding: "15px",
              "&:hover": {
                backgroundColor: "#000",
              },
              backgroundColor:
                start + itemsPerPage >= categories.length
                  ? "#CECECE"
                  : "#00C9D2",
              color:
                start + itemsPerPage >= categories.length ? "#CECECE" : "#fff",
            }}
            onClick={handleNext}
            disabled={start + itemsPerPage >= categories.length}
          >
            <Icon style={{ fontSize: "30px" }} icon="ep:right" />
          </IconButton>
        </div>
      }

      <CatalogCategoryDrawer
        openCategoryDrawer={openCategoryDrawer}
        toggleCategorydrawer={toggleCategoryDrawer}
        categories={displayedCategories}
        setCategoryID={setCategoryID}
        handleCategory={handleClick}
      />
      <div id="Products" style={{backgroundColor:"rgba(242, 252, 253, 0.6)", borderTop: '0.88px solid rgba(0, 201, 210, 1)',
        borderRight: '0px solid rgba(0, 201, 210, 1)',
        borderBottom: '0.88px solid rgba(0, 201, 210, 1)',
         borderLeft: '0px solid rgba(0, 201, 210, 1)', 
        // padding:"2px 2px 2px 2px"
        }}>
      <Products searchQuery={searchQuery} selectedCategory={selectedCategory} />
      </div>
      {uploadDialog && (
        <UploadProductDialog
          open={uploadDialog}
          setOpen={closeImportDialog}
          setShowLoader={setShowLoader}
        />
      )}
      {uploadTemplateDialog && <UploadTemplateValueDialog open={uploadTemplateDialog} setOpen={toggleUploadTemplateDialog} setShowLoader={setShowLoader} />}

    </div>
  );
};