import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent, Typography, Button } from "@mui/material";
import { monthsSet } from "../../../../provider/EsgDataFilteration";
import { MultipleSelectWithCheckBox } from "../../../../components/form/selectComponents";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ChartArea from "../../ExternalCharts/ChartArea";

const ScopeIntensityChart = ({
  data,
  defaultLocations,
  defaultYears,
  scope,
}) => {
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedYear, setSelectedYear] = useState([]);
  const [commonYears, setCommonYears] = useState([]);
  const [commonLocations, setCommonLocations] = useState([]);
  const [isFieldsSelected,setIsFieldsSelected]=useState(true)

  const isAllYearSelected =
    commonYears.length > 0 && selectedYear.length === commonYears.length;
  const isAllLocationSelected =
    commonLocations.length > 0 &&
    selectedLocation.length === commonLocations.length;

  const handleYearChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedYear(
        selectedYear.length === commonYears.length ? [] : commonYears
      );
      return;
    }
    setSelectedYear(value);
  };

  const handleLocationChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedLocation(
        selectedLocation.length === commonLocations.length
          ? []
          : commonLocations
      );
      return;
    }
    setSelectedLocation(value);
  };

  useEffect(() => {
    if (defaultLocations) {
      const filteredLocations = defaultLocations.filter(
        (loc) => loc !== "Others"
      );
      setCommonLocations(filteredLocations);
      setSelectedLocation(filteredLocations);
    }
    if (defaultYears) {
      const sortedYearsArray = Array.from(defaultYears)
        .map((year) => year)
        .sort((a, b) => {
          const [fyAStart, fyAEnd] = a.split("-").map(Number);
          const [fyBStart, fyBEnd] = b.split("-").map(Number);
          return fyBStart - fyAStart || fyBEnd - fyAEnd;
        });

      setCommonYears(sortedYearsArray);
      setSelectedYear([
        sortedYearsArray[0],
        sortedYearsArray[sortedYearsArray.length - 1],
      ]);
    }
  }, [defaultLocations, defaultYears]);

  useEffect(() => {
    setIsFieldsSelected(selectedLocation.length > 0 && selectedYear.length > 0);
  }, [selectedLocation, selectedYear]);
  const aggregateData = (location, years, scope) => {
    let aggregatedData = {};

    data.forEach((locationObj) => {
      if (location.includes(locationObj?.location)) {
        years.forEach((year) => {
          const monthlyData = locationObj?.years[year]?.monthlyData;
          if (monthlyData) {
            if (!aggregatedData[year]) {
              aggregatedData[year] = {
                Jan: 0,
                Feb: 0,
                Mar: 0,
                Apr: 0,
                May: 0,
                Jun: 0,
                Jul: 0,
                Aug: 0,
                Sep: 0,
                Oct: 0,
                Nov: 0,
                Dec: 0,
              };
            }
            Object.keys(monthlyData).forEach((month) => {
              const intensityData = monthlyData[month][scope]?.totalIntensity;
              if (intensityData) {
                aggregatedData[year][month] += intensityData || 0;
              }
            });
          }
        });
      }
    });

    return aggregatedData;
  };

  const aggregatedDataForScope = aggregateData(
    selectedLocation,
    selectedYear,
    scope
  );

  const monthDataArray = Array.from(monthsSet)
    .map((month) => {
      const monthData = {
        month: month,
        data: {},
      };

      selectedYear.forEach((year) => {
        const dataValue = aggregatedDataForScope[year]?.[month] || 0;
        if (dataValue !== 0 && dataValue !== null) {
          monthData.data[year] = dataValue.toFixed(2);
        }
      });

      if (Object.keys(monthData.data).length > 0) {
        return monthData;
      } else {
        return null;
      }
    })
    .filter((item) => item !== null);



  const generateCSV = () => {
    const headers = ["Year", "Month", "Emission Intensity (tCO2e/Cr)"];
    const rows = [];

    Object.keys(aggregatedDataForScope).forEach((year) => {
      monthsSet.forEach((month) => {
        if (aggregatedDataForScope[year][month]) {
          rows.push([year, month, aggregatedDataForScope[year][month]]);
        }
      });
    });

    const csvContent =
      "data:text/csv;charset=utf-8," +
      [headers.join(","), ...rows.map((row) => row.join(","))].join("\n");

    return encodeURI(csvContent);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.setAttribute("href", generateCSV());
    link.setAttribute("download", "emission_intensity_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Card elevation={5} style={{ marginBottom: "20px" }}>
      <CardContent>
      <Grid sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "10px",
          marginBottom: "20px",
        }} container spacing={2}>
          <Grid item xs={3}>
            <Typography
              variant="h6"
              fontWeight={700}
              style={{ textTransform: "capitalize" }}
            >
              {scope} Intensity Chart
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <MultipleSelectWithCheckBox
              label="Select Location"
              id="location-multi-select"
              value={selectedLocation}
              options={commonLocations}
              onChange={handleLocationChange}
              isAllSelected={isAllLocationSelected}
            />
          </Grid>
          <Grid item xs={3}>
            <MultipleSelectWithCheckBox
              label="Select Year"
              id="year-multi-select"
              value={selectedYear}
              options={commonYears}
              onChange={handleYearChange}
              isAllSelected={isAllYearSelected}
            />
          </Grid>
          
        </Grid>
        {isFieldsSelected && (
            <>
             <Grid item xs={3}>
             <Button
                sx={{
                 backgroundColor: "#149ba1",
                 "&:hover": {
                   backgroundColor: "#000000",
                 },
               }}
               startIcon={<FileDownloadIcon />}
               onClick={handleDownload}
               variant="contained"
               color="primary"
             >
               Download CSV
             </Button>
           </Grid>
           <ChartArea data={monthDataArray} selectedYear={selectedYear}/>
           </>
          )}
      </CardContent>
    </Card>
  );
};

export default ScopeIntensityChart;
