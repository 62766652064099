import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Tabs,
  Tab,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { SmartDataModal } from "./SmartDataModal";
import {
  extractTextInBrackets,
  removeBracketAndText,
} from "../../utils/helperFunctions";
import { postSmartDataExtraction } from "../../services/external";
import SwipeableViews from "react-swipeable-views";
import ReactECharts from "echarts-for-react";
const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    width: "100%",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    padding: "10px 0",
    backgroundColor: "#f6f8fe",
    borderTop: "1px solid #ccc",
  },
}));

const ResetButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontSize: 16,
  fontWeight: "bold",
  width: "20%",
  padding: "12px 24px",
  borderRadius: 5,
  border: "1px solid #FA3F3F",
  backgroundColor: "#fff",
  color: "#FA3F3F",
  "& svg": {
    width: 24,
    height: 24,
    marginRight: theme.spacing(1),
  },
  "&:hover": {
    backgroundColor: "#fff",
    boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)",
  },
}));

const SaveButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontSize: 16,
  fontWeight: "bold",
  width: "20%",
  padding: "12px 24px",
  borderRadius: 5,
  border: "1px solid #149BA1",
  backgroundColor: "#E2E2E2",
  color: "#030229",
  "&:hover": {
    backgroundColor: "#E2E2E2",
    boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)",
  },
}));

const DataExtraction = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontSize: 16,
  fontWeight: "bold",
  width: "30%",
  padding: "12px 24px",
  borderRadius: 5,
  border: "1px solid #149BA1",
  backgroundColor: "#E2E2E2",
  color: "#030229",
  "&:hover": {
    backgroundColor: "#E2E2E2",
    boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)",
  },
}));

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
export default function CanvasTemplateModal({
  tempProp,
  openDialog,
  setOpenDialog,
  tempProduct,

  setTempProduct,
  data,
  currentTab,
  setTab,
}) {
  const [formData, setFormData] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [fileUploadOpen, setFileUploadOpen] = useState(false);
  const [selectedFormData, setSelectedFormData] = useState({});
  const [updatedNodes, setUpdatedNodes] = useState(null);

  const classes = useStyles();

  const handleMouseEnter = (index) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };

  const handleChange = (event, newValue) => {
    if (newValue === 0 || (newValue === 1 && localAnalytics)) {
      setTab(newValue);
    }
  };

  const payload = {
    productName: data.productName,
    nodes: [
      {
        data: {
          ...tempProduct,
          templateSchemaProp: tempProduct?.templateSchemaProp.filter(
            (item) => item.category === "factor"
          ),
        },
      },
    ],
  };

  const [localAnalytics, setLocalAnalytics] = useState(null);

  useEffect(() => {
    setLocalAnalytics(tempProduct?.Analytics || null);
  }, [tempProduct]);
  useEffect(() => {
    if (tempProp) {
      const filteredData = tempProp.filter(
        (item) => item.category === "factor" || item.category === "attribute"
      );
      setFormData(filteredData);
    }
  }, [tempProp]);

  const handleInputChange = (index, event) => {
    const newFormData = [...formData];
    newFormData[index].ctrlData[0].name = event.target.value;
    setFormData(newFormData);

    // Store selected form data
    setSelectedFormData({
      ...selectedFormData,
      [index]: event.target.value,
    });

    console.log("Selected Form Data:", selectedFormData);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleFileUploadOpen = () => {
    setFileUploadOpen(true);
  };

  const handleFileUploadClose = () => {
    setFileUploadOpen(false);
  };

  const handleFileUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await postSmartDataExtraction(formData);
      console.log("File upload response:", response);

      // Handle the response as needed
    } catch (error) {
      console.error("Error uploading file:", error);
      // Handle error
    }
  };

  const handleChangeIndex = (index) => {
    if (index === 0 || (index === 1 && localAnalytics)) {
      setTab(index);
    }
  };

  const [activityChartOption, setActivityChartOption] = useState(null);
  const [scopeChartOption, setScopeChartOption] = useState(null);

  useEffect(() => {
    if (localAnalytics) {
      // Prepare data for activity breakdown chart
      const activityData = localAnalytics?.activityBreakdown.map((item) => ({
        name: item.activityName,
        value: parseFloat(item.Emissions),
      }));

      const activityTotal = activityData.reduce(
        (sum, item) => sum + item.value,
        0
      );

      setActivityChartOption({
        legend: {
          orient: "vertical",
          left: "left",
          top: "middle",
          itemWidth: 10,
          itemHeight: 10,
          icon: "circle",
          formatter: function (name) {
            const item = activityData.find((data) => data.name === name);
            const percentage = item
              ? ((item.value / activityTotal) * 100).toFixed(2)
              : "0";
            return `${
              name.length > 50 ? name.slice(0, 40) + "..." : name
            } : ${percentage}%`;
          },
          textStyle: {
            fontFamily: "Poppins",
            fontSize: 12,
            color: "#333",
          },
        },
        tooltip: {
          show: false,
        },
        series: [
          {
            name: "Activity Breakdown",
            type: "pie",
            data: activityData,
            radius: ["30%", "60%"],
            center: ["80%", "50%"],
            avoidLabelOverlap: false,
            padAngle: 5,
            itemStyle: {
              borderRadius: 10,
              borderWidth: 3,
              borderColor: "#FFFFFF",
            },
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            emphasis: {
              scale: false,
              itemStyle: {
                shadowBlur: 0,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0)",
              },
            },
            silent: true,
          },
        ],
      });

      // Prepare data for scope breakdown chart
      const scopeData = localAnalytics?.ScopeBreakdown.map((item) => ({
        name: item.scope,
        value: parseFloat(item.summedEmissions),
      }));

      const scopeTotal = scopeData.reduce((sum, item) => sum + item.value, 0);

      setScopeChartOption({
        legend: {
          orient: "vertical",
          left: "left",
          top: "middle",
          itemWidth: 10,
          itemHeight: 10,
          icon: "circle",
          formatter: function (name) {
            const item = scopeData.find((data) => data.name === name);
            const percentage = item
              ? ((item.value / scopeTotal) * 100).toFixed(2)
              : "0";
            return `${name}: ${percentage}%`;
          },
          textStyle: {
            fontFamily: "Poppins",
            fontSize: 12,
            color: "#333",
          },
        },
        series: [
          {
            name: "Scope Breakdown",
            type: "pie",
            data: scopeData,
            radius: ["30%", "50%"],
            center: ["60%", "50%"],
            avoidLabelOverlap: false,
            padAngle: 5,
            itemStyle: {
              borderRadius: 10,
              borderWidth: 3,
              borderColor: "#FFFFFF",
            },
            labelLine: {
              show: false,
              length: 17,
            },
          },
        ],
      });
    }
  }, [localAnalytics]);

  const updateFormDataWithNodes = (currentFormData, nodes) => {
    if (!nodes || !currentFormData) return currentFormData;
    console.log("Updating form data with nodes:", nodes);
    return currentFormData.map((formItem) => {
      const matchingNode = nodes.nodes[0].data.templateSchemaProp.find(
        (nodeItem) => nodeItem.ctrlName === formItem.ctrlName
      );
      if (matchingNode) {
        console.log("Matching node found:", matchingNode);
        // Check if the ctrlData[0].name in updatedNodes is empty or just whitespace
        const updatedName = matchingNode.ctrlData[0].name.trim();
        if (updatedName === "" || updatedName === '""') {
          console.log("Keeping existing name for:", formItem.ctrlName);
          // Keep the existing name from formData
          return {
            ...matchingNode,
            ctrlData: [
              { ...matchingNode.ctrlData[0], name: formItem.ctrlData[0].name },
            ],
          };
        } else {
          console.log("Using new name for:", formItem.ctrlName);
          // Use the new name from updatedNodes
          return { ...matchingNode };
        }
      }
      return formItem;
    });
  };
  useEffect(() => {
    if (updatedNodes) {
      setFormData((prevFormData) =>
        updateFormDataWithNodes(prevFormData, updatedNodes)
      );
    }
  }, [updatedNodes]);

  const handleActivitySave = () => {
    setTempProduct({
      ...tempProduct,
      templateSchemaProp: formData,
    });
  };


  return (
    <Dialog
      open={openDialog}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          width: "800px",
          maxHeight: "80vh",
          margin: "auto",
          borderRadius: 2,
          boxShadow: 24,
          overflowY: "auto",
        },
      }}
    >
      <Box
        component="img"
        src="/assets/images/close_small.png"
        alt="close"
        width={40}
        height={40}
        sx={{
          position: "absolute",
          top: 15,
          right: 10,
          cursor: "pointer",
          zIndex: 1,
        }}
        onClick={handleClose}
      />
      <Box sx={{ borderBottom: 1, borderColor: "divider", paddingTop: 4 }}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          TabIndicatorProps={{ style: { background: "grey" } }}
        >
          <Tab label="Activity Inputs" {...a11yProps(0)} />
          {localAnalytics && (
            <Tab
              label="Analytics"
              {...a11yProps(1)}
              // disabled={!formik.isValid}
            />
          )}
        </Tabs>
      </Box>

      <DialogContent>
        <SwipeableViews index={currentTab} onChangeIndex={handleChangeIndex}>
          <Box sx={{ bgcolor: "background.paper", paddingX: 2 }}>
            <Grid container spacing={4} alignItems="stretch">
              {formData &&
                formData.map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    spacing={3}
                    key={index}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Box sx={{ flexGrow: 1, mb: 1 }}>
                      <Typography
                        sx={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "inherit",
                        }}
                      >
                        {removeBracketAndText(item.ctrlDisplayName)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{ flexGrow: 1 }}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <TextField
                        size="small"
                        value={item.ctrlData[0].name}
                        onChange={(event) => handleInputChange(index, event)}
                        fullWidth
                        variant="outlined"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor:
                                hoverIndex === index ? "#00CCC0" : "inherit",
                            },
                            "&:hover fieldset": {
                              borderColor: "#00CCC0",
                            },
                          },
                        }}
                        InputProps={{
                          sx: {
                            height: "40px",
                            fontSize: "14px",
                            padding: "8px",
                            borderRadius: "8px",
                          },
                        }}
                      />
                    </Box>
                    <Box
                      sx={{ flexGrow: 1, mt: 1 }}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <Typography
                        sx={{ color: "#0BDB7B", textDecoration: "underline" }}
                      >
                        {extractTextInBrackets(item.ctrlDisplayName)}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </Box>
          {localAnalytics && (
            <Box sx={{ bgcolor: "background.paper", p: 2 }}>
              {localAnalytics ? (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      style={{ fontSize: "20px", fontWeight: 700 }}
                    >
                      Stage Emissions:{" "}
                      {localAnalytics?.stageEmissions.toFixed(2)} kgCo2e/kg
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Scope Breakdown</Typography>
                    {scopeChartOption && (
                      <ReactECharts
                        option={scopeChartOption}
                        style={{ height: "400px" }}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      Activity Breakdown
                    </Typography>
                    {activityChartOption && (
                      <ReactECharts
                        option={activityChartOption}
                        style={{ height: "400px" }}
                      />
                    )}
                  </Grid>
                </Grid>
              ) : (
                <Typography>No analytics data available.</Typography>
              )}
            </Box>
          )}
        </SwipeableViews>
      </DialogContent>
      <DialogActions sx={{ bgcolor: "background.paper" }}>
        <div className={classes.buttonGroup}>
          <DataExtraction
            onClick={handleFileUploadOpen}
            size="large"
            variant="contained"
          >
            <Typography fontFamily="Raleway" fontWeight={600}>
              Smart Data Extraction
            </Typography>
          </DataExtraction>
          <SaveButton
            type="submit"
            size="large"
            variant="contained"
            onClick={handleActivitySave}
          >
            <Typography fontFamily="Raleway" fontWeight={600}>
              Save
            </Typography>
          </SaveButton>
        </div>
      </DialogActions>
      <SmartDataModal
        open={fileUploadOpen}
        payload={payload}
        onClose={handleFileUploadClose}
        onFileUpload={handleFileUpload}
        setUpdatedNodes={setUpdatedNodes}
      />
    </Dialog>
  );
}
