import axios from 'axios';
import { APP_KEYS } from '../utils/constants/AppKeyConstants';
import { refreshAccessToken } from './auth';

/**
 * @description Authentication Interceptor. Attaches the token to the requests.
 */
axios.interceptors.request.use(async (request) => {
  const token = localStorage.getItem(APP_KEYS.ACCESS_TOKEN_KEY);

  try {
    if (!request.url.split("/").includes("session")){      
    
    let activities = JSON.parse(sessionStorage.getItem('activity')) || [];
     
    activities.push(request.url);
    
    sessionStorage.setItem('activity', JSON.stringify(activities));
    }
  } catch (error) {
    console.error('Error updating activity:', error);
  }
  if (token) {
    request.headers['Authorization'] = `${APP_KEYS.AUTH_BEARER_KEY} ${token}`;
  }
  return request;
});

/**
 * @description Authentication response interceptor.
 * It captches specific response codes which is known for token expirations
 * It'll try to fetch the new access token. and makes the corresponding request
 */
axios.interceptors.response.use(response => response, async (error) => {
  const actualRequest = error.config;

  // Check for jwt token expiration
  if (error?.response?.data?.statusCode == '205103') {
    const { accessToken } = await refreshAccessToken();

    if (accessToken) {
      const userObj = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));
      userObj.accessToken = accessToken;

      localStorage.setItem(APP_KEYS.USER_INFO, JSON.stringify(userObj));
      localStorage.setItem(APP_KEYS.ACCESS_TOKEN_KEY, accessToken);
      
      // Retry the original request
      actualRequest._retry = true;
      actualRequest.headers['Authorization'] = `${APP_KEYS.AUTH_BEARER_KEY} ${accessToken}`;
      return axios(actualRequest);
    } else {
      // If case there is no accesss token then refresh the token
      localStorage.clear();
      window.location.reload();
    }
  }
});

export default axios;
