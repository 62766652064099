import React from "react";
import ReactECharts from "echarts-for-react";
import scopeImage from "../../Assets2/pie_center.png";
import { Button } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export default function ChartArea1({ props, year, stageId }) {
  const filteredProps = {};

  Object.keys(props).forEach((location) =>
    Object.keys(props[location]).forEach((id) => {
      if (id === stageId) {
        filteredProps[location] = props[location][id];
      }
    })
  );

  let filteredDataById = {};

  if (stageId === "15") {
    Object.keys(filteredProps).forEach((loc) => {
      if (!filteredDataById[loc]) {
        filteredDataById[loc] = {};
      }
      Object.keys(filteredProps[loc]).forEach((year) => {
        if (!filteredDataById[loc][year]) {
          filteredDataById[loc][year] = {};
        }
        const { DIESEL, EB_POWER, RE_POWER } = filteredProps[loc][year];
        filteredDataById[loc][year] = { DIESEL, EB_POWER, RE_POWER };
      });
    });
  } else if (stageId === "12") {
    Object.keys(filteredProps).forEach((loc) => {
      if (!filteredDataById[loc]) {
        filteredDataById[loc] = {};
      }
      Object.keys(filteredProps[loc]).forEach((year) => {
        if (!filteredDataById[loc][year]) {
          filteredDataById[loc][year] = {};
        }
        const { FRESH_WATER, TREATED_WATER } = filteredProps[loc][year];
        filteredDataById[loc][year] = { FRESH_WATER, TREATED_WATER };
      });
    });
  } else {
    filteredDataById = filteredProps;
  }

  let totloc = {};

  Object.keys(filteredDataById).forEach((loc) => {
    totloc[loc] = 0;
    year.forEach((y) => {
      if (filteredDataById[loc][y]) {
        if (typeof filteredDataById[loc][y] === "object") {
          Object.values(filteredDataById[loc][y]).forEach((value) => {
            totloc[loc] += value;
          });
        } else {
          totloc[loc] += filteredDataById[loc][y];
        }
      }
    });
  });

  let newData = {};

  let totalValue = 0;
  Object.keys(totloc).forEach((location) => {
    totalValue += totloc[location];
  });

  Object.keys(totloc).forEach((location) => {
    const percentage = (totloc[location] / totalValue) * 100;
    newData[location] = {
      percentage: percentage.toFixed(2),
      data: totloc[location].toFixed(2),
    };
  });

  const colors = [
    "#FD5308",
    "#FB9902",
    "#36454F",
    "#66B132",
    "#0291CD",
    "#0247FE",
    "#3E01A4",
    "#8601B0",
    "#A7194B",
  ];
  const pieChartDataArray = Object.entries(newData)
    .map(([location, { percentage, data }]) => ({
      name: location,
      percentage: parseFloat(percentage),
      data: parseFloat(data),
    }))
    .filter(({ data }) => data !== null && data !== 0);

  const generateChartOption = () => {
    const filteredPieChartDataArray = pieChartDataArray.filter(
      ({ data }) => data !== null && data !== 0
    );
    const topThreeData = pieChartDataArray
      .sort((a, b) => b.data - a.data)
      .slice(0, 3);

    const topThreeLegendData = topThreeData.map(({ name }) => name);

    return {
      tooltip: {
        trigger: "item",
        formatter: (params) => {
          const { name, data } = params;
          if (stageId === "15") {
            return `${name}<br/>Value: ${data?.data} GJ`;
          } else if (stageId === "12") {
            return `${name}<br/>Value: ${data?.data} kl`;
          } else if (stageId === "13") {
            return `${name}<br/>Value: ${data?.data} tons`;
          } else {
            return `${name}<br/>Value: ${data?.data} kms`;
          }
        },
      },
      toolbox: {
        show: true,
        orient: "vertical",
        left: "right",
        top: "center",
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ["line", "bar", "stack"] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      legend: {
        selectedMode: true,
        data: topThreeLegendData,
        left: "center",
        textStyle: {
          color: "#000000",
        },
      },
      graphic: [
        {
          type: "image",
          left: "center",
          top: "center",
          z: 10,
          style: {
            image: scopeImage,
            width: 128,
            height: 128,
          },
        },
      ],
      series: [
        {
          name: "Location:",
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          padAngle: 5,
          itemStyle: {
            borderRadius: 10,
            borderWidth: 3,
            borderColor: "white",
          },
          label: {
            show: true,
            formatter: "{c}%",
            position: "outside",
            fontFamily: "Poppins",
            fontWeight: "bold",
            fontSize: 14,
            color: "#000000",
          },
          labelLine: {
            show: false,
            length: 17,
          },
          color: colors,
          data: filteredPieChartDataArray.map(
            ({ name, percentage, data }, index) => ({
              value: percentage,
              data,
              name,
            })
          ),
        },
      ],
    };
  };

  const generateCSVData = () => {
    const headers = ["Location", "Percentage", "Value"];
    const rows = pieChartDataArray.map(({ name, percentage, data }) => [
      name,
      `${percentage}%`,
      `${data} ${stageId === "15" ? "GJ" : stageId === "12" ? "kl" : stageId === "13" ? "tons" : "kms"}`,
    ]);
    return [headers, ...rows];
  };

  const handleDownload = () => {
    const csvContent = generateCSVData()
      .map(row => row.join(','))
      .join('\n');
    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "chart_data.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div>
      <Button
        sx={{
          backgroundColor: "#149ba1",
          "&:hover": {
            backgroundColor: "#000000",
          },
        }}
        variant="contained"
        color="primary"
        startIcon={<FileDownloadIcon />}
        onClick={handleDownload}
        style={{ marginTop: "10px" }}
      >
      </Button>
      <ReactECharts option={generateChartOption()} style={{ height: "400px" }} />
    </div>
  );
}
