import React from 'react';
import ReactECharts from 'echarts-for-react';
import { Box, Grid, Card, CardHeader } from '@mui/material';
import scopeImage from "./../../Assets2/pie_center.png";

const generateOptions = (data, scope, colors, colors2) => {
  if (!data) return {};

  const sortedEntries = Object.entries(data).sort((a, b) => b[1].total_emissions - a[1].total_emissions);

  const topEntries = sortedEntries.slice(0, 7);

  const total = topEntries.reduce((acc, [, value]) => acc + value.total_emissions, 0);
  const chunkSize = Math.ceil(topEntries.length / 4);

  const legendChunks = [
    topEntries.slice(0, chunkSize),
    topEntries.slice(chunkSize, 2 * chunkSize),
    topEntries.slice(2 * chunkSize, 3 * chunkSize),
    topEntries.slice(3 * chunkSize, topEntries.length),
  ];

  return {
    title: {
      left: 'center',
      top: 'center',
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        return `${params.seriesName}<br />${params.name}: ${params.data.value} (tCO2e)`;
      },
    },
    legend: legendChunks.map((chunk, index) => ({
      orient: 'vertical',
      left: index % 2 === 0 ? 0 : 'auto',
      right: index % 2 !== 0 ? 0 : 'auto',
      top: index < 2 ? 0 : 'auto',
      bottom: index >= 2 ? 0 : 'auto',
      type: 'scroll',
      data: chunk.map(([name]) => name),
      formatter: function (name) {
        const value = data[name].total_emissions;
        const percent = ((value / total) * 100).toFixed(2);
        return `${name}\n${percent}%`;
      },
    })),
    graphic: [
      {
        type: 'image',
        left: 'center',
        top: 'center',
        z: 10,
        style: {
          image: scopeImage,
          width: 128,
          height: 128,
        },
      },
    ],
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderWidth: 10,
          borderColor: 'white',
        },
        label: {
          show: false,
          position: 'center',
        },
        labelLine: {
          show: false,
        },
        data: topEntries.map(([name, value], index) => ({
          value: value.total_emissions.toFixed(2),
          name,
          itemStyle: {
            color: scope === 'scope2' ? colors2[index % colors2.length] : colors[index % colors.length],
          },
        })),
      },
    ],
  };
};

const scopeLabels = {
  scope1: 'Scope 1',
  scope2: 'Scope 2',
  scope3: 'Scope 3'
};

export const ScopeBreakdownPie = ({ ScopeBreakdown }) => {
  const colors = [
    "#00B0FA", "#230455", "#FD5308", "#FB9902", "#0033BB", "#5100D9",
    "#9700C7", "#E1004F", "#BD1100", "#EE4900", "#EF9100", "#02FEE0"
  ];
  
  const colors2 = [
    "#8601B0", "#A7194B", "#FE2712", "#37877D", "#A87E00", "#EF9100",
    "#02FEE0", "#A772FF", "#69DBFF", "#00B0FA", "#230455", "#FD5308"
  ];

  if (!ScopeBreakdown) return <div>No data available</div>; // Handle missing data
  
  return (
    <Box mt={3} sx={{ flexGrow: 1, fontFamily: 'Poppins, sans-serif' }}>
      <Grid container spacing={3} justifyContent="center">
        {['scope1', 'scope2', 'scope3'].map((scope, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card sx={{p:2}} elevation={3}>
              <CardHeader
                title={`GHG Emission (${scopeLabels[scope]})`}
                sx={{ textTransform: "uppercase" }}
                titleTypographyProps={{
                  fontWeight: 600,
                  fontSize: 20,
                  textAlign: "center",
                }}
              />
              
              <ReactECharts 
                style={{ height: '450px' }}
                option={generateOptions(ScopeBreakdown[scope]?.category_breakdown_emissions || {}, scope, colors, colors2)} 
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
