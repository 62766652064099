import { APP_KEYS } from '../utils/constants/AppKeyConstants';
import axios from './axiosInstance';

const API_URL = process.env.REACT_APP_API_URL;

const userInfo = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO) || '{}');

export const getTotalCarbonData = async ({productId, page, limit}) => {
    try {
        const response = await axios({
            url: `${API_URL}/api/v1/total-emission`,
            method: 'GET',
            params: {
                productId,
                compId: userInfo?.userId,
                page: page,
                limit: limit                
            }
        });
        return response.data;
    } catch (err) {
        console.log(err);
        return err;
    }
};

export const getProductBatchEmissionData = async (productId) => {
    try {
        const response = await axios({
            url: `${API_URL}/api/v1/product-batch-emission`,
            method: 'GET',
            params: {
                productId,
                compId: userInfo?.userId
                // compId: 1
            }
        });
        return response.data;
    } catch (err) {
        console.log(err);
        return err;
    }
};

export const getProductBatchEmissionData2 = async (productId) => {
    try {
        const response = await axios({
            url: `${API_URL}/api/v1/product-batch-emission`,
            method: 'GET',
            params: {
                productId,
            }
        });
        return response.data;
    } catch (err) {
        console.log(err);
        return err;
    }
};

export const getProductBatchEmissionDataById = async (productId) => {
    try {
        const response = await axios({
            url: `${API_URL}/api/v1/product-batch-emission`,
            method: 'GET',
            params: {
                productId : productId,
                // compId: userInfo?.userId
                compId: 1
            }
        });
        return response.data;
    } catch (err) {
        console.log(err);
        return err;
    }
};

export const getSupplierWiseEmissions = async (email) => {
    try {
        const response = await axios({
            url: `${API_URL}/api/v1/stage-by-supplier?${email}`,
            method: 'GET',
            params: {
                email: email
            }
        });
        return response.data;
    } catch (err) {
        console.log(err);
        return err;
    }
};