import axios from './axiosInstance';

import { APP_KEYS } from '../utils/constants/AppKeyConstants';

const API_URL = process.env.REACT_APP_API_URL;

export const userLogin = async ({ email, password }) => {
  try {
    const { data: responseData = {} } = await axios({
      url: `${API_URL}/api/v1/signin`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        userEmail: email,
        userPwd: password,
      },
    });

    // return the user detail
    if (responseData?.data?.length > 0) {
      localStorage.setItem(APP_KEYS.ACCESS_TOKEN_KEY, responseData.accessToken);
      localStorage.setItem(APP_KEYS.REFRESH_TOKEN_KEY, responseData.refreshToken);
      localStorage.setItem(
        APP_KEYS.USER_INFO,
        JSON.stringify(responseData?.data[0])
      );
      return responseData?.data[0];
    }

    // Throw error when there is no data in the response body
    throw new Error(responseData.msg);
  } catch (err) {
    console.error(err);
    // Throw unknown errors as well
    throw err;
  }
};

export const userRegister = async (userObject) => {
  try {
    const { data: responseData = {} } = await axios({
      url: `${API_URL}/api/v1/signup`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        userName: userObject.name,
        userEmail: userObject.email,
        userPwd: userObject.password,
        userMobile: userObject.mobile,
        isActive: "true",
      },
    }).catch(err => {
      throw err?.response?.data || err;
    });

    if (responseData?.data?.length) {
      return responseData.data[0];
    }

    // Throw error when there is no data in the response body
    throw new Error(responseData.msg);
  } catch (err) {
    console.error(err);
    // Throw unknown errors as well
    throw err;
  }
};

// API for sending email for resetting the password
export const forgotPassword = async ({
  userEmail,
}) => {
  try {
    const resp = await axios({
      url: `${API_URL}/api/v1/forgot-pwd`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      data:
      {
        userEmail: userEmail
      }
    });
    return resp.data;
  } catch (err) {
    console.log(err);
    return { message: 'error' };
  }
};

// Change password POST API call    //** Not using this as of now
export const resetPassword = async ({
  newPassword,
  token
}) => {
  try {
    console.log(token)
    const resp = await axios({
      url: `${API_URL}/api/v1/reset-pwd`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      data:
      {
        newPassword: newPassword,
        token: token
      }
    });
    console.log(token)
    return resp.data;
  } catch (err) {
    console.log(err);
    return { message: 'error' };
  }
};

// Change password using old password
export const changePassword = async ({
  userEmail,
  newPassword,
  oldPassword
}) => {
  try {
    const resp = await axios({
      url: `${API_URL}/api/v1/reset-password`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      data:
      {
        newPassword: newPassword,
        email: userEmail,
        oldPassword: oldPassword
      }
    });
    return resp.data;
  } catch (err) {
    console.log(err);
    return { message: 'error' };
  }
};

/**
 * @description Get new access token by refresh token
 * 
 * @returns {Object} access token object
 */
export const refreshAccessToken = async () => {
  try {
    const refreshToken = localStorage.getItem(APP_KEYS.REFRESH_TOKEN_KEY);
    if (!refreshToken) {
      throw new Error('Refresh token not found');
    }
    const resp = await axios({
      url: `${API_URL}/api/v1/token/refresh`,
      method: 'POST',
      data: {
        refresh_token: refreshToken
      }
    });
    return resp?.data?.data || {};
  } catch (err) {
    console.error('Unable to fetch access token');
    return {
      message: 'Error while refreshing the token'
    }
  } 
};