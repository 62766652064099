import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Stack,
  Paper,
  Button,
  Container,
  Typography,
  IconButton,
  TableContainer,
  Tooltip,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
// components
import { getAllCorporateSuppliers } from '../../services/corporatesuppliers';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// mock
import EditUserDialog from './Dialog/EditUser';
import DeleteUserDialog from './Dialog/DeleteUser';
import AddSupplierDialog from './Dialog/AddUser';
import Premium from '../Clients/Dialog/PremiumUser';
import { APP_KEYS } from '../../utils/constants/AppKeyConstants';
import Joyride from 'react-joyride';
/* eslint-disable no-case-declarations */
/* eslint-disable prefer-const */
// ----------------------------------------------------------------------

const joyrideSteps=[{
  target: `body`,
  title: `Welcome to the Supplier's Hub!`,
  content: `This page provides detailed information about your existing suppliers. You can edit their details, add new suppliers to the list, and update their subscriptions effortlessly.`,
  locale: {
    next: `Start`, skip: `Skip`
  },
  disableBeacon: true,
  placement: `center`
},{
  target:"#addNewSupplier",
  content:"Click here to add a new suppler",
  spotlightPadding:0,
  disableBeacon: true,
},{
  target: `#datagrid`,
  content:"Browse your existing suppliers here. Easily edit, remove, or update their subscriptions.",
  spotlightPadding:0,
  disableBeacon: true,
}
]

function CustomToolbar() {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    </div>
  );
}

export const CorporateSuppliers = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [open, setOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openSubDialog, setOpenSubDialog] = useState(false);
  const [runJoyrideSuppliers, setRunJoyrideSuppliers]= useState(false)

  const handleJoyrideCallback = (data) =>{
    if ((data.action==="close") || (data.action==="reset")){
      setRunJoyrideSuppliers(false)
      localStorage.setItem("supplierpagetour",'false')
    } 
  }

  useEffect(() => {

    const guidetourval= localStorage.getItem("supplierpagetour")==='false' ? false:true    
    setRunJoyrideSuppliers(guidetourval)
  }, []);

  const TABS = ['All Suppliers'];

  const handlePopulateData = async () => {
    let response;
    let tempRows = [];
    setShowLoader(true);

    try {
      switch (selectedTab) {
        case 0:
          setColumns(ALL_USERS_COL);
          const userInfo = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO) || '{}');
          response = await getAllCorporateSuppliers(userInfo?.userId);

          // Check if response has a 'data' property that is an array before using map
          if (response.data && Array.isArray(response.data)) {
            tempRows = response.data.map((r) => ({
              id: r.userId,
              name: r.userName,
              email: r.userEmail,
              mobile: r.userMobile,
              subsName: r.subs && r.subs.length > 0 ? r.subs[0].subsName : 'N/A'
            }));
          } else {
            console.error('Data property is not an array in the response:', response);
          }
          setRows(tempRows);
          setShowLoader(false);
          break;
        default:
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setShowLoader(false);
    }
  };

  const ALL_USERS_COL = [
    { field: 'id', headerName: 'ID', width: '80' },
    { field: 'name', headerName: 'Name', width: '219' },
    { field: 'email', headerName: 'Email', width: '250' },
    { field: 'mobile', headerName: 'Mobile', width: '190' },
    { field: 'subsName', headerName: 'Subscription', width: '170' },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      selectable: false,
      filter: false,
      width: '300',
      renderCell: (params) => {
        const onClick = (e, key) => {
          e.stopPropagation();
          if (key === 'edit') {
            setSelectedRow(params.row);
            setOpenEditDialog(true);
          }
          if (key === 'delete') {
            setSelectedRow(params.row);
            setOpenDeleteDialog(true);
          }
          if (key === 'subscription') {
            setSelectedRow(params.row);
            setOpenSubDialog(true);
          }
        };
        return (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
              }}
            >
              <div>
                <IconButton onClick={(e) => onClick(e, 'delete')}>
                  <Iconify icon="ic:round-delete" />
                </IconButton>
              </div>
              <div>
                <IconButton onClick={(e) => onClick(e, 'edit')}>
                  <Iconify icon="material-symbols:edit" />
                </IconButton>
              </div>
              <div>
                <Button onClick={(e) => onClick(e, 'subscription')} sx={{color:"#00C9D2"}}>Change Subscription</Button>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    handlePopulateData();
  }, [selectedTab]);

  const handleSubscriptionChange = () => {
    console.log('Changing subscription to: Premium');
  };

  return (
    <>
      <Helmet>
        <title> Suppliers </title>
      </Helmet>
      <Joyride 
        steps={joyrideSteps}
        run={runJoyrideSuppliers}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        spotlightClicks="true"
        callback={handleJoyrideCallback}
        styles={{
          options: {
            zIndex: 10000,  
          },
            overlay: {
            top: 0,
            left: 0,
            width: '100%',
            height: '1000%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', 
            zIndex: 9999,
          },
          tooltip: {
            zIndex: 10001,  
          },
          spotlight: {
            borderRadius: 'inherit',
            padding: 0,  
          },
          buttonNext: {
            backgroundColor: '#009999', 
            color: 'white',
          },
          buttonBack: {
            backgroundColor: '#009999',  
            color: 'white',
          },
          buttonSkip: {
            backgroundColor: '#009999',  
            color: 'white',
          },
        }}
        />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container maxWidth="xl">
        <Stack m={1} direction="row" alignItems="center" justifyContent="space-between">

          <Grid sx={{ marginLeft: 'auto' }}>
            <Box>
              <Button id="addNewSupplier" sx={{ background: 'rgba(242, 252, 253, 1)' }} onClick={() => setOpen(true)} variant="contained" color="primary">
                <Typography fontFamily="Raleway" fontWeight={600} color={"rgba(0, 201, 210, 1)"}>
                  + Add New Supplier
                </Typography>
              </Button>
            </Box>
          </Grid>
        </Stack>

        <Card sx={{ mt: 1 }}>
          <Scrollbar>
            <TableContainer id="datagrid" sx={{ minWidth: 800 }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[54]}
                // checkboxSelection
                disableSelectionOnClick
                sx={{ minHeight: 600, fontFamily: 'Poppins', fontSize: '15px', fontWeight: 'bolder' }}
                components={{ Toolbar: CustomToolbar }}
                density="comfortable"
              />
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
      {open ? <AddSupplierDialog open={open} setOpen={setOpen} handleFetchUser={handlePopulateData} /> : null}
      {openEditDialog ? (
        <EditUserDialog
          open={openEditDialog}
          setOpen={setOpenEditDialog}
          userDetails={selectedRow}
          handleFetchUser={handlePopulateData}
        />
      ) : null}
      {openDeleteDialog ? (
        <DeleteUserDialog
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          userDetails={selectedRow}
          handleFetchUser={handlePopulateData}
        />
      ) : null}
      {openSubDialog ? (
        <Premium
          open={openSubDialog}
          setOpen={setOpenSubDialog}
          userDetails={selectedRow}
          handleFetchUser={handlePopulateData}
        />
      ) : null}
    </>
  );
}