import axios from './axiosInstance';
const API_URL = process.env.REACT_APP_API_URL;


export const postStartSession = async (sessionId, startTime, userId, compId, roleName) => {
    try {
        // return PRODUCT[productId];

        await axios({
            url: `${API_URL}/api/v1/session/start`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                sessionId,
                startTime,
                userId,
                compId,
                roleName
            }
        });
        return true;
    } catch (err) {
        console.error(err);
        return false
    }
};

export const pingSession = async (sessionId, lastActive, activity=[]) => {
    try {
        // return PRODUCT[productId];

        await axios({
            url: `${API_URL}/api/v1/session/ping`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                sessionId,
                lastActive,
                activity
            }
        });
        return true;
    } catch (err) {
        console.error(err);
        return false
    }
};