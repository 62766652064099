import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  ListItemIcon,
  capitalize,
} from "@mui/material";
import FlowConstants from "../FlowConstant";

import { useFormik } from "formik";
import * as yup from "yup";
import { ICONLIB } from "./IconLib";

export default function AddNewNodeDialog({
  openDialog,
  toggleDialog,
  dialogConfig,
  emitSubmitEvent,
}) {
  const validationSchema = yup.object({
    node_name: yup.string().required("Please enter node name"),
    node_type: yup.string().required("Please select node type"),
    node_icon: yup.string().required("Please select node icon"),
  });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      node_name: "",
      node_type: "",
      node_icon: "",
    },
    onSubmit: () => {
      const emitObj = {
        nodeName: formik.values.node_name,
        nodeType: formik.values.node_type,
        nodeIcon: formik.values.node_icon,
      };
      // Emit the data  to parent component
      emitSubmitEvent(emitObj);
      // Reset the form
      formik.resetForm();
      //Close the dialog
      toggleDialog(false);
    },
  });

  // Cancel Dialog
  const dialogCancel = (e) => {
    emitSubmitEvent(null);
    toggleDialog(false);
  };

  return (
    <Dialog open={openDialog} fullWidth={true} maxWidth={"xs"}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Add New Node</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Node Type</InputLabel>
              <Select
                id="node_type"
                label="Node Type"
                name="node_type"
                value={formik.values.node_type}
                onChange={formik.handleChange}
              >
                <MenuItem value={FlowConstants.TRANSPORT_NODE}>
                  Transport Node
                </MenuItem>
                <MenuItem value={FlowConstants.PROCESS_NODE}>
                  Process Node
                </MenuItem>
                <MenuItem value={FlowConstants.INFO_NODE}>Info Node</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <TextField
                autoFocus
                id="node_name"
                label="Node Name"
                type="text"
                variant="standard"
                name="node_name"
                value={formik.values.node_name}
                onChange={formik.handleChange}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Node Icon</InputLabel>
              <Select
                id="node_type"
                label="Node Icon"
                name="node_icon"
                value={formik.values.node_icon}
                onChange={formik.handleChange}
              >
                {
                  Object.keys(ICONLIB).map(icon => {
                    return <MenuItem value={icon} key={icon}>
                      <ListItemIcon>
                        {ICONLIB[icon]}
                      </ListItemIcon>
                      {capitalize(icon)}
                  </MenuItem>;
                  })
                }
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => dialogCancel()}>Cancel</Button>
          <Button type="submit">Add Node</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
