import {
    Box,
    Typography,
    Card,
    CardContent,
    Container,
    Stack,
    Divider
} from "@mui/material";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Helmet } from "react-helmet-async";

const vars = {};

const useStyles = makeStyles(
    theme => ({
        configurationCategory: {
            [theme.breakpoints.down("md")]: {
                gridTemplateColumns: "1fr",
            },
            display: "grid",
            gap: theme.spacing(4),
            gridTemplateColumns: "1fr 3fr",
            padding: theme.spacing(4, 0),
        },

        configurationItem: {
            display: "grid",
            gap: theme.spacing(4),
            gridTemplateColumns: "1fr 1fr",
        },
        configurationLabel: {
            paddingBottom: 20,
        },

        link: {
            display: "contents",
            marginBottom: theme.spacing(4),
        },
        icon: {
            "& path": {
                fill: theme.palette.primary.main,
            },
            fontSize: 48,
        },
        sectionDescription: {},
        sectionTitle: {
            fontSize: 20,
            fontWeight: 600,
        },
        navigationCard: {
            backgroundColor: '#cecece',
            border: `1px solid ${vars?.colors?.border?.neutralDefault}`,
            height: 130,
            boxShadow: "none !important",
            "& .MuiCardContent-root": {
                // borderRadius: vars?.borderRadius,
            },
        },
    }),
    { name: "ConfigurationPage" },
);

export default function Configure(props) {

    const classes = useStyles(props);
    const menuItems = [
        {
            label: "Roles and Permissions",
            menuItems: [
                {
                    description: "Manage user roles",
                    icon: <ManageAccountsIcon />,
                    title: 'Roles',
                    url: '/carboncompete/configure/roles'
                },
                // {
                //     description: "Manage platform permissions",
                //     icon: <SecurityIcon />,
                //     title: 'Permissions',
                //     url: '/carboncompete/configure/permissions'
                // },
                {
                    description: "Manage Feature Modules",
                    icon: <ViewModuleIcon />,
                    title: 'Modules',
                    url: '/carboncompete/configure/modules'
                }
            ],
        },
        {
            label: "Platform Subscription",
            menuItems: [
                {
                    description: "View or Change Subscription",
                    icon: <AttachMoneyIcon />,
                    title: 'Change Subscription',
                    url: '/carboncompete/configure/plans'
                }
            ],
        }
    ]
    return <Stack>
        <Helmet>
            <title> Configure </title>
        </Helmet>
        <Container>
            <Typography variant="h3">
                Configure
            </Typography>

        </Container>

        <Divider />


        {/* <Outlet /> */}
        <Box paddingX={6} margin="auto">
            {menuItems
                .map((menu, menuIndex) => (
                    <div className={classes.configurationCategory} key={menuIndex}>
                        <div className={classes.configurationLabel}>
                            <Typography variant="subtitle1">{menu.label}</Typography>
                        </div>
                        <div className={classes.configurationItem}>
                            {menu.menuItems
                                .map((item, itemIndex) => (
                                    <Link
                                        className={classes.link}
                                        to={item.url}
                                        key={`${item.title}-${itemIndex}`}
                                    >
                                        <Card
                                            className={classes.navigationCard}
                                            key={itemIndex}
                                            icon={item.icon}
                                            title={item.title}
                                            description={item.description}
                                            data-test-id={
                                                item.testId +
                                                "-settings-subsection-" +
                                                item.title.toLowerCase()
                                            }
                                        >
                                            <CardContent>
                                                <Container>
                                                    {item.icon}
                                                    <Stack>
                                                        <div>
                                                            {item.title}
                                                        </div>
                                                        <div>
                                                            {item.description}
                                                        </div>
                                                    </Stack>
                                                </Container>
                                            </CardContent>
                                        </Card>
                                    </Link>
                                ))}
                        </div>
                    </div>
                ))}
        </Box>
    </Stack>;
}