import { alpha, styled } from "@mui/material";
import { DataGrid, gridClasses, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { useEffect, useState } from "react";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

const StripDataGrid = ({ columnObj, rowObj, enableExport=false, opacity=0.3, page=0, pageSize=5, pageSizeOptions=[0,5,10], ...others}) => {
    const [column, setColumn] = useState([]);
    const [row, setRow] = useState([]);
    const [exporting, setExporting] = useState(false)
    const [ODD_OPACITY, setOpacity] = useState(0)
    const [pagination, setPagination] = useState({ page: 0, pageSize: 0, pageSizeOptions: [] })

    useEffect(() => {
        if (columnObj) {
            setColumn(columnObj)
        }
        if (rowObj) {
            setRow(rowObj)
        }
        if(page){
            setPagination(prevState => ({...prevState, page: page }));
        }
        if(pageSize){
            setPagination(prevState => ({...prevState, pageSize: pageSize }));
        }
        if(pageSizeOptions){
            setPagination(prevState => ({...prevState, pageSizeOptions: pageSizeOptions}))
        }
        if(enableExport){
            setExporting(enableExport)
        }
        if(opacity){
            setOpacity(opacity)
        }
    }, [columnObj, rowObj, page, pageSize, pageSizeOptions, enableExport, opacity])

    // const ODD_OPACITY = opacity || 0.2;

    const StripedDataGrid = styled(DataGrid)(({ theme }) => ({

        [`& .${gridClasses.row}.even`]: {
            backgroundColor: theme.palette.grey[200],
            '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
            '&.Mui-selected': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY + theme.palette.action.selectedOpacity,
                ),
                '&:hover': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY +
                        theme.palette.action.selectedOpacity +
                        theme.palette.action.hoverOpacity,
                    ),
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        backgroundColor: alpha(
                            theme.palette.primary.main,
                            ODD_OPACITY + theme.palette.action.selectedOpacity,
                        ),
                    },
                },
            },
        },
    }));


    return (
        <>
            <StripedDataGrid
                rows={row}
                columns={column}
                initialState={{
                    pagination: {
                        paginationModel: { page: pagination.page, pageSize: pagination.pageSize },
                    },
                }}
                sx={{
                    fontFamily: 'Poppins',
                    fontSize: 16,
                    border: 1,
                    borderColor: 'grey.400'
                }}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                slots={{
                    toolbar: exporting ? CustomToolbar : '',
                }}
                getRowId={(row) => row.row}
                pageSizeOptions={pageSizeOptions}
                {...others}
            />
        </>
    )

}

export default StripDataGrid