import { Typography, Button, Container, Stack, Divider } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid } from "@mui/x-data-grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useState, useEffect } from "react";
import * as rolesService from "../../../services/rolesAndPermissions";
import AddNewModule from "./CreateModuleDialog";
import { useSnackbar } from "notistack";
import { NOTIFICATION_SUCCESS } from "../../../utils/constants/NotificationConstants";
import DeleteIcon from '@mui/icons-material/Delete';

export default function Modules(props) {
  const [modules, setModules] = useState([]);
  const [createModuleDialog, toggleCreateModule] = useState(false);
  const [selectedModule, selectModule] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const onRowClick = (moduleData) => {
    selectModule(moduleData);
    toggleCreateModule(true);
  };

  const onDeleteModule = (moduleId) => {
    rolesService.deleteModule(moduleId).then((res) => {
      getAllModules();
      setModules(res);
        // show success message to user
        enqueueSnackbar("Module Deleted Successfully", {
          autoHideDuration: 3000,
          variant: NOTIFICATION_SUCCESS,
        });
    });
  };

  useEffect(() => {
    getAllModules();
  }, []);

  const getAllModules = () => {
    rolesService.getModules().then((modulesList) => {
      setModules(modulesList.filter(module => module.isActive));
    });
  };

  const onDialogClose = (val) => {
    // Reload modules to fetch latest modules
    getAllModules();
    selectModule(null);
    toggleCreateModule(val);
  };

  const columns = [
    { field: "moduleId", width: 300, headerName: "Module Id" },
    { field: "moduleName", width: 300, headerName: "Module Name" },
    { field: "displayOrder", width: 300, headerName: "Display Order" },
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => (
        <>
          <Button onClick={(e) => onRowClick(params.row)}>
            <EditIcon />
          </Button>
          <Button
            onClick={(e) => onDeleteModule(params.row.moduleId)}
            style={{ color: "red" }}
          >
            <DeleteIcon />
          </Button>
        </>
      ),
    },
  ];

  return (
    <Stack>
      <Container>
        <Typography variant="h5">
          <Link to="/carboncompete/configure">
            <Button>
              <ArrowBackIcon />
            </Button>
          </Link>
          Manage Modules
        </Typography>
      </Container>

      <Divider />

      <Container>
        <Button
          style={{ float: "right" }}
          onClick={(e) => toggleCreateModule(true)}
        >
          <AddIcon /> Create New Module
        </Button>
      </Container>
      <Container>
        <DataGrid
          rowSelection={false}
          checkboxSelection={false}
          getRowId={(row) => row.moduleId}
          rows={modules}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 15 },
            },
          }}
          pageSizeOptions={[5, 10, 15]}
        />
      </Container>
      {createModuleDialog ? (
        <AddNewModule
          openDialog={createModuleDialog}
          toggleDialog={(val) => onDialogClose(val)}
          moduleData={selectedModule}
        />
      ) : (
        ""
      )}
    </Stack>
  );
}
