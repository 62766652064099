import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  // TextField,
  // InputAdornment,
  // IconButton,
} from "@mui/material";
import { Link} from "react-router-dom";
import { Icon } from "@iconify/react";
import catalogue from "../Assets2/catalogue.png";
import { generateReport } from "../../services/catalogue/productlist";
import { CircularProgress } from "@material-ui/core";

const GridContainerStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
  gap: "30px",
  marginTop: "20px",
};

const outerBoxStyles = {
  textDecoration: "none",
  color: "#000",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "10px",
  paddingTop: "12px",
  width: "227px",
  height: "221.833px",
  flexShrink: 0,
  borderRadius: "10px",
  borderTop: "1px solid #D7D7D7",
  background: "#FFF",
  boxShadow: "4px 4px 4px 0px rgba(0, 0, 0, 0.16)",
};

const cardButton = {
  paddingBottom: "7px",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid #EE5491",
  width: "200px",
};

const productName = {
  fontSize: "12px",
  fontWeight: "bold",
  fontFamily: "poppins",
};

const innerBoxStyles = {
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  width: "200px",
  height: "122px",
  borderRadius: "8px",
  border: "1px dashed #978989",
  position: "relative",
};

// const iconStyles = {
//   fontSize: "56px",
//   color: "#149BA1",
// };

// const confidenceDetail = {
//   display: "flex",
//   flexDirection: "column",
//   gap: "13px",
// };

// const textStyles = {
//   fontFamily: "Poppins",
//   fontWeight: "bold",
//   fontSize: "24px",
// };

// const confidenceScore = {
//   fontFamily: "Poppins",
//   fontWeight: "bold",
//   fontSize: "10px",
// };

// const carbonScore = {
//   fontFamily: "Poppins",
//   fontSize: "14px",
//   fontWeight: "bold",
// };

export const Products = ({ searchQuery, selectedCategory }) => {
  // const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // useEffect(() => {
  //   if (!selectedCategory) {
  //     return; // No category selected, so nothing to do
  //   }

  //   const interval = setInterval(() => {
  //     setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? 1 : 0));
  //   }, 1000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [selectedCategory]); // Add selectedCategory as a dependency

  const [generating, setGenerating] = useState({});
  if (!selectedCategory) {
    return <div>No category selected.</div>;
  }

  const filteredProducts = selectedCategory.Products.filter((product) => {
    return product.productName
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
  });
  const handleGenerate = async (productId,productName) => {
    
    try {
      setGenerating(prev => ({ ...prev, [productId]: true }));

      const response = await generateReport(productName);
      
      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
   
      // Create a link element and trigger the download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${productName}_report.docx`); // Replace with the desired file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }finally{
      setGenerating(prev => ({ ...prev, [productId]: false }));
    }
  };
  console.log(generating)
  return (
    <div style={{ margin: "0 20px" }}>
    <div style={GridContainerStyle}>
      {filteredProducts.map((product, index) => (
        <Link
          style={outerBoxStyles}
          key={"catalog_link_" + index}
          to={{
            pathname: `/carboncompete/carboncatalogue/product/${product.productId}`,
            state: { productName: product.productName },
          }}
        >
          <Button sx={cardButton}>
            
            <Typography style={productName}>{product.productName.length>15?(product.productName.slice(0,15)+"..."):(product.productName)}</Typography>
            <Icon style={{ color: "#149BA1" }} icon="mingcute:right-line" />
          </Button>
          <Box style={innerBoxStyles}>
            <img
              src={product?.productImage || catalogue}
              style={{ height: "70px", width: "70px" }}
              alt="Product"
            />
            {/* <div style={confidenceDetail}>
              <Typography style={textStyles}>{product.confidence_score} %</Typography>
              <Typography style={confidenceScore}>Carbon <br /> Confidence Score</Typography>
            </div> */}
          </Box>
          <Button
            sx={{
              paddingX: "10px",
              paddingY: "3px",
              marginBottom: "10px",
              color: "whitesmoke",
              backgroundColor: "#00CCC0",

              "&:hover": {
                backgroundColor: "#00CCC0", // or any color you prefer for hover
                color: "white",
              },
            }}
            startIcon={generating[product.productId] && <CircularProgress size={20} />}
            onClick={(e) => {
              e.preventDefault(); 
              handleGenerate(product.productId,product.productName); 
            }}
          >
            {generating[product.productId] ? "Generating..." : "Generate Report"}
            
          </Button>
          {/* <Typography style={carbonScore}>
            <span style={{ fontSize: '25px' }}>{product.carbon_score}</span> kg Co2e
          </Typography> */}
        </Link>
      ))}
    </div>
  </div>

  );
};
