import React, { memo, useEffect, useState } from "react";
import { Handle } from "reactflow";
import styled from "styled-components";
import { Container, Stack, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ICONLIB } from "./IconLib";

const Node = styled.div`
  height: 110px;
  width: 110px;
  border-radius: 10px;
  box-shadow: 0 0 20px -5px;
  background-color: #f1f1f1;
  border: none;

  .react-flow__handle {
    width: 8px;
    height: 8px;
    border: none;
    background-color: transparent;
  }
`;

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    height: 300,
    width: 300,
    marginLeft: "-100px !important",
    paddingLeft: "125px",
    color: "black",
  },
}));

// const ActionTooltip = styled(({ className, ...props }) => (
//     <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//         backgroundColor: 'transparent',
//         top: -50,
//         color: 'black'
//     },
// }));

// const nodeOptions = {
//     farmer: [
//         { name: 'Milking', carbonEmission: '234 co2e' },
//         { name: 'Electricity', carbonEmission: '637 co2e' },
//         { name: 'Fuel', carbonEmission: '167 co2e' },
//         { name: 'Food', carbonEmission: '237 co2e' },
//         { name: 'Waste Management', carbonEmission: '67 co2e' }
//     ],
//     production: [
//         { name: 'Pasturization', carbonEmission: '300 co2e' },
//         { name: 'Homogenzation', carbonEmission: '104 co2e' },
//         { name: 'Packaging', carbonEmission: '99 co2e' },
//         { name: 'Storage and Cooling', carbonEmission: '55 co2e' },
//         { name: 'Waste Management', carbonEmission: '36 co2e' }
//     ],
//     transport: [
//         { name: 'Small vehicle', carbonEmission: '307 co2e' },
//         { name: 'Large vehicle', carbonEmission: '334 co2e' },
//         { name: 'Fuel', carbonEmission: '555 co2e' },
//         { name: 'Food', carbonEmission: '102 co2e' },
//         { name: 'Waste Management', carbonEmission: '67 co2e' }
//     ],
//     retail: [
//         { name: 'Milking', carbonEmission: '101 co2e' },
//         { name: 'Electricity', carbonEmission: '907 co2e' },
//         { name: 'Fuel', carbonEmission: '134 co2e' },
//         { name: 'Food', carbonEmission: '889 co2e' },
//         { name: 'Waste Management', carbonEmission: '996 co2e' }
//     ]
// };

export default memo(({ id, data = {}, isConnectable, selected }) => {

  const [selectMenus, setMenu] = useState(false);

  const [showAddIcon, setShowAddIcon] = useState(false);

  const [factorOptions, setFactorOptions] = useState([]);

  const toggleAddButton = (e) => {
    setShowAddIcon(!showAddIcon);
    e.preventDefault();
  };

  const onNodeOptionClick = (e, optIndex) => {
    console.log(factorOptions[optIndex]?.name);
    setMenu(false);
    e.stopPropagation();
  };

  useEffect(() => {
    const { factors = {} } = data;
    const factorOptions = [];

    for (let [key, value] of Object.entries(factors)) {
      factorOptions.push({
        name: key,
        value: value,
      });
    }
    setFactorOptions(factorOptions);
  }, [data]);

  return (
    <Node
      onClick={() => setMenu(true)}
      onMouseLeave={() => setMenu(false) && setShowAddIcon(false)}
      selected={selectMenus}
      onMouseEnter={toggleAddButton}
    >
      <Handle
        id="target-left"
        position="left"
        type="target"
        style={{
          backgroundColor: selected ? "transparent" : "rgba(0,0,0, 0.1)",
        }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <Handle
        id="target-right"
        position="right"
        type="target"
        style={{
          backgroundColor: selected ? "transparent" : "rgba(0,0,0, 0.1)",
        }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <Handle
        id="target-top"
        position="top"
        type="target"
        style={{
          backgroundColor: selected ? "transparent" : "rgba(0,0,0, 0.1)",
        }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <Handle
        id="target-bottom"
        position="bottom"
        type="target"
        style={{
          backgroundColor: selected ? "transparent" : "rgba(0,0,0, 0.1)",
        }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />

      <Stack spacing={1} pt={3} pr={0}>
        <Container>
          <span
            style={{
              background: "#c9e5e9",
              borderRadius: "50px",
              boxShadow: "0 0 15px -5px",
            }}
          >
            {ICONLIB[data?.metaData?.icon]}
          </span>
        </Container>

        <Container>
          <Typography mt={4}>{data?.metaData?.name}</Typography>
        </Container>
      </Stack>

      <HtmlTooltip
        open={selectMenus}
        title={
          <React.Fragment>
            <div className="cicleCenter">
              {factorOptions.map((opt, optIndex) => {
                return (
                  <div
                    key={"popup_button_" + optIndex}
                    className={"circle"}
                    style={{
                      transform: `rotate(${350 + 45 - (optIndex * (360 / factorOptions.length))}deg) translate(140px) rotate(-${350 + 45 - (optIndex * (360 / factorOptions.length))}deg)`
                    }}
                    onClick={(e) => onNodeOptionClick(e, optIndex)}
                  >
                    {/* <Category sx={{color: '#fff'}}/> */}
                    <p
                      style={{
                        textAlign: "center",
                        marginTop: "-14px",
                        color: "#000",
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {parseFloat(opt.value).toFixed(0)}
                    </p>

                    <Typography variant="caption" display="block" gutterBottom marginTop={"-40px"}>
                      {opt.name}
                    </Typography>
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        }
        placement="right"
      ></HtmlTooltip>

      {/* <ActionTooltip open={showAddIcon} title={
                <React.Fragment>
                    <IconButton aria-label="Add Node" onClick={e => data.addNodeAction(id, data)} size="large">
                        <AddIcon fontSize='30px' />
                    </IconButton>
                    <IconButton aria-label="Add Node" onClick={e => data.nodeEditAction(id, data)} size="large">
                        <EditIcon fontSize='30px' />
                    </IconButton>
                </React.Fragment>
            } placement='bottom' placeholder='Add New Node'>
                <Button style={{ visibility: 'hidden' }}></Button>
            </ActionTooltip> */}

      <Handle
        type="source"
        id="source-top"
        position="top"
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        id="source-bottom"
        position="bottom"
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        id="source-right"
        position="right"
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        id="source-left"
        position="left"
        isConnectable={isConnectable}
      />
    </Node>
  );
});
