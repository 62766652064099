import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Button,
  Grow
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Iconify from '../../../components/iconify';

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif',
  },
  palette: {
    primary: {
      main: '#00C9D2',
    },
    secondary: {
      main: '#FF4081',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          border: '1px solid #00C9D2',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s, box-shadow 0.3s',
          '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h5: {
          color: '#00C9D2',
        },
        h6: {
          color: '#00C9D2',
        },
      },
    },
  },
});

const ScenarioAnalysis = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const data = location.state?.data;
  const id = location.state?.data2;
  const scenario = data?.scenarios[0];

  console.log(data)
  if (!scenario) {
    return <Typography variant="h6" color="error">Scenario data is not available.</Typography>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Button
        sx={{
          backgroundColor: "#222",
          "&:hover": { backgroundColor: "#149BA1" },
          color: "#fff",
          marginRight: "20px",
        }}
        onClick={() => navigate(`/carboncompete/scenarioplanning`)}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Iconify icon="icon-park-outline:back" color="white" />
          <Typography
            fontFamily="Poppins"
            style={{ textTransform: "capitalize", marginLeft: "0.5rem" }}
          >
            Back
          </Typography>
        </div>
      </Button>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h5" gutterBottom>Info</Typography>
        <Card variant="outlined" sx={{ marginBottom: 2 }}>
          <CardContent>
            <Grid container spacing={2}>
              {Object.entries(scenario.info).map(([key, value]) => (
                <Grid item xs={6} key={key}>
                  <Typography variant="subtitle1">{capitalizeFirstLetter(key)}: {value}</Typography>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>

        <Typography variant="h5" gutterBottom>Scope Emission Analysis</Typography>
        <Grid container spacing={2} sx={{ marginBottom: 2, display: 'flex', alignItems: 'stretch' }}>
          {scenario.analysis.scopeEmissions.map((scope) => (
            <Grid item xs={12} md={4} key={scope.scope} sx={{ display: 'flex' }}>
              <Grow in={true} timeout={500}>
                <Card sx={{ flex: 1 }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>{scope.scope}</Typography>
                    <Typography variant="body2">Trend: {scope.trend}</Typography>
                    <Typography variant="subtitle1" sx={{ mt: 1 }}>Contributors:</Typography>
                    <List dense>
                      {scope.contributors.map((contributor, index) => (
                        <ListItem key={index}>
                          <ListItemIcon>
                            <FiberManualRecordIcon sx={{ fontSize: '0.5rem' }} />
                          </ListItemIcon>
                          <ListItemText primary={contributor} />
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                </Card>
              </Grow>
            </Grid>
          ))}
        </Grid>

        <Typography variant="h5" gutterBottom>Activity Analysis</Typography>
        <Grid container spacing={2} sx={{ marginBottom: 2, display: 'flex', alignItems: 'stretch' }}>
          {scenario.analysis.activityData.map((activity) => (
            <Grid item xs={12} sm={6} md={3} key={activity.activity} sx={{ display: 'flex' }}>
              <Grow in={true} timeout={500}>
                <Card sx={{ flex: 1 }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>{activity.activity}</Typography>
                    <Typography variant="body2">Trend: {activity.trend}</Typography>
                  </CardContent>
                </Card>
              </Grow>
            </Grid>
          ))}
        </Grid>

        <Typography variant="h5" gutterBottom>Recommendations</Typography>
        <Grid container spacing={2} mb={2} sx={{ display: 'flex', alignItems: 'stretch' }}>
          {scenario.recommendations[0].actions.map((action, index) => (
            <Grid item xs={12} md={6} key={index} sx={{ display: 'flex' }}>
              <Grow in={true} timeout={500}>
                <Card sx={{ flex: 1 }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>Action {index + 1}</Typography>
                    <Typography variant="body2">{action.action}</Typography>
                    <Typography variant="subtitle1" sx={{ mt: 1 }}>Impact:</Typography>
                    <List dense>
                      {Object.entries(action.impact).map(([key, value]) => (
                        <ListItem key={key}>
                          <ListItemIcon>
                            <FiberManualRecordIcon sx={{ fontSize: '0.5rem' }} />
                          </ListItemIcon>
                          <ListItemText primary={`${capitalizeFirstLetter(key)}: ${value}`} />
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                </Card>
              </Grow>
            </Grid>
          ))}
        </Grid>

        {scenario.conclusion && (
          <>
            <Typography variant="h5" gutterBottom>Conclusion</Typography>
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              {Object.entries(scenario.conclusion).map(([key, value]) => (
                <Grid item xs={12} key={key} sx={{ display: 'flex' }}>
                  <Grow in={true} timeout={500}>
                    <Card sx={{ flex: 1, marginBottom: 2 }}>
                      <CardContent>
                        <Typography variant="h6" gutterBottom>{capitalizeFirstLetter(key)}</Typography>
                        <Typography variant="body2">{value}</Typography>
                      </CardContent>
                    </Card>
                  </Grow>
                </Grid>
              ))}
            </Grid>
          </>
        )}

        <Box sx={{ mt: 4, display: 'flex', justifyContent: "center", gap: 4 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(`/carboncompete/scenarioplanning/analysis/scope-graph/${id}`)}
          >
            View Scope Graph
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate(`/carboncompete/scenarioplanning/analysis/activity-graph/${id}`)}
          >
            View Activity Graph
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ScenarioAnalysis;
