export const images = [
    {
        id: 240,
        image: 'https://productimagescc.blob.core.windows.net/product/p240.webp',
    },
    {
        id: 241,
        image: 'https://productimagescc.blob.core.windows.net/product/p241.webp',
    },
    {
        id: 242,
        image: 'https://productimagescc.blob.core.windows.net/product/p242.webp',
    },
    {
        id: 243,
        image: 'https://productimagescc.blob.core.windows.net/product/p243.webp',
    },
    {
        id: 244,
        image: 'https://productimagescc.blob.core.windows.net/product/p244.webp',
    },
    {
        id: 245,
        image: 'https://productimagescc.blob.core.windows.net/product/p245.webp',
    },
    {
        id: 246,
        image: 'https://productimagescc.blob.core.windows.net/product/p246.webp',
    },
    {
        id: 247,
        image: 'https://productimagescc.blob.core.windows.net/product/p247.webp',
    },
    {
        id: 248,
        image: 'https://productimagescc.blob.core.windows.net/product/p248.webp',
    },
    {
        id: 250,
        image: 'https://productimagescc.blob.core.windows.net/product/p250.jpg',
    },
    {
        id: 251,
        image: 'https://productimagescc.blob.core.windows.net/product/p251.jpg',
    },
    {
        id: 252,
        image: 'https://productimagescc.blob.core.windows.net/product/p252.jpg',
    },
    {
        id: 253,
        image: 'https://productimagescc.blob.core.windows.net/product/p253.jpg',
    },
    {
        id: 254,
        image: 'https://productimagescc.blob.core.windows.net/product/p254.jpg',
    },
    {
        id: 255,
        image: 'https://productimagescc.blob.core.windows.net/product/p255.jpg',
    },
    {
        id: 256,
        image: 'https://productimagescc.blob.core.windows.net/product/p256.jpg',
    },
    {
        id: 257,
        image: 'https://productimagescc.blob.core.windows.net/product/p257.jpg',
    },
    {
        id: 300,
        image: 'https://productimagescc.blob.core.windows.net/product/p300.webp',
    },
    {
        id: 301,
        image: 'https://productimagescc.blob.core.windows.net/product/p301.jpg',
    },
    {
        id: 302,
        image: 'https://productimagescc.blob.core.windows.net/product/p302.webp',
    },
    {
        id: 303,
        image: 'https://productimagescc.blob.core.windows.net/product/p303.webp',
    },
    {
        id: 304,
        image: 'https://productimagescc.blob.core.windows.net/product/p304.webp',
    },
    {
        id: 305,
        image: 'https://productimagescc.blob.core.windows.net/product/p305.webp',
    },
    {
        id: 306,
        image: 'https://productimagescc.blob.core.windows.net/product/p306.webp',
    },
    {
        id: 307,
        image: 'https://productimagescc.blob.core.windows.net/product/p307.webp',
    },
    {
        id: 308,
        image: 'https://productimagescc.blob.core.windows.net/product/p308.webp',
    },
    {
        id: 309,
        image: 'https://productimagescc.blob.core.windows.net/product/p309.webp',
    },
    {
        id: 310,
        image: 'https://productimagescc.blob.core.windows.net/product/p310.webp',
    },
    {
        id: 311,
        image: 'https://productimagescc.blob.core.windows.net/product/p311.webp',
    },
    {
        id: 312,
        image: 'https://productimagescc.blob.core.windows.net/product/p312.webp',
    },
    {
        id: 313,
        image: 'https://productimagescc.blob.core.windows.net/product/p313.webp',
    },
    {
        id: 314,
        image: 'https://productimagescc.blob.core.windows.net/product/p314.webp',
    },
    {
        id: 315,
        image: 'https://productimagescc.blob.core.windows.net/product/p315.webp',
    },
    {
        id: 316,
        image: 'https://productimagescc.blob.core.windows.net/product/p316.webp',
    },
    {
        id: 317,
        image: 'https://productimagescc.blob.core.windows.net/product/p317.webp',
    },
    {
        id: 318,
        image: 'https://productimagescc.blob.core.windows.net/product/p318.webp',
    },
    {
        id: 319,
        image: 'https://productimagescc.blob.core.windows.net/product/p319.webp',
    },
    {
        id: 320,
        image: 'https://productimagescc.blob.core.windows.net/product/p320.webp',
    },
    {
        id: 321,
        image: 'https://productimagescc.blob.core.windows.net/product/p321.jpeg',
    },
    {
        id: 322,
        image: 'https://productimagescc.blob.core.windows.net/product/p322.webp',
    },
    {
        id: 323,
        image: 'https://productimagescc.blob.core.windows.net/product/p323.webp',
    },
    {
        id: 324,
        image: 'https://productimagescc.blob.core.windows.net/product/p324.webp',
    },
    {
        id: 325,
        image: 'https://productimagescc.blob.core.windows.net/product/p325.jpg',
    },
    {
        id: 326,
        image: 'https://productimagescc.blob.core.windows.net/product/p326.webp',
    },
    {
        id: 327,
        image: 'https://productimagescc.blob.core.windows.net/product/p327.webp',
    },
    {
        id: 328,
        image: 'https://productimagescc.blob.core.windows.net/product/p328.jpeg',
    },
    {
        id: 329,
        image: 'https://productimagescc.blob.core.windows.net/product/p329.webp',
    },
    {
        id: 330,
        image: 'https://productimagescc.blob.core.windows.net/product/p330.webp',
    },
    {
        id: 331,
        image: 'https://productimagescc.blob.core.windows.net/product/p331.jpeg',
    },
    {
        id: 332,
        image: 'https://productimagescc.blob.core.windows.net/product/p332.jpg',
    },
    {
        id: 333,
        image: 'https://productimagescc.blob.core.windows.net/product/p333.webp',
    },
    {
        id: 334,
        image: 'https://productimagescc.blob.core.windows.net/product/p334.webp',
    },
    {
        id: 335,
        image: 'https://productimagescc.blob.core.windows.net/product/p335.webp',
    },
    {
        id: 336,
        image: 'https://productimagescc.blob.core.windows.net/product/p336.webp',
    },
    {
        id: 338,
        image: 'https://productimagescc.blob.core.windows.net/product/p338.jpeg',
    },
    {
        id: 339,
        image: 'https://productimagescc.blob.core.windows.net/product/p339.jpeg',
    },
    {
        id: 340,
        image: 'https://productimagescc.blob.core.windows.net/product/p340.jpeg',
    },
    {
        id: 342,
        image: 'https://productimagescc.blob.core.windows.net/product/p342.jpeg',
    },
    {
        id: 343,
        image: 'https://productimagescc.blob.core.windows.net/product/p343.jpeg',
    },
    {
        id: 344,
        image: 'https://productimagescc.blob.core.windows.net/product/p344.jpeg',
    },
    {
        id: 345,
        image: 'https://productimagescc.blob.core.windows.net/product/p345.jpeg',
    },
    {
        id: 346,
        image: 'https://productimagescc.blob.core.windows.net/product/p346.jpeg',
    },
    {
        id: 347,
        image: 'https://productimagescc.blob.core.windows.net/product/p347.jpeg',
    },
    {
        id: 348,
        image: 'https://productimagescc.blob.core.windows.net/product/p348.jpeg',
    },
    {
        id: 349,
        image: 'https://productimagescc.blob.core.windows.net/product/p349.jpeg',
    },
    {
        id: 350,
        image: 'https://productimagescc.blob.core.windows.net/product/p350.jpeg',
    },
    {
        id: 351,
        image: 'https://productimagescc.blob.core.windows.net/product/p351.jpeg',
    },
    {
        id: 352,
        image: 'https://productimagescc.blob.core.windows.net/product/p352.png',
    },
    {
        id: 353,
        image: 'https://productimagescc.blob.core.windows.net/product/p353.jpeg',
    },
    {
        id: 354,
        image: 'https://productimagescc.blob.core.windows.net/product/p354.jpeg',
    },
    {
        id: 355,
        image: 'https://productimagescc.blob.core.windows.net/product/p355.jpeg',
    },
    {
        id: 356,
        image: 'https://productimagescc.blob.core.windows.net/product/p356.jpeg',
    },
    {
        id: 357,
        image: 'https://productimagescc.blob.core.windows.net/product/p357.jpeg',
    },
    {
        id: 358,
        image: 'https://productimagescc.blob.core.windows.net/product/p358.webp',
    },
    {
        id: 359,
        image: 'https://productimagescc.blob.core.windows.net/product/p359.jpeg',
    },
    {
        id: 360,
        image: 'https://productimagescc.blob.core.windows.net/product/p360.jpeg',
    },
    {
        id: 361,
        image: 'https://productimagescc.blob.core.windows.net/product/p361.webp',
    },
    {
        id: 362,
        image: 'https://productimagescc.blob.core.windows.net/product/p362.webp',
    },
    {
        id: 363,
        image: 'https://productimagescc.blob.core.windows.net/product/p363.webp',
    },
    {
        id: 364,
        image: 'https://productimagescc.blob.core.windows.net/product/p364.webp',
    },
    {
        id: 365,
        image: 'https://productimagescc.blob.core.windows.net/product/p365.webp',
    },
    {
        id: 366,
        image: 'https://productimagescc.blob.core.windows.net/product/p366.webp',
    },
    {
        id: 367,
        image: 'https://productimagescc.blob.core.windows.net/product/p367.webp',
    },
    {
        id: 368,
        image: 'https://productimagescc.blob.core.windows.net/product/p368.webp',
    },
    {
        id: 369,
        image: 'https://productimagescc.blob.core.windows.net/product/p369.webp',
    },
    {
        id: 370,
        image: 'https://productimagescc.blob.core.windows.net/product/p370.jpg',
    },
    {
        id: 371,
        image: 'https://productimagescc.blob.core.windows.net/product/p371.webp',
    },
    {
        id: 372,
        image: 'https://productimagescc.blob.core.windows.net/product/p372.webp',
    },
    {
        id: 373,
        image: 'https://productimagescc.blob.core.windows.net/product/p373.webp',
    },
    {
        id: 374,
        image: 'https://productimagescc.blob.core.windows.net/product/p374.webp',
    },
    {
        id: 375,
        image: 'https://productimagescc.blob.core.windows.net/product/p375.webp',
    },
    {
        id: 376,
        image: 'https://productimagescc.blob.core.windows.net/product/p376.webp',
    },
    {
        id: 377,
        image: 'https://productimagescc.blob.core.windows.net/product/p377.webp',
    },
    {
        id: 378,
        image: 'https://productimagescc.blob.core.windows.net/product/p378.webp',
    },
    {
        id: 379,
        image: 'https://productimagescc.blob.core.windows.net/product/p379.webp',
    },
    {
        id: 380,
        image: 'https://productimagescc.blob.core.windows.net/product/p380.webp',
    },
    {
        id: 381,
        image: 'https://productimagescc.blob.core.windows.net/product/p381.webp',
    },
    {
        id: 382,
        image: 'https://productimagescc.blob.core.windows.net/product/p382.webp',
    },
    {
        id: 383,
        image: 'https://productimagescc.blob.core.windows.net/product/p383.webp',
    },
    {
        id: 384,
        image: 'https://productimagescc.blob.core.windows.net/product/p384.webp',
    },
    {
        id: 386,
        image: 'https://productimagescc.blob.core.windows.net/product/p386.webp',
    },
    {
        id: 387,
        image: 'https://productimagescc.blob.core.windows.net/product/p387.webp',
    },
    {
        id: 388,
        image: 'https://productimagescc.blob.core.windows.net/product/p388.webp',
    },
    {
        id: 389,
        image: 'https://productimagescc.blob.core.windows.net/product/p389.png',
    },
    {
        id: 390,
        image: 'https://productimagescc.blob.core.windows.net/product/p390.webp',
    },
    {
        id: 391,
        image: 'https://productimagescc.blob.core.windows.net/product/p391.webp',
    },
    {
        id: 392,
        image: 'https://productimagescc.blob.core.windows.net/product/p392.webp',
    },
    {
        id: 393,
        image: 'https://productimagescc.blob.core.windows.net/product/p393.webp',
    },
    {
        id: 394,
        image: 'https://productimagescc.blob.core.windows.net/product/p394.webp',
    },
    {
        id: 395,
        image: 'https://productimagescc.blob.core.windows.net/product/p395.jpg',
    },
]

export const categoryImage = [
    {
        id: 1,
        image: "healthicons:fruits-outline",
    },
    {
        id: 2,
        image: "iconoir:electronics-chip",
    },
    {
        id: 3,
        image: "streamline:food-drinks-milk-canister-milk-dairy-canister-drink",
    },
    {
        id: 4,
        image: "openmoji:cooked-rice",
    },
    {
        id: 5,
        image:
            "streamline:food-drinks-wine-bottle-cook-bottle-wine-drink-cooking-nutrition-food",
    },
    {
        id: 6,
        image: "fluent-emoji-high-contrast:salt",
    },
    {
        id: 7,
        image: "mdi:spoon-sugar",
    },
    {
        id: 9,
        image: "fluent-mdl2:manufacturing",
    },
    {
        id: 8,
        image: "game-icons:steel-claws",
    },
];
