import React, { useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { MenuItem, FormControl, InputLabel, Select, Box, Grid, Typography, Card } from '@mui/material';

export const MonthWiseScopeEmissions = ({ analyticsData, selectedLocations, selectedYears }) => {
  const [selectedScope, setSelectedScope] = useState('Scope 1');

  const handleScopeChange = (event) => {
    setSelectedScope(event.target.value);
  };

  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  const calculateMonthlyEmissions = () => {
    const monthlyEmissions = {};

    if (selectedLocations.length > 0 && selectedYears.length > 0) {
      selectedLocations.forEach(locationName => {
        const location = analyticsData?.locations?.find(loc => loc.name === locationName);
        if (location) {
          location.yearly_data.forEach(yearData => {
            if (selectedYears.includes(yearData.year)) {
              yearData.monthly_data.forEach(monthData => {
                const month = monthData.month;
                const monthName = monthNames[parseInt(month, 10) - 1]; // Converting month number to name

                if (!monthlyEmissions[monthName]) {
                  monthlyEmissions[monthName] = {};
                }

                if (!monthlyEmissions[monthName][yearData.year]) {
                  monthlyEmissions[monthName][yearData.year] = 0;
                }

                monthlyEmissions[monthName][yearData.year] += monthData.analytics[selectedScope]?.total_emissions || 0;
              });
            }
          });
        }
      });
    }

    // Format the emissions to two decimal places
    Object.keys(monthlyEmissions).forEach(month => {
      Object.keys(monthlyEmissions[month]).forEach(year => {
        monthlyEmissions[month][year] = parseFloat(monthlyEmissions[month][year].toFixed(2));
      });
    });

    return monthlyEmissions;
  };

  const monthlyEmissions = calculateMonthlyEmissions();

  const getChartOptions = () => {
    const yearColors = [
      "#00B0FA", "#230455", "#9700C7", "#E1004F","#BD1100", "#EE4900",
      "#FD5308", "#FB9902", "#0033BB", "#5100D9","#EF9100", "#02FEE0",
    ];
    const months = Object.keys(monthlyEmissions);
    const years = [...new Set(Object.values(monthlyEmissions).flatMap(month => Object.keys(month)))];

    const seriesData = years.map((year, index) => ({
      name: `Year ${year}`,
      type: 'bar',
      data: (months.map(month => monthlyEmissions[month][year]) || 0),
      itemStyle: {
        color: yearColors[index % yearColors.length],
      },
      barWidth: '10%',
    }));

    return {
      xAxis: {
        type: 'category',
        data: months,
        axisLine: {
          lineStyle: {
            color: '#000000',
          },
        },
      },
      yAxis: {
        type: 'value',
        name: 'Total Emissions',
        axisLine: {
          lineStyle: {
            color: '#000000',
          },
        },
        splitLine: {
          lineStyle: {
            color: '#e0e0e0',
          },
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: function (params) {
          let tooltip = '<div style="text-align: left;">';
          params.forEach((param) => {
            const value = param.value !== undefined && param.value !== null ? param.value.toFixed(2) : '0.00';
            tooltip +=
              '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;background-color:' +
              param.color +
              '"></span>' +
              param.seriesName +
              ": " +
              value +
              " (tCO2e)" +
              "<br/>";
          });
          tooltip += "</div>";
          return tooltip;
        }
        
      },
      legend: {
        data: years.map(year => `Year ${year}`),
        left: 'center',
        top: 'top',
        textStyle: {
          color: '#000000',
        },
      },
      series: seriesData,
      toolbox: {
        show: true,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ['line', 'bar', 'stack'] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
    };
  };

  const chartOptions = getChartOptions();

  return (
    <Card elevation={3} sx={{ p: 3, mt: 4 }}>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Grid item xs={6}>
          <Typography variant="h6" fontWeight={700}>
            Total GHG Emissions (Scope 1, 2 & 3) (tCO2e)
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel>Scope</InputLabel>
            <Select
              labelId="scope-select-label"
              id="scope-select"
              label="Select Scope"
              value={selectedScope}
              onChange={handleScopeChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                  },
                },
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
                variant: "menu",
              }}
              style={{
                width: "100%",
                height: "42px",
                backgroundColor: "#fff",
                borderRadius: "8px",
                fontFamily: "Poppins !important",
                fontWeight: 500,
                border: "none !important",
                "&:focus": {
                  border: "none",
                },
              }}
            >
              <MenuItem sx={{ textTransform: "capitalize" }} value="Scope 1">Scope 1</MenuItem>
              <MenuItem sx={{ textTransform: "capitalize" }} value="Scope 2">Scope 2</MenuItem>
              <MenuItem sx={{ textTransform: "capitalize" }} value="Scope 3">Scope 3</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: 2 }}>
        <ReactECharts option={chartOptions} style={{ height: 400 }} />
      </Box>
    </Card>
  );
};
