import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActionArea,
  CardHeader,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { roundDecimal } from "../../../utils/formatNumber";
import { stringWithUnderScoreWithUpperCase } from "../../../utils/helperFunctions";
import { commaNumbers } from "../../../utils/formatNumber";
import { useSnackbar } from "notistack";
import { NOTIFICATION_ERROR } from "../../../utils/constants/NotificationConstants";

export const EsgCards = ({
  scopeData,
  data,
  selectedLocation,
  selectedYear,
}) => {
  let filteredData = data.filter((item) => {
    if (selectedLocation.includes("All")) {
      return true;
    } else if (selectedLocation.length === 0) {
      return false;
    } else {
      return selectedLocation.includes(item.location);
    }
  });

  let filteredScopeData = [];
  if (scopeData !== null) {
    filteredScopeData = scopeData.filter((item) => {
      if (selectedLocation.includes("All")) {
        return true;
      } else if (selectedLocation.length === 0) {
        return false;
      } else {
        return selectedLocation.includes(item.location);
      }
    });
  }
  const { enqueueSnackbar } = useSnackbar();

  let scopeObj = {};
  filteredScopeData.forEach((item) => {
    for (const year in item.years) {
      if (selectedYear.includes(year)) {
        const yearData = item.years[year].yearlyData;

        ["scope3", "scope1And2"].forEach((category) => {
          if (!scopeObj[category]) {
            scopeObj[category] = {};
          }
          if (!scopeObj[category][year]) {
            scopeObj[category][year] = 0; // Initialize with 0
          }
          scopeObj[category][year] += yearData[category];
        });
      }
    }
  });

  let defaultObj = {};
  data.forEach((item) => {
    for (const year in item.years) {
      if (selectedYear) {
        const yearData = item.years[year];

        ["Energy", "Water", "Waste", "Food_Travel"].forEach((category) => {
          const categoryData = yearData[category];
          if (categoryData) {
            if (!defaultObj[category]) {
              defaultObj[category] = {
                stageId: categoryData.stageId,
              };
            }
          }
        });
      }
    }
  });

  let myObject = {};
  filteredData.forEach((item) => {
    for (const year in item.years) {
      if (selectedYear.includes("All")) {
        const yearData = item.years[year];

        ["Energy", "Water", "Waste", "Food_Travel"].forEach((category) => {
          const categoryData = yearData[category];
          if (categoryData) {
            if (!myObject[category]) {
              myObject[category] = {};
            }
            if (!myObject[category][year]) {
              myObject[category][year] = {
                stageId: categoryData.stageId,
                Total_Yearly_ActivityData: 0,
              };
            }
            myObject[category][year].Total_Yearly_ActivityData +=
              categoryData.yearlyData.Total_Yearly_ActivityData;
          }
        });
      } else if (selectedYear.length === 0) {
        return false;
      } else if (selectedYear.includes(year)) {
        const yearData = item.years[year];

        ["Energy", "Water", "Waste", "Food_Travel"].forEach((category) => {
          const categoryData = yearData[category];
          if (categoryData) {
            if (!myObject[category]) {
              myObject[category] = {};
            }
            if (!myObject[category][year]) {
              myObject[category][year] = {
                stageId: categoryData.stageId,
                Total_Yearly_ActivityData: 0,
              };
            }
            myObject[category][year].Total_Yearly_ActivityData +=
              categoryData.yearlyData.Total_Yearly_ActivityData;
          }
        });
      }
    }
  });

  const navigate = useNavigate();
  const [hoveredCategory, setHoveredCategory] = useState(null);

  const handleClick = (stageId, stageName) => {
    navigate(`/carboncompete/esgdashboard/stageInfo/${stageId}/${stageName}`);
  };

  const handleScopeClick = (scopeName) => {
    navigate(`/carboncompete/esgdashboard/scopeinfo/${scopeName}`);
  };

  const getCategoryTextColor = (category) => {
    switch (category) {
      default:
        return "#306E91";
    }
  };

  const getUnits = (category) => {
    switch (category) {
      case "Energy":
        return "(GJ)";
      case "Water":
        return "(kl)";
      case "Waste":
        return "(tons)";
      case "Food_Travel":
        return "(kms)";
      default:
        return "";
    }
  };

  const orders = ["Energy", "Water", "Waste", "Food_Travel"];

  const getArrowIcon = (currentYearConsumption, previousYearConsumption) => {
    // console.log(currentYearConsumption, previousYearConsumption, "values")
    let arrowIcon = null;
    let changeSymbol = "";
    let percentageChange = 0;

    previousYearConsumption
      ? (percentageChange =
        ((currentYearConsumption - previousYearConsumption) /
          previousYearConsumption) *
        100)
      : (percentageChange = 100);

    if (currentYearConsumption > previousYearConsumption) {
      arrowIcon = <ArrowUpwardIcon fontSize="small" style={{ color: "red" }} />;
      changeSymbol = "+";
    }
    if (currentYearConsumption < previousYearConsumption) {
      arrowIcon = (
        <ArrowDownwardIcon fontSize="small" style={{ color: "green" }} />
      );
      changeSymbol = "-";
    }

    return {
      arrowIcon: arrowIcon,
      changeSymbol: changeSymbol,
      percentageChange: Math.abs(percentageChange).toFixed(2) + "%",
    };
  };

  //We are using this ---> Function to sort dates in descending order
  const sortDatesDescending = (a, b) => {
    return parseInt(b.split("-")[1]) - parseInt(a.split("-")[1]);
  };

  return (
    <Grid mt={1} mb={2} container spacing={2}>
      {orders.map((category) => (
        <Grid key={category} item xs={12} sm={6} md={3} lg={4}>
          <Card
            elevation={hoveredCategory === category ? 10 : 3}
            sx={{
              height: "180px",
              transition: "transform 0.2s",
              transform:
                hoveredCategory === category ? "scale(1.02)" : "scale(1)",
              border: `1px solid ${getCategoryTextColor(category)}`,
              borderRadius: "6px",
              boxShadow: " 0 6px 10px 0 rgba(0, 0, 0, 0.06) ",
            }}
            onMouseEnter={() => setHoveredCategory(category)}
            onMouseLeave={() => setHoveredCategory(null)}
          >
            <CardActionArea
              sx={{
                height: "max-content",
                justifyContent: "start",
                cursor: defaultObj[category] ? "pointer" : "not-allowed",
              }}
              onClick={() => {
                if (defaultObj[category]) {
                  handleClick(defaultObj[category].stageId, category);
                } else {
                  enqueueSnackbar(
                    "No Data Available Please Select Other years",
                    {
                      autoHideDuration: 3000,
                      variant: NOTIFICATION_ERROR,
                    }
                  );
                }
              }}
            >
              <CardHeader
                sx={{ padding: "12px 10px" }}
                title={`TOTAL ${stringWithUnderScoreWithUpperCase(
                  category
                )} ${getUnits(category)}`}
                titleTypographyProps={{
                  fontSize: 16,
                  fontWeight: 600,
                  color: getCategoryTextColor(category),
                }}
              />
              <CardContent
                sx={{
                  height: "135px",
                  overflowY: "scroll",
                  scrollbarWidth: "thin",
                  padding: 0,
                }}
              >
                {Object.keys(myObject[category] || {})
                  .sort(sortDatesDescending)
                  .map((val2, index) => (
                    <CardHeader
                      sx={{ paddingTop: 0.5 }}
                      title={
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                          }}
                        >
                          <span>
                            {commaNumbers(
                              roundDecimal(
                                myObject[category][val2]
                                  ?.Total_Yearly_ActivityData,
                                2
                              ),
                              2
                            )}
                          </span>
                          {index !==
                            Object.keys(myObject[category])?.length - 1 &&
                            myObject[category][val2]
                              .Total_Yearly_ActivityData !==
                            myObject[category][
                              Object.keys(myObject[category])[
                              Object.keys(myObject[category])?.length -
                              (index + 2)
                              ]
                            ].Total_Yearly_ActivityData && (
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span>
                                  {
                                    getArrowIcon(
                                      myObject[category][
                                        Object.keys(myObject[category])[
                                        Object.keys(myObject[category])
                                          .length -
                                        (index + 1)
                                        ]
                                      ].Total_Yearly_ActivityData,
                                      myObject[category][
                                        Object.keys(myObject[category])[
                                        Object.keys(myObject[category])
                                          .length -
                                        (index + 2)
                                        ]
                                      ].Total_Yearly_ActivityData
                                    ).arrowIcon
                                  }
                                </span>
                                <Typography variant="caption">
                                  {
                                    getArrowIcon(
                                      myObject[category][
                                        Object.keys(myObject[category])[
                                        Object.keys(myObject[category])
                                          .length -
                                        (index + 1)
                                        ]
                                      ].Total_Yearly_ActivityData,
                                      myObject[category][
                                        Object.keys(myObject[category])[
                                        Object.keys(myObject[category])
                                          .length -
                                        (index + 2)
                                        ]
                                      ].Total_Yearly_ActivityData
                                    ).percentageChange
                                  }
                                </Typography>
                              </span>
                            )}
                        </div>
                      }
                      subheader={val2}
                      titleTypographyProps={{ fontSize: 16, fontWeight: 700 }}
                      subheaderTypographyProps={{
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    />
                  ))}
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}

      {["scope1And2", "scope3"].map((category) => (
        <Grid key={category} item xs={12} sm={6} md={3} lg={4}>
          <Card
            elevation={hoveredCategory === category ? 10 : 3}
            sx={{
              height: "180px",
              transition: "transform 0.2s",
              transform:
                hoveredCategory === category ? "scale(1.02)" : "scale(1)",
              border: `1px solid ${getCategoryTextColor(category)}`,
              borderRadius: "6px",
              boxShadow: " 0 6px 10px 0 rgba(0, 0, 0, 0.06) ",
            }}
            onMouseEnter={() => setHoveredCategory(category)}
            onMouseLeave={() => setHoveredCategory(null)}
          >
            <CardActionArea
              sx={{
                height: "max-content",
                justifyContent: "start",
                cursor: scopeObj[category] ? "pointer" : "not-allowed",
              }}
              onClick={() => {
                if (scopeObj[category]) {
                  handleScopeClick(category);
                } else {
                  enqueueSnackbar(
                    "No Data Available Please Select Other years",
                    {
                      autoHideDuration: 3000,
                      variant: NOTIFICATION_ERROR,
                    }
                  );
                }
              }}
            >
              <CardHeader
                sx={{ padding: "12px 10px" }}
                title={`TOTAL ${category === "scope3" ? "SCOPE 3 " : "SCOPE 1 AND 2 "
                  }(tCO2e)`}
                titleTypographyProps={{
                  fontSize: 16,
                  fontWeight: 600,
                  color: getCategoryTextColor(category),
                }}
              />
              <CardContent
                sx={{
                  height: "135px",
                  overflowY: "scroll",
                  scrollbarWidth: "thin",
                  padding: 0,
                }}
              >
                {Object.keys(scopeObj[category] || {})
                  .reverse()
                  .map((val, index) => (
                    <CardHeader
                      sx={{ paddingTop: 0.5 }}
                      title={
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                          }}
                        >
                          <span>
                            {commaNumbers(
                              roundDecimal(scopeObj[category][val], 2),
                              2
                            )}
                          </span>

                          {index !==
                            Object.keys(scopeObj[category])?.length - 1 &&
                            scopeObj[category][val] !==
                            scopeObj[category][
                            Object.keys(scopeObj[category])[
                            Object.keys(scopeObj[category])?.length -
                            (index + 2)
                            ]
                            ] && (
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span>
                                  {
                                    getArrowIcon(
                                      scopeObj[category][val],
                                      scopeObj[category][
                                      Object.keys(scopeObj[category])[
                                      Object.keys(scopeObj[category])
                                        .length -
                                      (index + 2)
                                      ]
                                      ]
                                    ).arrowIcon
                                  }
                                </span>
                                <Typography variant="caption">
                                  {
                                    getArrowIcon(
                                      scopeObj[category][val],
                                      scopeObj[category][
                                      Object.keys(scopeObj[category])[
                                      Object.keys(scopeObj[category])
                                        .length -
                                      (index + 2)
                                      ]
                                      ]
                                    ).percentageChange
                                  }
                                </Typography>
                              </span>
                            )}
                        </div>
                      }
                      subheader={val}
                      titleTypographyProps={{ fontSize: 16, fontWeight: 700 }}
                      subheaderTypographyProps={{
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    />
                  ))}
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
