/**
 * @description A helper function which generated unique string generation
 * @returns {String}
 */
export const nodeIdGenerator = () => {
  return (Math.random() + 1).toString(36).substring(2);
};

/**
 * @description A helper function which generated unique string generation
 * @returns {String}
 */
export const stringWithUnderScore = (string = "") => {
  return string.split("_").join(" ");
};

export const stringWithUnderScoreWithUpperCase = (string = "") => {
  return string.split("_").join(" ").toLocaleUpperCase();
};

export const yearSlice = (string) => {
  return string.slice(2, 4);
};

export const toCamelCase = (str) => {
  return str
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/(^\w|\s\w)/g, (match) => match.toUpperCase());
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};
export const getUnits = ({ category, stageName }) => {
  if (stageName == "Energy") {
    return category === "DIESEL" ||
      category === "EB_POWER" ||
      category === "RE_POWER"
      ? "(GJ)"
      : category === "Total_Intensity"
      ? "(GJ/Cr)"
      : category === "RE"
      ? "(kWp)"
      : category === "RE_SHARE"
      ? "(%)"
      : "(kWh)";
  } else if (stageName === "Water") {
    return category === "Total_Intensity"
      ? "(kl/Cr)"
      : category === "Treated_Water_Share"
      ? "(%)"
      : "(kl)";
  } else if (stageName === "Waste") {
    return category === "Total_Intensity" ? "(ton/Cr)" : "(tons)";
  } else if (stageName === "Food_Travel") {
    return category === "Total_Intensity" ? "(km/Cr)" : "(kms)";
  }
};

// financial year -> 1st April 2020 - 31st March 2021 (FY 20-21)
export const financialYearConversion = (year, month) => {
  if (typeof year === "object") {
    let month = year["$d"].toString().split(" ")[1];
    if (month === "Jan" || month === "Feb" || month === "Mar") {
      return `FY ${
        parseInt(year["$d"].toString().split(" ")[3].slice(2)) - 1
      }-${parseInt(year["$d"].toString().split(" ")[3].slice(2))}`;
    } else {
      return `FY ${year["$d"].toString().split(" ")[3].slice(2)}-${
        parseInt(year["$d"].toString().split(" ")[3].slice(2)) + 1
      }`;
    }
  } else {
    if (month === "Jan" || month === "Feb" || month === "Mar") {
      return `FY ${parseInt(year.toString().slice(2)) - 1}-${parseInt(
        year.toString().slice(2)
      )}`;
    } else {
      return `FY ${year.toString().slice(2)}-${
        parseInt(year.toString().slice(2)) + 1
      }`;
    }
  }
};

// param year(in FY format) and return numeric year
export const yearConversion = (year, month) => {
  if (month === "Jan" || month === "Feb" || month === "Mar") {
    return parseInt(`20${year.split("-")[1]}`);
  } else {
    return parseInt(`20${year.split("-")[0].split(" ")[1]}`);
  }
};

//For extracting the string from the bracket to display in the Template Input Field in Canvas
export const extractTextInBrackets = (str) => {
  const start = str.indexOf('(');
  const end = str.indexOf(')');
  if (start !== -1 && end !== -1) {
    return str.slice(start + 1, end);
  }
  return "in units"; // Return null if no brackets found
};


export const removeBracketAndText=(str)=> {
  return str.replace(/\s*\(.*?\)\s*/g, '');
}