import { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  IconButton,
  styled,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { postSmartDataExtraction } from '../../services/external';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 20,
    padding: theme.spacing(2),
  },
}));

const UploadButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#4caf50',
  color: '#fffff',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#45a045',
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  color: '#f44336',
  borderColor: '#f44336',
  textTransform: 'none',
  '&:hover': {
    borderColor: '#d32f2f',
  },
}));

const ResetButton = styled(Button)(({ theme }) => ({
  color: '#ff9800',
  borderColor: '#ff9800',
  textTransform: 'none',
  '&:hover': {
    borderColor: '#e68900',
  },
}));

export const SmartDataModal = ({ open, onClose, onFileUpload, payload ,setUpdatedNodes}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      setUploading(true);

      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        const response = await postSmartDataExtraction(formData, payload);
        setUpdatedNodes(response)
       
      } catch (error) {
        console.log(error);
      } finally {
        setUploading(false);
        setSelectedFile(null);
        onClose();
      }
    }
  };

  const handleResetFile = () => {
    setSelectedFile(null);
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
          <Typography textAlign="center" variant="h6">Smart Data Extraction</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="200px"
          p={2}
          borderRadius={2}
          bgcolor="#f5f5f5"
          border="2px dashed #ccc"
        >
          <CloudUploadIcon style={{ fontSize: 50, color: '#ccc' }} />
          <Typography variant="h6" mt={2}>
            Drag & drop your file here
          </Typography>
          <Typography variant="body2" color="textSecondary">
            or click to select a file (CSV, PDF, PNG, SVG)
          </Typography>
          <input
            type="file"
            accept=".csv,.pdf,.png,.svg"
            onChange={handleFileChange}
            style={{
              opacity: 0,
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: '100%',
              height: '100%',
              cursor: 'pointer',
            }}
          />
          {selectedFile && (
            <Typography mt={2}>{`Selected file: ${selectedFile.name}`}</Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <UploadButton
          onClick={handleUpload}
          disabled={!selectedFile || uploading}
          startIcon={uploading && <CircularProgress size={20} />}
        >
          {uploading ? 'Uploading...' : 'Upload'}
        </UploadButton>
        <ResetButton
          onClick={handleResetFile}
          disabled={!selectedFile || uploading}
          variant="outlined"
        >
          Reset File
        </ResetButton>
        <CancelButton onClick={onClose} variant="outlined">
          Cancel
        </CancelButton>
      </DialogActions>
    </StyledDialog>
  );
};
