import axios from './axiosInstance';

const API_URL = process.env.REACT_APP_API_URL;

export const getAllProducts = async () => {
    try {
        const response = await axios({
            url: `${API_URL}/api/v1/product`,
            method: 'GET',
        });
        return response.data;
    } catch (err) {
        console.log(err);
        return err;
    }
};

export const getCountries = async () => {
    try {
        const response = await axios({
            url: `${API_URL}/api/v1/country`,
            method: 'GET',
        });
        return response.data;
    } catch (err) {
        console.log(err);
        return err;
    }
};

export const getState = async ({ countryId }) => {
    try {
        const params = {
            countryId
        };
        const { data: responseData = {} } = await axios({
            url: `${API_URL}/api/v1/state`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            params
        });
        return responseData;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getCity = async ({ countryId, stateId }) => {
    try {
        const params = {
            countryId,
            stateId
        };
        const { data: responseData = {} } = await axios({
            url: `${API_URL}/api/v1/city`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            params
        });
        return responseData;
    } catch (err) {
        console.error(err);
        throw err;
    }
};
