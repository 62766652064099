import axios from './axiosInstance';
// import { PRODUCT } from './mockData';
const API_URL = process.env.REACT_APP_API_URL;



export const getCanvasDetailsByProductId = async (productId) => {
    try {
        // return PRODUCT[productId];

        const { data: responseData = {} } = await axios({
            url: `${API_URL}/api/v1/product-batch-emission`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                productId,
            }
        });
        return (responseData?.data[0] || {})?.products || {};
    } catch (err) {
        console.error(err);
        throw err;
    }
};



// export const getCanvasDetailsByProductIdMockData = () => {
//     return PRODUCT['yogurt_id'];
// }