import React, { useEffect, useState } from 'react';
import { Typography, Grid, createTheme, ThemeProvider, Grow, Button } from '@mui/material';
import { MultipleSelectWithCheckBox } from '../../components/form/selectComponents';
import { getAnalyticsData } from '../../services/corporatecanvas';
import { useNavigate, useParams } from 'react-router-dom';
import { ScopeInfoCards } from './Charts/ScopeInfoCards';
import { APP_KEYS } from '../../utils/constants/AppKeyConstants';
import Iconify from '../../components/iconify';

export const ScopeWiseInfo = () => {
    const userInfo = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));
    const [analyticsData, setAnalyticsData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [selectedYears, setSelectedYears] = useState([]);
    const navigate = useNavigate()
    const { scopeName } = useParams();

    const scopeLabels = {
        scope1: 'Scope 1',
        scope2: 'Scope 2',
        scope3: 'Scope 3'
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getAnalyticsData(userInfo?.userId);
                setAnalyticsData(data.data);

                const defaultLocations = data.data.locations.map(location => location.name);
                const availableYears = [...new Set(
                    data.data.locations?.flatMap(location =>
                        location?.yearly_data?.map(data => data.year)
                    ) || []
                )].sort((a, b) => a - b);
                const firstYear = availableYears[0];
                const lastYear = availableYears[availableYears.length - 1];
                setSelectedLocations(defaultLocations);
                setSelectedYears([firstYear, lastYear]);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleLocationChange = (event) => {
        const newSelectedLocations = event.target.value;
        const filteredLocations = newSelectedLocations.filter(location => location !== "Select All");

        if (newSelectedLocations.includes("Select All")) {
            setSelectedLocations(locations.map(location => location.name));
        } else {
            setSelectedLocations(filteredLocations);
        }
    };

    const handleYearChange = (event) => {
        const newSelectedYears = event.target.value;
        const filteredYears = newSelectedYears.filter(year => year !== "Select All");

        if (newSelectedYears.includes("Select All")) {
            setSelectedYears(years);
        } else {
            setSelectedYears(filteredYears);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    const locations = analyticsData?.locations?.map(location => ({
        id: location.id,
        name: location.name
    })) || [];

    const years = [...new Set(
        analyticsData?.locations?.flatMap(location =>
            location?.yearly_data?.map(data => data.year)
        ) || []
    )].sort((a, b) => a - b);

    const isAllLocationSelected = locations.length > 0 && selectedLocations.length === locations.length;
    const isAllYearSelected = years.length > 0 && selectedYears.length === years.length;

    const theme = createTheme({
        typography: {
            fontFamily: "Poppins !important",
            fontWeight: "bolder !important",
        },
    });

    const getAggregatedEmissions = () => {
        let emissionsByCategory = {};

        selectedLocations.forEach(locationName => {
            const location = analyticsData.locations.find(loc => loc.name === locationName);
            if (location) {
                selectedYears.forEach(year => {
                    const yearlyData = location.yearly_data.find(data => data.year === year);
                    if (yearlyData) {
                        const scopeData = yearlyData.total_scope_emissions[scopeName];
                        if (scopeData) {
                            Object.entries(scopeData.category_breakdown_emissions).forEach(([category, data]) => {
                                if (!emissionsByCategory[category]) {
                                    emissionsByCategory[category] = {};
                                }
                                if (!emissionsByCategory[category][year]) {
                                    emissionsByCategory[category][year] = {
                                        totalEmission: 0,
                                        subcategory_breakdown_emissions: {}
                                    };
                                }

                                // Aggregate total emissions
                                emissionsByCategory[category][year].totalEmission += data.total_emissions;

                                // Aggregate subcategory breakdown emissions
                                Object.entries(data.subcategory_breakdown_emissions).forEach(([subcategory, subData]) => {
                                    if (!emissionsByCategory[category][year].subcategory_breakdown_emissions[subcategory]) {
                                        emissionsByCategory[category][year].subcategory_breakdown_emissions[subcategory] = {
                                            total_emissions: 0,
                                            activity_breakdown_emissions: {}
                                        };
                                    }
                                    emissionsByCategory[category][year].subcategory_breakdown_emissions[subcategory].total_emissions += subData.total_emissions;

                                    // Aggregate activity breakdown emissions
                                    Object.entries(subData.activity_breakdown_emissions).forEach(([activity, activityEmissions]) => {
                                        if (!emissionsByCategory[category][year].subcategory_breakdown_emissions[subcategory].activity_breakdown_emissions[activity]) {
                                            emissionsByCategory[category][year].subcategory_breakdown_emissions[subcategory].activity_breakdown_emissions[activity] = 0;
                                        }
                                        emissionsByCategory[category][year].subcategory_breakdown_emissions[subcategory].activity_breakdown_emissions[activity] += activityEmissions;
                                    });
                                });
                            });
                        }
                    }
                });
            }
        });

        return emissionsByCategory;
    };

    const aggregatedEmissions = getAggregatedEmissions();

    return (
        <ThemeProvider theme={theme}>
            <Grid sx={{ padding: 2 }}>
                <Grid
                    container
                    spacing={2}
                    p={2}
                    sx={{ display: "flex", alignItems: "center" }}
                >
                    <Grid item xs={6} container alignItems="center">
                        <Button
                            sx={{
                                backgroundColor: "#222",
                                "&:hover": { backgroundColor: "#149BA1" },
                                color: "#fff",
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "10px",
                                marginRight: 2, // Adjust margin as needed
                            }}
                            onClick={() => navigate("/carboncompete/corporatedashboard")}
                        >
                            <Iconify
                                icon="icon-park-outline:back"
                                color="white"
                                sx={{ marginRight: 1 }}
                            />
                            <Typography
                                fontFamily="Poppins"
                                style={{ textTransform: "capitalize" }}
                            >
                                Back
                            </Typography>
                        </Button>
                        <Typography variant="h6" fontWeight={700} sx={{ marginLeft: 4, textTransform: 'uppercase' }}>
                            {scopeLabels[scopeName]} Info
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <MultipleSelectWithCheckBox
                            label="Locations"
                            id="locations-select"
                            value={selectedLocations}
                            options={locations.map(location => location.name)}
                            onChange={handleLocationChange}
                            isAllSelected={isAllLocationSelected}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <MultipleSelectWithCheckBox
                            label="Years"
                            id="years-select"
                            value={selectedYears}
                            options={years}
                            onChange={handleYearChange}
                            isAllSelected={isAllYearSelected}
                        />
                    </Grid>
                </Grid>
                <ScopeInfoCards
                    aggregatedEmissions={aggregatedEmissions}
                    selectedYears={selectedYears}
                />
            </Grid>
        </ThemeProvider>
    );
};
