import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import {
  Card,
  Stack,
  Paper,
  Button,
  Container,
  Typography,
  IconButton,
  TableContainer,
  Tooltip,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import EditUserDialog from './Dialog/EditUser';
import DeleteUserDialog from './Dialog/DeleteUser';
import { getAllClients } from '../../services/clients';
import AddClientDialog from './Dialog/AddUser';

function CustomToolbar() {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    </div>
  );
}

export default function Clients() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [open, setOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const TABS = ['All Clients'];

  const handlePopulateData = async () => {
    let response;
    let tempRows = [];
    setShowLoader(true);

    try {
      switch (selectedTab) {
        case 0:
          setColumns(ALL_USERS_COL);
          response = await getAllClients();

          // Check if response has a 'data' property that is an array before using map
          if (response.data && Array.isArray(response.data)) {
            tempRows = response.data.map((r) => ({
              id: r.userId,
              name: r.userName,
              email: r.userEmail,
              userMobile: r.userMobile,
            }));
          } else {
            console.error('Data property is not an array in the response:', response);
          }

          setRows(tempRows);
          setShowLoader(false);
          break;
        default:
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setShowLoader(false);
    }
  };

  const ALL_USERS_COL = [
    { field: 'id', headerName: 'Client ID', width: '250' },
    { field: 'name', headerName: 'Name', width: '250' },
    { field: 'email', headerName: 'Email', width: '250' },
    { field: 'userMobile', headerName: 'userMobile', width: '250' },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      selectable: false,
      filter: false,
      renderCell: (params) => {
        const onClick = (e, key) => {
          e.stopPropagation();
          if (key === 'edit') {
            setSelectedRow(params.row);
            setOpenEditDialog(true);
          }
          if (key === 'delete') {
            setSelectedRow(params.row);
            setOpenDeleteDialog(true);
          }
        };
        return (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
              }}
            >
              <div>
                <IconButton onClick={(e) => onClick(e, 'delete')}>
                  <Iconify icon="ic:round-delete" />
                </IconButton>
              </div>
              <div>
                <IconButton onClick={(e) => onClick(e, 'edit')}>
                  <Iconify icon="material-symbols:edit" />
                </IconButton>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    handlePopulateData();
  }, [selectedTab]);

  return (
    <>
      <Helmet>
        <title> Clients </title>
      </Helmet>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container maxWidth="xl">
        <Stack m={1} direction="row" alignItems="center" justifyContent="space-between">

          <Grid sx={{ marginLeft: 'auto' }}>
            <Box>
              <Button sx={{ background: '#149BA1' }} onClick={() => setOpen(true)} variant="contained" color="primary">
                <Typography fontFamily="Raleway" fontWeight={600}>
                  + Add New Client
                </Typography>
              </Button>
            </Box>

            <Paper elevation={3} sx={{ width: '100%', mt: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Tooltip title={<Typography fontFamily="Raleway">{TABS[selectedTab]}</Typography>}>
                  <IconButton disabled>
                    <Iconify icon="material-symbols:keyboard-arrow-left" />
                  </IconButton>
                </Tooltip>
                <Tooltip title={<Typography fontFamily="Raleway">{TABS[selectedTab]}</Typography>}>
                  <IconButton disabled>
                    <Iconify icon="material-symbols:keyboard-arrow-right" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Paper>
          </Grid>
        </Stack>

        <Card sx={{ mt: 1 }}>
          <Scrollbar>
            <TableContainer id="datagrid" sx={{ minWidth: 800 }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[54]}
                // checkboxSelection
                disableSelectionOnClick
                sx={{ minHeight: 500, fontFamily: 'Poppins' }}
                components={{ Toolbar: CustomToolbar }}
                density="comfortable"
              />
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>

      {open ? <AddClientDialog open={open} setOpen={setOpen} handleFetchUser={handlePopulateData} /> : null}
      {openEditDialog ? (
        <EditUserDialog
          open={openEditDialog}
          setOpen={setOpenEditDialog}
          userDetails={selectedRow}
          handleFetchUser={handlePopulateData}
        />
      ) : null}
      {openDeleteDialog ? (
        <DeleteUserDialog
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          userDetails={selectedRow}
          handleFetchUser={handlePopulateData}
        />
      ) : null}
    </>
  );
}
