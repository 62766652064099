import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { monthsSet } from "../../../provider/EsgDataFilteration";
import { Button, Card, CardContent, Grid } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export default function BarChartArea({
  propsForAll,
  selectedLocation,
  selectedYear,
  stageId,
  intensity,
}) {
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = echarts.init(chartRef.current);
    const filteredLocationForAll = {};
    selectedLocation.forEach((loc) => {
      if (propsForAll[loc]) {
        filteredLocationForAll[loc] = propsForAll[loc];
      }
    });

    const aggregatedDataByYear = {};
    selectedYear.forEach((year) => {
      Object.keys(filteredLocationForAll).forEach((loc) => {
        if (filteredLocationForAll[loc][year]) {
          Object.keys(filteredLocationForAll[loc][year]).forEach((month) => {
            if (!aggregatedDataByYear[year]) {
              aggregatedDataByYear[year] = {};
            }
            if (!aggregatedDataByYear[year][month]) {
              aggregatedDataByYear[year][month] = 0;
            }
            aggregatedDataByYear[year][month] +=
              filteredLocationForAll[loc][year][month];
          });
        }
      });
    });

    const monthDataArray = Array.from(monthsSet)
      .map((month) => {
        const monthData = {
          month: month,
          data: {},
        };

        selectedYear.forEach((year) => {
          const dataValue = aggregatedDataByYear[year]?.[month] || 0;
          if (dataValue !== 0 && dataValue !== null) {
            monthData.data[year] = dataValue.toFixed(2);
          }
        });

        if (Object.keys(monthData.data).length > 0) {
          return monthData;
        } else {
          return null;
        }
      })
      .filter((item) => item !== null);

    const colors = [
      "#FD5308",
      "#FB9902",
      "#36454F",
      "#66B132",
      "#0291CD",
      "#0247FE",
      "#3E01A4",
      "#8601B0",
      "#A7194B",
    ];

    const legendData = selectedYear.map((year, index) => ({
      name: year,
    }));

    const option = {
      xAxis: {
        type: "category",
        data: monthDataArray.map((item) => item.month),
      },
      yAxis: {
        type: "value",
      },
      toolbox: {
        show: true,
        orient: "vertical",
        left: "right",
        top: "center",
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ["line", "bar", "stack"] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: (params) => {
          return params
            .map((param) => {
              if (param.value !== 0) {
                const color = param.color;
                if (stageId === "15") {
                  return `${intensity
                    ? `<span style="color:${color};">${param.seriesName}: ${param.value} GJ/Cr</span>`
                    : `<span style="color:${color};">${param.seriesName}: ${param.value} GJ</span>`
                    }`;
                } else if (stageId === "12") {
                  return `${intensity
                    ? `<span style="color:${color};">${param.seriesName}: ${param.value} kl/Cr</span>`
                    : `<span style="color:${color};">${param.seriesName}: ${param.value} kl</span>`
                    }`;
                } else if (stageId === "13") {
                  return `${intensity
                    ? `<span style="color:${color};">${param.seriesName}: ${param.value} tons/Cr</span>`
                    : `<span style="color:${color};">${param.seriesName}: ${param.value} Tons</span>`
                    }`;
                } else {
                  return `${intensity
                    ? `<span style="color:${color};">${param.seriesName}: ${param.value} kms/Cr</span>`
                    : `<span style="color:${color};">${param.seriesName}: ${param.value} kms</span>`
                    }`;
                }
              } else {
                return "";
              }
            })
            .filter(Boolean)
            .join("<br/>");
        },
      },
      legend: {
        data: legendData,
        left: "center",
        top: "top",
        textStyle: {
          color: "#000000",
        },
      },
      series: selectedYear
        .filter((year) =>
          monthDataArray.some(
            (item) => item.data[year] !== undefined && item.data[year] !== 0
          )
        )
        .map((year, index) => ({
          name: year,
          type: "bar",
          barWidth: "20%",
          barCategoryGap: "30%",
          barGap: "20%",
          data: monthDataArray.map((item) => item.data[year] || 0),
          itemStyle: {
            color: colors[index % colors.length],
            borderRadius: [5, 5, 0, 0],
          },
        })),
    };

    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [propsForAll, selectedLocation, selectedYear, stageId]);

  const generateCSV = () => {
    const filteredLocationForAll = {};
    selectedLocation.forEach((loc) => {
      if (propsForAll[loc]) {
        filteredLocationForAll[loc] = propsForAll[loc];
      }
    });

    const aggregatedDataByYear = {};
    selectedYear.forEach((year) => {
      Object.keys(filteredLocationForAll).forEach((loc) => {
        if (filteredLocationForAll[loc][year]) {
          Object.keys(filteredLocationForAll[loc][year]).forEach((month) => {
            if (!aggregatedDataByYear[year]) {
              aggregatedDataByYear[year] = {};
            }
            if (!aggregatedDataByYear[year][month]) {
              aggregatedDataByYear[year][month] = 0;
            }
            aggregatedDataByYear[year][month] +=
              filteredLocationForAll[loc][year][month];
          });
        }
      });
    });

    let csvContent = "data:text/csv;charset=utf-8,";

    // Construct CSV content
    csvContent += "Month," + selectedYear.join(",") + "\n";
    Array.from(monthsSet).forEach((month) => {
      let row = month;
      selectedYear.forEach((year) => {
        row += "," + (aggregatedDataByYear[year]?.[month] || 0);
      });
      csvContent += row + "\n";
    });

    // Create a virtual link and trigger download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "chart_data.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Button
              sx={{
                backgroundColor: "#149ba1",
                "&:hover": {
                  backgroundColor: "#000000",
                },
              }}
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={generateCSV}
            >
              Download Data
            </Button>
            <div ref={chartRef} style={{ width: "100%", height: "400px" }} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
