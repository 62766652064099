import { APP_KEYS } from '../utils/constants/AppKeyConstants';
import axios from './axiosInstance';

const API_URL = process.env.REACT_APP_API_URL;

const userInfo = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO) || '{}');

export const getAllCorporateSuppliers = async () => {
  try {
    const response = await axios({
      url: `${API_URL}/api/v1/corporate-suppliers`,
      method: 'GET',
      headers: {
      },
      params: {
        roleId: 7,
        compId: userInfo?.compId
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const editCorporateSupplier = async ({ userId, userName, userEmail, userMobile }) => {
  try {
    await axios({
      url: `${API_URL}/api/v1/user`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        userId: userId,
        userName: userName,
        userEmail: userEmail,
        userMobile: userMobile,
        roles: [
          {
            roleId: 7,
            roleName: "Supplier"
          }
        ],
        isActive: true
      },
    });

    return { success: 'supplier_updated' };
  } catch (err) {
    console.log(err);
    return { error: err.message };
  }
};

export const deleteCorporateSupplier = async ({ id }) => {
  try {
    const supplier = JSON.parse(localStorage.getItem('supplier'));

    await axios({
      url: `${API_URL}/delete-supplier/${id}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        email: supplier.email,
      },
    });
    return { success: 'supplier_deleted' };
  } catch (err) {
    console.log(err);
    return { error: err.message };
  }
};

export const addNewCorporateSupplier = async ({
  userName,
  userEmail,
  userMobile,
}) => {
  try {
    const resp = await axios({
      url: `${API_URL}/api/v1/invite/corporate-suppliers`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      data:
      {
        userName: userName,
        userEmail: userEmail,
        userMobile: userMobile,
        compId: userInfo?.userId,
        roles: [
          {
            roleId: 7,
            roleName: "CorporateGhg Supplier"
          }
        ],
        subs: [
          {
            subsId: 1,
            subsName: "Basic"
          }
        ],
        "isActive": true
      }
    });
    return resp.data;
  } catch (err) {
    console.log(err);
    return { message: 'error' };
  }
};

export const postBatchData = async (requestPayload) => {
  try {
    const response = await axios({
      url: `${API_URL}/api/v1/corporate-batch`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: requestPayload,
      params: {
        supplierUserId: userInfo?.userId,
        compId: userInfo?.compId,
      },
    });

    return response.data;
  } catch (err) {
    console.log(err);
    return { message: 'error' };
  }
};