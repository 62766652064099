import React, { useState } from 'react';
import { Paper, Grid, Box, Typography, Button } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpen';
// import MyProfile from './MyProfile';
// import Notifications from './Notifications';
import { ChangePassword } from './ChangePassword';

export const SupplierConfigure = () => {
  const [selectedCard, selectCard] = useState('password');

  return (
    <Box
      p={2}
      margin={4}
      display="flex"
      flexDirection="column"
      alignItems="start"
      justifyContent="center"
    >
      <Grid container spacing={22} justifyContent="start" alignItems="center">
        <Grid item xs={12} sm={4} md={3}>
          <Paper
            onClick={() => selectCard('profile')}
            elevation={3}
            sx={{
              padding: 2,
              textAlign: 'center',
              cursor: 'pointer',
              backgroundColor: selectedCard === 'profile' ? '#00C9D2' : '#fff',
              color: selectedCard === 'profile' ? '#fff' : 'black',
              width: '300px',
              height: '124px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <AccountCircleOutlinedIcon sx={{ fontSize: 60, color: selectedCard === 'profile' ? '#fff' : 'black' }} />
            <Typography variant="body1">My Profile</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Paper
            onClick={() => selectCard('notifications')}
            elevation={3}
            sx={{
              padding: 2,
              textAlign: 'center',
              cursor: 'pointer',
              backgroundColor: selectedCard === 'notifications' ? '#00C9D2' : '#fff',
              color: selectedCard === 'notifications' ? '#fff' : 'black',
              width: '300px',
              height: '124px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <NotificationsNoneOutlinedIcon sx={{ fontSize: 60, color: selectedCard === 'notifications' ? '#fff' : 'black' }} />
            <Typography variant="body1">Notifications</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Paper
            onClick={() => selectCard('password')}
            elevation={3}
            sx={{
              padding: 2,
              textAlign: 'center',
              cursor: 'pointer',
              backgroundColor: selectedCard === 'password' ? '#00C9D2' : '#fff',
              color: selectedCard === 'password' ? '#fff' : 'black',
              width: '300px',
              height: '124px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <LockOpenIcon sx={{ fontSize: 60, color: selectedCard === 'password' ? '#fff' : 'black' }} />
            <Typography variant="body1">Change Password</Typography>
          </Paper>
        </Grid>
      </Grid>

      <Box mt={4}  display="flex" justifyContent="start">
        {/* {selectedCard === 'profile' && <MyProfile />}
        {selectedCard === 'notifications' && <Notifications />} */}
        {selectedCard === 'password' && <ChangePassword />}
      </Box>
    </Box>
  );
};


