
import React, { useState, useRef, useCallback, useEffect } from "react";
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    Controls,
    ConnectionMode,
    Background,
    BackgroundVariant,
    useReactFlow,
    getIncomers,
    getOutgoers,
    getConnectedEdges,
} from "reactflow";
import "reactflow/dist/style.css";
import "./Flow.css";
import { nodeIdGenerator } from '../../../utils/helperFunctions';
import CustomNode from "./CustomNode";
import CombinedEdge from "./CustomEdge";
import { v4 as uuidv4 } from 'uuid';
import "./Flow.css"
import { enqueueSnackbar } from "notistack";
import { NOTIFICATION_INFO } from "../../../utils/constants/NotificationConstants";
import { StageConfigureModal, SupplierConfigureModal } from "./modal"
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { APP_KEYS } from "../../../utils/constants/AppKeyConstants";

let PreSchema = [
    {
        "category": "normal",
        "ctrlName": "Name",
        "ctrlDisplayName": "Name",
        "ctrlType": "textbox",
        "ctrlData": [
            {
                "id": "",
                "name": ""
            }
        ],
    },
    {
        category: "normal",
        ctrlName: "Email",
        ctrlDisplayName: "Email",
        "ctrlType": "textbox",
        "ctrlData": [
            {
                "id": "",
                "name": ""
            }
        ],
    },
    {
        "category": "stage",
        "ctrlName": "stagetype",
        "ctrlDisplayName": "Stage Type",
        "ctrlType": "textbox",
        "ctrlData": [
            {
                "id": "",
                "name": ""
            }
        ],
    },
    {
        "category": "stage",
        "ctrlName": "stagename",
        "ctrlDisplayName": "Stage Name",
        "ctrlType": "textbox",
        "ctrlData": [
            {
                "id": "",
                "name": ""
            }
        ],
    },
    {
        "category": "confidence",
        "ctrlName": "Source",
        "ctrlDisplayName": "Data Source",
        "ctrlType": "dropdown",
        "ctrlData": [
            {
                "id": "",
                "name": ""
            }
        ],
    },
    {
        "category": "confidence",
        "ctrlName": "Frequency",
        "ctrlDisplayName": "Data Frequency",
        "ctrlType": "dropdown",
        "ctrlData": [
            {
                "id": "",
                "name": ""
            }
        ],
    },
    {
        "category": "confidence",
        "ctrlName": "Completeness",
        "ctrlDisplayName": "Data Completeness",
        "ctrlType": "dropdown",
        "ctrlData": [
            {
                "id": "",
                "name": ""
            }
        ],
    },
    {
        "category": "confidence",
        "ctrlName": "Audited",
        "ctrlDisplayName": "Data Audited",
        "ctrlType": "dropdown",
        "ctrlData": [
            {
                "id": "",
                "name": ""
            }
        ],
    },
]

const nodeTypes = {
    custom: CustomNode,
};
const edgeTypes = {
    custom: CombinedEdge,
};

const Flow = ({ setExternalMappingResponse, externalMappingResponse, nodes, setNodes, onNodesChange, edges, setEdges, onEdgesChange, onNodeUpdate, onEdgeUpdate, loading, stageProps, setStageProps, setBackdrop }) => {

    let id = 1; // this id should be no.of(intitalNodes) + 1 only if using id as number
    const getId = () => `${id++}`;
    const userInfo = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));
    const reactFlowWrapper = useRef(null);
    const connectingNodeId = useRef(null);
    const { screenToFlowPosition } = useReactFlow();
    // const [nodes, setNodes, onNodesChange] = useNodesState([]);
    // const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [selectedNodeData, setSelectedNodeData] = useState(null);
    const [addNewNodeDialog, toggleAddNodeDialog] = useState(false);
    const [addSupplierDialog, toggleSupplierDialog] = useState(false);
    const [newNodePayload, setNewNodePayload] = useState({})
    const [flowEvent, setFlowEvent] = useState({})
    // const [stageProps, setStageProps] = useState([])
    // const [loading, setLoading] = useState(false)

    // add node on drag func start
    const onConnect = useCallback((params) => {
        connectingNodeId.current = null;
        setEdges((eds) => addEdge({ ...params, type: "custom" }, eds)); // simple floating
    }, []);

    const onConnectStart = useCallback((_, { nodeId }) => {
        connectingNodeId.current = nodeId;
    }, []);

    const onConnectEnd = useCallback(
        (event) => {
            if (!connectingNodeId.current) return;

            // console.log(newNodePayload, "something new payload")

            const targetIsPane = event.target.classList.contains("react-flow__pane");

            if (targetIsPane) {
                const id = getId();

                let newNode = newNodePayload;
                console.log(newNode, "inside the function")

                newNode.position = screenToFlowPosition({ x: event.clientX - 100, y: event.clientY})

                // const newNode = {
                //     id,
                //     position: screenToFlowPosition({
                //         x: event.clientX - 100,
                //         y: event.clientY,
                //     }),
                //     data: { stageName: `New stage ${id}` },
                //     style: { color: "#FF2E2E" },
                //     type: "custom",
                // };

                setNodes((nds) => nds.concat(newNode));
                setEdges((eds) =>
                    eds.concat({
                        // id: `e${connectingNodeId.current}-${newNode?.id}`,
                        id: uuidv4(),
                        source: connectingNodeId.current,
                        target: newNode?.id,
                        type: "custom",
                        data: {
                            attributionFactor: 0
                        }
                    })
                );
                enqueueSnackbar(`${newNode?.data?.stageName} Stage added!`, {
                    autoHideDuration: 3000,
                    variant: NOTIFICATION_INFO
                });
            }
        },
        [screenToFlowPosition, newNodePayload]
    );
    // add node on drag func end

    useEffect(()=>{
        if(Object.keys(newNodePayload).length && !addNewNodeDialog){
            onConnectEnd(flowEvent)
            // console.log(newNodePayload, "newly created node data")
        }
    },[addNewNodeDialog, newNodePayload])

    const handleDragEnd = (event) =>{
        setFlowEvent(event)
        toggleAddNodeDialog(true)
    }

    // delete middle func start

const onNodesDelete = useCallback(
  (deleted) => {
    const deleteNodesRecursively = (node, remainingNodes, remainingEdges) => {
      const incomers = getIncomers(node, remainingNodes, remainingEdges);
      let nodesToDelete = [node];

      incomers.forEach((incomer) => {
        nodesToDelete = nodesToDelete.concat(deleteNodesRecursively(incomer, remainingNodes, remainingEdges));
      });

      return nodesToDelete;
    };

    let remainingNodes = nodes.filter((n) => !deleted.includes(n));
    let nodesToDelete = [];

    deleted.forEach((node) => {
      nodesToDelete = nodesToDelete.concat(deleteNodesRecursively(node, remainingNodes, edges));
    });

    // Remove duplicates
    nodesToDelete = Array.from(new Set(nodesToDelete));

    remainingNodes = nodes.filter((node) => !nodesToDelete.includes(node));
    const remainingEdges = edges.filter(
      (edge) => !nodesToDelete.some((node) => edge.source === node.id || edge.target === node.id)
    );

    setEdges(remainingEdges);
    setNodes(remainingNodes);

    enqueueSnackbar(`${deleted[0]?.data?.stageName} stage and its connected stages are removed!`, {
      autoHideDuration: 3000,
      variant: NOTIFICATION_INFO,
    });
  },
  [nodes, edges, setEdges, setNodes]
);

    // delete middle func end

    const handleNodeClick = (event, node) => {
        if (node?.data?.stageName.includes("New stage")) {
            // toggleAddNodeDialog(true);
            // setSelectedNodeData(node?.data)
        }
        else {
            toggleSupplierDialog(true)
            setSelectedNodeData(node?.data)
        }
    }

    // useEffect(()=>{
    //     if(initialNodes){
    //         setNodes(initialNodes)
    //     }
    //     if(initialEdges){
    //         setEdges(initialEdges)
    //     }
    // }, [initialNodes, initialEdges])

    // const externalMappingService = async (productName, productDesc, Location) => {
    //     setLoading(true)
    //     const response = await postExternalMappingService({ productName, productDesc, Location });
    //     if (response) {
    //         const { nodes: layoutedNodes, edges: layoutedEdges } = await getLayoutedElements(response.nodes, response.edges);
    //         setNodes(layoutedNodes || [])
    //         setEdges(layoutedEdges || [])
    //     }
    //     setLoading(false)
    // }

    // useEffect(() => {
    //     if (initData) {
    //         externalMappingService(initData?.productName, initData?.description, initData?.country)
    //     }
    // }, [])

    // this function is to modify the templateschema and combining with stageProps after clicking the configure button 
    
    
    useEffect(()=>{
        PreSchema = PreSchema.map((prop)=>{
            if(prop.ctrlName === "Name"){
                return {...prop, ctrlData: [{id: "", name: userInfo?.userName}]}
            }else if(prop.ctrlName === "Email"){
                return {...prop, ctrlData: [{id: "", name: userInfo?.userEmail}]}
            }else{
                return prop
            }
        })
    },[])

    // const updatePreSchema = (stagename, staegtype) =>{
    //     return PreSchema.map((prop)=>{
    //         if(prop.ctrlName === "Name"){
    //             return {...prop, ctrlData: [{id: "", name: userInfo?.userName}]}
    //         }else if(prop.ctrlName === "Email"){
    //             return {...prop, ctrlData: [{id: "", name: userInfo?.userEmail}]}
    //         }else if(prop.ctrlName === "stagename"){
    //                 return {...prop, ctrlData: [{id: "", name: stagename}]}            
    //         }else if(prop.ctrlName === "stagetype"){
    //             return {...prop, ctrlData: [{id: "", name: staegtype}]}
    //         }else{
    //             return prop
    //         }
    //     })
    // }

    // const schemaConfigure = (mappingResponse = FirstData, templateResponse = SecondData) => {
    //     if (templateResponse?.nodes?.length) {
    //         templateResponse.nodes = templateResponse.nodes.map((node, index) => {
    //             let customSchema = updatePreSchema(node.data.stageName, node.data.lifecyclestage)
    //             return {
    //                 ...node,
    //                 data: {
    //                     ...node.data,
    //                     templateSchemaProp: node.data.templateSchemaProp.concat(customSchema)
    //                 }
    //             };
    //         });
    //     }

    //     templateResponse.edges = mappingResponse.edges
    //     templateResponse.stageProps = stageProps
    // }


    // Adds new node with corresponding data
    
    const onAddNodeSubmit = (nodeData) => {
        if (nodeData === null) {
            // clear the selected node data;
            setSelectedNodeData(null);
            return;
        }
        let edgeStyle = {};

        edgeStyle = {
            type: "smoothstep",
            style: { strokeWidth: 20 },
        };
        const newNode = {
            id: 'node_' + nodeIdGenerator(),
            type: "customdefault",
            position: selectedNodeData.position,
            // animated: true,
            data: {
                metaData: {
                    name: nodeData.stageName,
                    icon: nodeData.stageIcon,
                    stageId: nodeData.stageId,
                    supplierId: nodeData.supplierId,
                    supplierName: nodeData.supplierName
                },
            },
        };


        newNode.type = "customdefault";

        // clear the selected node data;


        setNodes([...nodes, newNode]);
        onNodeUpdate([...nodes, newNode]);

        const targetId = edges[nodes.length - 2]?.target || "root";

        const newEdge = {
            id: 'edge_' + nodeIdGenerator(),
            source: targetId,
            target: newNode.id,
            ...edgeStyle,
        }
        setSelectedNodeData(null);
        toggleAddNodeDialog(false);
        setEdges([...edges, newEdge]);
        onEdgeUpdate([...edges, newEdge]);
    };

    return (
        // <div>

        // <ReactFlowProvider>
        <div className="dndflow wrapper simple-floatingedges" style={{width: '100%'}} ref={reactFlowWrapper}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {
                loading ? (
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: '100%', gap: 1}}>
                        <CircularProgress color="inherit" sx={{ textAlign: 'center', alignItems: 'center', display:"flex"}}/>
                        <Typography>Good things take time...</Typography>
                    </Box>
                ) : (
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        onConnectStart={onConnectStart} // add node on drag
                        // onConnectEnd={onConnectEnd} // add node on darg
                        onConnectEnd={handleDragEnd} // add node on darg
                        edgeTypes={edgeTypes} // simple floating
                        nodeTypes={nodeTypes} // simple floating
                        onNodesDelete={onNodesDelete} // delete middle
                        connectionMode={ConnectionMode.Loose}
                        // onNodeClick={(event, node) => handleNodeClick(event, node)}
                        onNodeDoubleClick={(event, node) => handleNodeClick(event, node)}

                        // onInit={setReactFlowInstance}
                        // onDrop={onDrop}
                        // proOptions={proOptions}
                        // onDragOver={onDragOver}
                        // onSelectionDragStart={onSelectionDragStart}
                        // onSelectionEnd={onSelectionEnd}
                        // nodesDraggable={false}

                        // onNodesChange={onNodesChangeEvent}
                        // onEdgesChange={onEdgeChangeEvent}
                        // onConnect={onConnect}
                        // nodeTypes={nodeTypes}

                        fitView
                        nodeOrigin={[0.5, 0]} //new
                        fitViewOptions={{
                            padding: 2, // Adjusts the padding around the fit view. Increase for more padding.
                        }}
                    >
                        <Background variant={BackgroundVariant.Cross} gap={50} />
                        <Controls />
                    </ReactFlow>
                )
            }

            {addNewNodeDialog && <StageConfigureModal
                openDialog={addNewNodeDialog}
                toggleDialog={toggleAddNodeDialog}
                emitSubmitEvent={(e) => onAddNodeSubmit(e)}
                nodeData={selectedNodeData}
                setBackdrop={setBackdrop}
                setNewNodePayload={setNewNodePayload}
                setStageProps={setStageProps}
            />}

            {addSupplierDialog && <SupplierConfigureModal
                setNodes={setNodes}
                nodes={nodes}
                setExternalMappingResponse={setExternalMappingResponse}
                externalMappingResponse={externalMappingResponse}
                openDialog={addSupplierDialog}
                toggleDialog={toggleSupplierDialog}
                stageData={selectedNodeData}
                stageProps={stageProps}
                setStageProps={setStageProps}
            />}
        </div>
        // </div>
    )
}

const FlowProvider = ({ setExternalMappingResponse, externalMappingResponse, nodes, setNodes, onNodesChange, edges, setEdges, onEdgesChange, onNodeUpdate, onEdgeUpdate, loading, setBackdrop, stageProps, setStageProps}) => {
    return (
        <ReactFlowProvider>
            <Flow setExternalMappingResponse={setExternalMappingResponse} externalMappingResponse={externalMappingResponse} nodes={nodes} setNodes={setNodes} onNodesChange={onNodesChange} edges={edges} setEdges={setEdges} onEdgesChange={onEdgesChange} onNodeUpdate={onNodeUpdate} onEdgeUpdate={onEdgeUpdate} loading={loading} setBackdrop={setBackdrop} stageProps={stageProps} setStageProps={setStageProps}/>
        </ReactFlowProvider>
    );
}

export default FlowProvider