
import { APP_KEYS } from '../../utils/constants/AppKeyConstants';
import axios from '../axiosInstance';

const userInfo = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO) || '{}');

const API_URL = process.env.REACT_APP_API_URL;
const GEN_URL=process.env.REACT_APP_GENERATE_URL
export const getAllProducts = async () => {
    try {
        const response = await axios({
            url: `${API_URL}/api/v1/product`,
            method: 'GET',
            params: {
                compId: userInfo?.userId
            }
        });
        return response.data;
    } catch (err) {
        console.log(err);
        return err;
    }
};

export const getAllProductsByCategory = async (userObj = {}) => {
    try {
        const response = await axios({
            url: `${API_URL}/api/v1/product-by-category/`,
            method: 'GET',
            params: {
                compId: userObj.userId
            }
        });
        return response.data;
    } catch (err) {
        console.log(err);
        return err;
    }
};

export const getProductsById = async (pid) => {
    const productId = pid;
    try {
        const response = await axios({
            url: `${API_URL}/api/v1/product`,
            method: 'GET',
            params: {
                productId
              },
        });
        return response.data;
    } catch (err) {
        console.log(err);
        return err;
    }
};


export const generateReport=async(productName)=>{
    const compId=userInfo?.compId
    try {
        const response=await axios({
            url:`${GEN_URL}/generate_report`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        
                responseType:"blob",
            

            data:{productName:productName,compId:compId}
        })
        return response
    } catch (error) {
        console.log(error)
        return error
    }
        
}
