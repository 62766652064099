import React, { useEffect, useState } from "react";
import {
  ThemeProvider,
  createTheme,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import ChartArea1 from "./ChartArea1";
import ChartArea2 from "./ChartArea2";
import { MultipleSelectWithCheckBox } from "../../../components/form/selectComponents";


export default function PieCharts({ data, stageId, stageName }) {
  const [selectedLocation, setSelectedLocation] = useState(
    data ? data.map((location) => location.location) : []
  );
  const [selectedPlantWiseYear, setSelectedPlantWiseYear] = useState([]);
  const [commonPlantYears, setPlantCommonYears] = useState([]);
  const [commonTypeYears, setTypeCommonYears] = useState([]);
  const [selectedTypeYear, setSelectedTypeYear] = useState([]);
  const [TypeFieldsSelected, setTypeFieldsSelected] = useState(true);
  const [plantWiseFieldSelected, setPlantWiseFieldSelected] = useState(true);
  const locations = data ? data.map((location) => location.location) : [];
  const isAllPlantYearSelected =
    commonPlantYears.length > 0 &&
    selectedPlantWiseYear.length === commonPlantYears.length;
  const isAllTypeYearSelected =
    commonTypeYears.length > 0 &&
    selectedTypeYear.length === commonTypeYears.length;
  const isAllLocationSelected =
    locations.length > 0 && selectedLocation.length === locations.length;

  const handlePlantWiseYearChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedPlantWiseYear(
        selectedPlantWiseYear.length === commonPlantYears.length
          ? []
          : commonPlantYears
      );
      return;
    }
    setSelectedPlantWiseYear(value);
  };
  const handleTypeWiseYearChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedTypeYear(
        selectedTypeYear.length === commonTypeYears.length
          ? []
          : commonTypeYears
      );
      return;
    }
    setSelectedTypeYear(value);
  };

  const handleLocationChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedLocation(
        selectedLocation.length === locations.length ? [] : locations
      );
      return;
    }
    setSelectedLocation(value);
  };

  useEffect(() => {
    if (data) {
      const allYears = data
        .map((location) => Object.keys(location.years))
        .flat();
      const commonYearsSet = new Set(
        allYears.filter((year) =>
          allYears.every((y) =>
            data.some((location) => Object.keys(location.years).includes(y))
          )
        )
      );

      const sortedYearsArray = Array.from(commonYearsSet)
        .map((year) => year)
        .sort((a, b) => {
          const [fyAStart, fyAEnd] = a.split("-").map(Number);
          const [fyBStart, fyBEnd] = b.split("-").map(Number);
          return fyBStart - fyAStart || fyBEnd - fyAEnd;
        });

      setPlantCommonYears(sortedYearsArray);
      setSelectedPlantWiseYear([
        sortedYearsArray[0],
        sortedYearsArray[sortedYearsArray.length - 1],
      ]);

      // Log the sorted string
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const allYears = data
        .map((location) => Object.keys(location.years))
        .flat();
      const commonYearsSet = new Set(
        allYears.filter((year) =>
          allYears.every((y) =>
            data.some((location) => Object.keys(location.years).includes(y))
          )
        )
      );

      const sortedYearsArray = Array.from(commonYearsSet)
        .map((year) => year)
        .sort((a, b) => {
          const [fyAStart, fyAEnd] = a.split("-").map(Number);
          const [fyBStart, fyBEnd] = b.split("-").map(Number);
          return fyBStart - fyAStart || fyBEnd - fyAEnd;
        });

      setTypeCommonYears(sortedYearsArray);
      setSelectedTypeYear([
        sortedYearsArray[0],
        sortedYearsArray[sortedYearsArray.length - 1],
      ]);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const allLocations = data.map((location) => location.location);
      setSelectedLocation(allLocations);
    }
  }, [data]);

  useEffect(() => {
    setTypeFieldsSelected(
      selectedLocation.length > 0 && selectedTypeYear.length > 0
    );
  }, [selectedLocation, selectedTypeYear]);

  useEffect(() => {
    setPlantWiseFieldSelected(selectedPlantWiseYear.length > 0);
  }, [selectedPlantWiseYear]);

  const theme = createTheme({
    typography: {
      fontFamily: "Poppins !important",
      fontWeight: "bolder !important",
    },
  });

  const [newUpdatedStore, setNewUpdatedStore] = useState({});
  useEffect(() => {
    const store = {};
    data.forEach((entry) => {
      const yearlyData = entry.years;
      const location = entry.location;

      if (!store[location]) {
        store[location] = {};
      }

      Object.keys(yearlyData).forEach((year) => {
        const monthlyData = yearlyData[year];
        Object.keys(monthlyData).forEach((k) => {
          const stageId = monthlyData[k].stageId;
          const yearlyEmission =
            monthlyData[k].yearlyData.Yearly_Activity_Breakdown;

          if (!store[location][stageId]) {
            store[location][stageId] = {};
          }

          store[location][stageId][year] = yearlyEmission;
        });
      });
    });
    setNewUpdatedStore(store);
  }, [data]);

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2} padding={2}>
        <Grid
          item
          xs={6}
          style={{ display: "flex", flexDirection: "row", gap: "10px" }}
        >
          <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <Card style={{ height: "100%" }}>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: 900,
                      textAlign: "center",
                      color: "#0BDB7B",
                      marginLeft: "150px",
                    }}
                  >
                    {stageName === "Energy"
                      ? `Plant-Wise ${stageName} Consumption (GJ)`
                      : stageName === "Water"
                      ? `Plant-Wise ${stageName} Consumption (kl)`
                      : stageName === "Waste"
                      ? `Plant Wise ${stageName} Production (tons)`
                      : `Plant Wise Travel Miles (kms)`}
                  </Typography>
                </div>
                <MultipleSelectWithCheckBox
                  label="Select Year"
                  id="year-multi-select"
                  value={selectedPlantWiseYear}
                  options={commonPlantYears}
                  onChange={handlePlantWiseYearChange}
                  isAllSelected={isAllPlantYearSelected}
                />
                {plantWiseFieldSelected && (
                  <div style={{ flex: 1 }}>
                    <ChartArea1
                      props={newUpdatedStore}
                      year={selectedPlantWiseYear}
                      stageId={stageId}
                    />
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <Card style={{ height: "100%" }}>
              <CardContent>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "70px" }}
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: 900,
                      textAlign: "center",
                      color: "#0BDB7B",
                      marginLeft: "190px",
                    }}
                  >
                    {stageName === "Food_Travel"
                      ? `Type of Fuels Used (%)`
                      : stageName === "Waste"
                      ? `Type of ${stageName} Produced (%)`
                      : `Type of ${stageName} Used (%)`}
                  </Typography>
                </div>
                <Grid container style={{ marginTop: "5px" }} spacing={2}>
                  <Grid item xs={6}>
                    <MultipleSelectWithCheckBox
                      label="Select Year"
                      id="year-multi-select"
                      value={selectedTypeYear}
                      options={commonTypeYears}
                      onChange={handleTypeWiseYearChange}
                      isAllSelected={isAllTypeYearSelected}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MultipleSelectWithCheckBox
                      label="Select Location"
                      id="location-multi-select"
                      value={selectedLocation}
                      options={locations}
                      onChange={handleLocationChange}
                      isAllSelected={isAllLocationSelected}
                    />
                  </Grid>
                </Grid>

                {TypeFieldsSelected && (
                  <div style={{ flex: 1 }}>
                    <ChartArea2
                      props={data}
                      propsForAll={newUpdatedStore}
                      year={selectedTypeYear}
                      location={selectedLocation}
                      stageId={stageId}
                    />
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
