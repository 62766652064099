import { APP_KEYS } from "../utils/constants/AppKeyConstants";
import axios from "./axiosInstance";

const API_URL = process.env.REACT_APP_API_URL;

/**
 * @description Get Product or Products
 * @param {*} productId
 * @returns
 */

const userInfo = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO) || '{}');

export const getProducts = async (productId) => {
  try {
    const { data: responseData = {} } = await axios({
      url: `${API_URL}/api/v1/product`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        productId,
        compId: userInfo?.userId
      },
    });

    return responseData.data;
  } catch (err) {
    // Throw unknown errors as well
    throw err;
  }
};

export const getProductById=async(productId)=>{
  try {
    const { data: responseData = {} } = await axios({
      url: `${API_URL}/api/v1/productById`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        productId,
        compId: userInfo?.userId
      },
    });

    return responseData.data;
  } catch (err) {
    // Throw unknown errors as well
    throw err;
  }
}
/**
 * @description Get Product Carbon Score
 * @param {Object} productId
 * @returns {Object}
 */
export const getProductScore = async (productId) => {
  try {
    const { data: responseData = {} } = await axios({
      url: `${API_URL}/api/v1/total-emission`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        productId,
      },
    });

    return responseData.data;
  } catch (err) {
    // Throw unknown errors as well
    throw err;
  }
};

/**
 * @description Upload Product from excel file
 * @param {Object} fileObj
 * @returns {Object}
 */

export const uploadProduct = async (fileObj) => {
  try {
    var bodyFormData = new FormData();

    bodyFormData.append('file', fileObj)

    const { data: responseData = {} } = await axios({
      url: `${API_URL}/api/v1/product/upload`,
      method: "POST",
      headers: {
        "Content-Type": `multipart/form-data; boundary=${bodyFormData._boundary}`,
      },
      data: bodyFormData
    });

    return responseData.data;
  } catch (err) {
    // Throw unknown errors as well
    throw err;
  }
};

export const uploadAIProduct=async({productId,data})=>{
   try {
    console.log(productId)
    console.log(data)
    //  await axios({
    //   url:`${API_URL}/api/v1/aiProduct/${productId}`,
    //   method:"PUT",
    //   data:data
    //  })

     return "Success"
   } catch (error) {
       throw error;
   }
}
