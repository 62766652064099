import React, { useEffect, useState } from 'react';
import { Paper, Box, Typography } from '@mui/material';
import styled from 'styled-components';

const typoStyle = {
  fontFamily: 'Poppins',
  fontWeight: '800',
  color: '#000',
  fontSize: '16px',
  textAlign: 'start',
}

const ColoredPipe = ({ percentage, color, productName, number, customStyle, score }) => {
  return (
    <div style={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', }}>
      <StyledBox>
        <StyledHeader>
          <Typography style={typoStyle} variant="subtitle1">{productName}</Typography>
          <Typography style={typoStyle} variant="subtitle1">{number}</Typography>
        </StyledHeader>
        <StyledPaper elevation={0} customStyle={customStyle}>
          <StyledColoredPart percentage={percentage} color={color} />
        </StyledPaper>
      </StyledBox>
      <div>
        <Typography style={typoStyle} variant="subtitle1">{(score).toFixed(2)} Kg CO<sub>2</sub>e</Typography>
      </div>
    </div>
  );
};


const colors = [
  "linear-gradient(270deg, #FFBA05 17.02%, rgba(156, 250, 255, 0.00) 202.98%)",
  "linear-gradient(270deg, #05D776 17.03%, rgba(156, 250, 255, 0.00) 202.36%);",
  "linear-gradient(270deg, #FF9626 17.02%, rgba(156, 250, 255, 0.00) 207.36%);",
  "linear-gradient(180deg, rgba(20, 155, 161, 0.70) -30%, rgba(5, 215, 118, 0.70) 124.29%)",
  "linear-gradient(270deg, #006B3A 17.02%, rgba(156, 250, 255, 0.00) 231.07%);",
]

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`;

const StyledPaper = styled(Paper)`
  width: 342px;
  height: 24px;
  flex-shrink: 0;
  border-radius: ${(props) => props.customStyle?.borderRadius || '10px'};
  position: relative;
  overflow: hidden;
  background: ${(props) => props.customStyle?.background || '#d9d9d9'};

  @media (max-width: 1260px) {
    width: 314px !important;
  }
  @media (max-width: 995px) {
    width: 300px !important;
  }
  @media (max-width: 995px) {
    width: 300px !important;
  }
  @media (max-width: 660px) {
    width: 250px !important;
  }
  @media (max-width: 370px) {
    width: 160px !important;
  }
`;

const StyledColoredPart = styled.div`
  height: 100%;
  width: ${(props) => props.percentage}%;
  background: ${(props) => props.color};
  transition: width 1s ease-in-out;
`;

const StyledPipeUI = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 1352px) {
    gap: 15px;
  }
  @media (max-width: 670px) {
    gap: 10px;
  }
`;

const PipeUI = (props) => {
  const [highestCarbonProducts, setHighestCarbonProducts] = useState([]);

  useEffect(()=>{
    setHighestCarbonProducts(props?.highestCarbonProducts || [])
  },[props])


  return (
    <>
      <Typography sx={{ fontFamily: 'Poppins', fontSize: '15px', fontWeight: 600, marginBottom: '25px', textAlign: 'center', paddingTop: '20px' }}>
        Top 5 Carbon Score Contributors
      </Typography>
      <div style={{ margin: '36px 0', display: 'flex', justifyContent: 'space-between' }}>
        <h1 style={{ color: '#149BA1', textAlign: 'center', fontFamily: 'Poppins', fontSize: '17px', fontWeight: 800 }}>Confidence Score</h1>
        <h1 style={{ color: '#149BA1', textAlign: 'center', fontFamily: 'Poppins', fontSize: '17px', fontWeight: 800 }}>Carbon Score</h1>
      </div>
      <StyledPipeUI>
        {highestCarbonProducts.map((product, index) => (
          <ColoredPipe
            key={index}
            percentage={product.confidenceScore}
            score={product.carbonScore}
            color={colors[index]}
            label={`Scope ${index + 1}`}
            productName={product.productName.split(' ').slice(0, 4).join(' ')}
            number={`${product.confidenceScore} %`}
          />
        ))}
      </StyledPipeUI>
    </>
  );
};

export default PipeUI;