// routes
import { Routes } from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';

import { useEffect, useRef } from 'react';
import { Button } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { RouterProvider } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {postStartSession, pingSession} from "./services/sessiontracking"
import { APP_KEYS } from "./utils/constants/AppKeyConstants";

// ----------------------------------------------------------------------

export default function App() {
  const notistackRef = useRef();
  const pingIntervalRef = useRef(null);
  const userInfo = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));

  useEffect(()=>{

    //store sessionId
  if(userInfo){
    const generateSessionId = () => {
      const newSessionId = uuidv4();
      localStorage.setItem('currentSessionId', newSessionId);
      return newSessionId;
    };

    const startSession = async()=>{
     

      try {
        const sessionId = generateSessionId();
        await postStartSession(sessionId, new Date().toISOString(), userInfo?.userId, userInfo?.compId, userInfo?.roles[0].roleName)

      
      } catch (error) {
        console.error('Failed to start session:', error);
      }
  
    }

    startSession()
    
    //every 5 minute activity pinging and cleanup logic  
    const postPingSession = async () => {

      const sessionId= localStorage.getItem('currentSessionId')
      if (sessionId) {
        try {

          
          let  activity= JSON.parse(sessionStorage.getItem('activity')) || []
          
          await pingSession(sessionId,new Date().toISOString(), activity ); //api pinging logic

        } catch (error) {
          console.error('Failed to ping session:', error);
          // If ping fails (e.g., session not found), start a new session
          if (error.response && error.response.status === 404) {
            await startSession();
          }
        }
      }
    };

    pingIntervalRef.current = setInterval(postPingSession, 3 * 60 * 1000);

    const endSession = async () => {
      const sessionId= localStorage.getItem('currentSessionId')
      if (sessionId) {
      
        return
      }
    };
    
    return ()=>{      
      //store last activity, logout time and lastsessionId cookie if the user 
      if (pingIntervalRef.current) {
        clearInterval(pingIntervalRef.current);
      }

      localStorage.removeItem("currentSessionId")
      endSession()
    }
  }
  },[])

  const resizeObserverLoopErr = function() {
    const resizeObserverErr = window.console.error;
    window.console.error = (...args) => {
      if (args[0] && args[0].includes('ResizeObserver loop limit exceeded')) {
        return;
      }
      resizeObserverErr(...args);
    };
  };
  
  resizeObserverLoopErr();  

  return (
    <ThemeProvider>
      <SnackbarProvider
        ref={notistackRef}
        action={(key) => (
          <Button
            onClick={() => notistackRef.current.closeSnackbar(key)}
            style={{ color: '#fff', fontSize: '20px' }}
          >
            <CloseIcon />
          </Button>
        )}
      >
        <RouterProvider
          router={Routes}
          fallbackElement={<p>Initial Load...</p>}
        >
          <ScrollToTop />
          <StyledChart />
        </RouterProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
