import React, { memo, useState } from "react";
import { Handle } from "reactflow";
import styled from "styled-components";
import { Box, Container, Typography } from "@mui/material";
import { ICONLIB } from "./IconLib";

const Node = styled.div`
//   border: none;
//   border-radius: 50px;
//   width: 125px;
//   border: 1px solid black;

  .react-flow__handle {
    width: 8px;
    height: 8px;
    border: none;
    background-color: transparent;
  }
`;

export default memo(({ id, data, isConnectable, selected }) => {
  const [clicked, unclicked] = useState(false);
  const [showAddIcon, setShowAddIcon] = useState(false);

  const toggleAddButton = (e) => {
    setShowAddIcon(!showAddIcon);
    e.preventDefault();
  };

  return (
    <Node>
      <Handle
        id="position-left"
        style={{
          backgroundColor: clicked ? "transparent" : "rgba(0,0,0, 0.1)",
        }}
        type="target"
        position="left"
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <Handle
        id="position-right"
        style={{
          backgroundColor: clicked ? "transparent" : "rgba(0,0,0, 0.1)",
        }}
        type="target"
        position="right"
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <Handle
        id="position-top"
        style={{
          backgroundColor: clicked ? "transparent" : "rgba(0,0,0, 0.1)",
        }}
        type="target"
        position="top"
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <Handle
        id="position-bottom"
        style={{
          backgroundColor: clicked ? "transparent" : "rgba(0,0,0, 0.1)",
        }}
        type="target"
        position="bottom"
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />

      {/* <Container>
                
                <Typography mt={4}>
                    {data.label}
                </Typography>
            </Container> */}

      <Box
        className="transport-node-icon"
        style={{
          border: "4px solid #159ba1",
          borderRadius: "50px",
          padding: "20px",
          boxShadow: "0 0 15px -5px",
          height: 100,
          width: 100,
        }}
      >
        {ICONLIB[data?.metaData?.icon || "shipping"]}
      </Box>
    </Node>
  );
});
