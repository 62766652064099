import { APP_KEYS } from '../utils/constants/AppKeyConstants';
import axios from './axiosInstance';

const API_URL = process.env.REACT_APP_API_URL;

const userInfo = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO) || '{}');

export const getAllSuppliers = async () => {
  try {
    const roleId = 4;
    const response = await axios({
      url: `${API_URL}/api/v1/user`,
      method: 'GET',
      headers: {
      },
      params: {
        roleId: 4,
        compId: userInfo?.userId
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const editSupplier = async ({ userId, userName, userEmail, userMobile }) => {
  try {
    await axios({
      url: `${API_URL}/api/v1/user`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        userId: userId,
        userName: userName,
        userEmail: userEmail,
        userMobile: userMobile,
        roles: [
          {
            roleId: 4,
            roleName: "Supplier"
          }
        ],
        isActive: true
      },
    });

    return { success: 'supplier_updated' };
  } catch (err) {
    console.log(err);
    return { error: err.message };
  }
};

export const deleteSupplier = async ({ id }) => {
  try {
    const supplier = JSON.parse(localStorage.getItem('supplier'));

    await axios({
      url: `${API_URL}/delete-supplier/${id}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        email: supplier.email,
      },
    });
    return { success: 'supplier_deleted' };
  } catch (err) {
    console.log(err);
    return { error: err.message };
  }
};

export const addNewSupplier = async ({
  userName,
  userEmail,
  userMobile,
}) => {
  try {
    const resp = await axios({
      url: `${API_URL}/api/v1/invite-user`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      data:
      {
        userName: userName,
        userEmail: userEmail,
        userMobile: userMobile,
        compId: userInfo?.userId,
        roles: [
          {
            roleId: 4,
            roleName: "Supplier"
          }
        ],
        subs: [
          {
            subsId: 1,
            subsName: "Basic"
          }
        ],
        "isActive": true
      }
    });
    return resp.data;
  } catch (err) {
    console.log(err);
    return { message: 'error' };
  }
};