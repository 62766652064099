import { Box, Button, CircularProgress, FormControl, Grid, IconButton, MenuItem, Modal, styled, TextField, Typography } from '@mui/material'
import { FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { APP_KEYS } from '../../utils/constants/AppKeyConstants';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { makeStyles } from '@material-ui/core';
import { financialYearConversion, stringWithUnderScore, yearConversion } from '../../utils/helperFunctions';
import dayjs from 'dayjs';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: '80vh',
    transform: 'translate(-50%, -50%)',
    borderRadius: 2,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
};

const useStyles = makeStyles((theme) => ({
    activeTab: {
        borderBottom: '2px solid',
        borderColor: '#149BA1',
        color: 'black', // This makes the text color black
        fontWeight: 'bold'
    },
    backButton: {
        alignItems: "start",
    },
    backIcon: {
        marginRight: "0.5rem",
    },
    buttonGroup: {
        width: '100%',
        // position: "fixed",
        // bottom: 0,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 5,
        justifyContent: 'space-evenly',
        padding: '8px 0',
        backgroundColor: '#f6f8fe',
    },
    inputLabel: {
        // color: '#316EDE',
        color: '#000',
        fontWeight: '500',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        lineHeight: 'normal',

    },
    helperLabel: {
        marginTop: '5px',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontStyle: 'normal',
        lineHeight: 'normal',
        textDecoration: 'underline'
    }
}));

const ResetButton = styled(Button)({
    textTransform: 'none',
    boxShadow: 'none',
    fontSize: 16,
    fontWeight: 'bolder',
    width: '15%',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#fff',
    borderColor: '#FA3F3F',
    color: '#FA3F3F',
    fontFamily: 'Poppins',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    '&:hover': {
        backgroundColor: '#fff',
        borderColor: '#FA3F3F',
        boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.24)',
    },
});

const SaveButton = styled(Button)({
    textTransform: 'none',
    boxShadow: 'none',
    fontSize: 16,
    fontWeight: 'bolder',
    width: '15%',
    border: '1px solid #149BA1',
    lineHeight: 1.5,
    backgroundColor: '#E2E2E2',
    color: '#030229',
    fontFamily: 'Poppins',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    '&:hover': {
        backgroundColor: '#E2E2E2',
        borderColor: '#149BA1',
        boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.24)',
    },
});

const monthMap = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
};

const EditModal = ({ modalStatus, setModalStatus, stageSchema, stageValues, handleSubmission }) => {

    const classes = useStyles();
    const [schemaValue, setSchemaValue] = useState({});
    const [schemaProps, setSchemaProps] = useState([])
    const [dateValue, setDateValue] = useState(null);
    const [validationSchema, setValidationSchema] = useState(null);
    const [confirmSubmission, setConfirmSubmission] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const formik = useFormik({
        validationSchema,
        initialValues: {...schemaValue.activityBreakDown},
        onSubmit: (values) => {
            setConfirmSubmission(true);
        },
    });

    useEffect(() => {
        if (stageSchema) {
            setSchemaProps(stageSchema)
        }
        if (stageValues && stageValues.date) {
            const [month, year] = stageValues?.date?.split("_")
            // setDateValue(dayjs().year(year).month(monthMap[month]))
            setDateValue(dayjs().year(yearConversion(year, month)).month(monthMap[month]))
            setSchemaValue(stageValues)
            formik.setValues({...stageValues?.activityBreakDown, location: stageValues?.location, locationId: stageValues?.locationId, month: month, year: parseInt(year)})
        }
    }, [stageSchema, stageValues])

    const handleDatePicker = (event, item) => {
        if (event) {
            setDateValue(event);
            formik.values["month"] = event["$d"].toString().split(" ")[1];
            // formik.values["year"] = parseInt(event["$d"].toString().split(" ")[3]);
            formik.values["year"] = financialYearConversion(event);
        } else {
            setDateValue(null);
            formik.values["month"] = "";
            formik.values["year"] = "";
        }
    };

    const handleClose = () =>{
        setModalStatus(false)
        setConfirmSubmission(false)
        formik.resetForm();
    }

    const handleDropdownChange = (event, itemData) => {
        const { value } = event.target;
        let locationName = itemData.filter((item) => item.id === value)[0].name;

        formik.setFieldValue("locationId", value);
        formik.setFieldValue("location", locationName);
    };

    return (
        <div>
            <Modal
                onClose={() => setModalStatus(false)}
                open={modalStatus}
                sx={{ border: 0 }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <>
                    <Box sx={style}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', my: 1}}>
                            <Typography sx={{ textAlign: 'center', textOverflow: 'ellipsis', overflow: 'hidden', width: "90%", whiteSpace: '' }}>{`${schemaValue?.stageName} stage batch data of ${stringWithUnderScore(schemaValue?.date)}`}</Typography>
                            <IconButton aria-label="close" onClick={handleClose}>
                                <CloseRoundedIcon />
                            </IconButton>
                        </Box>
                        <Box sx={{ p: 3, height: "85%", overflowY: 'auto'}} >
                            <FormikProvider value={formik}>
                                <Grid container spacing={3} mb={3} >
                                    {schemaProps
                                        ?.filter(
                                            (item) =>
                                                item.ctrlName !== "month" &&
                                                item.category !== "confidence" &&
                                                item.category !== "stage" &&
                                                item.category !== "normal" &&
                                                item.category !== "dynamic"
                                        )
                                        ?.map((item) => (
                                            <Grid item xs={12} sm={6} key={item.ctrlName}>
                                                <>
                                                    {item.ctrlName === "year" && (
                                                        <div>
                                                            <Typography className={classes.inputLabel}>
                                                                {item.ctrlDisplayName?.split("(")[0] ||
                                                                    item.ctrlDisplayName}
                                                            </Typography>
                                                            <FormControl
                                                                fullWidth
                                                                variant="outlined"
                                                                size="small"
                                                            >
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        id="date"
                                                                        value={dateValue}
                                                                        onChange={(event) =>
                                                                            handleDatePicker(event, item)
                                                                        }
                                                                        views={["month", "year"]}
                                                                        sx={{
                                                                            boxShadow:
                                                                                "0px 4px 30px 0px rgba(0, 0, 0, 0.15)",
                                                                            borderRadius: 1,
                                                                        }}
                                                                        slotProps={{
                                                                            textField: {
                                                                                placeholder: "Month & Year",
                                                                            },
                                                                        }}
                                                                    />
                                                                </LocalizationProvider>
                                                            </FormControl>
                                                        </div>
                                                    )}
                                                    {item.ctrlType === "dropdown" && (
                                                        <div>
                                                            <Typography className={classes.inputLabel}>
                                                                {item.ctrlDisplayName?.split("(")[0] ||
                                                                    item.ctrlDisplayName}
                                                            </Typography>
                                                            <FormControl fullWidth variant="outlined">
                                                                <TextField
                                                                    select
                                                                    variant="outlined"
                                                                    sx={{
                                                                        boxShadow:
                                                                            "0px 4px 30px 0px rgba(0, 0, 0, 0.15)",
                                                                        borderRadius: 1,
                                                                    }}
                                                                    labelId={`${item.ctrlName}-label`}
                                                                    id={item.ctrlName}
                                                                    name={item.ctrlName}
                                                                    value={formik.values["locationId"] || ""}
                                                                    onChange={(event) =>
                                                                        handleDropdownChange(event, item.ctrlDropdown)
                                                                    }
                                                                >
                                                                    {item.ctrlDropdown.map((locationObj) => (
                                                                        <MenuItem
                                                                            key={locationObj.id}
                                                                            value={locationObj.id}
                                                                        >
                                                                            {locationObj.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </FormControl>
                                                        </div>
                                                    )}
                                                    {item.ctrlType === "textbox" &&
                                                        item.ctrlName !== "year" && (
                                                            <div
                                                                style={{
                                                                    alignItems: "start",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                }}
                                                            >
                                                                {item.ctrlDisplayName.includes("Food travel") ? (
                                                                    <Typography className={classes.inputLabel}>
                                                                        {item.ctrlDisplayName
                                                                            ?.split(" ")
                                                                            .slice(0, 5)
                                                                            .join(" ") || item.ctrlDisplayName}
                                                                    </Typography>
                                                                ) : (
                                                                    <Typography className={classes.inputLabel}>
                                                                        {item.ctrlDisplayName?.split("(")[0] ||
                                                                            item.ctrlDisplayName}
                                                                    </Typography>
                                                                )}
                                                                <FormControl fullWidth variant="outlined">
                                                                    <TextField
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        id={item.ctrlName}
                                                                        name={item.ctrlName}
                                                                        value={formik.values[item.ctrlName] || ""}
                                                                        onChange={formik.handleChange}
                                                                        sx={{
                                                                            boxShadow:
                                                                                "0px 4px 30px 0px rgba(0, 0, 0, 0.15)",
                                                                            borderRadius: 1,
                                                                        }}
                                                                        onBlur={formik.handleBlur}
                                                                        error={
                                                                            formik.touched[item.ctrlName] &&
                                                                            Boolean(formik.errors[item.ctrlName])
                                                                        }
                                                                    />
                                                                    <Typography className={classes.helperLabel}>
                                                                        {item.ctrlDisplayName
                                                                            ?.split("(")[1]
                                                                            ?.split(")")[0]
                                                                            ?.split(" ")[1] || ""}
                                                                    </Typography>
                                                                </FormControl>
                                                            </div>
                                                        )}
                                                </>
                                            </Grid>
                                        ))}
                                </Grid>
                            </FormikProvider>
                        </Box>
                        <Box sx={classes.buttonGroup} >
                            {confirmSubmission ? (
                                <div className={classes.buttonGroup}>
                                    <ResetButton
                                        variant="contained"
                                        onClick={() => setConfirmSubmission(false)}
                                    >
                                        <Typography fontFamily="Raleway" fontWeight={600}>
                                            No
                                        </Typography>
                                    </ResetButton>
                                    <SaveButton
                                        type="button"
                                        onClick={()=> handleSubmission(formik.values)}
                                        variant="contained"
                                        startIcon={
                                            showLoader ? (
                                                <CircularProgress size={20} style={{ color: "white" }} />
                                            ) : null
                                        }
                                    >
                                        <Typography fontFamily="Raleway" fontWeight={600}>
                                            Yes
                                        </Typography>
                                    </SaveButton>
                                </div>
                            ) : (
                                <div className={classes.buttonGroup}>
                                    <ResetButton
                                        variant="outlined"
                                        onClick={handleClose}
                                        // disabled={confirmSubmission}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="21"
                                            height="21"
                                            viewBox="0 0 21 24"
                                            fill="none"
                                        >
                                            <path
                                                d="M9.09375 23.125C6.53125 22.9167 4.375 21.8646 2.625 19.9688C0.875 18.0729 0 15.8229 0 13.2188C0 11.6146 0.369792 10.1042 1.10938 8.6875C1.84896 7.27083 2.88542 6.11458 4.21875 5.21875L5.5625 6.5625C4.39583 7.25 3.48958 8.19271 2.84375 9.39062C2.19792 10.5885 1.875 11.8646 1.875 13.2188C1.875 15.3021 2.5625 17.1042 3.9375 18.625C5.3125 20.1458 7.03125 21.0208 9.09375 21.25V23.125ZM10.9688 23.125V21.25C13.0521 21 14.7708 20.1198 16.125 18.6094C17.4792 17.099 18.1562 15.3021 18.1562 13.2188C18.1562 10.9479 17.3698 9.02604 15.7969 7.45312C14.224 5.88021 12.3021 5.09375 10.0312 5.09375H9.40625L11.2812 6.96875L9.9375 8.3125L5.78125 4.15625L9.9375 0L11.2812 1.34375L9.40625 3.21875H10.0312C12.8229 3.21875 15.1875 4.19271 17.125 6.14062C19.0625 8.08854 20.0312 10.4479 20.0312 13.2188C20.0312 15.8229 19.1615 18.0729 17.4219 19.9688C15.6823 21.8646 13.5312 22.9167 10.9688 23.125Z"
                                                fill="#FA3F3F"
                                            />
                                        </svg>
                                        <Typography fontFamily="Raleway" fontWeight={600}>
                                            Close
                                        </Typography>
                                    </ResetButton>
                                    <SaveButton
                                        type="submit"
                                        onClick={() => formik.handleSubmit()}
                                        variant="contained"
                                    >
                                        <Typography fontFamily="Raleway" fontWeight={600}>
                                            Update
                                        </Typography>
                                    </SaveButton>
                                </div>
                            )}
                        </Box>
                    </Box>
                </>
            </Modal>
        </div>
    )
}

export default EditModal