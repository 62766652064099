import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Checkbox,
  ListItemText,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import * as yup from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";

import {
  NOTIFICATION_ERROR,
  NOTIFICATION_SUCCESS,
} from "../../../utils/constants/NotificationConstants";
import * as subsService from "../../../services/subscription";
import * as rolesService from "../../../services/rolesAndPermissions";

export default function AddNewModule({ openDialog, toggleDialog, moduleData }) {
  const [roles, setRoles] = useState([]);
  const [subs, setSubs] = useState([]);
  const [subModules, setSubModules] = useState(
    moduleData?.subModules || [{ subModuleName: "" }]
  );
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = yup.object({
    module_name: yup.string().required("Please enter module name"),
    roles: yup.array().min(1).required("Please select roles"),
    subs: yup.array().required("Please select subs"),
    display_order: yup.string().required("Please enter display order"),
    subModules: yup
      .array()
  });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      module_name: moduleData?.moduleName || "",
      roles: (moduleData?.roles || []).map((e) => ({
        ...e,
        roleId: Number(e.roleId),
      })),
      subs: (moduleData?.subs || []).map((e) => ({
        ...e,
        subsId: Number(e.subsId),
      })),
      display_order: moduleData?.displayOrder || 1,
      subModules: moduleData?.subModules || [{ subModuleName: "" }],
    },
    onSubmit: () => {
      const moduleObj = {
        moduleName: formik.values.module_name,
        roles: formik.values.roles,
        subs: formik.values.subs,
        displayOrder: formik.values.display_order,
        subModules: subModules,
      };

      if (moduleData?.moduleName) {
        moduleObj.moduleId = moduleData.moduleId;
        rolesService
          .updateModule(moduleObj)
          .then((response) => {
            enqueueSnackbar("Module Updated Successfully", {
              autoHideDuration: 3000,
              variant: NOTIFICATION_SUCCESS,
            });

            formik.resetForm();
            toggleDialog(false);
          })
          .catch((err) => {
            enqueueSnackbar(
              err.message ||
                err.msg ||
                "Error occurred while updating the module",
              {
                autoHideDuration: 3000,
                variant: NOTIFICATION_ERROR,
              }
            );
          });
      } else {
        rolesService
          .createModule(moduleObj)
          .then((response) => {
            enqueueSnackbar("Module Created Successfully", {
              autoHideDuration: 3000,
              variant: NOTIFICATION_SUCCESS,
            });

            formik.resetForm();
            toggleDialog(false);
          })
          .catch((err) => {
            enqueueSnackbar(
              err.message || err.msg || "Error occurred while creating module",
              {
                autoHideDuration: 3000,
                variant: NOTIFICATION_ERROR,
              }
            );
          });
      }
    },
  });

  useEffect(() => {
    getAllRoles();
    getAllSubs();
  }, []);

  const getAllRoles = () => {
    rolesService.getRole().then((rolesList) => {
      setRoles(
        rolesList.map((role) => ({
          roleId: role.roleId,
          roleName: role.roleName,
        }))
      );
    });
  };

  const getAllSubs = () => {
    subsService.getSubscriptions().then((subsList) => {
      setSubs(subsList);
    });
  };

  const dialogCancel = (e) => {
    toggleDialog(false);
  };

  const handleSelect = ({ target: { value } }) => {
    const objCount = {};
    for (let i = 0; i < value.length; i++) {
      objCount[value[i].roleId] = (objCount[value[i].roleId] || 0) + 1;
    }
    const newArr = [];
    for (let [key, val] of Object.entries(objCount)) {
      if (val < 2) {
        newArr.push(value.find((e) => e.roleId == key));
      }
    }
    formik.setFieldValue("roles", newArr);
  };

  const handleSubsSelect = ({ target: { value } }) => {
    const objCount = {};
    for (let i = 0; i < value.length; i++) {
      objCount[value[i].subsId] = (objCount[value[i].subsId] || 0) + 1;
    }
    const newArr = [];
    for (let [key, val] of Object.entries(objCount)) {
      if (val < 2) {
        newArr.push(value.find((e) => e.subsId == key));
      }
    }
    formik.setFieldValue("subs", newArr);
  };

  const addSubModule = () => {
    setSubModules([...subModules, { subModuleName: "" }]);
  };

  const removeSubModule = (index) => {
    const updatedSubModules = [...subModules];
    updatedSubModules.splice(index, 1);
    setSubModules(updatedSubModules);
  };

  const handleSubModuleChange = (index, value) => {
    const updatedSubModules = [...subModules];
    updatedSubModules[index].subModuleName = value;
    setSubModules(updatedSubModules);
  };

  return (
    <Dialog open={openDialog} fullWidth={true} maxWidth={"md"}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          <Typography variant="h4">
            {moduleData?.moduleId ? "Edit Module" : "Create New Module"}
          </Typography>
        </DialogTitle>

        <Divider></Divider>

        <DialogContent>
          <Stack spacing={2}>
            <FormControl fullWidth>
              <TextField
                autoFocus
                id="module_name"
                label="Module Name"
                type="text"
                variant="standard"
                name="module_name"
                value={formik.values.module_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.module_name &&
                  Boolean(formik.errors.module_name)
                }
                helperText={
                  formik.touched.module_name && formik.errors.module_name
                }
              />
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="roles">Roles</InputLabel>
              <Select
                id="roles"
                multiple
                name="roles"
                value={formik.values.roles}
                onChange={handleSelect}
                renderValue={(selected) =>
                  selected.map((x) => x.roleName).join(", ")
                }
                error={formik.touched.roles && Boolean(formik.errors.roles)}
                helperText={formik.touched.roles && formik.errors.roles}
              >
                {roles.map((role) => (
                  <MenuItem key={role.roleId} value={role}>
                    <Checkbox
                      checked={
                        !!(formik?.values?.roles || []).find(
                          (item) => item.roleId === role.roleId
                        )
                      }
                    />
                    <ListItemText primary={role.roleName} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="subs">Subscriptions</InputLabel>
              <Select
                multiple
                id="subs"
                name="subs"
                label="Subscription"
                value={formik.values.subs}
                onChange={handleSubsSelect}
                renderValue={(selected) =>
                  selected.map((x) => x.subsName).join(", ")
                }
                error={formik.touched.subs && Boolean(formik.errors.subs)}
                helperText={formik.touched.subs && formik.errors.subs}
              >
                {subs.map((sub) => (
                  <MenuItem key={sub.subsId} value={sub}>
                    <Checkbox
                      checked={
                        !!formik?.values?.subs?.find(
                          (item) => item.subsId === sub.subsId
                        )
                      }
                    />
                    <ListItemText primary={sub.subsName} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <TextField
                id="display_order"
                label="Display Order"
                type="text"
                variant="standard"
                name="display_order"
                value={formik.values.display_order}
                onChange={formik.handleChange}
                error={
                  formik.touched.display_order &&
                  Boolean(formik.errors.display_order)
                }
                helperText={
                  formik.touched.display_order && formik.errors.display_order
                }
              />
            </FormControl>

            {subModules.map((subModule, index) => (
              <Stack key={index} direction="row" alignItems="center" spacing={1}>
                <FormControl fullWidth>
                  <TextField
                    id={`subModules[${index}].subModuleName`}
                    label="Sub Module Name"
                    type="text"
                    variant="standard"
                    value={subModule.subModuleName}
                    onChange={(e) =>
                      handleSubModuleChange(index, e.target.value)
                    }
                    error={
                      formik.touched.subModules &&
                      Boolean(formik.errors.subModules)
                    }
                    helperText={
                      formik.touched.subModules &&
                      formik.errors.subModules &&
                      formik.errors.subModules[index]?.subModuleName
                    }
                  />
                </FormControl>
                <IconButton
                  color="secondary"
                  onClick={() => removeSubModule(index)}
                >
                  <RemoveIcon />
                </IconButton>
              </Stack>
            ))}
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={addSubModule}
            >
              Add Sub Module
            </Button>
          </Stack>
        </DialogContent>

        <Divider></Divider>

        <DialogActions>
          <Button onClick={dialogCancel}>Cancel</Button>
          <Button type="submit">
            {moduleData?.moduleName ? "Update Module" : "Create Module"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
