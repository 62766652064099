import { useEffect, useState } from "react";
import { getScenarioById } from "../../../services/scenario/scenario";
import { useNavigate, useParams } from "react-router-dom";
import ReactECharts from 'echarts-for-react';
import { Paper, Typography, Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Iconify from "../../../components/iconify";

// Custom styles
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  borderRadius: "15px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#ffffff",
  border: "1px solid #e0e0e0"
}));

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  fontFamily: 'Poppins, sans-serif',
  backgroundColor: '#ffffff',
}));

const MonthNames = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const transformTargetData = (data) => {
  const transformed = {};

  for (const scope in data) {
    transformed[scope] = {
      currentYear: data[scope].currentYear,
      yearlyTargets: {}
    };

    for (const year in data[scope].yearlyTargets) {
      const yearlyValue = parseFloat(data[scope].yearlyTargets[year]);
      const monthlyValue = (yearlyValue / 12).toFixed(4);

      transformed[scope].yearlyTargets[year] = {};

      MonthNames.forEach(month => {
        transformed[scope].yearlyTargets[year][month] = monthlyValue;
      });
    }
  }

  return transformed;
};

const prepareChartData = (transformedTargetData, activityHistoricalData, activityForecastData, activity) => {
  let allDates = new Set();

  const collectDates = (data) => {
    Object.keys(data).forEach(year => {
      Object.keys(data[year]).forEach(month => {
        allDates.add(`${year}-${month}`);
      });
    });
  };

  collectDates(transformedTargetData[activity].yearlyTargets);
  if (activityHistoricalData && activityHistoricalData[activity]) {
    collectDates(activityHistoricalData[activity]);
  }
  if (activityForecastData && activityForecastData[activity]) {
    collectDates(activityForecastData[activity]);
  }

  const xAxisData = Array.from(allDates).sort();

  const targetData = new Array(xAxisData.length).fill(null);
  const historicalData = new Array(xAxisData.length).fill(null);
  const forecastData = new Array(xAxisData.length).fill(null);
  const lowerBoundData = new Array(xAxisData.length).fill(null);
  const upperBoundData = new Array(xAxisData.length).fill(null);

  const isValidValue = (value) => value !== null && value !== undefined && value !== '' && !isNaN(value);

  xAxisData.forEach((date, index) => {
    const [year, month] = date.split('-');

    if (transformedTargetData[activity].yearlyTargets[year] && transformedTargetData[activity].yearlyTargets[year][month]) {
      targetData[index] = parseFloat(transformedTargetData[activity].yearlyTargets[year][month]);
    }

    if (activityHistoricalData && activityHistoricalData[activity] && activityHistoricalData[activity][year] && activityHistoricalData[activity][year][month]) {
      const value = parseFloat(activityHistoricalData[activity][year][month]);
      historicalData[index] = isValidValue(value) ? value.toFixed(2) : null;
    }

    if (activityForecastData && activityForecastData[activity] && activityForecastData[activity][year] && activityForecastData[activity][year][month]) {
      const forecast = activityForecastData[activity][year][month];
      forecastData[index] = isValidValue(forecast.value) ? parseFloat(forecast.value).toFixed(2) : null;
      lowerBoundData[index] = isValidValue(forecast.lowerbound) ? parseFloat(forecast.lowerbound).toFixed(2) : null;
      upperBoundData[index] = isValidValue(forecast.upperbound) ? parseFloat(forecast.upperbound).toFixed(2) : null;
    }
  });

  return {
    xAxis: {
      type: 'category',
      data: xAxisData,
      name: 'Year-Month'
    },
    yAxis: {
      type: 'value',
      name: 'Value'
    },
    series: [
      { name: 'Target', type: 'line', data: targetData, smooth: true, color: '#00C9D2' },
      { name: 'Historical', type: 'line', data: historicalData, smooth: true, color: '#FF5722' },
      { name: 'Forecast', type: 'line', data: forecastData, smooth: true, color: '#4CAF50' },
      { name: 'Lower Bound', type: 'line', data: lowerBoundData, smooth: true, color: '#FFC107' },
      { name: 'Upper Bound', type: 'line', data: upperBoundData, smooth: true, color: '#9C27B0' },
    ],
    tooltip: {
      trigger: 'axis'
    },
    toolbox: {
      show: true,
      feature: {
        dataZoom: {
          yAxisIndex: 'none'
        },
        dataView: { readOnly: false },
        magicType: { type: ['line', 'bar'] },
        saveAsImage: {}
      }
    },
    legend: {
      data: ['Target', 'Historical', 'Forecast', 'Lower Bound', 'Upper Bound'],
      textStyle: { color: '#333' }
    }
  };
};

export default function ActivityScenarioGraph() {
  const { scenarioId } = useParams();
  const navigate = useNavigate()
  const [scenarioData, setScenarioData] = useState(null);
  const [targetData, setTargetData] = useState(null);
  const [transformedTargetData, setTransformedTargetData] = useState(null);
  const [activityHistoricalData, setActivityHistoricalData] = useState(null);
  const [activityForecastData, setActivityForecastData] = useState(null);
  const [chartOptions, setChartOptions] = useState({});

  const getAllScenarios = async () => {
    try {
      const options = { scenarioId };
      const response = await getScenarioById(options);

      if (response) {
        setScenarioData(response?.data[0]?.data);
        const rawTargetData = response?.data[0]?.data?.scenarios[0]?.targets.activities;
        setTargetData(rawTargetData);
        setTransformedTargetData(transformTargetData(rawTargetData));
        setActivityHistoricalData(response?.data[0]?.data?.data.activities.historical);
        setActivityForecastData(response?.data[0]?.data?.data?.activities.forecast);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllScenarios();
  }, [scenarioId]);

  useEffect(() => {
    if (transformedTargetData && activityHistoricalData && activityForecastData) {
      const activityChartOptions = {};
      Object.keys(transformedTargetData).forEach(activity => {
        activityChartOptions[activity] = prepareChartData(transformedTargetData, activityHistoricalData, activityForecastData, activity);
      });
      setChartOptions(activityChartOptions);
    }
  }, [transformedTargetData, activityHistoricalData, activityForecastData]);

  return (
    <Container>
      <Button
        sx={{
          backgroundColor: "#222",
          "&:hover": { backgroundColor: "#149BA1" },
          color: "#fff",
          marginRight: "20px",
        }}
        onClick={() => navigate(-1)}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Iconify icon="icon-park-outline:back" color="white" />
          <Typography
            fontFamily="Poppins"
            style={{ textTransform: "capitalize", marginLeft: "0.5rem" }}
          >
            Back
          </Typography>
        </div>
      </Button>
      <Typography
        sx={{ fontFamily: 'poppins', fontSize: '25px', fontWeight: 600, marginBottom: '25px', textAlign: 'center' }}
      >
        Activity Scenario Graphs for {scenarioData?.location || 'Loading...'}
      </Typography>
      {Object.keys(chartOptions).length > 0 ? (
        Object.keys(chartOptions).map(scope => (
          <StyledPaper key={scope}>
            <Typography
              sx={{
                '&::first-letter': {
                  textTransform: 'capitalize',
                }, textTransform: 'lowercase', fontFamily: 'poppins', fontSize: '25px', fontWeight: 600, marginBottom: '25px', textAlign: 'center'
              }}
            >
              {scope.replace(/_/g, ' ')}
            </Typography>
            <ReactECharts option={chartOptions[scope]} style={{ height: '400px' }} />
          </StyledPaper>
        ))
      ) : (
        <Typography variant="body1">Loading charts...</Typography>
      )}
    </Container>
  );
}
