import { redirect } from 'react-router-dom';
import { APP_KEYS } from '../utils/constants/AppKeyConstants';

/**
 * @description Guard function to check whether user has access to the route or not
 * 
 * @param {String} moduleName
 * @returns {Function}
 */
const protectedLoader = (moduleName) => ({ request }) => {
    const user = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));

    // If the user is not logged in, we redirect
    // them to `/login` with a `from` parameter that allows login to redirect back
    // to this page upon successful authentication

    if (!user) {
        let params = new URLSearchParams();
        params.set('from', new URL(request.url).pathname);
        return redirect('/login?' + params.toString());
    }

    // check user has access to current route or not
    if (moduleName && !(user?.modules || []).some(mod => mod.moduleName === moduleName)) {
        alert('You don\'t have access to view the content');

        // User don't have access to the requested route to direct him to the home page
        // Re direct user to corresnpoding to logged in user
        let toURL = '';

        if ((user?.roles).find(e => e.roleName === 'Super Admin'))
            toURL = '/carboncompete/sp-dashboard';
        else if ((user?.roles).find(e => e.roleName === 'Admin'))
            toURL = '/carboncompete/dashboard';
        else if ((user?.roles).find(e => e.roleName === 'ESG'))
            toURL = '/carboncompete/esgdashboard';
        else if ((user?.roles).find(e => e.roleName === 'Corporate Supplier'))
            toURL = '/carboncompete/suppliercorporatecanvas';
        else if ((user?.roles).find(e => e.roleName === 'Corporate Ghg'))
            toURL = '/carboncompete/corporatedashboard';
        return redirect(toURL);
    }

    // Set Page Title on each page route change
    document.title = moduleName;

    return user;
};

const closeAlertAfterDelay = () => {
    setTimeout(() => {
        const alertElement = document.querySelector('.alert');
        if (alertElement) {
            alertElement.remove(); // Remove the alert from DOM
        }
    }, 5000); // 5 seconds delay
}

/**
 * 
 * @description Guard function to redirect to dashboard page if user already loggedin
 * 
 * @param {*} param0 
 */
const unProtectedPageLoaded = ({ request }) => {
    const user = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));

    if (user) {
        return redirect('/carboncompete/dashboard');
    }
    return user;
}

export const roleGuard = {
    protectedLoader,
    unProtectedPageLoaded
};
