import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  List,
  Typography,
  Collapse,
} from "@mui/material";
import { StyledNavItem, StyledNavItemIcon, StyledSubNavItem } from "./styles";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { SvgIcon } from '@mui/material';
import { BorderBottom } from "@material-ui/icons";

const GradientExpandLessIcon = (props) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <defs>
        <linearGradient id="expandMoreGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#05D776" />
          <stop offset="100%" stopColor="#149BA1" />
        </linearGradient>
      </defs>
      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" fill="url(#expandMoreGradient)" />
    </svg>
  </SvgIcon>
);

const navitem = {
  active: {
    color: "#fff",
    fontWeight: "900",
    fontFamily: "Poppins",
    marginTop: "3px",
  },
  inactive: {
    color: "white",
    fontWeight: "100",
    fontFamily: "Poppins",
    background: "",
    backdropFilter: "",
    marginTop: "3px",
  },
};

export default function NavSection({
  data = [],
  isDesktop,
  showText,
  ...other
}) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ py: 1 }}>
        {data.map((item) => (
          <NavItem
            key={item.title}
            item={item}
            isDesktop={isDesktop}
            showText={showText}
          />
        ))}
      </List>
    </Box>
  );
}

NavSection.propTypes = {
  data: PropTypes.array,
  isDesktop: PropTypes.bool.isRequired,
  showText: PropTypes.bool.isRequired,
};

export function NavItem({ item, isDesktop, showText }) {
  const { title, path, component, icon, subModules } = item;
  const locationurl = window.location.pathname;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate(null);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleNavigate = () => {
    navigate(path);
  };

  return (
    <>
      <StyledNavItem
        onClick={subModules.length ? handleClick : handleNavigate}
        sx={{
          ...(locationurl.indexOf(component) !== -1 || open
            ? {
                backdropFilter: "saturate(180%) blur(10px)",
                background: "rgba(34, 34, 34, 1)",
                borderRadius: "5px",
              }
            : {}),
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "42px", // Correct height property
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <StyledNavItemIcon
            className="nav-item-icons"
            sx={{
              marginRight: "1px",
              width: "20px",
              height: "20px",
              color:locationurl.indexOf(component) !== -1 || open ? "rgba(5, 215, 118, 1)": "black"
            }}
          >
            {icon}
          </StyledNavItemIcon>

          {(!showText || !isDesktop) && (
            <Typography
              variant="body2"
              sx={{
                ...(locationurl.indexOf(component) !== -1 || open
                  ? {
                      background: 'linear-gradient(90deg, rgba(5, 215, 118, 1) 0%, rgba(20, 155, 161, 1) 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      backgroundClip: 'text',
                      textFillColor: 'transparent',
                    }
                  : {}),
                fontWeight: 600,
                fontFamily: "Poppins",
                fontSize: "12px",
                color: "#202A2B",
                lineHeight: "28px",
                textAlign: "left",
                letterSpacing: '-0.02em',
              }}
            >
              {title}
            </Typography>
          )}
        </Box>
        {subModules.length ? open ? <GradientExpandLessIcon /> : <ArrowForwardIosIcon sx={{ height: "16px" }} /> : null}
      </StyledNavItem>
      {subModules.length ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subModules.map((item, idx) => (
              <SubModulesNav
                key={idx}
                item={item}
                isDesktop={isDesktop}
                showText={showText}
              />
            ))}
          </List>
        </Collapse>
      ) : null}
    </>
  );
}


function SubModulesNav({ item, showText, isDesktop }) {

  const {title, path, icon, component} = item
  const locationurl = window.location.pathname;
  return (
    <StyledSubNavItem
  component={RouterLink}
  to={path}
  sx={{
    ...(locationurl.indexOf(component) !== -1 
        ? navitem.active
        : navitem.inactive),
        width: '180px',
        height: '42px',
        backgroundColor: '#FFFFFF',
        ml: 4,
        position: 'relative',
        '::before': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '4px',
          background:locationurl.indexOf(component) !== -1? 'linear-gradient(90deg, rgba(5, 215, 118, 1) 0%, rgba(20, 155, 161, 1) 100%)':"white",
          borderBottomLeftRadius: '16px', 
          borderBottomRightRadius: '16px', 
          borderTopLeftRadius: '0px',     
      borderTopRightRadius: '0px',    

        },
  }}
>
  <Box sx={{ display: "flex", alignItems: "center" }}>
    <StyledNavItemIcon
      className="nav-item-icons"
      sx={{
        ...(locationurl.indexOf(component) !== -1
        ? {}
        : {}),
        color:"black"
      }}
    >
      {icon}
    </StyledNavItemIcon>

    {(!showText || !isDesktop) && (
      <Typography
        variant="body2"
        sx={{
          fontWeight: 400,
          fontFamily: "poppins",
          fontSize: "12px",
          color: "#202A2B",
          lineHeight: "28px",
          textAlign: "left",
          letterSpacing: '-0.02em'
        }}
      >
        {String(title)}
      </Typography>
    )}
  </Box>
</StyledSubNavItem>

  );
}
NavItem.propTypes = {
  item: PropTypes.object,
  isDesktop: PropTypes.bool.isRequired,
  showText: PropTypes.bool.isRequired,
};
