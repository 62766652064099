import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  capitalize,
  Divider,
  Box,
  FormHelperText,
  Tabs,
  Tab,
  Checkbox,
  Typography,
  FormGroup,
  FormControlLabel,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { getAllSuppliers } from "../../../services/suppliers";
import SwipeableViews from "react-swipeable-views";
import {
  postGenerateActivityObj,
  postGenerateStageSchema,
} from "../../../services/external";
import SearchIcon from "@mui/icons-material/Search";
import {
  NOTIFICATION_ERROR,
  NOTIFICATION_SUCCESS,
} from "../../../utils/constants/NotificationConstants";
import BomTable from "./BomModal";

function a11yProps(index) {
  return {
    id: `add-node-full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const SupplierConfigureModal = ({
  stageData,
  openDialog,
  toggleDialog,
  stageProps,
  setStageProps,
  setNodes,
  nodes,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [suppliersList, setSuppliersList] = useState([]);
  // const [activities, setActivities] = useState(
  //   // stageData.templateSchemaProp || []
  //   []
  // );
  const [activities, setActivities] = useState(() =>
    stageData?.templateSchemaProp?.filter(
      (prop) =>
        prop.category !== "normal" &&
        prop.category !== "stage" &&
        prop.category !== "confidence"
    )
  );
  const [displayNames, setDisplayedNames] = useState(
    activities.map((item) => item.ctrlDisplayName) || []
  );
  const [bom, setBom] = useState(stageData?.bom );
  const [bomDisplayNames, setBomDisplayNames] = useState(
    bom.map((item) => item.componentName) || []
  );


  const [checkedActivities, setCheckedActivities] = useState(
    stageData.templateSchemaProp || []
  );

  const [checkedBom, setCheckedBom] = useState(stageData?.bom || []);
  const [value, setValue] = useState(0);
  const validationSchema = yup.object({
    // stageName: yup.string().required("Please enter stage name"),
    // lifecyclestage: yup.string().required("Please enter stage type"),
    // stageDescription: yup.string().required("Please enter the description"),
    // stageLocation: yup.string().required("Please enter the location"),
    supplier_id: yup.string().required("Please select the supplier"),
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const onSubmitForm = () => {
    if (formik.isValid) {
      let selectedSupplier = suppliersList?.find(
        (supplier) => supplier.userId === formik.values.supplier_id
      );
      let exsit = stageProps?.find(
        (prop) => prop.stageID === formik.values.stageID
      ); // check if user is trying to edit the already added supplier
      if (exsit) {
        exsit.supplierId = formik.values.supplier_id;
        exsit.supplierName = selectedSupplier ? selectedSupplier.userName : "";
        let modify = stageProps?.map((prop) => {
          if (prop.stageID === exsit.stageID) {
            return exsit;
          } else {
            return prop;
          }
        });
        setStageProps(modify);
      } else {
        let emitObj = {
          stageID: formik.values.stageID,
          stageName: formik.values.stageName,
          // lifecyclestage: formik.values.lifecyclestage,
          stageLocation: formik.values.stageLocation,
          stageDescription: formik.values.stageDescription,
          supplierId: formik.values.supplier_id,
          supplierName: selectedSupplier ? selectedSupplier.userName : "",
        };
        setStageProps((prev) => [...prev, emitObj]);
      }

      let updatedNodes = nodes?.map((node) => {
        if (node.id === formik.values.stageID) {
          return {
            ...node,
            data: {
              ...node.data,
              templateSchemaProp: checkedActivities,
            },
          };
        } else {
          return node;
        }
      });

      setNodes(updatedNodes);

      // Reset the form
      formik.resetForm();
      // Close the dialog
      toggleDialog(false);
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues: {
      stageID: "",
      stageName: "",
      lifecyclestage: "",
      stageDescription: "",
      stageLocation: "",
      supplier_id: "",
    },
    onSubmit: onSubmitForm,
  });

  const getSupplier = () => {
    let prop = stageProps.filter((prop) => prop.stageID === stageData.stageID);
    if (prop.length) {
      return prop[0].supplierId;
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (stageData && suppliersList.length > 0) {
      formik.setValues({
        stageID: stageData.stageID,
        stageName: stageData.stageName,
        lifecyclestage: stageData.lifecyclestage,
        stageDescription: stageData.stageDescription,
        stageLocation: stageData.stageLocation,
        supplier_id: stageProps.length ? getSupplier() : "",
      });
    }
  }, [stageData, suppliersList]);

  // useEffect(()=>{
  //   if(stageData.templateSchemaProp){
  //     setActivities(()=> stageData?.templateSchemaProp?.filter((prop)=> prop.category !== "normal" && prop.category !== "stage" && prop.category !== "confidence" ))
  //   }
  // },[stageData])

  const dialogCancel = (e) => {
    toggleDialog(false);
  };

  const getSuppliers = async () => {
    try {
      const response = await getAllSuppliers();
      setSuppliersList(response.data || []);
    } catch (err) {
      enqueueSnackbar("Unable to fetch suppliers data. Please try again", {
        autoHideDuration: 3000,
        variant: "warning",
      });
    }
  };

  useEffect(() => {
    getSuppliers();
  }, []);

  const handleActivityChange = (ctrlDisplayName) => {
    if (displayNames.includes(ctrlDisplayName)) {
      setDisplayedNames((prev) =>
        prev.filter((item) => item !== ctrlDisplayName)
      );
      setCheckedActivities((prev) =>
        prev.filter((item) => item.ctrlDisplayName !== ctrlDisplayName)
      );
    } else {
      setDisplayedNames((prev) => [...prev, ctrlDisplayName]);
      const activityToAdd = activities.find(
        (item) => item.ctrlDisplayName === ctrlDisplayName
      );
      setCheckedActivities((prev) => [...prev, activityToAdd]);
    }
  };

  const handleBomChange = (componentName) => {
    if (bomDisplayNames.includes(componentName)) {
      setBomDisplayNames((prev) =>
        prev.filter((item) => item !== componentName)
      );

      setCheckedBom((prev) =>
        prev.filter((item) => item.componentName !== componentName)
      );
    } else {
      setBomDisplayNames((prev) => [...prev, componentName]);
      const bomToAdd = bom.find((item) => item.componentName === componentName);
      setCheckedBom((prev) => [...prev, bomToAdd]);
    }
  };

  
  const [activityName, setActivityName] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSearchActivity = async () => {
    try {
      setLoading(true);
      const repsonse = await postGenerateActivityObj({ activityName });
      if (repsonse) {
        setActivities((prev) => [...prev, repsonse]);
        setCheckedActivities((prev) => [...prev, repsonse]);
        setDisplayedNames((prev) => [...prev, repsonse?.ctrlDisplayName]);
        setActivityName("");
        enqueueSnackbar(`${repsonse?.ctrlDisplayName} is added`, {
          autoHideDuration: 3000,
          variant: NOTIFICATION_SUCCESS,
        });
      } else {
        setActivityName("");
        enqueueSnackbar("Something went wrong", {
          autoHideDuration: 2000,
          variant: NOTIFICATION_ERROR,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      enqueueSnackbar("Something went wrong", {
        autoHideDuration: 2000,
        variant: NOTIFICATION_ERROR,
      });
    }
  };

  console.log(bom)

  return (
    <Dialog open={openDialog} fullWidth={true} maxWidth="md">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle my={1} variant="h6">
          Map the supplier
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            aria-label="full width tabs example"
            indicatorColor="primary"
          >
            <Tab label="Stage Details" {...a11yProps(0)} />
            <Tab label="Template Configuration" {...a11yProps(1)} />
            {bom.length>0 &&(<Tab label="Bill of Materials" {...a11yProps(2)} />)}
            
          </Tabs>
          <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
            <Box sx={{ bgcolor: "background.paper" }}>
              <Stack spacing={2} mt={2}>
                <FormControl fullWidth>
                  <TextField
                    disabled
                    id="stageName"
                    label="Stage Name"
                    type="text"
                    variant="outlined"
                    name="stageName"
                    value={formik.values.stageName}
                    onChange={formik.handleChange}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    disabled
                    id="lifecyclestage"
                    label="Stage Type"
                    type="text"
                    variant="outlined"
                    name="lifecyclestage"
                    value={formik.values.lifecyclestage}
                    onChange={formik.handleChange}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    disabled
                    id="stageLocation"
                    label="Stage Location"
                    type="text"
                    variant="outlined"
                    name="stageLocation"
                    value={formik.values.stageLocation}
                    onChange={formik.handleChange}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    disabled
                    multiline
                    rows={2}
                    id="stageDescription"
                    label="Stage Description"
                    type="text"
                    variant="outlined"
                    name="stageDescription"
                    value={formik.values.stageDescription}
                    onChange={formik.handleChange}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.supplier_id &&
                    Boolean(formik.errors.supplier_id)
                  }
                >
                  <InputLabel id="demo-simple-select-label">
                    Supplier
                  </InputLabel>
                  <Select
                    id="supplier_id"
                    label="Supplier"
                    name="supplier_id"
                    value={formik.values.supplier_id}
                    // onChange={handleSelectChange}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value="">Select a Supplier</MenuItem>
                    {suppliersList.map((user, index) => (
                      <MenuItem value={user.userId} key={index}>
                        {capitalize(user.userName) || "Supplier Name"}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.supplier_id && formik.errors.supplier_id}
                  </FormHelperText>
                </FormControl>
              </Stack>
            </Box>
            <Box maxHeight={"20vh"} pl={3}>
              <Box sx={{ bgcolor: "background.paper" }}>
                <Typography variant="h6">Activities</Typography>
                <Box
                  sx={{
                    display: "flex",
                    position: "sticky",
                    marginY: 3,
                    gap: 3,
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      id="activityName"
                      label="Add New Activity"
                      type="text"
                      variant="outlined"
                      name="activityName"
                      value={activityName}
                      onChange={(e) => setActivityName(e.target.value)}
                    />
                  </FormControl>
                  <Button
                    variant="outlined"
                    endIcon={<SearchIcon />}
                    onClick={handleSearchActivity}
                  >
                    Search
                  </Button>
                </Box>
                <FormGroup>
                  {activities.map((activity, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={displayNames.includes(
                            activity.ctrlDisplayName
                          )}
                          onChange={() =>
                            handleActivityChange(activity.ctrlDisplayName)
                          }
                          name={activity.ctrlDisplayName}
                        />
                      }
                      label={
                        capitalize(activity.ctrlDisplayName) || "Activity Name"
                      }
                    />
                  ))}
                </FormGroup>
              </Box>
            </Box>
            <Box  py={3} overflow="auto">
  
    <BomTable 
      bom={bom} 
      bomDisplayNames={bomDisplayNames} 
      handleBomChange={handleBomChange}
    />
  </Box>
          </SwipeableViews>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
            my={1}
          >
            <Button variant="outlined" onClick={dialogCancel}>
              Cancel
            </Button>
            <Button variant="outlined" type="submit" onClick={onSubmitForm}>
              Update
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export const StageConfigureModal = ({
  stageData,
  openDialog,
  toggleDialog,
  stageProps,
  setStageProps,
  setNewNodePayload,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [suppliersList, setSuppliersList] = useState([]);
  const [activities, setActivities] = useState([]);
  const [displayNames, setDisplayedNames] = useState([]);
  const [checkedActivities, setCheckedActivities] = useState([]); // final checked state
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [activityName, setActivityName] = useState("");
  const validationSchema = yup.object({
    stageName: yup.string().required("Please enter stage name"),
    stageDescription: yup.string().required("Please enter the description"),
    stageLocation: yup.string().required("Please enter the location"), // TODO: add supplier info setstageprop
    supplier_id: yup.string().required("Please select the supplier"),
    // lifecyclestage: yup.string().required("Please enter stage type"),
    // supplier_id: yup.string().required("Please select the supplier"),
  });

  const handleSearchActivity = async () => {
    try {
      setLoading(true);
      const repsonse = await postGenerateActivityObj({ activityName });
      if (repsonse) {
        setActivities((prev) => [...prev, repsonse]);
        setCheckedActivities((prev) => [...prev, repsonse]);
        setDisplayedNames((prev) => [...prev, repsonse?.ctrlDisplayName]);
        setActivityName("");
        enqueueSnackbar(`${repsonse?.ctrlDisplayName} is added`, {
          autoHideDuration: 3000,
          variant: NOTIFICATION_SUCCESS,
        });
      } else {
        setActivityName("");
        enqueueSnackbar("Something went wrong", {
          autoHideDuration: 2000,
          variant: NOTIFICATION_ERROR,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      enqueueSnackbar("Something went wrong", {
        autoHideDuration: 2000,
        variant: NOTIFICATION_ERROR,
      });
    }
  };

  const handleContinue = async () => {
    try {
      formik.setTouched({
        stageName: true,
        stageDescription: true,
        stageLocation: true,
        supplier_id: true,
      });
      const errors = await formik.validateForm();
      if (formik.isValid && Object.keys(errors).length === 0) {
        setLoading(true);
        const response = await postGenerateStageSchema({
          stageName: formik.values.stageName,
          stageDescription: formik.values.stageDescription,
          stageLocation: formik.values.stageLocation,
        });
        if (response?.data?.templateSchemaProp?.length) {
          setNewNodePayload(response);
          setDisplayedNames(
            response?.data?.templateSchemaProp?.map(
              (item) => item.ctrlDisplayName
            )
          );
          setActivities(response?.data?.templateSchemaProp);
          setCheckedActivities(response?.data?.templateSchemaProp);
          setValue(1);
          let selectedSupplier = suppliersList?.find(
            (supplier) => supplier.userId === formik.values.supplier_id
          );
          let emitObj = {
            stageID: response.data.stageID,
            stageName: response.data.stageName,
            // lifecyclestage: formik.values.lifecyclestage,
            stageLocation: response.data.stageLocation,
            stageDescription: response.data.stageDescription,
            supplierId: formik.values.supplier_id,
            supplierName: selectedSupplier ? selectedSupplier.userName : "",
          };
          setStageProps((prev) => [...prev, emitObj]);
        } else {
          enqueueSnackbar("Something went wrong", {
            autoHideDuration: 2000,
            variant: NOTIFICATION_ERROR,
          });
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      enqueueSnackbar("Something went wrong", {
        autoHideDuration: 2000,
        variant: NOTIFICATION_ERROR,
      });
    }
  };

  const onSubmitForm = () => {
    // if (formik.isValid) {
    //   let selectedSupplier = suppliersList?.find((supplier) => supplier.userId === formik.values.supplier_id);
    //   let exsit = stageProps?.find((prop) => prop.stageID === formik.values.stageID) // check if user is trying to edit the already added supplier
    //   if (exsit) {
    //     exsit.supplierId = formik.values.supplier_id
    //     exsit.supplierName = selectedSupplier ? selectedSupplier.userName : ""
    //     let modify = stageProps?.map((prop) => {
    //       if (prop.stageID === exsit.stageID) {
    //         return exsit
    //       } else {
    //         return prop
    //       }
    //     })
    //     setStageProps(modify)
    //   } else {
    //     let emitObj = {
    //       stageID: formik.values.stageID,
    //       stageName: formik.values.stageName,
    //       // lifecyclestage: formik.values.lifecyclestage,
    //       stageLocation: formik.values.stageLocation,
    //       stageDescription: formik.values.stageDescription,
    //       supplierId: formik.values.supplier_id,
    //       supplierName: selectedSupplier ? selectedSupplier.userName : "",
    //     };
    //     setStageProps((prev) => [...prev, emitObj]);
    //   }
    //   // Reset the form
    //   formik.resetForm();
    //   // Close the dialog
    //   toggleDialog(false);
    // }
    if (formik.isValid) {
      console.log(checkedActivities, "final activities");
      setNewNodePayload((prev) => ({
        ...prev,
        data: { ...prev.data, templateSchemaProp: checkedActivities },
      }));
      toggleDialog(false);
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues: {
      stageName: "",
      stageDescription: "",
      stageLocation: "",
      supplier_id: "",
    },
    onSubmit: handleContinue,
  });

  const getSupplier = () => {
    let prop = stageProps.filter((prop) => prop.stageID === stageData.stageID);
    if (prop.length) {
      return prop[0].supplierId;
    } else {
      return "";
    }
  };

  // useEffect(() => {
  //   if (stageData && suppliersList.length > 0) {
  //     formik.setValues({
  //       stageID: stageData.stageID,
  //       stageName: stageData.stageName,
  //       lifecyclestage: stageData.lifecyclestage,
  //       stageDescription: stageData.stageDescription,
  //       stageLocation: stageData.stageLocation,
  //       supplier_id: stageProps.length ? getSupplier() : "",
  //     });
  //   }
  // }, [stageData, suppliersList]);

  const dialogCancel = (e) => {
    setNewNodePayload({});
    toggleDialog(false);
  };

  const getSuppliers = async () => {
    try {
      const response = await getAllSuppliers();
      setSuppliersList(response.data || []);
    } catch (err) {
      enqueueSnackbar("Unable to fetch suppliers data. Please try again", {
        autoHideDuration: 3000,
        variant: "warning",
      });
    }
  };

  useEffect(() => {
    getSuppliers();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setActivityName("");
  };

  const handleChangeIndex = (index) => {
    setValue(index);
    setActivityName("");
  };

  const handleActivityChange = (ctrlDisplayName) => {
    if (displayNames.includes(ctrlDisplayName)) {
      setDisplayedNames((prev) =>
        prev.filter((item) => item !== ctrlDisplayName)
      );
      setCheckedActivities((prev) =>
        prev.filter((item) => item.ctrlDisplayName !== ctrlDisplayName)
      );
    } else {
      setDisplayedNames((prev) => [...prev, ctrlDisplayName]);
      const activityToAdd = activities.find(
        (item) => item.ctrlDisplayName === ctrlDisplayName
      );
      setCheckedActivities((prev) => [...prev, activityToAdd]);
    }
  };

  return (
    <Dialog open={openDialog} fullWidth={true} maxWidth="sm">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle my={1} variant="h6">
          Configure New Stage
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            aria-label="full width tabs example"
            indicatorColor="primary"
          >
            <Tab label="Stage Details" {...a11yProps(0)} />
            <Tab
              label="Template Configuration"
              {...a11yProps(1)}
              disabled={!formik.isValid}
            />
            <Tab
              label="Bill of Materials"
              {...a11yProps(2)}
              disabled={!formik.isValid}
            />
          </Tabs>
          <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
            <Box sx={{ bgcolor: "background.paper" }}>
              <Stack spacing={2} mt={2}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.stageName && Boolean(formik.errors.stageName)
                  }
                >
                  <TextField
                    disabled={loading}
                    id="stageName"
                    label="Stage Name"
                    type="text"
                    variant="outlined"
                    name="stageName"
                    value={formik.values.stageName}
                    onChange={formik.handleChange}
                  />
                  <FormHelperText>
                    {formik.touched.stageName && formik.errors.stageName}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.stageDescription &&
                    Boolean(formik.errors.stageDescription)
                  }
                >
                  <TextField
                    disabled={loading}
                    id="stageDescription"
                    label="Stage Description"
                    type="text"
                    variant="outlined"
                    name="stageDescription"
                    value={formik.values.stageDescription}
                    onChange={formik.handleChange}
                  />
                  <FormHelperText>
                    {formik.touched.stageDescription &&
                      formik.errors.stageDescription}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.stageLocation &&
                    Boolean(formik.errors.stageLocation)
                  }
                >
                  <TextField
                    disabled={loading}
                    id="stageLocation"
                    label="Stage Location"
                    type="text"
                    variant="outlined"
                    name="stageLocation"
                    value={formik.values.stageLocation}
                    onChange={formik.handleChange}
                  />
                  <FormHelperText>
                    {formik.touched.stageLocation &&
                      formik.errors.stageLocation}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.supplier_id &&
                    Boolean(formik.errors.supplier_id)
                  }
                >
                  <InputLabel id="demo-simple-select-label">
                    Supplier
                  </InputLabel>
                  <Select
                    id="supplier_id"
                    label="Supplier"
                    name="supplier_id"
                    value={formik.values.supplier_id}
                    // onChange={handleSelectChange}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value="">Select a Supplier</MenuItem>
                    {suppliersList.map((user, index) => (
                      <MenuItem value={user.userId} key={index}>
                        {capitalize(user.userName) || "Supplier Name"}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.supplier_id && formik.errors.supplier_id}
                  </FormHelperText>
                </FormControl>
              </Stack>
            </Box>
            <Box maxHeight={"50vh"}>
              <Box
                sx={{
                  display: "flex",
                  position: "sticky",
                  marginY: 3,
                  gap: 3,
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    id="activityName"
                    label="Add New Activity"
                    type="text"
                    variant="outlined"
                    name="activityName"
                    value={activityName}
                    onChange={(e) => setActivityName(e.target.value)}
                  />
                </FormControl>
                <Button
                  variant="outlined"
                  endIcon={<SearchIcon />}
                  onClick={handleSearchActivity}
                >
                  Search
                </Button>
              </Box>
              <Box sx={{ bgcolor: "background.paper" }}>
                <Typography variant="h6">Activities</Typography>
                <FormGroup>
                  {activities.map((activity, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={displayNames.includes(
                            activity.ctrlDisplayName
                          )}
                          onChange={() =>
                            handleActivityChange(activity.ctrlDisplayName)
                          }
                          name={activity.ctrlDisplayName}
                        />
                      }
                      label={
                        capitalize(activity.ctrlDisplayName) || "Activity Name"
                      }
                    />
                  ))}
                </FormGroup>
              </Box>
            </Box>
            <Box>
              <Typography>BOM</Typography>
            </Box>
          </SwipeableViews>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
            my={1}
          >
            <Button variant="outlined" onClick={dialogCancel}>
              Cancel
            </Button>
            {value === 0 ? (
              <Button
                variant="outlined"
                type="submit"
                onClick={handleContinue}
                disabled={loading}
              >
                Continue
              </Button>
            ) : (
              <Button variant="outlined" type="submit" onClick={onSubmitForm}>
                Add
              </Button>
            )}
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};