import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Button, ThemeProvider, Typography, createTheme } from '@mui/material';
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { getProductsById } from '../../services/catalogue/productlist';
import { getProductsLogHistory } from '../../services/SuuplyChainConfig/supplychainconfig';
import { APP_KEYS } from '../../utils/constants/AppKeyConstants';
import Iconify from '../../components/iconify/Iconify';
import { makeStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginLeft: '20px',
    backgroundColor: "#222",
    "&:hover": { backgroundColor: "#149BA1" },
    color: "#fff",
    display: "flex",
    height: '40px',
    width: '100px',
    alignItems: "center",
    borderRadius: "10px",
  },
  backIcon: {
    marginRight: "0.5rem",
  }
}));

export default function ProductLogHistory() {
  const classes = useStyles();
  const location = useLocation();
  const productId = new URLSearchParams(location.search).get('product');
  const [pData, setPData] = useState([]);
  const [pName, setPName] = useState('');
  const userInfo = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO) || '{}');

  useEffect(() => {
    async function productBatchData() {
      try {
        const response = await getProductsLogHistory(productId, { batchLimit: 100 });
        const productData = await getProductsById(productId);
        
        if (response.success) {

          let stagename = "";
          productData.data[0].stageProp.map((stage) => {
            if (stage.supplierId === JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO)).userId) {
              stagename = stage.stageName;
            }
          });
          console.log(productData.data[0].stageProp)
          console.log((response.data[0].products[(productData.data)[0].productName]))
          // const batchArray = (response.data[0].products[(productData.data)[0].productName]).stages[stagename].batches;
          // All type of stages in current batch listing page
          let batchArray = [];
          Object.values((response.data[0].products[(productData.data)[0].productName]).stages).map(({ batches = [] }) => {
            // Filter batch entries as per logged in user
            batches = batches.filter(stage => stage?.createdBy?.id === userInfo?.userId || stage?.createdBy?.namae === userInfo?.userEmail);
            batchArray = [...batchArray, ...batches];
          })
          let ct = 0;
          let displayRows = [];
          batchArray.map((p) => {
            ct = ct + 1;
            displayRows.push({
              id: ct, SNo: ct, Batch: p.batchNo, ProductName: p.productName, Date: new Date(p.createdAt).getDate() + '-' + (new Date(p.createdAt).getMonth() + 1) + '-' + new Date(p.createdAt).getFullYear()
              , Time: new Date(p.createdAt).getHours() + ':' + new Date(p.createdAt).getMinutes(), Stage: p.stageName, CarbonEmission: p.stage_carbon_score + 'kg CO2e', ConfidenceScore: p.stage_confidence_score + '%'
            });
            setPName(p.productName)
          });
          setPData(displayRows);
        } else {
          console.error('Error fetching p data:', response.msg);
        }
      } catch (error) {
        console.error('Error fetching p data:', error);
      }
    }
    productBatchData();
  }, []);
  const useNavigateSearch = () => {
    const navigate = useNavigate();
    return (pathname, params) =>
      navigate(`${pathname}?${createSearchParams(params)}`);
  };

  const navigateSearch = useNavigateSearch();

  const handlenavigate = (pid) => {
    navigateSearch('/carboncompete/supplychainconfig/product/stageentry', { product: pid });
  }

  const theme = createTheme({
    typography: {
      fontFamily: 'Poppins',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ height: 500, width: '100%', }}>

        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          // gap: 2,
          m: 2,
          p: 2,
          mx: 'auto', width: '90%',
          // boxShadow: 2,
        }}>
          <Button
            // className={classes.backButton}
            onClick={() => navigateSearch("/carboncompete/supplychainconfig")}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <mask id="mask0_171_1111" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_171_1111)">
                <path d="M7.825 13L13.425 18.6L12 20L4 12L12 4L13.425 5.4L7.825 11H20V13H7.825Z" fill="#1C1B1F" />
              </g>
            </svg>
          </Button>
          <Typography sx={{ fontFamily: "Poppins", fontSize: '18px', fontweight: '600', textAlign: 'center' }}><b>BATCH HISTORY :  <span style={{ textTransform: 'capitalize' }}>{pName}</span></b></Typography>
          <Button sx={{ bgcolor: '#149BA1', boxShadow: 'none', }} startIcon={<Add />} variant="contained" onClick={() => handlenavigate(productId)}> Add Entry</Button>
        </Box>

        <DataGrid
          sx={{
            m: 2,
            p: 2,
            mx: 'auto',
            width: '90%', boxShadow: 2,
            border: 1,
            fontSize: '16px',
            fontweight: 'bolder !important',
            borderColor: '#DFE3E6',
            '& .MuiDataGrid-row': {
              border: 1,
              borderColor: '#DFE3E6',
            },
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main'
            },
          }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          columns={[{ field: 'SNo', width: 70, }, { field: 'Batch', width: 200, }, { field: 'Date', width: 200, }, { field: 'Time', width: 180, },
          { field: 'Stage', width: 200, }, { field: 'CarbonEmission', width: 200, }, { field: 'ConfidenceScore', width: 130, },]}
          rows={pData}
          slots={{ toolbar: GridToolbar }}
        />
      </Box>
    </ThemeProvider>
  );
}