
import axios from "../axiosInstance"

const API_URL = process.env.REACT_APP_API_URL;

export const postScenarion=async(data)=>{
    try {
        
        const response=await axios({
            url:`${API_URL}/api/v1/scenario`,
            method:"POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              data: data,
      maxContentLength:Infinity,
      maxBodyLength:Infinity
        })
        return response.data
    } catch (error) {
        console.log(error)
        return { message: 'error' };
    }
}


export const getScenario=async()=>{
    try {
        
        const response=await axios({
            url:`${API_URL}/api/v1/scenario`,
            method:"GET",
            
        })
        return response.data
    } catch (error) {
        console.log(error)
        return { message: 'error' };
    }
}


export const getScenarioById=async(data)=>{
    try {
        const response=await axios({
            url:`${API_URL}/api/v1/scenario`,
            method:"GET",
            params:data

        })
        return response.data
    } catch (error) {
        console.log(error)
        return {message:"error"}
    }
}