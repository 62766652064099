import React from "react";
import { commaNumbers, roundDecimal, sortFiscalYearsDescending } from "../../../../utils/formatNumber";
import { stringWithUnderScore } from "../../../../utils/helperFunctions";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
const firstYearValueForScope3 = {};
export default function Scope3Cards({
  data,
  selectedLocation,
  selectedYear,
  esgData,
}) {
  let tempOb = {};
  let myObj = {
    scope_3_Logistics: {},
    scope_3_Waste: {},
    scope_3_Water: {},
    scope_3_Goods_and_Services: {},
    scope_3_Employee_Commute: {},
    scope_3_Business_Travel: {},
    scope_3_Intensity: {},
    percent_change_scope_3: {},
    total_emission_avoided: {},
  };

  const categorys = [
    "Food_Travel",
    "Water",
    "Waste",
    "Business_Travel",
    "Employee_Commute",
    "Goods_&_Services",
  ];

  // Define the order of keys
  const order = [
    "scope_3_Logistics",
    "scope_3_Waste",
    "scope_3_Water",
    "scope_3_Goods_and_Services",
    "scope_3_Employee_Commute",
    "scope_3_Business_Travel",
    "scope_3_Intensity",
    "percent_change_scope_3",
    "total_emission_avoided",
  ];
  const getCategoryTextColor = (category) => {
    switch (category) {
      case "scope_3_Logistics":
        return "#225E83";
      case "scope_3_Waste":
        return "#225E83";
      case "scope_3_Water":
        return "#225E83";
      case "scope_3_Goods_and_Services":
        return "#225E83";
      case "scope_3_Intensity":
        return "#FFC74A";
      case "total_emission_avoided":
        return "#0BDB7B";
      case "percent_change_scope_3":
        return "#FFC74A";

      default:
        return "#225E83";
    }
  };
  data.forEach((loc) => {
    if (selectedLocation === "All" || selectedLocation === loc.location) {
      const yearsToIterate =
        selectedYear === "All" ? Object.keys(loc.years) : [selectedYear];
      yearsToIterate.forEach((year) => {
        const yearData = loc.years[year];

        if (yearData) {
          categorys.forEach((category) => {
            const categoryData = yearData.yearly_stage_emission[category];

            if (categoryData) {
              if (category == "Food_Travel") {
                if (!myObj.scope_3_Logistics[year]) {
                  myObj.scope_3_Logistics[year] = 0;
                }
                myObj.scope_3_Logistics[year] +=
                  categoryData.totalEmissionForScope3;
              }
              if (category == "Water") {
                if (!myObj.scope_3_Water[year]) {
                  myObj.scope_3_Water[year] = 0;
                }
                myObj.scope_3_Water[year] +=
                  categoryData.totalEmissionForScope3;
              }
              if (category == "Waste") {
                if (!myObj.scope_3_Waste[year]) {
                  myObj.scope_3_Waste[year] = 0;
                }
                myObj.scope_3_Waste[year] +=
                  categoryData.totalEmissionForScope3;
              }
              if (category == "Business_Travel") {
                if (!myObj.scope_3_Business_Travel[year]) {
                  myObj.scope_3_Business_Travel[year] = 0;
                }
                myObj.scope_3_Business_Travel[year] +=
                  categoryData.totalEmissionForScope3;
              }
              if (category == "Employee_Commute") {
                if (!myObj.scope_3_Employee_Commute[year]) {
                  myObj.scope_3_Employee_Commute[year] = 0;
                }
                myObj.scope_3_Employee_Commute[year] +=
                  categoryData.totalEmissionForScope3;
              }
              if (category == "Goods_&_Services") {
                if (!myObj.scope_3_Goods_and_Services[year]) {
                  myObj.scope_3_Goods_and_Services[year] = 0;
                }
                myObj.scope_3_Goods_and_Services[year] +=
                  categoryData.totalEmissionForScope3;
              }
              if (categoryData.totalRevenue) {
                if (!tempOb["scope_Reveune"]) {
                  tempOb["scope_Reveune"] = {};
                }

                if (!tempOb["scope_Reveune"][year]) {
                  tempOb["scope_Reveune"][year] = 0;
                }
                tempOb["scope_Reveune"][year] = categoryData.totalRevenue;
              }

              if (categoryData.totalEmissionForScope3) {
                if (!tempOb["scope_3_Activity"]) {
                  tempOb["scope_3_Activity"] = {};
                }

                if (!tempOb["scope_3_Activity"][year]) {
                  tempOb["scope_3_Activity"][year] = 0;
                }
                tempOb["scope_3_Activity"][year] +=
                  categoryData.totalEmissionForScope3;
              }

              if (!myObj.scope_3_Intensity[year]) {
                myObj.scope_3_Intensity[year] = 0;
              }
              myObj.scope_3_Intensity[year] +=
                categoryData.totalIntensityForScope3;
            }
          });
        }
      });
    }
  });

  if(tempOb["scope_3_Activity"] && tempOb["scope_Revenue"]){
    Object.keys(tempOb.scope_3_Activity).forEach((stage) => {
      Object.keys(tempOb.scope_3_Activity[stage]).forEach((year) => {
        if (tempOb.scope_Reveune[stage] && tempOb.scope_Reveune[stage][year]) {
          if (!myObj.scope_3_Intensity[stage]) {
            myObj.scope_3_Intensity[stage] = {};
          }
          myObj.scope_3_Intensity[stage][year] = roundDecimal(
            tempOb.scope_3_Activity[stage][year] /
              tempOb.scope_Reveune[stage][year],
            2
          );
        }
      });
    });
  }
  

  // const tempOb={}

 
  esgData.forEach((loc) => {
    if (selectedLocation === "All" || selectedLocation === loc.location) {
      const yearsToIterate =
        selectedYear === "All" ? Object.keys(loc.years) : [selectedYear];
      yearsToIterate.forEach((year) => {
        const yearData = loc.years[year];
        if (yearData) {
          Object.keys(yearData).forEach((stage) => {
            if (stage == "Food_Travel") {
              const { EV_FIRST, EV_LAST } =
                yearData[stage].yearlyData.Yearly_Activity_Breakdown;
              if (!myObj.total_emission_avoided["EV_COMBINED"]) {
                myObj.total_emission_avoided["EV_COMBINED"] = {};
              }
              if (!myObj.total_emission_avoided["EV_COMBINED"][year]) {
                myObj.total_emission_avoided["EV_COMBINED"][year] = 0;
              }
              myObj.total_emission_avoided["EV_COMBINED"][year] +=
                EV_FIRST * 0.0006 + EV_LAST * 0.0006;
            }
          });
        }
      });
    }
  });

  if(myObj["scope_3_Intensity"]){
    const scopes3 = Object.keys(myObj.scope_3_Intensity);
    const scopes3Set=new Set()

    scopes3.forEach(year=>{
      scopes3Set.add(year)
      })

  
      const yearArray=[...scopes3Set]

      const sortedYearsArray=sortFiscalYearsDescending(yearArray)
  scopes3.forEach((year) => {
    if (selectedYear !== "All") {
      Object.keys(myObj.scope_3_Intensity).forEach((year) => {
        if (year == selectedYear) {
          const lastYearData = myObj.scope_3_Intensity[year];
          const firstYearData = Object.values(firstYearValueForScope3)[0];
          const percentChangeScope3 =
            ((lastYearData - firstYearData) / firstYearData) * 100;
          myObj.percent_change_scope_3["year"] =
            myObj.percent_change_scope_3["year"] || {};
          myObj.percent_change_scope_3["year"][
            Object.keys(firstYearValueForScope3)[0]
          ] = parseFloat(percentChangeScope3);
        }
      });
    } else {
      let firstYearIndex = sortedYearsArray.length - 1;
      let firstYearData = myObj.scope_3_Intensity[sortedYearsArray[firstYearIndex]]; 
      while(firstYearData===undefined || isNaN(firstYearData)){
        firstYearIndex-=1
        if(firstYearIndex<=sortedYearsArray.length){
          firstYearData=null
          break;
        }
        firstYearData= myObj.scope_3_Intensity[sortedYearsArray[firstYearIndex]]
      }
      let lastYearIndex = 0;
let lastYearData =  myObj.scope_3_Intensity[sortedYearsArray[lastYearIndex]];

// Loop to find the next valid lastYearData if it is undefined or NaN
while (lastYearData === undefined || isNaN(lastYearData)) {
  lastYearIndex += 1;
  if (lastYearIndex >= sortedYearsArray.length) {
    // If no valid lastYearData is found, handle it appropriately (e.g., break the loop or set a default value)
    lastYearData = null;
    break;
  }
  lastYearData =  myObj.scope_3_Intensity[sortedYearsArray[lastYearIndex]];
}



      firstYearValueForScope3[sortedYearsArray[firstYearIndex]] =  myObj.scope_3_Intensity[sortedYearsArray[firstYearIndex]];
      const percentChange =
        ((lastYearData - firstYearData) / firstYearData) * 100;
    myObj.percent_change_scope_3["year"] =  myObj.percent_change_scope_3["year"] || {};
    myObj.percent_change_scope_3["year"][sortedYearsArray[firstYearIndex]] = parseFloat(percentChange);
   
    }
  });
  }
  
  const getUnits = (str) => {
    switch (str) {
      case "scope_3_Logistics":
      case "scope_3_Waste":
      case "scope_3_Water":
      case "scope_3_Goods_and_Services":
      case "scope_3_Employee_Commute":
      case "scope_3_Business_Travel":
      case "total_emission_avoided":
        return "tCO2e";
      case "scope_3_Intensity":
        return "tCO2e/Cr";
      default:
        return "";
    }
  };

  //We are using this ---> Function to sort dates in descending order
  const sortDatesDescending = (a, b) => {
    return parseInt(b.split("-")[1]) - parseInt(a.split("-")[1]);
  };
  console.log(myObj)

  return (
    <Grid mt={1} mb={2} container spacing={2} padding={2}>
      {order.map((category) =>
        category === "percent_change_scope_3" ? (
          <Grid key={category} item xs={12} sm={6} md={3} lg={3}>
            <Card
              sx={{
                border: `1px solid ${getCategoryTextColor(category)}`,
                borderRadius: "6px",
                boxShadow: " 0 6px 10px 0 rgba(0, 0, 0, 0.06) ",
              }}
            >
              <CardHeader
                sx={{ padding: "12px 10px", textTransform: "uppercase" }}
                title={`% change Scope3 Intensity`}
                titleTypographyProps={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: getCategoryTextColor(category),
                }}
              />
              <CardContent
                sx={{
                  height: "110px",
                  overflowY: "scroll",
                  scrollbarWidth: "thin",
                  padding: 0,
                }}
              >
                {Object.keys(myObj[category] || {})
                  .sort(sortDatesDescending)
                  .map((stage, index) => (
                    <React.Fragment key={index}>
                      {Object.keys(myObj[category][stage]).map((year, idx) => {
                        const value = myObj[category][stage][year];
                        if (!isNaN(value) && value !== undefined) {
                          const textColor = value < 0 ? "green" : "red";
                          return (
                            <div key={idx}>
                              <CardHeader
                                sx={{
                                  textAlign: "center",
                                  marginLeft: "70px", // Center align text
                                  color: textColor,
                                }}
                                title={
                                  <div style={{ display: "flex", gap: 10 }}>
                                    <span>
                                      {commaNumbers(roundDecimal(value, 2), 2)}{" "}
                                      %
                                    </span>
                                  </div>
                                }
                                titleTypographyProps={{
                                  fontSize: 20,
                                  fontWeight: 900,
                                }}
                              />
                            </div>
                          );
                        }
                        return null;
                      })}
                    </React.Fragment>
                  ))}
              </CardContent>
            </Card>
          </Grid>
        ) : category === "total_emission_avoided" ? (
          <Grid key={category} item xs={12} sm={6} md={3} lg={3}>
            <Card
              sx={{
                border: `1px solid ${getCategoryTextColor(category)}`,
                borderRadius: "6px",
                boxShadow: " 0 6px 10px 0 rgba(0, 0, 0, 0.06) ",
              }}
            >
              <CardHeader
                sx={{
                  padding: "12px 10px",
                }}
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {`${stringWithUnderScore(
                      category
                    ).toUpperCase()} (${getUnits(category)})`}
                  </div>
                }
                titleTypographyProps={{
                  fontSize: 15,
                  fontWeight: 600,
                  color: getCategoryTextColor(category),
                }}
              />
              <CardContent
                sx={{
                  height: "110px",
                  overflowY: "scroll",
                  scrollbarWidth: "thin",
                  padding: 0,
                }}
              >
                {Object.keys(myObj[category] || {}).map((stage, index) => (
                  <>
                    {Object.keys(myObj[category][stage])
                      .sort(sortDatesDescending)
                      .map((year, index) => (
                        <div key={index}>
                          <CardHeader
                            sx={{
                              paddingTop: 0.5,
                              textTransform: "capitalize",
                            }}
                            title={
                              <div
                                style={{
                                  display: "flex",

                                  gap: 10,
                                }}
                              >
                                <span key={index}>
                                  {commaNumbers(
                                    roundDecimal(
                                      myObj[category][stage][year],
                                      2
                                    ),
                                    2
                                  )}
                                </span>
                              </div>
                            }
                            subheader={<span key={index}>{year}</span>}
                            titleTypographyProps={{
                              fontSize: 14,
                              fontWeight: 700,
                            }}
                            subheaderTypographyProps={{
                              fontSize: 14,
                              fontWeight: 700,
                            }}
                          />
                        </div>
                      ))}
                  </>
                ))}
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <Grid key={category} item xs={12} sm={6} md={3} lg={3}>
            <Card
              sx={{
                border: `1px solid ${getCategoryTextColor(category)}`,
                borderRadius: "6px",
                boxShadow: " 0 6px 10px 0 rgba(0, 0, 0, 0.06) ",
                height: "150px",
              }}
            >
              <CardHeader
                sx={{
                  padding: "12px 10px",
                }}
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {`${stringWithUnderScore(
                      category
                    ).toUpperCase()} (${getUnits(category)})`}
                  </div>
                }
                titleTypographyProps={{
                  fontSize: 15,
                  fontWeight: 600,
                  color: getCategoryTextColor(category),
                }}
              />
              <CardContent
                sx={{
                  height: "110px",
                  overflowY: "scroll",
                  scrollbarWidth: "thin",
                  padding: 0,
                }}
              >
                {Object.keys(myObj[category] || {})
                  .sort(sortDatesDescending)
                  .map((year, index) => (
                    <>
                      <div key={index}>
                        <CardHeader
                          sx={{ paddingTop: 0.5 }}
                          title={
                            <div
                              style={{
                                display: "flex",

                                gap: 10,
                              }}
                            >
                              <span key={index}>
                                {commaNumbers(
                                  roundDecimal(myObj[category][year], 2),
                                  2
                                )}
                              </span>
                            </div>
                          }
                          subheader={<span key={index}>{year}</span>}
                          titleTypographyProps={{
                            fontSize: 14,
                            fontWeight: 700,
                          }}
                          subheaderTypographyProps={{
                            fontSize: 14,
                            fontWeight: 700,
                          }}
                        />
                      </div>
                    </>
                  ))}
              </CardContent>
            </Card>
          </Grid>
        )
      )}
    </Grid>
  );
}
