import axios from '../axiosInstance';

const API_URL = process.env.REACT_APP_API_URL;

export const getProductsBySupplier = async (supplierId) => {

    // const supplierId = 17;
    try {
        const response = await axios({
            url: `${API_URL}/api/v1/product`,
            method: 'GET',
            params: {
                supplierId
            },
        });
        return response.data;
    }
    catch (err) {
        console.log(err);
        return err;
    }
};

export const getProductsLogHistory = async (productId, query = {}) => {
    try {
        const response = await axios({
            url: `${API_URL}/api/v1/product-batch-emission`,
            method: 'GET',
            params: {
                productId,
                ...query
            },
        });
        return response.data;
    }
    catch (err) {
        console.log(err);
        return err;
    }

};

export const getEmissionFactors = async (item) => {
    try {
        const response = await axios({
            url: `${API_URL}/api/v1/${item.ctrlApi}`,
            method: 'GET',
            params: {

            },
        });
        // console.log(response.data)
        // console.log(item.ctrlApi)
        return response.data
    }
    catch (err) {
        console.log(err);
        return err;
    }
}

export const getTemplateSchema = async (templateId) => {

    try {
        const response = await axios({
            url: `${API_URL}/api/v1/templateschema`,
            method: 'GET',
            params: {
                templateSchemaId: templateId
            },
        });
        return response.data
    }
    catch (err) {
        console.log(err);
        return err;
    }
}

export const saveSupplierInput = async (requestData) => {
    try {
        const response = await axios({
            url: `${API_URL}/api/v1/templatevalue`,
            method: 'POST',
            data: requestData,
        });

        if (response && response.data) {
            console.log('API Response Data:', response.data);
            return response.data;
        } else {
            console.error('API Error: Unexpected response structure', response);
            return { success: false, statusCode: 500, msg: 'Unexpected response structure' };
        }
    } catch (err) {
        console.error('API Error:', err);
        return { success: false, statusCode: 500, msg: 'Internal Server Error' };
    }
};

/**
 * @description Upload Product from excel file
 * @param {Object} fileObj
 * @returns {Object}
 */

export const uploadTemplateValue = async (fileObj) => {
    try {
      var bodyFormData = new FormData();
  
      bodyFormData.append('file', fileObj)
  
      const { data: responseData = {} } = await axios({
        url: `${API_URL}/api/v1/templatevalue/upload`,
        method: "POST",
        headers: {
          "Content-Type": `multipart/form-data; boundary=${bodyFormData._boundary}`,
        },
        data: bodyFormData
      });
  
      return responseData.data;
    } catch (err) {
      // Throw unknown errors as well
      throw err;
    }
  };

/**
 * @description get previous entry of templatevalue
 * @param {Object}
 * @returns {Object}
 */

export const getTemplateValue = async (productId, stageId, templateValueName) => {
    try {
        const response = await axios({
            url: `${API_URL}/api/v1/templatevalue`,
            method: 'GET',
            params: {
                productId: productId,
                stageId: stageId,
                templateValueName: templateValueName
            },
        });
        return response.data;
    }
    catch (err) {
        console.log(err);
        return err;
    }
  };
