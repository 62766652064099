import React, { useEffect, useRef } from 'react'
import * as echarts from "echarts";
export default function ChartArea({data,selectedYear}) {
    const chartRef = useRef(null);
   useEffect(()=>{
    const chart = echarts.init(chartRef.current);
    const colors = [
        "#FD5308",
        "#FB9902",
        "#36454F",
        "#66B132",
        "#0291CD",
        "#0247FE",
        "#3E01A4",
        "#8601B0",
        "#A7194B",
      ];
  
      const legendData = selectedYear.map((year, index) => ({
        name: year,
      }));
  
      const option = {
        xAxis: {
          type: "category",
          data: data.map((item) => item.month),
        },
        yAxis: {
          type: "value",
        },
        toolbox: {
          show: true,
          orient: "vertical",
          left: "right",
          top: "center",
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar", "stack"] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
            formatter: function (params) {
              let tooltip = '<div style="text-align: left;">';
      
              params.forEach((param) => {
                tooltip +=
                  '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;background-color:' +
                  param.color +
                  '"></span>' +
                  param.seriesName +
                  ": " +
                  param.value +
                  " (tCO2e)" +
                  "<br/>";
              });
      
              tooltip += "</div>";
              return tooltip;
            },
          },
        legend: {
          data: legendData,
          left: "center",
          top: "top",
          textStyle: {
            color: "#000000",
          },
        },
        series: selectedYear
          .filter((year) =>
            data.some(
              (item) => item.data[year] !== undefined && item.data[year] !== 0
            )
          )
          .map((year, index) => ({
            name: year,
            type: "bar",
            barWidth: "20%",
            barCategoryGap: "30%",
            barGap: "20%",
            data: data.map((item) => item.data[year] || 0),
            itemStyle: {
              color: colors[index % colors.length],
              borderRadius: [5, 5, 0, 0],
            },
          })),
      };
  
      chart.setOption(option);
  
      return () => {
        chart.dispose();
      };
   })
  return (
    <div ref={chartRef} style={{ width: "100%", height: "400px" }} />
  )
}
