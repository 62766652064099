import React, { useEffect, useReducer, useRef, useState } from "react";
import {
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Typography,
  FormHelperText,
  Button,
  Paper,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import ReactECharts from "echarts-for-react";
import { AnalyticsTable } from "./AnalyticsTable";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    height: '500px',
    width: '100%'
  },
}));

const initialStates = {
  historicalData: null,
  message: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_HISTORICAL_DATA":
      return { ...state, historicalData: action.payload };
    case "SET_WS_MESSAGE":
      return { ...state, message: action.payload };
    default:
      return state;
  }
};

export const Forecasting = () => {
  const locationData = [
    { locationId: "loc1", locationName: "Virugambakkam" },
    { locationId: "loc2", locationName: "Banasvadi" },
    { locationId: "loc3", locationName: "Bangalore Region" },
    { locationId: "loc4", locationName: "Bannerghatta" },
  ];

  const emissionTypes = [
    { id: "emission1", name: "Scope_1" },
    { id: "emission2", name: "Scope_2" },
    { id: "emission3", name: "Scope_3" },
  ];

  const [state, dispatch] = useReducer(reducer, initialStates);
  const { historicalData } = state;
  const [showLoader, setShowLoader] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const ws = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    ws.current = new WebSocket("wss://socketforecasting.azurewebsites.net/ws");

    ws.current.onopen = () => {
      console.log("WebSocket connection established.");
    };

    ws.current.onmessage = async (event) => {
      const message = event.data;

      try {
        const response = JSON.parse(message);

        if (response) {
          dispatch({ type: "SET_HISTORICAL_DATA", payload: response });
          setShowLoader(false);
        } else {
          console.error("Unexpected response:", response);
          setShowLoader(false);
        }
      } catch (error) {
        if (message === "Task started") {
          console.log("Running");
        } else {
          console.error("Error parsing WebSocket message:", error);
          setShowLoader(false);
        }
      }
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error:", error);
      setShowLoader(false);
    };

    ws.current.onclose = () => {
      console.log("WebSocket connection closed.");
      setShowLoader(false);
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      location: "",
      emissionType: "",
      frequency: "",
    },
    onSubmit: (values) => {
      setShowLoader(true);
      setFormSubmitted(true);
      const payload = {
        location: values.location,
        periods: parseInt(values.frequency),
        emissionType: values.emissionType
          ? `${values.emissionType}_Emissions`
          : "",
        compId: 151,
      };

      ws.current.send(JSON.stringify(payload));
      setSelectedLocation(values.location);
    },
  });

  const handleLocationChange = (event) => {
    formik.setFieldValue("location", event.target.value);
    setSelectedLocation(event.target.value);
  };

  const handleEmissionTypeChange = (event) => {
    formik.setFieldValue("emissionType", event.target.value);
  };

  const handleFrequencyChange = (event) => {
    formik.setFieldValue("frequency", event.target.value);
  };

  const renderChart = () => {
    if (!historicalData || typeof historicalData !== "object") return null;

    const locationKey = Object.keys(historicalData)[0];
    if (!locationKey || !historicalData[locationKey][0]?.historical)
      return null;

    const data = historicalData[locationKey][0].historical;
    const years = Object.keys(data).filter((year) =>
      Object.values(data[year]).some((value) => value !== "")
    );
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const series = years.map((year) => ({
      name: year,
      type: "bar",
      data: months.map((month) => parseFloat(data[year][month] || 0)),
    }));

    const option = {
      color: ['#FFC950', '#FFA058', '#149BA1', '#006799', '#DAF7A6', '#CCCCFF'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: function (params) {
          let tooltip = '<div style="text-align: left;">';

          params.forEach((param) => {
            tooltip +=
              '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;background-color:' +
              param.color +
              '"></span>' +
              param.seriesName +
              ': ' +
              (param.value).toFixed(2) +
              ' tCO₂e' +
              '<br/>';
          });

          tooltip += '</div>';
          return tooltip;
        },
      },
      toolbox: {
        show: true,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ['line', 'bar', 'stack'] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      legend: {
        data: series.map(item => item.name), // Extract series names for the legend
        textStyle: {
          color: '#030229',
          fontFamily: 'Poppins',
          fontSize: 12,
        },
      },
      xAxis: {
        type: 'category',
        data: months,
        axisLabel: {
          color: '#030229',
          textAlign: 'center',
          fontFamily: 'Poppins',
          fontSize: 12,
        },
        axisTick: { show: false },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#030229',
          textAlign: 'center',
          fontFamily: 'Poppins',
          fontSize: 16,
        },
      },
      itemStyle: {
        borderRadius: [8, 8, 0, 0],
        borderColor: '#fff',
      },
      series: series.map(item => ({
        ...item,
        smooth: true, // Make the line curvy
      })),
    };


    return (
      <div className={classes.chartContainer}>
        <ReactECharts option={option} style={{ height: '100%', width: '100%' }} />
      </div>
    );
  };


  const renderMultiLineChart = () => {
    if (!historicalData || typeof historicalData !== "object") return null;
  
    const locationKey = Object.keys(historicalData)[0];
    if (!locationKey || !historicalData[locationKey][0]) return null;
  
    const data = historicalData[locationKey][0];
    const historical = data.historical;
    const forecast = data.forecast;
  
    const allYears = [
      ...new Set([...Object.keys(historical), ...Object.keys(forecast)]),
    ].sort();
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
  
    // Complete x-axis data
    const xAxisData = allYears.flatMap((year) =>
      months.map((month) => `${year}-${month}`)
    );
  
    // Generate complete data arrays for each series
    const getDataPoints = (dataObj, valueKey = null) => {
      return xAxisData.map((x) => {
        const [year, month] = x.split('-');
        const value = valueKey ? dataObj[year]?.[month]?.[valueKey] : dataObj[year]?.[month];
        return value ? parseFloat(value) : null; // Ensure null values for missing data
      });
    };
  
    const historyData = getDataPoints(historical);
    const forecastData = getDataPoints(forecast, "value");
    const lowerBoundData = getDataPoints(forecast, "lowerbound");
    const upperBoundData = getDataPoints(forecast, "upperbound");
  
    const option = {
      color: ['#FFC950', '#FFA058', '#149BA1', '#006799', '#DAF7A6', '#CCCCFF'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: function (params) {
          let tooltip = '<div style="text-align: left;">';
  
          params.forEach((param) => {
            if (param.value !== null && param.value !== undefined) {
              tooltip +=
                '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;background-color:' +
                param.color +
                '"></span>' +
                param.seriesName +
                ': ' +
                param.value +
                ' tCO₂e' +
                '<br/>';
            }
          });
  
          return tooltip || 'No data available';
        },
      },
      toolbox: {
        show: true,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ['line', 'bar', 'stack'] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      legend: {
        data: ['Historical', 'Forecast', 'Lower Bound', 'Upper Bound'],
        textStyle: {
          color: '#030229',
          fontFamily: 'Poppins',
          fontSize: 12,
        },
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
        axisLabel: {
          color: '#030229',
          textAlign: 'center',
          fontFamily: 'Poppins',
          fontSize: 12,
        },
        axisTick: { show: false },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#030229',
          textAlign: 'center',
          fontFamily: 'Poppins',
          fontSize: 16,
        },
      },
      itemStyle: {
        borderRadius: [8, 8, 0, 0],
        borderColor: '#fff',
      },
      series: [
        {
          name: 'Historical',
          type: 'line',
          data: historyData,
          smooth: true,
        },
        {
          name: 'Forecast',
          type: 'line',
          data: forecastData,
          smooth: true,
        },
        {
          name: 'Lower Bound',
          type: 'line',
          data: lowerBoundData,
          smooth: true,
        },
        {
          name: 'Upper Bound',
          type: 'line',
          data: upperBoundData,
          smooth: true,
        },
      ],
    };
  
    return (
      <div className={classes.chartContainer}>
        <ReactECharts option={option} style={{ height: '100%', width: '100%' }} />
      </div>
    );
  };
  

  return (
    <ThemeProvider theme={createTheme({ typography: { fontFamily: 'Poppins' } })}>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <form onSubmit={formik.handleSubmit}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Location</InputLabel>
                <Select
                  name="location"
                  value={formik.values.location}
                  onChange={handleLocationChange}
                  label="Location"
                  required
                  style={{ fontFamily: 'Poppins' }}
                >
                  {locationData.map((location) => (
                    <MenuItem key={location.locationId} value={location.locationName}>
                      {location.locationName}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText style={{ fontFamily: 'Poppins' }}>
                  Select the location for which you want to view data.
                </FormHelperText>
              </FormControl>

              <FormControl fullWidth margin="normal">
                <InputLabel>Emission Type</InputLabel>
                <Select
                  name="emissionType"
                  value={formik.values.emissionType}
                  onChange={handleEmissionTypeChange}
                  label="Emission Type"
                  required
                  style={{ fontFamily: 'Poppins' }}
                >
                  {emissionTypes.map((emissionType) => (
                    <MenuItem key={emissionType.id} value={emissionType.name}>
                      {(emissionType.name).replace(/_/g, ' ')}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText style={{ fontFamily: 'Poppins' }}>
                  Choose the type of emissions to view.
                </FormHelperText>
              </FormControl>

              <FormControl fullWidth margin="normal">
                <InputLabel>Frequency</InputLabel>
                <Select
                  name="frequency"
                  value={formik.values.frequency}
                  onChange={handleFrequencyChange}
                  label="Frequency"
                  required
                  style={{ fontFamily: 'Poppins' }}
                >
                  <MenuItem value={3}>3 months</MenuItem>
                  <MenuItem value={6}>6 months</MenuItem>
                  <MenuItem value={12}>12 months</MenuItem>
                  <MenuItem value={24}>24 months</MenuItem>
                </Select>
                <FormHelperText style={{ fontFamily: 'Poppins' }}>
                  Select the frequency for data view.
                </FormHelperText>
              </FormControl>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  fontFamily: 'Poppins',
                  backgroundColor: '#149BA1',
                  '&:hover': { backgroundColor: '#167266'}
                }}
              >
                Generate Analytics
              </Button>
            </form>
          </Paper>
        </Grid>

        <Grid item xs={12} md={8}>
          <Paper elevation={3} >
            <AnalyticsTable location={selectedLocation} data={historicalData} />
          </Paper>
        </Grid>

        {formSubmitted && (
          <Grid item xs={12} md={12}>
            <Paper elevation={3} style={{ padding: '16px' }}>
              <Typography
                sx={{ fontFamily: 'poppins', fontSize: '20px', fontWeight: 600, marginBottom: '25px', textAlign: 'center' }}
              >
                Historical Emission Data for {selectedLocation}
              </Typography>
              {renderMultiLineChart()}
            </Paper>
          </Grid>
        )}

        {formSubmitted && (
          <Grid item xs={12} md={12}>
            <Paper elevation={3} style={{ padding: '16px' }}>
              <Typography
                sx={{ fontFamily: 'poppins', fontSize: '20px', fontWeight: 600, marginBottom: '25px', textAlign: 'center' }}
              >
                Emission Data for {selectedLocation}
              </Typography>
              {renderChart()}
            </Paper>
          </Grid>
        )}
      </Grid>

      <Backdrop open={showLoader} style={{ zIndex: 1000 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </ThemeProvider>
  );
};
