import React from "react";
import ExcelToJsonConverter from "./ExcelData";

export default function StageTemplateProps({ stageId }) {
  return (
    <>
      {stageId === 19 && (
        <div style={{ padding: "5px", margin: "5px" }}>
          <ExcelToJsonConverter />
        </div>
      )}
    </>
  );
}
