import React, { useState } from "react";
import {ScopeConfig} from "./ScopeConfig";

const Configuration = ({location}) => {
  const [page, setPage] = useState("ScopeConfig");

  if (page === "ScopeConfig") {
    return (
      <ScopeConfig
        setPage={setPage}
        location={location}
      />
    );
  } 
};

export default Configuration;