import { APP_KEYS } from '../../utils/constants/AppKeyConstants';
import axios from '../axiosInstance';

const API_URL = process.env.REACT_APP_API_URL;
const userInfo = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO) || '{}');

export const getCategoryData = async () => {
  try {
    const response = await axios({
      url: `${API_URL}/api/v1/category`,
      method: 'GET',
    });
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getSubCategoryData = async (categoryId) => {
  try {
    const response = await axios({
      url: `${API_URL}/api/v1/subcat1`,
      method: 'GET',
      params: { categoryId }, // Include categoryId as a query parameter
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const getSubCategory2Data = async (categoryId, subCat1Id) => {
  try {
    const response = await axios({
      url: `${API_URL}/api/v1/subcat2`,
      method: 'GET',
      params: {
        categoryId,
        subCat1Id,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const getBrandsData = async () => {
  try {
    const response = await axios({
      url: `${API_URL}/api/v1/brand`,
      method: 'GET',
    });
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

// api integration for add product
// export const addNewProduct = async ({
//   productName,
//   SKU,
//   category,
//   brand,
//   country,
//   subCategoryId,
//   subCategoryName,
//   subCategory2Id,
//   subCategory2Name,
//   stages,
//   image,
//   nodes = [],
//   edges = []
// }) => {
//   try {
//     const { userId } = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));
//     const resp = await axios({
//       url: `${API_URL}/api/v1/product`,
//       method: 'POST',
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//       },
//       data: {
//         productCode: 'PRODUCT_CODE',
//         productName: productName,
//         shortName: '',
//         productDesc: 'Description',
//         categoryId: category,
//         subCategories: [
//           {
//             id: subCategoryId,
//             name: subCategoryName,
//             subCat2: [
//               {
//                 id: subCategory2Id,
//                 name: subCategory2Name,
//               },
//             ],
//           },
//         ],
//         brandId: brand,
//         unitId: 1,
//         skuCode: SKU,
//         countryId: country,
//         userId,
//         compId: userInfo?.userId,
//         stageProp: stages,
//         isActive: true,
//         imageFile: image,
//         supplyChainConfig: {
//           edges,
//           nodes
//         }
//       },
//     });
//     return resp.data;
//   } catch (err) {
//     console.log(err);
//     return { message: 'error' };
//   }
// };

export const addNewProduct = async (data) => {
  try {
    // const { userId } = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));
    const response = await axios({
      url: `${API_URL}/api/v1/product`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: data,
      maxContentLength:Infinity,
      maxBodyLength:Infinity
    });
    return response.data
  } catch (err) {
    console.log(err);
    return { message: 'error' };
  }
};

// Not using this api anymore
export const updateProduct = async ({
  productId,
  productName,
  description,
  category,
  brand,
  country,
  stageProp,
  image,
  nodes = [],
  edges = []
}) => {
 
  try {
    const { userId } = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));

    const resp = await axios({
      url: `${API_URL}/api/v1/product`,
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        productId,
        productCode: 'PRODUCT_CODE',
        productName: productName,
        shortName: '',
        productDesc: description,
        categoryId: category,
        brandId: brand,
        countryId: country,
        userId,
        compId: userInfo?.userId,
        stageProp: stageProp,
        isActive: true,
        imageFile: image,
        supplyChainConfig: {
          edges,
          nodes
        },
        aiValues:false,
        analytics:[]
      },
    });
    return resp.data;
  } catch (err) {
    console.log(err);
    return { message: 'error' };
  }
};

/**
 * @description Get Production by ProductId
 * @param {String|Number} productId
 * @returns {Object}
 */
export const getProductByProductId = async (productId) => {
  try {
    const response = await axios({
      url: `${API_URL}/api/v1/product`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        productId: productId,
        isActive: true,
      },
    });
    return response?.data?.data[0] || {};
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateProductAIValues=async({productId,data})=>{
  try {


    await axios({
      url:`${API_URL}/api/v1/aiProduct/${productId}`,
      method:"PUT",
      headers: {
        'Content-Type': 'application/json',
      },
      data:data,
      maxContentLength:Infinity,
      maxBodyLength:Infinity

    })

    return {
      status:"success",
      code:200
    }
  } catch (error) {
    console.log(error)
  }
}

export const deleteProduct = async (productId) => {
  try {
      const { data: responseData = {} } = await axios({
          url: `${API_URL}/api/v1/product`,
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
          },
          data: {
              productId,
              isActive: 'false'
          }
      });

      return responseData;
  } catch (err) {
      throw err;
  }
};

export const getMaxId = async () =>{
  try{
    const response = await axios({
      url: `${API_URL}/api/v1/max-id`,
      method: 'GET',
    });
    return response.data;
  }catch(err){
    console.log(err);
    return err;
  }
  
};