import React, { useEffect, useState } from 'react';
import { Dialog, Typography, Container, Grid, IconButton, Button, Alert, CircularProgress } from '@mui/material';
import Iconify from '../../../components/iconify/Iconify';
import { deleteCorporateSupplier } from '../../../services/corporatesuppliers';

const DeleteUser = ({ open, setOpen, userDetails, handleFetchUser }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const handleUserDelete = async () => {
    try {
      setShowLoader(true);
      const response = await deleteCorporateSupplier({ id: userDetails.id });
      setShowLoader(false);
      if (response.success) {
        setShowAlert('user_deleted');
        handleFetchUser();
      }
      if (response.error) {
        setShowAlert('failed_to_delete');
      }
    } catch (err) {
      console.log(err);
      setShowLoader(false);
      setShowAlert('failed_to_delete');
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowAlert('');
    }, 1500);
  }, [showAlert]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <Container sx={{ p: 1 }}>
        <Container sx={{ pb: 1 }}>
          <Grid container py={1}>
            <Grid item>
              <Typography sx={{ m: 'auto', fontSize: '20px' }} fontFamily="Raleway" fontWeight={600}>
                Sure to delete user
              </Typography>
            </Grid>
            <Grid item sx={{ ml: 'auto' }}>
              <IconButton onClick={() => setOpen((s) => !s)}>
                <Iconify icon="material-symbols:close" />
              </IconButton>
            </Grid>
          </Grid>
        </Container>
        {showAlert === 'user_deleted' ? (
          <Alert severity="success" sx={{ width: '100%' }}>
            <strong>User deleted successfully</strong>
          </Alert>
        ) : (
          <>
            {showAlert === 'failed_to_delete' ? (
              <Alert severity="error" sx={{ width: '100%' }}>
                <strong>Failed to delete user</strong>
              </Alert>
            ) : null}
          </>
        )}
        <Container>
          <Grid container spacing={2} mt={1} sx={{ justifyContent: 'center' }}>
            <Grid item xs={4} md={4}>
              <Button
                onClick={() => handleUserDelete()}
                variant="contained"
                color="primary"
                sx={{ width: '100%', mt: 1 }}
                startIcon={showLoader ? <CircularProgress size={20} style={{ color: 'white' }} /> : null}
              >
                <Typography fontFamily="Raleway" fontWeight={600}>
                  Yes
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={4} md={4}>
              <Button
                variant="contained"
                sx={{
                  width: '100%',
                  backgroundColor: 'red',
                  mt: 1,
                  '&:hover': {
                    backgroundColor: 'red',
                  },
                }}
              >
                <Typography fontFamily="Raleway" fontWeight={600}>
                  No
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Dialog>
  );
};

export default DeleteUser;
