import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Checkbox,
    FormControlLabel,
  } from "@mui/material";
  
  export default function BomTable({ bom, bomDisplayNames, handleBomChange }){
  
  
    const sliceText = (text, maxLength = 30) => {
      return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    };
  
    return (
      <TableContainer>
        <Table size="small" aria-label="BOM table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox"></TableCell>
              <TableCell align="center"><Typography variant="subtitle2">Name</Typography></TableCell>
              <TableCell align="center"><Typography variant="subtitle2">Description</Typography></TableCell>
              <TableCell align="center"><Typography variant="subtitle2">Quantity</Typography></TableCell>
              <TableCell align="center"><Typography variant="subtitle2">Mass (kg)</Typography></TableCell>
              <TableCell align="center"><Typography variant="subtitle2">Est Carbon Footprint</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bom.map((row) => (
              <TableRow key={row?.componentID}>
                <TableCell padding="checkbox">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={bomDisplayNames.includes(row?.componentName)}
                        onChange={() => handleBomChange(row?.componentName)}
                        // disabled
                      />
                    }
                    label=""
                  />
                </TableCell>
                <TableCell align="center">{sliceText(row?.componentName)}</TableCell>
                <TableCell align="center">{sliceText(row?.componentDescription)}</TableCell>
                <TableCell align="center">{row?.quantity}</TableCell>
                <TableCell align="center">{row?.componentMass}</TableCell>
                <TableCell align="center">{row?.carbonFootprint}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  
  // Replace the existing BOM section in your SwipeableViews component:
  
  
