import React from "react";
import ReactECharts from "echarts-for-react";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import PropTypes from "prop-types";
import scopeImage from "./../../Assets2/pie_center.png";
import { roundDecimal } from "../../../utils/formatNumber";
import { toCamelCase } from "../../../utils/helperFunctions";

export const PieChart1 = ({ selectedLocation, selectedYear, data }) => {
  const colors = [
    "#00B0FA",
    "#230455",
    "#FD5308",
    "#FB9902",
    "#0033BB",
    "#5100D9",
    "#9700C7",
    "#E1004F",
    "#BD1100",
    "#EE4900",
    "#EF9100",
    "#02FEE0",
    "#A772FF",
    "#69DBFF",
    "#37877D",
    "#A87E00",
    "#44A600",
    "#F9BC02",
    "#66B132",
    "#0291CD",
    "#0247FE",
    "#3E01A4",
    "#8601B0",
    "#A7194B",
    "#FE2712",
  ];

  const colors2 = [
  
    "#8601B0",
    "#A7194B",
    "#FE2712",
    "#37877D",
    "#A87E00",     
    "#EF9100",
    "#02FEE0",
    "#A772FF",
    "#69DBFF",
    "#00B0FA",
    "#230455",
    "#FD5308",
    "#FB9902",
    "#0033BB",
    "#5100D9",
    "#9700C7",
    "#E1004F",
    "#BD1100",
    "#EE4900",
    "#44A600",
    "#F9BC02",
    "#66B132",
    "#0291CD",
    "#0247FE",
    "#3E01A4",
    
  ];

  const generateChartOption = ({ yearlyScopeData, scope }) => {
    const dataEntries = Object.entries(yearlyScopeData);
    const total = Object.values(yearlyScopeData).reduce(
      (acc, cur) => acc + cur,
      0
    );
    const stages = Object.keys(yearlyScopeData);
    const stages1 = stages.filter((_, index) => index % 4 === 0);
    const stages2 = stages.filter((_, index) => index % 4 === 1);
    const stages3 = stages.filter((_, index) => index % 4 === 2);
    const stages4 = stages.filter((_, index) => index % 4 === 3);

    return {
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          if (scope === "scope1") {
            return `${params.seriesName}<br />${toCamelCase(
              params.name
            )}("Diesel"): ${roundDecimal(params.data.value, 3)} (tCO2e)`;
          } else if (scope === "scope2") {
            return `${params.seriesName}<br />${toCamelCase(
              params.name
            )}("EB POWER"): ${roundDecimal(params.data.value, 3)} (tCO2e)`;
          } else if (scope === "scope3") {
            return `${params.seriesName}<br />${toCamelCase(
              params.name
            )}: ${roundDecimal(params.data.value, 2)} (tCO2e)`;
          }
        },
      },
      toolbox: {
        show: true,
        orient: "vertical",
        left: "right",
        top: "center",
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ["line", "bar", "stack"] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      graphic: [
        {
          type: "image",
          left: "center",
          top: "center",
          z: 10,
          style: {
            image: scopeImage,
            width: 128,
            height: 128,
          },
        },
      ],
      legend: [
        {
          orient: "vertical",
          left: 0,
          top: 0,
          type: "scroll",
          data: stages1,
          formatter: function (name) {
            const value = yearlyScopeData[name];
            const percent = ((value / total) * 100).toFixed(2);
            if (scope === "scope1") {
              return `${toCamelCase(name)} (Diesel) 
              \n${percent}%`;
            } else if (scope === "scope2") {
              return `${toCamelCase(name)} (EB Power)\n${percent}%`;
            } else {
              return `${toCamelCase(name)}\n${percent}%`;
            }
          },
          rich: {
            name: { padding: [0, 0, 5, 0] },
            percent: { padding: [5, 0, 0, 0] },
          },
        },
        {
          orient: "vertical",
          right: 0,
          top: 0,
          type: "scroll",
          data: stages2,
          formatter: function (name) {
            const value = yearlyScopeData[name];
            const percent = ((value / total) * 100).toFixed(2);
            if (scope === "scope1") {
              return `${toCamelCase(name)} (Diesel) 
              \n${percent}%`;
            } else if (scope === "scope2") {
              return `${toCamelCase(name)} (EB Power)\n${percent}%`;
            } else {
              return `${toCamelCase(name)}\n${percent}%`;
            }
          },
        },
        {
          orient: "vertical",
          left: 0,
          bottom: 0,
          type: "scroll",
          data: stages3,
          formatter: function (name) {
            const value = yearlyScopeData[name];
            const percent = ((value / total) * 100).toFixed(2);
            if (scope === "scope1") {
              return `${toCamelCase(name)} (Diesel) 
              \n${percent}%`;
            } else if (scope === "scope2") {
              return `${toCamelCase(name)} (EB Power)\n${percent}%`;
            } else {
              return `${toCamelCase(name)}\n${percent}%`;
            }
          },
        },
        {
          orient: "vertical",
          right: 0,
          bottom: 0,
          type: "scroll",
          data: stages4,
          formatter: function (name) {
            const value = yearlyScopeData[name];
            const percent = ((value / total) * 100).toFixed(2);
            if (scope === "scope1") {
              return `${toCamelCase(name)} (Diesel) 
              \n${percent}%`;
            } else if (scope === "scope2") {
              return `${toCamelCase(name)} (EB Power)\n${percent}%`;
            } else {
              return `${toCamelCase(name)}\n${percent}%`;
            }
          },
          rich: {
            name: { padding: [0, 0, 5, 0] },
            percent: { padding: [5, 2, 0, 0] },
          },
        },
      ],
      series: [
        {
          name: scope,
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          padAngle: 5,
          itemStyle: {
            borderRadius: 10,
            borderWidth: 10,
            borderColor: "white",
          },
          label: {
            show: false,
            position: "center",
          },
          labelLine: {
            show: false,
          },
          data: dataEntries.map(([name, value], index) => ({
            value,
            name,
            itemStyle: {
               color: scope === "scope2" ? colors2[index % colors2.length] : colors[index % colors.length],
            },
          })),
        },
      ],
    };
  };

  const getYearlyScopeData = (selectedYear, scope) => {
    const yearlyScopeData = {};
    data.forEach(({ location, years }) => {
      if (selectedLocation.includes(location)) {
        Object.keys(years).forEach((year) => {
          if (selectedYear.includes(year) || selectedYear.includes("All")) {
            Object.entries(years[year]).forEach(([stage, stageData]) => {
              if (
                stageData.yearlyData &&
                stageData.yearlyData.Total_Yearly_ScopeData &&
                stageData.yearlyData.Total_Yearly_ScopeData[scope]
              ) {
                yearlyScopeData[stage]
                  ? (yearlyScopeData[stage] +=
                      stageData?.yearlyData?.Total_Yearly_ScopeData[scope])
                  : (yearlyScopeData[stage] =
                      stageData?.yearlyData?.Total_Yearly_ScopeData[scope]);
              }
            });
          }
        });
      }
    });
    return { yearlyScopeData, scope };
  };

  const sliceScope = (str) => {
    return str.slice(0, 5) + " " + str.slice(5);
  };

  return (
    <Grid container spacing={2} mb={2}>
      {["scope1", "scope2", "scope3"].map((scope, index) => (
        <Grid key={index} item xs={12} sm={4}>
          <Card elevation={3}>
            <CardHeader
              title={`GHG Emission (${sliceScope(scope)})`}
              sx={{ textTransform: "uppercase" }}
              titleTypographyProps={{
                fontWeight: 600,
                fontSize: 20,
                textAlign: "center",
              }}
            />
            <CardContent>
              <ReactECharts
                style={{ height: "350px" }}
                option={generateChartOption(
                  getYearlyScopeData(selectedYear, scope)
                )}
              />
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

PieChart1.propTypes = {
  selectedLocation: PropTypes.string.isRequired,
  selectedYear: PropTypes.string.isRequired,
  handleLocationChange: PropTypes.func.isRequired,
  handleYearChange: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};
