/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import LineChartArea from "./LineChartArea";
import { array } from "prop-types";
import { MultipleSelectWithCheckBox } from "../../../components/form/selectComponents";
import { toCamelCase } from "../../../utils/helperFunctions";
import { MultipleSelectWithCheckBoxYear } from "../../../components/form/selectComponentYear";
// import { filterDataByLocationAndYear } from '../../../provider/EsgDataFilteration';

export default function LineChart({ data, stageId }) {
  const [monthlyDataStore, setMonthlyDataStore] = useState({});

  const [selectedLocation, setSelectedLocation] = useState(
    data ? data.map((location) => location.location) : []
  );
  const [selectedYear, setSelectedYear] = useState([]);
  const [commonYears, setCommonYears] = useState([]);

  const locations = data ? data.map((location) => location.location) : [];
  const isAllYearSelected =
    commonYears.length > 0 && selectedYear.length === commonYears.length;
  const isAllLocationSelected =
    locations.length > 0 && selectedLocation.length === locations.length;

  const handleYearChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedYear(
        selectedYear.length === commonYears.length ? [] : commonYears
      );
      return;
    }
    setSelectedYear(value);
  };

  const handleLocationChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedLocation(
        selectedLocation.length === locations.length ? [] : locations
      );
      return;
    }
    setSelectedLocation(value);
  };

  useEffect(() => {
    if (data) {
      const allYears = data
        .map((location) => Object.keys(location.years))
        .flat();
      const commonYearsSet = new Set(
        allYears.filter((year) =>
          allYears.every((y) =>
            data.some((location) => Object.keys(location.years).includes(y))
          )
        )
      );

      const sortedYearsArray = Array.from(commonYearsSet)
        .map((year) => year)
        .sort((a, b) => {
          const [fyAStart, fyAEnd] = a.split("-").map(Number);
          const [fyBStart, fyBEnd] = b.split("-").map(Number);
          return fyBStart - fyAStart || fyBEnd - fyAEnd;
        });

      setCommonYears(sortedYearsArray);
      setSelectedYear([
        sortedYearsArray[0],
        sortedYearsArray[sortedYearsArray.length - 1],
      ]);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const allLocations = data.map((location) => location.location);
      setSelectedLocation(allLocations);
    }
  }, [data]);

  const newSet = new Set();
  let found = false;

  Object.keys(monthlyDataStore).some((loc) => {
    return Object.keys(monthlyDataStore[loc]).some((year) => {
      return Object.keys(monthlyDataStore[loc][year]).some((month) => {
        newSet.add(Object.keys(monthlyDataStore[loc][year][month]));
        found = true;
        return true;
      });
    });
  });

  let activity = Array.from(newSet)[0];

  const [selectedActivity, setSelectedActivity] = useState("");
  useEffect(() => {
    if (activity && activity.length > 0 && selectedActivity === "") {
      setSelectedActivity(activity[0]);
    }
  }, [activity, selectedActivity]);

  const [areFieldsSelected, setAreFieldsSelected] = useState(true);

  useEffect(() => {
    if (
      selectedLocation.length > 0 &&
      selectedYear.length > 0 &&
      selectedActivity !== ""
    ) {
      setAreFieldsSelected(true);
    } else {
      setAreFieldsSelected(false);
    }
  }, [selectedYear, selectedLocation, selectedActivity]);

  const selectStyles = {
    width: "100%",
    height: "42px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    fontWeight: 900,
    border: "none !important",
    "&:focus": {
      border: "none",
    },
  };

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 180,
        overflowY: "scroll",
      },
    },
  };

  const theme = createTheme({
    typography: {
      fontFamily: "Poppins !important",
      fontWeight: "bolder !important",
    },
  });
  useEffect(() => {
    const newDataStore = {};
    data.forEach((entry) => {
      const yearlyData = entry.years;
      const location = entry.location;

      Object.keys(yearlyData).forEach((year) => {
        const monthlyData = yearlyData[year];
        if (!newDataStore[location]) {
          newDataStore[location] = {};
        }
        if (!newDataStore[location][year]) {
          newDataStore[location][year] = {};
        }
        Object.keys(monthlyData).forEach((stageKey) => {
          if (monthlyData[stageKey].stageId === parseInt(stageId)) {
            //use parseInt if we use api data, for dummy data remove parseInt

            if (stageKey === "Energy") {
              const stageData = monthlyData[stageKey];
              Object.keys(stageData.monthlyData).forEach((month) => {
                const { ENERGY_SAVED } =
                  stageData.monthlyData[month].activityBreakdown;
                const energySaved = ENERGY_SAVED ?? 0;

                if (!newDataStore[location]) {
                  newDataStore[location] = {};
                }
                if (!newDataStore[location][year]) {
                  newDataStore[location][year] = {};
                }
                newDataStore[location][year][month] = {
                  ENERGY_SAVED: energySaved,
                };
              });
            } else {
              const stageData = monthlyData[stageKey];
              Object.keys(stageData.monthlyData).forEach((month) => {
                // const { DIESEL_FIRST, EV_FIRST } =
                //   stageData.monthlyData[month].activityBreakdown;
                // const diselFirst = DIESEL_FIRST ?? 0;
                // const evFirst = EV_FIRST ?? 0;
                if (!newDataStore[location]) {
                  newDataStore[location] = {};
                }
                if (!newDataStore[location][year]) {
                  newDataStore[location][year] = {};
                }
                newDataStore[location][year][month] =
                  stageData.monthlyData[month].activityBreakdown;
              });
            }
          }
        });
      });
    });
    setMonthlyDataStore(newDataStore);
  }, [data, stageId]);

  const handleActivityChange = (e) => {
    setSelectedActivity(e.target.value);
  };

  // Create a mapping between original keys and camel case keys
  const activityMapping = {};
  activity?.forEach((act) => {
    activityMapping[act] = toCamelCase(act);
  });

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={4}>
          <MultipleSelectWithCheckBox
            label="Select Location"
            id="location-multi-select"
            value={selectedLocation}
            options={locations}
            onChange={handleLocationChange}
            isAllSelected={isAllLocationSelected}
          />
        </Grid>
        <Grid item xs={4}>
          <MultipleSelectWithCheckBox
            label="Select Year"
            id="year-multi-select"
            value={selectedYear}
            options={commonYears}
            onChange={handleYearChange}
            isAllSelected={isAllYearSelected}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="year-select-label">Select Activity</InputLabel>
            <Select
              labelId="year-select-label"
              id="year-select"
              value={selectedActivity}
              onChange={handleActivityChange}
              MenuProps={menuProps}
              style={selectStyles}
            >
              {activity &&
                activity.map((act) => (
                  <MenuItem value={act} key={act}>
                    {activityMapping[act]}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {areFieldsSelected && (
        <LineChartArea
          propsForAll={monthlyDataStore}
          locations={selectedLocation}
          years={selectedYear}
          activities={selectedActivity}
          stageId={stageId}
        />
      )}
    </ThemeProvider>
  );
}
