export const filterDataByLocationAndYear = (data, location, year, stageId) => {
  const filteredLocation =
    location === "All"
      ? data
      : data.filter((item) => item.location === location);
  const filteredData = {};

  filteredLocation.forEach((item) => {
    for (const itemYear in item.years) {
      if (year === "All" || itemYear === year) {
        const yearData = item.years[itemYear];
        ["Energy", "Water", "Waste", "Food_Travel"].forEach((category) => {
          const categoryData = yearData[category];
          if (categoryData) {
            if (!filteredData[category]) {
              filteredData[category] = {};
            }
            filteredData[category][itemYear] = {
              stageId: categoryData.stageId,
              monthly_Data: categoryData.monthlyData,
              yearlyData: categoryData.yearlyData,
            };
          }
        });
      }
    }
  });

  const filteredByStageId = {};
  Object.keys(filteredData).forEach((category) => {
    Object.keys(filteredData[category]).forEach((year) => {
      if (filteredData[category][year].stageId === parseInt(stageId)) {
        if (!filteredByStageId[category]) {
          filteredByStageId[category] = {};
        }
        filteredByStageId[category][year] = filteredData[category][year];
      }
    });
  });

  return filteredByStageId;
};

export const monthsSet = new Set([
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]);
