import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  Tab,
  Box,
  Typography,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  Container,
  RadioGroup,
  FormControlLabel,
  Radio,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CircularProgress, Grid } from "@material-ui/core";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Iconify from "../../components/iconify";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import { useSnackbar } from "notistack";
import markergif from "../Assets2/location.gif";
import { postBatchData } from "../../services/corporatesuppliers";
import { getLocationDataByLocationId } from "../../services/corporatecanvas";
import { APP_KEYS } from "../../utils/constants/AppKeyConstants";
import { PurchasedGoods } from "./PurchasedGoods";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionIcon from "@mui/icons-material/Description";
import TableViewIcon from "@mui/icons-material/TableView";
import PhotoIcon from "@mui/icons-material/Photo";

const ResetButton = styled(Button)({
  textTransform: "none",
  boxShadow: "none",
  fontSize: 16,
  fontWeight: "bolder",
  width: "15%",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#fff",
  borderColor: "#FA3F3F",
  color: "#FA3F3F",
  fontFamily: "Poppins",
  alignItems: "center",
  justifyContent: "space-evenly",
  "&:hover": {
    backgroundColor: "#fff",
    borderColor: "#FA3F3F",
    boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)",
  },
});

const SaveButton = styled(Button)({
  textTransform: "none",
  boxShadow: "none",
  fontSize: 16,
  fontWeight: "bolder",
  width: "15%",
  border: "1px solid #149BA1",
  lineHeight: 1.5,
  backgroundColor: "#E2E2E2",
  color: "#030229",
  fontFamily: "Poppins",
  alignItems: "center",
  justifyContent: "space-evenly",
  "&:hover": {
    backgroundColor: "#E2E2E2",
    borderColor: "#149BA1",
    boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)",
  },
});

const buttonGroupStyle = {
  width: "92%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  padding: "10px 0",
  position: "fixed",
  bottom: 0,
  backgroundColor: "#f6f8fe",
};

const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`,
  "& .MuiTab-root": {
    minWidth: "auto",
    marginRight: theme.spacing(3),
    padding: theme.spacing(0, 3),
    borderRadius: theme.shape.borderRadius,
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}));

const CenteredTabs = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));

const TabPanel = React.memo(({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <Box pl={1}>{children}</Box>}
  </div>
));

const UploadConfirmationDialog = ({ open, onClose, onConfirm, fileType }) => {
  const [hasFile, setHasFile] = useState(null);
  const [reason, setReason] = useState('');

  useEffect(() => {
    // Reset state when dialog opens
    if (open) {
      setHasFile(null);
      setReason('');
    }
  }, [open]);

  const handleConfirm = () => {
    onConfirm(hasFile, reason);
    onClose();
  };

  const isNextEnabled = hasFile !== null && (hasFile || (!hasFile && reason));

  const activityInputReasons = [
    "Data is based on highly reliable internal systems (e.g., automated logs)",
    "Data is an estimate based on past experience or rough calculations",
    "Data is a rough guess with no underlying methodology"
  ];

  const emissionFactorReasons = [
    "EF is derived from a well-known, commonly accepted practice but no document is provided",
    "EF is based on internal calculations or estimates without any external validation",
    "EF is a rough estimate with no clear basis"
  ];

  const reasons = fileType === 'uploadedFile' ? activityInputReasons : emissionFactorReasons;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {fileType === 'uploadedFile' ? 'Activity Input File' : 'Emission Factor File'} Upload Confirmation
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Do you have a file to upload?
        </Typography>
        <RadioGroup value={hasFile} onChange={(e) => setHasFile(e.target.value === 'true')}>
          <FormControlLabel value={true} control={<Radio />} label="Yes" />
          <FormControlLabel value={false} control={<Radio />} label="No" />
        </RadioGroup>
        
        {hasFile === false && (
          <>
            <Typography variant="body1" gutterBottom style={{ marginTop: '16px' }}>
              Please select a reason:
            </Typography>
            <RadioGroup value={reason} onChange={(e) => setReason(e.target.value)}>
              {reasons.map((reasonText, index) => (
                <FormControlLabel 
                  key={index} 
                  value={reasonText} 
                  control={<Radio />} 
                  label={reasonText} 
                />
              ))}
            </RadioGroup>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleConfirm} disabled={!isNextEnabled}>Next</Button>
      </DialogActions>
    </Dialog>
  );
};

const DynamicForm2 = ({ scopeData, scope, formData, setFormData }) => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [currentUploadType, setCurrentUploadType] = useState(null);
  const [currentActivity, setCurrentActivity] = useState(null);
  const [currentKey, setCurrentKey] = useState(null);

  const handleUploadClick = (activity, key, fileType) => {
    setCurrentActivity(activity);
    setCurrentKey(key);
    setCurrentUploadType(fileType);
    setOpenDialog(true);
  };

  const handleConfirmUpload = (hasFile, reason) => {
    if (hasFile) {
      // Trigger file input click
      document
        .getElementById(`${currentUploadType}-${currentActivity}-${currentKey}`)
        .click();
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [scope]: {
          ...prevData[scope],
          [selectedCategory]: {
            ...prevData[scope][selectedCategory],
            [currentActivity]: {
              ...prevData[scope][selectedCategory][currentActivity],
              [currentKey]: {
                ...prevData[scope][selectedCategory][currentActivity][
                  currentKey
                ],
                [currentUploadType]: [],
                [`${currentUploadType}Reason`]: reason,
              },
            },
          },
        },
      }));
    }
  };

  const handleFileUpload = (activity, key, fileType, event) => {
    const files = Array.from(event.target.files);
    setFormData((prevData) => ({
      ...prevData,
      [scope]: {
        ...prevData[scope],
        [selectedCategory]: {
          ...prevData[scope][selectedCategory],
          [activity]: {
            ...prevData[scope][selectedCategory][activity],
            [key]: {
              ...prevData[scope][selectedCategory][activity][key],
              [fileType]: files,
              [`${fileType}Reason`]: "", // Clear the reason when files are uploaded
            },
          },
        },
      },
    }));
  };

  console.log("formData", formData);
  useEffect(() => {
    if (selectedCategory && !formData[scope][selectedCategory]) {
      initializeFormData();
    }
  }, [selectedCategory]);

  const initializeFormData = () => {
    const newFormData = { ...formData };
    const category = scopeData[scope].categories[selectedCategory];

    if (!newFormData[scope][selectedCategory]) {
      newFormData[scope][selectedCategory] = {};

      Object.entries(category.activity).forEach(([activityName, activity]) => {
        activity.inputs.forEach((subActivity) => {
          subActivity.inputs.forEach((input) => {
            const firstMethod = input.methods[0];
            const methodData = {};

            firstMethod.activityInputs.forEach((activityInput) => {
              methodData[activityInput.name] = {
                value: "",
                unit: activityInput.unit[0],
              };
            });

            const key =
              subActivity.label != input.label
                ? `${subActivity.label}-${input.label}`
                : subActivity.label;
            newFormData[scope][selectedCategory][activityName] = {
              ...newFormData[scope][selectedCategory][activityName],
              [key]: {
                methodName: firstMethod.name,
                methodData: methodData,
                emissionFactor: "",
                uploadedFile: [],
                emissionFactorFile: [],
                emissionFactorFileReason:"",
                uploadedFileReason:""
              },
            };
          });
        });
      });

      setFormData(newFormData);
    }
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handleMethodChange = (activity, key, method) => {
    setFormData((prevData) => ({
      ...prevData,
      [scope]: {
        ...prevData[scope],
        [selectedCategory]: {
          ...prevData[scope][selectedCategory],
          [activity]: {
            ...prevData[scope][selectedCategory][activity],
            [key]: {
              ...prevData[scope][selectedCategory][activity][key],
              methodName: method.name,
              methodData: method.activityInputs.reduce(
                (acc, input) => ({
                  ...acc,
                  [input.name]: { value: "", unit: input.unit[0] },
                }),
                {}
              ),
            },
          },
        },
      },
    }));
  };

  const handleActivityInputChange = useCallback((activity, key, inputName, value) => {
    setFormData((prevData) => {
      const currentValue = prevData[scope]?.[selectedCategory]?.[activity]?.[key]?.methodData?.[inputName]?.value;
      
      // Only update if the value has actually changed
      if (currentValue !== value) {
        return {
          ...prevData,
          [scope]: {
            ...prevData[scope],
            [selectedCategory]: {
              ...prevData[scope]?.[selectedCategory],
              [activity]: {
                ...prevData[scope]?.[selectedCategory]?.[activity],
                [key]: {
                  ...prevData[scope]?.[selectedCategory]?.[activity]?.[key],
                  methodData: {
                    ...prevData[scope]?.[selectedCategory]?.[activity]?.[key]?.methodData,
                    [inputName]: {
                      ...prevData[scope]?.[selectedCategory]?.[activity]?.[key]?.methodData?.[inputName],
                      value: value,
                    },
                  },
                },
              },
            },
          },
        };
      }
      return prevData; // Return unchanged state if the value hasn't changed
    });
  }, [scope, selectedCategory, setFormData]);

  const handleUnitChange = (activity, key, inputName, unit) => {
    setFormData((prevData) => ({
      ...prevData,
      [scope]: {
        ...prevData[scope],
        [selectedCategory]: {
          ...prevData[scope][selectedCategory],
          [activity]: {
            ...prevData[scope][selectedCategory][activity],
            [key]: {
              ...prevData[scope][selectedCategory][activity][key],
              methodData: {
                ...prevData[scope][selectedCategory][activity][key].methodData,
                [inputName]: {
                  ...prevData[scope][selectedCategory][activity][key]
                    .methodData[inputName],
                  unit: unit,
                },
              },
            },
          },
        },
      },
    }));
  };

  const handleEmissionFactorChange = (activity, key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [scope]: {
        ...prevData[scope],
        [selectedCategory]: {
          ...prevData[scope][selectedCategory],
          [activity]: {
            ...prevData[scope][selectedCategory][activity],
            [key]: {
              ...prevData[scope][selectedCategory][activity][key],
              emissionFactor: value,
            },
          },
        },
      },
    }));
  };

  return (
    <Box display="flex">
      <Box width="200px" borderRight={1} borderColor="divider">
        <List>
          {Object.keys(scopeData[scope].categories).map((category) => (
            <ListItem
              button
              key={category}
              onClick={() => handleCategoryClick(category)}
              selected={selectedCategory === category}
            >
              <ListItemText primary={category} />
            </ListItem>
          ))}
        </List>
      </Box>

      <Box flex={1} p={3}>
        {selectedCategory && (
          <>
            <Typography variant="h4">{selectedCategory}</Typography>
            {Object.entries(
              scopeData[scope].categories[selectedCategory].activity
            ).map(([activityName, activity]) => (
              <Box key={activityName} mt={4}>
                <Typography variant="h5">{activityName}</Typography>
                {activity.inputs.map((subActivity) =>
                  subActivity.inputs.map((input, index) => {
                    const key =
                      subActivity.label != input.label
                        ? `${subActivity.label}-${input.label}`
                        : subActivity.label;
                    return (
                      <Box key={key} mt={2}>
                        <Typography variant="h6">{key}</Typography>
                        <FormControl fullWidth margin="normal">
                          <InputLabel>Method</InputLabel>
                          <Select
                            value={
                              formData[scope]?.[selectedCategory]?.[
                                activityName
                              ]?.[key]?.methodName || ""
                            }
                            onChange={(e) =>
                              handleMethodChange(
                                activityName,
                                key,
                                input.methods.find(
                                  (m) => m.name === e.target.value
                                )
                              )
                            }
                          >
                            {input.methods.map((method) => (
                              <MenuItem key={method.name} value={method.name}>
                                {method.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {input.methods
                          .find(
                            (m) =>
                              m.name ===
                              formData[scope]?.[selectedCategory]?.[
                                activityName
                              ]?.[key]?.methodName
                          )
                          ?.activityInputs.map((activityInput) => (
                            <Box key={activityInput.name} mt={2}>
                              <TextField
                                label={activityInput.name}
                                fullWidth
                                type={activityInput.inputType}
                                value={
                                  formData[scope]?.[selectedCategory]?.[
                                    activityName
                                  ]?.[key]?.methodData?.[activityInput.name]
                                    ?.value || ""
                                }
                                onChange={(e) =>
                                  handleActivityInputChange(
                                    activityName,
                                    key,
                                    activityInput.name,
                                    e.target.value
                                  )
                                }
                              />
                              <RadioGroup
                                row
                                value={
                                  formData[scope]?.[selectedCategory]?.[
                                    activityName
                                  ]?.[key]?.methodData?.[activityInput.name]
                                    ?.unit?.name || ""
                                }
                                onChange={(e) =>
                                  handleUnitChange(
                                    activityName,
                                    key,
                                    activityInput.name,
                                    activityInput.unit.find(
                                      (u) => u.name === e.target.value
                                    )
                                  )
                                }
                              >
                                {activityInput.unit.map((unit) => (
                                  <FormControlLabel
                                    key={unit.name}
                                    value={unit.name}
                                    control={<Radio />}
                                    label={unit.name}
                                  />
                                ))}
                              </RadioGroup>
                            </Box>
                          ))}
                        <TextField
                          label="Emission Factor"
                          fullWidth
                          margin="normal"
                          value={
                            formData[scope]?.[selectedCategory]?.[
                              activityName
                            ]?.[key]?.emissionFactor || ""
                          }
                          onChange={(e) =>
                            handleEmissionFactorChange(
                              activityName,
                              key,
                              e.target.value
                            )
                          }
                        />

                        <Box mt={2}>
                          <Button
                            variant="contained"
                            onClick={() =>
                              handleUploadClick(
                                activityName,
                                key,
                                "uploadedFile"
                              )
                            }
                          >
                            Upload Activity Files
                          </Button>
                          <input
                            accept="*/*"
                            style={{ display: "none" }}
                            id={`uploadedFile-${activityName}-${key}`}
                            multiple
                            type="file"
                            onChange={(e) =>
                              handleFileUpload(
                                activityName,
                                key,
                                "uploadedFile",
                                e
                              )
                            }
                          />
                          {formData[scope]?.[selectedCategory]?.[
                            activityName
                          ]?.[key]?.uploadedFile?.length > 0 && (
                            <Typography variant="body2">
                              {
                                formData[scope][selectedCategory][activityName][
                                  key
                                ].uploadedFile.length
                              }{" "}
                              file(s) uploaded
                            </Typography>
                          )}
                          {formData[scope]?.[selectedCategory]?.[
                            activityName
                          ]?.[key]?.uploadedFileReason && (
                            <Typography variant="body2">
                              Reason:{" "}
                              {
                                formData[scope][selectedCategory][activityName][
                                  key
                                ].uploadedFileReason
                              }
                            </Typography>
                          )}
                        </Box>

                        <Box mt={2}>
                          <Button
                            variant="contained"
                            onClick={() =>
                              handleUploadClick(
                                activityName,
                                key,
                                "emissionFactorFile"
                              )
                            }
                          >
                            Upload Emission Factor Files
                          </Button>
                          <input
                            accept="*/*"
                            style={{ display: "none" }}
                            id={`emissionFactorFile-${activityName}-${key}`}
                            multiple
                            type="file"
                            onChange={(e) =>
                              handleFileUpload(
                                activityName,
                                key,
                                "emissionFactorFile",
                                e
                              )
                            }
                          />
                          {formData[scope]?.[selectedCategory]?.[
                            activityName
                          ]?.[key]?.emissionFactorFile?.length > 0 && (
                            <Typography variant="body2">
                              {
                                formData[scope][selectedCategory][activityName][
                                  key
                                ].emissionFactorFile.length
                              }{" "}
                              file(s) uploaded
                            </Typography>
                          )}
                          {formData[scope]?.[selectedCategory]?.[
                            activityName
                          ]?.[key]?.emissionFactorFileReason && (
                            <Typography variant="body2">
                              Reason:{" "}
                              {
                                formData[scope][selectedCategory][activityName][
                                  key
                                ].emissionFactorFileReason
                              }
                            </Typography>
                          )}
                        </Box>

                        <UploadConfirmationDialog
                          open={openDialog}
                          onClose={() => setOpenDialog(false)}
                          onConfirm={handleConfirmUpload}
                          fileType={currentUploadType}
                        />
                      </Box>
                    );
                  })
                )}
              </Box>
            ))}
          </>
        )}
      </Box>
    </Box>
  );
};

const hasObjects=(arr)=>{
  return Array.isArray(arr) && arr.length > 0 && arr.some(item => typeof item === 'object' && item !== null);
}

export const ActivityDialog = ({
  open,
  handleClose,
  handleSaveLocation,
  isLocationSaved,
  selectedLocation,
}) => {
  const userInfo = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [formData, setFormData] = useState({
    "Scope 1": {},
    "Scope 2": {},
    "Scope 3": {},
  });
  const [confirmSubmission, setConfirmSubmission] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showBackButton, setShowBackButton] = useState(true);
  const [roleId, setRoleId] = useState("");
  const { id } = useParams();
  const { search } = useLocation();
  const location = useLocation();
  const queryParams = new URLSearchParams(search);
  const locationName = queryParams.get("name");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(location?.state?.configuredTemplate || {});

  useEffect(() => {
    if (roleId === 6) {
      setShowBackButton(false);
    } else {
      setShowBackButton(true);
    }
  }, [userInfo]);

  const fetchConfiguredTemplate = async () => {
    const locationData = await getLocationDataByLocationId(id);
    if (!location?.state?.configuredTemplate && locationData) {
      setData(locationData[0]?.configuredTemplate || {});
    }
  };

  useEffect(() => {
    if (userInfo?.roles?.length > 0) {
      setRoleId(userInfo?.roles[0]?.roleId);
    }
  }, [userInfo?.roles]);

  useEffect(() => {
    fetchConfiguredTemplate();
  }, []);

  const validationSchema = Yup.object({
    selectedDate: Yup.date().required("Date is required"),
    formData: Yup.object({
      "Scope 1": Yup.object().required("Scope 1 data is required"),
      "Scope 2": Yup.object().required("Scope 2 data is required"),
      "Scope 3": Yup.object().required("Scope 3 data is required"),
    }),
  });

  const handleTabChange = useCallback((event, newValue) => {
    setSelectedTab(newValue);
  }, []);

  const handleDateChange = useCallback((date) => {
    setSelectedDate(date);
  }, []);

  const handleSubmit = useCallback(async () => {
    console.log("submit", uploadedFile);
    setShowLoader(true);
    try {
      const jsonData = {
        location: { id, locationName },
        date: selectedDate
          ? `${String(selectedDate.getMonth() + 1).padStart(
              2,
              "0"
            )}/${selectedDate.getFullYear()}`
          : null,
        "Scope 1": formData["Scope 1"],
        "Scope 2": formData["Scope 2"],
        "Scope 3": formData["Scope 3"],
      };
  
      const requestPayload = new FormData();
      requestPayload.append("locationId", id);
      requestPayload.append("locationName", locationName);
      requestPayload.append(
        "month",
        String(selectedDate.getMonth() + 1).padStart(2, "0")
      );
      requestPayload.append("year", selectedDate.getFullYear());
  
      // Append all files to FormData and update jsonData
      Object.entries(formData).forEach(([scope, scopeData]) => {
        Object.entries(scopeData).forEach(([category, categoryData]) => {
          Object.entries(categoryData).forEach(([activity, activityData]) => {
            Object.entries(activityData).forEach(([key, data]) => {
              if (data.uploadedFile) {
                data.uploadedFile.forEach((file, index) => {
                  
                  if (file instanceof File) {
                    requestPayload.append(file.name, file);
                    if (!jsonData[scope]) jsonData[scope] = {};
                    if (!jsonData[scope][category]) jsonData[scope][category] = {};
                    if (!jsonData[scope][category][activity]) jsonData[scope][category][activity] = {};
                    if (!jsonData[scope][category][activity][key]) jsonData[scope][category][activity][key] = {};
                    if (!jsonData[scope][category][activity][key].uploadedFile || 
                      (Array.isArray(jsonData[scope][category][activity][key].uploadedFile) && 
                       hasObjects(jsonData[scope][category][activity][key].uploadedFile))) {
                    jsonData[scope][category][activity][key].uploadedFile = [];
                  }
                    jsonData[scope][category][activity][key].uploadedFile.push(file.name);
                  } else if (typeof file === 'string') {
                    // If file is already a string (filename), just add it to jsonData
                    if (!jsonData[scope]) jsonData[scope] = {};
                    if (!jsonData[scope][category]) jsonData[scope][category] = {};
                    if (!jsonData[scope][category][activity]) jsonData[scope][category][activity] = {};
                    if (!jsonData[scope][category][activity][key]) jsonData[scope][category][activity][key] = {};
                    if (!jsonData[scope][category][activity][key].uploadedFile || 
                      (Array.isArray(jsonData[scope][category][activity][key].uploadedFile) && 
                       hasObjects(jsonData[scope][category][activity][key].uploadedFile))) {
                    jsonData[scope][category][activity][key].uploadedFile = [];
                  }
                    jsonData[scope][category][activity][key].uploadedFile.push(file);
                  }
                });
              }
              if (data.emissionFactorFile) {
                data.emissionFactorFile.forEach((file, index) => {
                  if (file instanceof File) {
                    requestPayload.append(file.name, file);
                    if (!jsonData[scope]) jsonData[scope] = {};
                    if (!jsonData[scope][category]) jsonData[scope][category] = {};
                    if (!jsonData[scope][category][activity]) jsonData[scope][category][activity] = {};
                    if (!jsonData[scope][category][activity][key]) jsonData[scope][category][activity][key] = {};
                    if (!jsonData[scope][category][activity][key].emissionFactorFile || 
                      (Array.isArray(jsonData[scope][category][activity][key].emissionFactorFile) && 
                       hasObjects(jsonData[scope][category][activity][key].emissionFactorFile))) {
                    jsonData[scope][category][activity][key].emissionFactorFile = [];
                  }
                    jsonData[scope][category][activity][key].emissionFactorFile.push(file.name);
                  } else if (typeof file === 'string') {
                    // If file is already a string (filename), just add it to jsonData
                    if (!jsonData[scope]) jsonData[scope] = {};
                    if (!jsonData[scope][category]) jsonData[scope][category] = {};
                    if (!jsonData[scope][category][activity]) jsonData[scope][category][activity] = {};
                    if (!jsonData[scope][category][activity][key]) jsonData[scope][category][activity][key] = {};
                    if (!jsonData[scope][category][activity][key].emissionFactorFile || 
                      (Array.isArray(jsonData[scope][category][activity][key].emissionFactorFile) && 
                       hasObjects(jsonData[scope][category][activity][key].emissionFactorFile))) {
                    jsonData[scope][category][activity][key].emissionFactorFile = [];
                  }
                    jsonData[scope][category][activity][key].emissionFactorFile.push(file);
                  }
                });
              }
            });
          });
        });
      });
  
      requestPayload.append("templateValue", JSON.stringify(jsonData));
      
  

      console.log(jsonData)
      const result = await postBatchData(requestPayload);
  
      if (result?.message === "error") {
        enqueueSnackbar("Data not submitted", { variant: "error" });
      } else {
        enqueueSnackbar("Data submitted successfully", { variant: "success" });
      }
      setConfirmSubmission(false);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("An error occurred. Please try again.", {
        variant: "error",
      });
    } finally {
      setShowLoader(false);
    }
  }, [selectedDate, formData, id, locationName, handleClose, enqueueSnackbar]);

  
  const handleFormReset = useCallback(() => {
    setFormData({
      "Scope 1": {},
      "Scope 2": {},
      "Scope 3": {},
    });
    setSelectedDate(null);
    setConfirmSubmission(false);
  }, []);

  return (
    <Box>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 10px",
        }}
      >
        {showBackButton && (
          <Button
            sx={{
              marginBottom: "10px",
              backgroundColor: "#222",
              "&:hover": { backgroundColor: "#149BA1" },
              color: "#fff",
              display: "flex",
              width: "100px",
              alignItems: "center",
              borderRadius: "10px",
            }}
            onClick={() => navigate("/carboncompete/suppliercorporatecanvas")}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Iconify icon="icon-park-outline:back" color="white" />
              <Typography
                fontFamily="Poppins"
                style={{ textTransform: "capitalize" }}
              >
                Back
              </Typography>
            </div>
          </Button>
        )}

        <div style={{ textAlign: "center" }}>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{
              fontFamily: "poppins",
              fontSize: "29px",
              fontWeight: 600,
              textAlign: "center",
              border: "1px solid #ccc",
              borderRadius: "10px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
              display: "inline-flex",
              alignItems: "center",
              padding: "3px 20px",
            }}
          >
            Activity inputs for {locationName}
            <span style={{ marginLeft: "10px" }}>
              <img
                style={{ width: "35px", height: "35px" }}
                src={markergif}
                alt="marker"
              />
            </span>
          </Typography>
        </div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            views={["month", "year"]}
            value={selectedDate}
            onChange={handleDateChange}
            sx={{
              borderRadius: 3,
              width: "31%",
            }}
            slotProps={{
              textField: {
                placeholder: "Month & Year",
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    fontFamily: "Poppins",
                  },
                }}
              />
            )}
          />
        </LocalizationProvider>
      </div>
      <div>
        <CenteredTabs>
          <StyledTabs value={selectedTab} onChange={handleTabChange}>
            <Tab
              label="Scope 1"
              sx={{ fontFamily: "Poppins, Arial, sans-serif" }}
            />
            <Tab
              label="Scope 2"
              sx={{ fontFamily: "Poppins, Arial, sans-serif" }}
            />
            <Tab
              label="Scope 3"
              sx={{ fontFamily: "Poppins, Arial, sans-serif" }}
            />
          </StyledTabs>
        </CenteredTabs>
      </div>
      <TabPanel value={selectedTab} index={0}>
        {Object.keys(data).length && (
          <DynamicForm2
            scopeData={data}
            scope="Scope 1"
            formData={formData}
            setFormData={setFormData}
            uploadedFile={uploadedFile}
            setUploadedFile={setUploadedFile}
          />
        )}
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        {Object.keys(data).length && (
          <DynamicForm2
            scopeData={data}
            scope="Scope 2"
            formData={formData}
            setFormData={setFormData}
            uploadedFile={uploadedFile}
            setUploadedFile={setUploadedFile}
          />
        )}
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        {Object.keys(data).length && (
          <DynamicForm2
            scopeData={data}
            scope="Scope 3"
            formData={formData}
            setFormData={setFormData}
            uploadedFile={uploadedFile}
            setUploadedFile={setUploadedFile}
          />
        )}
      </TabPanel>
      <Box maxWidth="90%" sx={buttonGroupStyle}>
        {confirmSubmission ? (
          <div style={buttonGroupStyle}>
            <ResetButton
              variant="contained"
              onClick={() => setConfirmSubmission(false)}
            >
              <Typography fontFamily="Raleway" fontWeight={600}>
                No
              </Typography>
            </ResetButton>
            <SaveButton
              variant="contained"
              onClick={handleSubmit}
              startIcon={
                showLoader ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : null
              }
            >
              <Typography fontFamily="Raleway" fontWeight={600}>
                Yes
              </Typography>
            </SaveButton>
          </div>
        ) : (
          <div style={buttonGroupStyle}>
            <ResetButton
              variant="outlined"
              onClick={handleFormReset}
              disabled={confirmSubmission}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 24"
                fill="none"
              >
                <path
                  d="M9.09375 23.125C6.53125 22.9167 4.375 21.8646 2.625 19.9688C0.875 18.0729 0 15.8229 0 13.2188C0 11.6146 0.369792 10.1042 1.10938 8.6875C1.84896 7.27083 2.88542 6.11458 4.21875 5.21875L5.5625 6.5625C4.39583 7.25 3.48958 8.19271 2.84375 9.39062C2.19792 10.5885 1.875 11.8646 1.875 13.2188C1.875 15.3021 2.5625 17.1042 3.9375 18.625C5.3125 20.1458 7.03125 21.0208 9.09375 21.25V23.125ZM10.9688 23.125V21.25C13.0521 21 14.7708 20.1198 16.125 18.6094C17.4792 17.099 18.1562 15.3021 18.1562 13.2188C18.1562 10.9479 17.3698 9.02604 15.7969 7.45312C14.224 5.88021 12.3021 5.09375 10.0312 5.09375H9.40625L11.2812 6.96875L9.9375 8.3125L5.78125 4.15625L9.9375 0L11.2812 1.34375L9.40625 3.21875H10.0312C12.8229 3.21875 15.1875 4.19271 17.125 6.14062C19.0625 8.08854 20.0312 10.4479 20.0312 13.2188C20.0312 15.8229 19.1615 18.0729 17.4219 19.9688C15.6823 21.8646 13.5312 22.9167 10.9688 23.125Z"
                  fill="#FA3F3F"
                />
              </svg>
              <Typography fontFamily="Raleway" fontWeight={600}>
                Reset
              </Typography>
            </ResetButton>
            <SaveButton
              variant="contained"
              onClick={() => setConfirmSubmission(true)}
              startIcon={
                showLoader ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : null
              }
            >
              <Typography fontFamily="Raleway" fontWeight={600}>
                Submit
              </Typography>
            </SaveButton>
          </div>
        )}
      </Box>
    </Box>
  );
};
