import React, { useEffect, useState } from "react";
import { deleteEsgData, getEsgData, putEsgData } from "../../services/esgdata";
import { APP_KEYS } from "../../utils/constants/AppKeyConstants";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { stringWithUnderScore } from "../../utils/helperFunctions";
import StripDataGrid from "../../components/data-grid/StripedDataGrid";
import { roundDecimal } from "../../utils/formatNumber";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { enqueueSnackbar } from "notistack";
import {
  NOTIFICATION_ERROR,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_WARN,
} from "../../utils/constants/NotificationConstants";
import { getStages } from "../../services/stage";
import EditModal from "./EsgEditModal";

export const ActivityHistory = () => {
  const columns = [
    // {
    //   field: 'id',
    // headerName: 'S.No',
    // filterable: false,
    //   renderCell: (params) => params.api.getRowIndexRelativeToVisibleRows(params.id) + 1,
    // },
    { field: "location", headerName: "Location", flex: 1 },
    {
      field: "date",
      headerName: "Batch Date",
      flex: 1,
      renderCell: (params) => {
        return `${stringWithUnderScore(params.row.date)}`;
      },
    },
    {
      field: "stageName",
      headerName: "Stage Name",
      flex: 1,
      renderCell: (params) => {
        return `${stringWithUnderScore(params.row.stageName)}`;
      },
    },
    {
      field: "emission",
      headerName: "Emission",
      flex: 1,
      renderCell: (params) => {
        return `${roundDecimal(params.row.emission, 2)} KgCo2e`;
      },
    },
    {
      field: "Edit Batch",
      align: "center",
      flex: 0.4,
      renderCell: (cellValues) => {
        return (
          <IconButton aria-label="delete" size='medium' onClick={(event) => handleEdit(event, cellValues)} disabled={cellValues.row.stageId == 19}>
            <EditIcon fontSize="inherit" />
          </IconButton>
        );
      },
    },
    {
      field: "Delete Batch",
      align: "center",
      flex: 0.6,
      renderCell: (cellValues) => {
        return (
          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
              <div>
                <IconButton aria-label="delete" size='medium' {...bindTrigger(popupState)}>
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Typography sx={{ p: 2, fontFamily: "Poppins" }}>
                    Delete this Batch Data?
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    p={2}
                    pt={0}
                  >
                    <Button
                      variant="outlined"
                      onClick={popupState.close}
                      size="small"
                    >
                      No
                    </Button>
                    <Button
                      variant="contained"
                      onClick={(event) =>
                        handleDelete(event, cellValues, popupState)
                      }
                      size="small"
                    >
                      Yes
                    </Button>
                  </Stack>
                </Popover>
              </div>
            )}
          </PopupState>
        );
      },
    },
  ];
  const [esgData, setEsgData] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [stageSchemaProps, setStageSchemaProps] = useState([]);
  const [stageValues, setStageValues] = useState({});
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedStage, setSelectedStage] = useState("");
  const [loading, setLoading] = useState(false);
  const [editLoad, setEditLoad] = useState(false);

  const userData = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));
  const compId = userData.compId;

  const handleEdit = async (event, cellValues) => {
    let rowData = { ...cellValues.row }; // Create a shallow copy of the row data to avoid mutating the original data

    // If the rowData already contains converted data, we should skip conversion.
    // This will prevent re-applying the conversion if the modal is opened multiple times.
    if (rowData.stageName === "Energy" && !rowData.conversionApplied) {
      rowData = { ...rowData }; // Shallow copy to avoid mutating original rowData
      rowData.activityBreakDown = { ...rowData.activityBreakDown }; // Shallow copy of activityBreakDown

      Object.entries(rowData.activityBreakDown).forEach(([key, value]) => {
        if (key === "EB_POWER" || key === "RE_POWER") {
          rowData.activityBreakDown[key] = roundDecimal(((value || 0) * 277.77), 3);
        }
        if (key === "DIESEL") {
          rowData.activityBreakDown[key] = roundDecimal(((value || 0) * 0.02487), 3);
        }
      });

      // Mark the conversion as applied
      rowData.conversionApplied = true;
    }

    const response = await getStages([cellValues.row.stageId]);

    if (response.success && response.data.length) {
      setStageValues(rowData);
      setStageSchemaProps(response.data[0]?.templateSchema?.templateSchemaProp);
      setEditModal(true);
    } else {
      enqueueSnackbar("Unable to edit(cannot get stage schema)", {
        autoHideDuration: 5000,
        variant: NOTIFICATION_ERROR,
      });
    }
  };

  const handleSubmission = async (payload) => {
    try {
      setEditLoad(true);
      const templateValueProp = stageSchemaProps.map((item) => {
        let value;

        if (item.ctrlName === "stagename") {
          value =
            stageSchemaProps[3] &&
            stageSchemaProps[3].ctrlData &&
            stageSchemaProps[3].ctrlData[0] &&
            stageSchemaProps[3].ctrlData[0].name;
        } else if (item.ctrlName === "stagetype") {
          value =
            stageSchemaProps[2] &&
            stageSchemaProps[2].ctrlData &&
            stageSchemaProps[2].ctrlData[0] &&
            stageSchemaProps[2].ctrlData[0].name;
        } else if (item.ctrlName === "Name") {
          value = userData.userName;
        } else if (item.ctrlName === "Email") {
          value = userData.userEmail;
        } else {
          value = payload[item.ctrlName] || "";
        }

        return {
          category: item.category,
          ctrlName: item.ctrlName,
          ctrlDisplayName: item.ctrlDisplayName,
          ctrlType: item.ctrlType,
          scope: item.scope,
          ctrlData:
            item.ctrlName === "Location" && item.ctrlDropdown
              ? [{ id: payload["locationId"], name: payload["location"] }]
              : [{ id: userData.compId, name: value }],
          ctrlApi: item.ctrlApi,
        };
      });

      const requestData = {
        location: stageValues?.location,
        locationId: stageValues?.locationId,
        year: payload?.year,
        month: payload?.month,
        stageName: stageValues?.stageName,
        stageId: stageValues?.stageId,
        compId: userData.compId,
        companyName: userData.userName,
        stageData: templateValueProp,
        isActive: true,
      };

      const response = await putEsgData(requestData);

      if (
        response &&
        response.success === true
        // && response.statusCode === "135101"
      ) {
        fetchData()
        setEditModal(false)
        enqueueSnackbar(response.msg, {
          autoHideDuration: 5000,
          variant: NOTIFICATION_SUCCESS,
        });
      } else {
        enqueueSnackbar(response.msg, {
          autoHideDuration: 5000,
          variant: NOTIFICATION_WARN,
        });
      }
    } catch (error) {
      enqueueSnackbar("Something Went Wrong", {
        autoHideDuration: 3000,
        variant: NOTIFICATION_ERROR,
      });
    }
  };

  const handleDelete = async (event, cellValues, popupState) => {
    let payload = {
      location: cellValues.row.location,
      stageName: cellValues.row.stage,
      month: cellValues.row.date.split("_")[0],
      year: cellValues.row.date.split("_")[1],
    };
    const response = await deleteEsgData({ compId: compId, ...payload });
    await popupState.close();
    if (response.success && response.data === 1) {
      await fetchData();
      enqueueSnackbar(response.msg, {
        autoHideDuration: 5000,
        variant: NOTIFICATION_SUCCESS,
      });
    } else {
      enqueueSnackbar(response.msg, {
        autoHideDuration: 5000,
        variant: NOTIFICATION_ERROR,
      });
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getEsgData(compId);

      if (response.data) {
        setEsgData(response.data);
      } else {
        console.error("Error fetching ESG data:", response.msg);
      }
    } catch (error) {
      console.error("Error fetching ESG data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const set = new Set();

  Object.keys(esgData).forEach((location) => {
    Object.keys(esgData[location].years).forEach((year) => {
      set.add(year);
    });
  });
  let uniqueYear = ["Default", ...set];

  const locations = esgData
    ? ["Default", ...esgData.map((location) => location.location)]
    : [];

  const allStagesSet = new Set();

  Object.keys(esgData).forEach((loc) => {
    Object.keys(esgData[loc].years).forEach((year) => {
      Object.keys(esgData[loc].years[year]).forEach((stage) => {
        allStagesSet.add(stage);
      });
    });
  });

  const arraySet = ["Default", ...allStagesSet];

  const selectStyles = {
    width: "100%",
    height: "42px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    fontWeight: 900,
    border: "none !important",
    "&:focus": {
      border: "none",
    },
  };

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 180,
        overflowY: "scroll",
      },
    },
  };

  const theme = createTheme({
    typography: {
      fontFamily: "Poppins !important",
      fontWeight: "bolder !important",
    },
  });

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value);
  };

  const handleStageChange = (e) => {
    setSelectedStage(e.target.value);
  };

  let filteredData = [];

  if (selectedLocation === "Default" || selectedLocation === "") {
    if (selectedYear === "Default" || selectedYear === "") {
      if (selectedStage === "Default" || selectedStage === "") {
        filteredData = esgData.filter(
          (item) => Object.keys(item.years).length > 0
        );
      } else {
        esgData.forEach((loc) => {
          Object.keys(loc.years).forEach((year) => {
            Object.keys(loc.years[year]).forEach((stage) => {
              if (selectedStage === stage) {
                filteredData.push({
                  location: loc.location,
                  locationId: loc.locationId,
                  years: {
                    [year]: {
                      [stage]: loc.years[year][stage],
                    },
                  },
                });
              }
            });
          });
        });
      }
    } else {
      if (selectedStage === "Default" || selectedStage === "") {
        filteredData = esgData
          .filter((item) => item.years[selectedYear] !== undefined)
          .map((item) => ({
            location: item.location,
            locationId: item.locationId,
            years: {
              [selectedYear]: item.years[selectedYear],
            },
          }));
      } else {
        esgData.forEach((loc) => {
          Object.keys(loc.years).forEach((year) => {
            if (year === selectedYear) {
              Object.keys(loc.years[year]).forEach((stage) => {
                if (stage === selectedStage) {
                  filteredData.push({
                    location: loc.location,
                    locationId: loc.locationId,
                    years: {
                      [year]: {
                        [stage]: loc.years[year][stage],
                      },
                    },
                  });
                }
              });
            }
          });
        });
      }
    }
  } else {
    if (selectedYear === "Default" || selectedYear === "") {
      if (selectedStage === "Default" || selectedStage === "") {
        filteredData = esgData
          .filter(
            (item) =>
              Object.keys(item.years).length > 0 &&
              item.location === selectedLocation
          )
          .map((item) => ({
            location: item.location,
            locationId: item.locationId,
            years: item.years,
          }));
      } else {
        esgData.forEach((loc) => {
          if (loc.location === selectedLocation) {
            Object.keys(loc.years).forEach((year) => {
              Object.keys(loc.years[year]).forEach((stage) => {
                if (selectedStage === stage) {
                  filteredData.push({
                    location: loc.location,
                    locationId: loc.locationId,
                    years: {
                      [year]: {
                        [stage]: loc.years[year][stage],
                      },
                    },
                  });
                }
              });
            });
          }
        });
      }
    } else {
      esgData.forEach((loc) => {
        if (loc.location === selectedLocation) {
          Object.keys(loc.years).forEach((year) => {
            if (year === selectedYear) {
              Object.keys(loc.years[year]).forEach((stage) => {
                if (stage === selectedStage) {
                  filteredData.push({
                    location: loc.location,
                    locationId: loc.locationId,
                    years: {
                      [year]: {
                        [stage]: loc.years[year][stage],
                      },
                    },
                  });
                }
              });
            }
          });
        }
      });
    }
  }

  let rows = [];

  filteredData.forEach((entry) => {
    let location = entry.location;
    let locationId = entry.locationId;
    Object.entries(entry.years).forEach(([year, data]) => {
      Object.entries(data).forEach(([category, categoryData]) => {
        let stageName = categoryData.stageName;
        let stageId = categoryData.stageId;
        let monthlyData = categoryData.monthlyData;

        Object.entries(monthlyData).forEach(([month, monthData]) => {
          let batchNo = monthData.batchNo;
          let totalEmission = monthData.totalEmission;
          let activityBreakdown = monthData.activityBreakdown;

          let outputEntry = {
            locationId: locationId,
            location: location,
            stageName: stageName,
            stageId: stageId,
            date: batchNo,
            emission: totalEmission,
            activityBreakDown: activityBreakdown,
          };

          rows.push(outputEntry);
        });
      });
    });
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <EditModal
          modalStatus={editModal}
          setModalStatus={setEditModal}
          stageSchema={stageSchemaProps}
          stageValues={stageValues}
          handleSubmission={handleSubmission}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            marginTop: "25px",
            gap: "30px",
            marginBottom: "30px",
          }}
        >
          <FormControl style={{ width: "170px" }}>
            <InputLabel id="location-select-label">Select Location</InputLabel>

            <Select
              labelId="location-select-label"
              id="location-select"
              value={selectedLocation}
              onChange={handleLocationChange}
              MenuProps={menuProps}
              style={selectStyles}
            >
              {locations.map((loc) => (
                <MenuItem key={loc} value={loc}>
                  {loc}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ width: "150px" }}>
            <InputLabel id="year-select-label">Select Year</InputLabel>

            <Select
              labelId="year-select-label"
              id="year-select"
              value={selectedYear}
              onChange={handleYearChange}
              MenuProps={menuProps}
              style={selectStyles}
            >
              {uniqueYear.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ width: "170px" }}>
            <InputLabel id="stage-select-label">Select Stages</InputLabel>

            <Select
              labelId="stage-select-label"
              id="stage-select"
              value={selectedStage}
              onChange={handleStageChange}
              MenuProps={menuProps}
              style={selectStyles}
            >
              {arraySet.map((stage) => (
                <MenuItem key={stage} value={stage}>
                  {stage}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </ThemeProvider>
      <div
        style={{
          height: 500,
          width: "95%",
          marginTop: 50,
          gap: 10,
          marginLeft: "auto",
          marginRight: "auto ",
        }}
      >
        <StripDataGrid
          rowObj={rows}
          columnObj={columns}
          pageSize={10}
          pageSizeOptions={[10, 20, 30]}
          enableExport={true}
          getRowId={(row) => row.location + "_" + row.date + "_" + row.stage}
          loading={loading}
        />
      </div>
      {/* <div style={{ padding: "20px" }}>
        {loading ? (
          <p>Loading...</p>
        ) : filteredData.length === 0 ? (
          <p>No data available for the selected location and year.</p>
        ) : (
          <>
            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
                padding: "20px",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              <thead
                style={{
                  color: "#05D776",
                  fontSize: "18px",
                  fontWeight: "bolder",
                }}
              >
                <tr>
                  <th style={{ padding: "10px" }}>S.No</th>
                  <th style={{ padding: "10px" }}>Batch</th>
                  <th style={{ padding: "10px" }}>Stage</th>
                  <th style={{ padding: "10px" }}>Location</th>
                  <th style={{ padding: "10px" }}>Date</th>
                  <th style={{ padding: "10px" }}>Emission</th>
                </tr>
              </thead>

              <tbody>
                {currentRows.map((loc, locIndex) => {
                  return Object.keys(loc.years).map((year) =>
                    Object.keys(loc.years[year]).map((stage) =>
                      Object.keys(loc.years[year][stage].monthlyData).map(
                        (month, index) => (
                          <tr key={`${locIndex}-${year}-${stage}-${month}`}>
                            <td
                              style={{
                                padding: "10px",
                                textAlign: "center",
                                fontWeight: 600,
                              }}
                            >
                              {sno++}
                            </td>
                            <td
                              style={{
                                padding: "10px",
                                textAlign: "center",
                                fontWeight: 600,
                              }}
                            >
                              {loc.years[year][stage].monthlyData[month]
                                .batchNo || "0"}
                            </td>
                            <td
                              style={{
                                padding: "10px",
                                textAlign: "center",
                                fontWeight: 600,
                              }}
                            >
                              {stage || "0"}
                            </td>
                            <td
                              style={{
                                padding: "10px",
                                textAlign: "center",
                                fontWeight: 600,
                              }}
                            >
                              {loc.location || 0}
                            </td>
                            <td
                              style={{
                                padding: "10px",
                                textAlign: "center",
                                fontWeight: 600,
                              }}
                            >
                              {`${month} ${year}` || 0}
                            </td>
                            <td
                              style={{
                                padding: "10px",
                                textAlign: "center",
                                fontWeight: 600,
                              }}
                            >
                              {`${loc.years[year][stage].monthlyData[
                                month
                              ].totalEmission.toFixed(2)} kg CO2e` ||
                                `${0} kg CO2e`}
                            </td>
                          </tr>
                        )
                      )
                    )
                  );
                })}
              </tbody>
            </table>

            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <button
                onClick={() =>
                  setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)
                }
                disabled={currentPage === 1}
                style={{
                  padding: "10px",
                  marginRight: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  cursor: "pointer",
                  backgroundColor: currentPage === 1 ? "#eee" : "#fff",
                }}
              >
                {"<"}
              </button>

              {[...Array(totalPages).keys()].map((page) => (
                <span
                  key={page}
                  onClick={() => setCurrentPage(page + 1)}
                  style={{
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingRight: "15px",
                    paddingLeft: "15px",

                    marginRight: "5px",
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    cursor: "pointer",
                    backgroundColor:
                      currentPage === page + 1 ? "#149BA1" : "#fff",
                    color: currentPage === page + 1 ? "#fff" : "#000",
                  }}
                >
                  {page + 1}
                </span>
              ))}
              <button
                onClick={() =>
                  setCurrentPage(
                    currentPage < totalPages ? currentPage + 1 : totalPages
                  )
                }
                disabled={currentPage === totalPages}
                style={{
                  padding: "10px",
                  marginLeft: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  cursor: "pointer",
                  backgroundColor: currentPage === totalPages ? "#eee" : "#fff",
                }}
              >
                {">"}
              </button>
            </div>
          </>
        )}
      </div> */}
    </>
  );
};
