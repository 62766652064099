import axios from './axiosInstance';

const API_URL = process.env.REACT_APP_API_URL;

/**************** Modules APIs *************************/
/**
 * @description Create Module
 * @param {Object} moduleData 
 * @returns 
 */
export const createModule = async (moduleData) => {
    try {
        const { data: responseData = {} } = await axios({
            url: `${API_URL}/api/v1/module`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: moduleData
        });

        return responseData.data;
    } catch (err) {
        // Throw unknown errors as well
        throw err;
    }
};

/**
 * @description Get Module Details
 * @param {Object} param
 * @returns 
 */
export const getModules = async (moduleId, roleId, moduleName) => {
    try {
        const { data: responseData = {} } = await axios({
            url: `${API_URL}/api/v1/module`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                moduleId,
                roleId,
                moduleName
            }
        });

        return responseData.data;
    } catch (err) {
        // Throw unknown errors as well
        throw err;
    }
};

/**
 * @description Update Module
 * @param {Object} param
 * @returns 
 */
export const updateModule = async (moduleData) => {
    try {
        const { data: responseData = {} } = await axios({
            url: `${API_URL}/api/v1/module`,
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {...moduleData, isActive: true}
        });

        return responseData.data;
    } catch (err) {
        // Throw unknown errors as well
        throw err;
    }
};

/**
 * @description Update Module
 * @param {Object} param
 * @returns 
 */
export const deleteModule = async (moduleId) => {
    try {
        const { data: responseData = {} } = await axios({
            url: `${API_URL}/api/v1/module`,
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                moduleId,
                isActive: 'false'
            }
        });

        return responseData;
    } catch (err) {
        // Throw unknown errors as well
        throw err;
    }
};

/**
 * @description Update Module
 * @param {String} moduleName
 * @returns 
 */
export const chkDuplicateModule = async (moduleName) => {
    try {
        const { data: responseData = {} } = await axios({
            url: `${API_URL}/api/v1/module`,
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            param: {
                moduleName
            }
        });

        return responseData;
    } catch (err) {
        // Throw unknown errors as well
        throw err;
    }
};

/**************** Role APIs *************************/
/**
 * @description Create Role
 * @param {Object} roleData 
 * @returns 
 */
export const createRole = async (roleData) => {
    try {
        const { data: responseData = {} } = await axios({
            url: `${API_URL}/api/v1/roles`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: roleData
        });

        return responseData;
    } catch (err) {
        // Throw unknown errors as well
        throw err;
    }
};

/**
 * @description Get Role
 * @param {String} roleId 
 * @param {String} roleName
 * @returns 
 */
export const getRole = async (roleId, roleName) => {
    try {
        const { data: responseData = {} } = await axios({
            url: `${API_URL}/api/v1/roles`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                roleId,
                roleName
            }
        });

        return responseData.data;
    } catch (err) {
        // Throw unknown errors as well
        throw err;
    }
};

/**
 * @description Update role
 * @param {Object} roleData
 * @returns 
 */
export const updateRole = async (roleData) => {
    try {
        const { data: responseData = {} } = await axios({
            url: `${API_URL}/api/v1/roles`,
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            data: roleData
        });

        return responseData;
    } catch (err) {
        // Throw unknown errors as well
        throw err;
    }
};

/**
 * @description Delete role
 * @param {Object} roleId 
 * @returns 
 */
export const deleteRole = async (roleId) => {
    try {
        const { data: responseData = {} } = await axios({
            url: `${API_URL}/api/v1/roles`,
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                roleId,
                isActive: "false"
            }
        });

        return responseData;
    } catch (err) {
        // Throw unknown errors as well
        throw err;
    }
};

/**
 * @description Check role is duplicate or not
 * @param {String} roleName
 * @returns 
 */
export const chkDuplicateName = async (roleName) => {
    try {
        const { data: responseData = {} } = await axios({
            url: `${API_URL}/api/v1/roles-isduplicate`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                roleName
            }
        });

        return responseData;
    } catch (err) {
        // Throw unknown errors as well
        throw err;
    }
};


/**************** Page Role APIs *************************/
/**
 * @description Create Page Role
 * @param {Object} pageRoleData 
 * @returns 
 */
export const createPageRole = async (pageRoleData) => {
    try {
        const { data: responseData = {} } = await axios({
            url: `${API_URL}/api/v1/pagerole`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: pageRoleData
        });

        return responseData;
    } catch (err) {
        // Throw unknown errors as well
        throw err;
    }
};

/**
 * @description Get Page role data
 * @param {String} roleId 
 * @param {String} moduleId 
 * @param {String} pageId
 * @returns 
 */
export const getPageRoleByPageId = async (roleId, moduleId, pageId) => {
    try {
        const { data: responseData = {} } = await axios({
            url: `${API_URL}/api/v1/pagerole`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                roleId,
                moduleId,
                pageId
            }
        });

        return responseData;
    } catch (err) {
        // Throw unknown errors as well
        throw err;
    }
};

/**
 * @description Update Page role status
 * @param {String} pageRoleId
 * @returns 
 */
export const updatePageRoleStatus = async (pageRoleId) => {
    try {
        const { data: responseData = {} } = await axios({
            url: `${API_URL}/api/v1/pagerole`,
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                pageRoleId,
                isActive: 'false'
            }
        });

        return responseData;
    } catch (err) {
        // Throw unknown errors as well
        throw err;
    }
};
