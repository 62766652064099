// @mui
import { styled } from '@mui/material/styles';
import { ListItemIcon, ListItemButton } from '@mui/material';
import { color } from 'echarts';

// ----------------------------------------------------------------------

export const StyledNavItem = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 70,
    position: "relative",
    textTransform: "capitalize",
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    marginBottom: "10px",
    width: "200px",
    transition: "opacity 0.3s ease",
    "&:hover": {
     backgroundColor:"rgba(0, 201, 210, 0.4)",
     color: '#000 !important',
     opacity:0.8
    }
  })
);

export const StyledSubNavItem = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 70,
    position: "relative",
    textTransform: "capitalize",
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    pl:'1px',
    width: "190px",
    marginLeft:"16px",
    transition: "opacity 0.3s ease",
    "&:hover": {
     backgroundColor:"initial",
     color: '#000 !important',
     opacity:0.8
    }
  })
);

export const StyledNavItemIcon = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});