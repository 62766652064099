import React, { useEffect, useState } from "react";
import { stringWithUnderScore } from "../../../../utils/helperFunctions";
import { commaNumbers, roundDecimal, sortFiscalYearsDescending } from "../../../../utils/formatNumber";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";

const firstYearValueForScope1 = {};
const firstYearValueForScope2 = {};
export default function Scope1And2Cards({
  data,
  selectedLocation,
  selectedYear,
  esgData,
}) {
  let tempOb = {
    scope_Reveune: {},
    scope_1_Activity: {},
    scope_2_Activity: {},
  };
  let myObj = {
    scope_1: {},
    scope_2: {},
    scope_1_Intensity: {},
    scope_2_Intensity: {},
    percent_change_scope_1: {},
    percent_change_scope_2: {},
    total_emission_avoided: {},
  };

  // Define the order of keys
  const order = [
    "scope_1",
    "scope_2",
    "scope_1_Intensity",
    "scope_2_Intensity",
    "percent_change_scope_1",
    "percent_change_scope_2",
    "total_emission_avoided",
  ];

  data.forEach((loc) => {
    if (selectedLocation === "All" || selectedLocation === loc.location) {
      const yearsToIterate =
        selectedYear === "All" ? Object.keys(loc.years) : [selectedYear];

      yearsToIterate.forEach((year) => {
        const yearData = loc.years?.[year];
        if (yearData) {
          Object.keys(yearData.yearly_stage_emission).forEach((stage) => {
            const scope1Data =
              yearData.yearly_stage_emission[stage].emissionData.scope1;

            const scope2Data =
              yearData.yearly_stage_emission[stage].emissionData.scope2;
            Object.keys(scope1Data).forEach((scope) => {
              if (scope1Data[scope]) {
                if (!myObj.scope_1[scope]) {
                  myObj.scope_1[scope] = {};
                }
                if (!myObj.scope_1[scope][year]) {
                  myObj.scope_1[scope][year] = 0;
                }
                myObj.scope_1[scope][year] += parseFloat(
                  scope1Data[scope].toFixed(2)
                );
              }
            });
            Object.keys(scope2Data).forEach((scope) => {
              if (scope2Data[scope]) {
                if (!myObj.scope_2[scope]) {
                  myObj.scope_2[scope] = {};
                }
                if (!myObj.scope_2[scope][year]) {
                  myObj.scope_2[scope][year] = 0;
                }
                myObj.scope_2[scope][year] += parseFloat(
                  scope2Data[scope].toFixed(2)
                );
              }
            });
            if (yearData.yearly_stage_emission[stage].totalRevenue) {
              if (!tempOb.scope_Reveune) {
                tempOb.scope_Reveune = {};
              }
              if (!tempOb.scope_Reveune[stage]) {
                tempOb.scope_Reveune[stage] = {};
              }
              if (!tempOb.scope_Reveune[stage][year]) {
                tempOb.scope_Reveune[stage][year] = 0;
              }
              tempOb.scope_Reveune[stage][year] +=
                yearData.yearly_stage_emission[stage].totalRevenue;
            }

            if (yearData.yearly_stage_emission[stage].totalEmissionForScope1) {
              if (!tempOb.scope_1_Activity) {
                tempOb.scope_1_Activity = {};
              }
              if (!tempOb.scope_1_Activity[stage]) {
                tempOb.scope_1_Activity[stage] = {};
              }
              if (!tempOb.scope_1_Activity[stage][year]) {
                tempOb.scope_1_Activity[stage][year] = 0;
              }
              tempOb.scope_1_Activity[stage][year] +=
                yearData.yearly_stage_emission[stage].totalEmissionForScope1;
            }
            if (yearData.yearly_stage_emission[stage].totalEmissionForScope2) {
              if (!tempOb.scope_2_Activity) {
                tempOb.scope_2_Activity = {};
              }
              if (!tempOb.scope_2_Activity[stage]) {
                tempOb.scope_2_Activity[stage] = {};
              }
              if (!tempOb.scope_2_Activity[stage][year]) {
                tempOb.scope_2_Activity[stage][year] = 0;
              }
              tempOb.scope_2_Activity[stage][year] +=
                yearData.yearly_stage_emission[stage].totalEmissionForScope2;
            }
          });
        }
      });
    }
  });

  if(tempOb["scope_1_Activity"] && tempOb["scope_Reveune"]){
    Object.keys(tempOb.scope_1_Activity).forEach((stage) => {
      Object.keys(tempOb.scope_1_Activity[stage]).forEach((year) => {
        if (tempOb.scope_Reveune[stage] && tempOb.scope_Reveune[stage][year]) {
          if (!myObj.scope_1_Intensity[year]) {
            myObj.scope_1_Intensity[year] = 0;
          }
          myObj.scope_1_Intensity[year] = roundDecimal(
            tempOb.scope_1_Activity[stage][year] /
              tempOb.scope_Reveune[stage][year],
            2
          );
        }
      });
    });
  }

  if(tempOb["scope_2_Activity"] && tempOb["scope_Reveune"]){
    Object.keys(tempOb.scope_2_Activity).forEach((stage) => {
      Object.keys(tempOb.scope_2_Activity[stage]).forEach((year) => {
        if (tempOb.scope_Reveune[stage] && tempOb.scope_Reveune[stage][year]) {
          if (!myObj.scope_2_Intensity[year]) {
            myObj.scope_2_Intensity[year] = 0;
          }
          myObj.scope_2_Intensity[year] = roundDecimal(
            tempOb.scope_2_Activity[stage][year] /
              tempOb.scope_Reveune[stage][year],
            2
          );
        }
      });
    });
  }
  
  if(myObj["scope_1_Intensity"]){
    const scopes1 = Object.keys(myObj.scope_1_Intensity);
    const scopes1Set=new Set()

    scopes1.forEach(year=>{
      scopes1Set.add(year)
      })

  
      const yearArray=[...scopes1Set]

      const sortedYearsArray=sortFiscalYearsDescending(yearArray)
  scopes1.forEach((year) => {
    if (selectedYear !== "All") {
      Object.keys(myObj.scope_1_Intensity).forEach((year) => {
        if (year == selectedYear) {
          const lastYearData = myObj.scope_1_Intensity[year];
          const firstYearData = Object.values(firstYearValueForScope1)[0];
          const percentChangeScope1=
            ((lastYearData - firstYearData) / firstYearData) * 100;
          myObj.percent_change_scope_1["year"] =
            myObj.percent_change_scope_1["year"] || {};
          myObj.percent_change_scope_1["year"][
            Object.keys(firstYearValueForScope1)[0]
          ] = parseFloat(percentChangeScope1);
        }
      });
    } else {
      let firstYearIndex = sortedYearsArray.length - 1;
      let firstYearData = myObj.scope_1_Intensity[sortedYearsArray[firstYearIndex]]; 
      while(firstYearData===undefined || isNaN(firstYearData)){
        firstYearIndex-=1
        if(firstYearIndex<=sortedYearsArray.length){
          firstYearData=null
          break;
        }
        firstYearData= myObj.scope_1_Intensity[sortedYearsArray[firstYearIndex]]
      }
      let lastYearIndex = 0;
let lastYearData =  myObj.scope_1_Intensity[sortedYearsArray[lastYearIndex]];

// Loop to find the next valid lastYearData if it is undefined or NaN
while (lastYearData === undefined || isNaN(lastYearData)) {
  lastYearIndex += 1;
  if (lastYearIndex >= sortedYearsArray.length) {
    // If no valid lastYearData is found, handle it appropriately (e.g., break the loop or set a default value)
    lastYearData = null;
    break;
  }
  lastYearData =  myObj.scope_1_Intensity[sortedYearsArray[lastYearIndex]];
}



      firstYearValueForScope1[sortedYearsArray[firstYearIndex]] =  myObj.scope_1_Intensity[sortedYearsArray[firstYearIndex]];
      const percentChange =
        ((lastYearData - firstYearData) / firstYearData) * 100;
    myObj.percent_change_scope_1["year"] =  myObj.percent_change_scope_1["year"] || {};
    myObj.percent_change_scope_1["year"][sortedYearsArray[firstYearIndex]] = parseFloat(percentChange);
   
    }
  });
  }

  if(myObj["scope_2_Intensity"]){
    const scopes2 = Object.keys(myObj.scope_2_Intensity);
    const scopes2Set=new Set()

    scopes2.forEach(year=>{
      scopes2Set.add(year)
      })

  
      const yearArray=[...scopes2Set]

      const sortedYearsArray=sortFiscalYearsDescending(yearArray)
      scopes2.forEach((year) => {
    if (selectedYear !== "All") {
      Object.keys(myObj.scope_2_Intensity).forEach((year) => {
        if (year == selectedYear) {
          const lastYearData = myObj.scope_2_Intensity[year];
          const firstYearData = Object.values(firstYearValueForScope2)[0];
          const percentChangeScope2 =
            ((lastYearData - firstYearData) / firstYearData) * 100;
          myObj.percent_change_scope_2["year"] =
            myObj.percent_change_scope_2["year"] || {};
          myObj.percent_change_scope_2["year"][
            Object.keys(firstYearValueForScope2)[0]
          ] = parseFloat(percentChangeScope2);
        }
      });
    } else {
      let firstYearIndex = sortedYearsArray.length - 1;
      let firstYearData = myObj.scope_2_Intensity[sortedYearsArray[firstYearIndex]]; 
      while(firstYearData===undefined || isNaN(firstYearData)){
        firstYearIndex-=1
        if(firstYearIndex<=sortedYearsArray.length){
          firstYearData=null
          break;
        }
        firstYearData= myObj.scope_2_Intensity[sortedYearsArray[firstYearIndex]]
      }
      let lastYearIndex = 0;
let lastYearData =  myObj.scope_2_Intensity[sortedYearsArray[lastYearIndex]];

// Loop to find the next valid lastYearData if it is undefined or NaN
while (lastYearData === undefined || isNaN(lastYearData)) {
  lastYearIndex += 1;
  if (lastYearIndex >= sortedYearsArray.length) {
    // If no valid lastYearData is found, handle it appropriately (e.g., break the loop or set a default value)
    lastYearData = null;
    break;
  }
  lastYearData =  myObj.scope_2_Intensity[sortedYearsArray[lastYearIndex]];
}



      firstYearValueForScope2[sortedYearsArray[firstYearIndex]] =  myObj.scope_2_Intensity[sortedYearsArray[firstYearIndex]];
      const percentChange =
        ((lastYearData - firstYearData) / firstYearData) * 100;
    myObj.percent_change_scope_2["year"] =  myObj.percent_change_scope_2["year"] || {};
    myObj.percent_change_scope_2["year"][sortedYearsArray[firstYearIndex]] = parseFloat(percentChange);
   
    }
  });
  }
 
  
 
  esgData.forEach((loc) => {
    if (selectedLocation === "All" || selectedLocation === loc.location) {
      const yearsToIterate =
        selectedYear === "All" ? Object.keys(loc.years) : [selectedYear];
      yearsToIterate.forEach((year) => {
        const yearData = loc.years[year];

        if (yearData) {
          Object.keys(yearData).forEach((stage) => {
            if (stage == "Energy") {
              const { RE_POWER } =
                yearData[stage].yearlyData.Yearly_Activity_Breakdown;
              if (!myObj.total_emission_avoided["RE_POWER"]) {
                myObj.total_emission_avoided["RE_POWER"] = {};
              }
              if (!myObj.total_emission_avoided["RE_POWER"][year]) {
                myObj.total_emission_avoided["RE_POWER"][year] = 0;
              }
              myObj.total_emission_avoided["RE_POWER"][year] +=
                RE_POWER * 0.2196;
            }
          });
        }
      });
    }
  });

  const getCategoryTextColor = (category) => {
    switch (category) {
      case "scope_1":
        return "#225E83";
      case "scope_2":
        return "#225E83";
      case "scope_1_Intensity":
        return "#FFC74A";
      case "scope_2_Intensity":
        return "#FFC74A";
      case "total_emission_avoided":
        return "#0BDB7B";
      case "percent_change_scope_1":
        return "#FFC74A";
      case "percent_change_scope_2":
        return "#FFC74A";
      default:
        return "#000";
    }
  };
  const getUnits = (str) => {
    switch (str) {
      case "scope_1":
      case "scope_2":
      case "total_emission_avoided":
        return "tCO2e";
      case "scope_1_Intensity":
      case "scope_2_Intensity":
        return "tCO2e/Cr";
      default:
        return "";
    }
  };

  //We are using this ---> Function to sort dates in descending order
  const sortDatesDescending = (a, b) => {
    return parseInt(b.split("-")[1]) - parseInt(a.split("-")[1]);
  };

  return (
    <Grid mt={1} mb={2} container spacing={2} padding={2}>
      {order.map((category) =>
        category === "percent_change_scope_1" ||
        category === "percent_change_scope_2" ? (
          <Grid
            key={category}
            item
            xs={12}
            sm={6}
            md={3}
            lg={3}

            // style={{
            //   borderRadius: "6px",
            //   borderColor: getCategoryTextColor(category),
            // }}
          >
            <Card
              sx={{
                border: `1px solid ${getCategoryTextColor(category)}`,
                borderRadius: "6px",
                boxShadow: " 0 6px 10px 0 rgba(0, 0, 0, 0.06) ",
              }}
            >
              <CardHeader
                sx={{ padding: "12px 10px", textTransform: "uppercase" }}
                title={`% change  Scope${
                  category === "percent_change_scope_1" ? "1" : "2"
                } Intensity`}
                titleTypographyProps={{
                  fontSize: 14,
                  fontWeight: 600,

                  color: getCategoryTextColor(category),
                }}
              />
              <CardContent
                sx={{
                  height: "110px",
                  overflowY: "scroll",
                  scrollbarWidth: "thin",
                  padding: 0,
                }}
              >
                {Object.keys(myObj[category] || {}).map((stage, index) => (
                  <React.Fragment key={index}>
                    {Object.keys(myObj[category][stage])
                      .sort(sortDatesDescending)
                      .map((year, idx) => {
                        const value = myObj[category][stage][year];
                        if (!isNaN(value) && value !== undefined) {
                          const textColor = value < 0 ? "green" : "red";
                          return (
                            <div key={idx}>
                              <CardHeader
                                sx={{
                                  textAlign: "center",
                                  marginLeft: "70px", // Center align text
                                  color: textColor,
                                }}
                                title={
                                  <div style={{ display: "flex", gap: 10 }}>
                                    <span>
                                      {commaNumbers(roundDecimal(value, 2), 2)}{" "}
                                      %
                                    </span>
                                  </div>
                                }
                                titleTypographyProps={{
                                  fontSize: 20,
                                  fontWeight: 900,
                                }}
                              />
                            </div>
                          );
                        }
                        return null;
                      })}
                  </React.Fragment>
                ))}
              </CardContent>
            </Card>
          </Grid>
        ) : category==="scope_1_Intensity" || category==="scope_2_Intensity"?(  <Grid key={category} item xs={12} sm={6} md={3} lg={3}>
          <Card
            sx={{
              border: `1px solid ${getCategoryTextColor(category)}`,
              borderRadius: "6px",
              boxShadow: " 0 6px 10px 0 rgba(0, 0, 0, 0.06) ",
              height: "150px",
            }}
          >
            <CardHeader
              sx={{
                padding: "12px 10px",
              }}
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  {`${stringWithUnderScore(
                    category
                  ).toUpperCase()} (${getUnits(category)})`}
                </div>
              }
              titleTypographyProps={{
                fontSize: 15,
                fontWeight: 600,
                color: getCategoryTextColor(category),
              }}
            />
            <CardContent
              sx={{
                height: "110px",
                overflowY: "scroll",
                scrollbarWidth: "thin",
                padding: 0,
              }}
            >
              {Object.keys(myObj[category] || {})
                .sort(sortDatesDescending)
                .map((year, index) => (
                  <>
                    <div key={index}>
                      <CardHeader
                        sx={{ paddingTop: 0.5 }}
                        title={
                          <div
                            style={{
                              display: "flex",

                              gap: 10,
                            }}
                          >
                            <span key={index}>
                              {commaNumbers(
                                roundDecimal(myObj[category][year], 2),
                                2
                              )}
                            </span>
                          </div>
                        }
                        subheader={<span key={index}>{year}</span>}
                        titleTypographyProps={{
                          fontSize: 14,
                          fontWeight: 700,
                        }}
                        subheaderTypographyProps={{
                          fontSize: 14,
                          fontWeight: 700,
                        }}
                      />
                    </div>
                  </>
                ))}
            </CardContent>
          </Card>
        </Grid>):(
          <Grid key={category} item xs={12} sm={6} md={3} lg={3}>
            <Card
              sx={{
                border: `1px solid ${getCategoryTextColor(category)}`,
                borderRadius: "6px",
                boxShadow: " 0 6px 10px 0 rgba(0, 0, 0, 0.06) ",
              }}
            >
              <CardHeader
                sx={{
                  padding: "12px 10px",
                }}
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {`${stringWithUnderScore(
                      category
                    ).toUpperCase()} (${getUnits(category)})`}
                  </div>
                }
                titleTypographyProps={{
                  fontSize: 15,
                  fontWeight: 600,
                  color: getCategoryTextColor(category),
                }}
              />
              <CardContent
                sx={{
                  height: "110px",
                  overflowY: "scroll",
                  scrollbarWidth: "thin",
                  padding: 0,
                }}
              >
                {Object.keys(myObj[category] || {})
                  .sort(sortDatesDescending)
                  .map((stage, index) =>
                    Object.keys(myObj[category][stage])
                      .sort(sortDatesDescending)
                      .map(
                        (year, index) =>
                          // Check if the value is not NaN or undefined before rendering
                          !isNaN(myObj[category][stage][year]) &&
                          myObj[category][stage][year] !== undefined && (
                            <div key={index}>
                              <CardHeader
                                sx={{ paddingTop: 0.5 }}
                                title={
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: 10,
                                    }}
                                  >
                                    <span key={index}>
                                      {commaNumbers(
                                        roundDecimal(
                                          myObj[category][stage][year],
                                          2
                                        ),
                                        2
                                      )}
                                    </span>
                                  </div>
                                }
                                subheader={<span key={index}>{year}</span>}
                                titleTypographyProps={{
                                  fontSize: 14,
                                  fontWeight: 700,
                                }}
                                subheaderTypographyProps={{
                                  fontSize: 14,
                                  fontWeight: 500,
                                }}
                              />
                            </div>
                          )
                      )
                  )}
              </CardContent>
            </Card>
          </Grid>
        )
      )}
    </Grid>
  );
}
