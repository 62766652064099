import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";

import PieCharts from "./InternalCharts/PieCharts";
import EsgStageCards from "./InternalCharts/EsgStageCards";
import { APP_KEYS } from "../../utils/constants/AppKeyConstants";
import { getEsgData } from "../../services/esgdata";
import BarChart from "./InternalCharts/BarChart";
import IntensityChart from "./InternalCharts/IntensityChart";
import LineChart from "./InternalCharts/LineChart";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins !important",
    fontWeight: "bolder !important",
  },
});

export const EsgInfo = () => {
  const [esgData, setEsgData] = useState(null);
  const userData = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));
  const compId = userData.userId;
  const { stageId, stageName } = useParams();

  useEffect(() => {
    const fetchEsgData = async () => {
      try {
        const response = await getEsgData(compId);
        if (response.success) {
          setEsgData(response.data);
        } else {
          console.error("Error fetching ESG data:", response.msg);
        }
      } catch (error) {
        console.error("Error fetching ESG data:", error);
      }
    };

    fetchEsgData();
  }, [compId]);

  return (
    <ThemeProvider theme={theme}>
      {esgData && <EsgStageCards Data={esgData} />}

      {/* Previous Implementation */}
      {/* {data !== "" && (                                  
                <PieCharts data={data} stageId={stageId} />
            )} */}

      {esgData && (
        <PieCharts data={esgData} stageId={stageId} stageName={stageName} />
      )}
      {esgData && (
        <IntensityChart
          data={esgData}
          stageId={stageId}
          stageName={stageName}
        />
      )}
      {esgData && (
        <BarChart data={esgData} stageId={stageId} stageName={stageName} />
      )}

      {/* {esgData && (
                <BarChart data={data} stageId={id} />
            )} */}
      {esgData && (
        <LineChart data={esgData} stageId={stageId} stageName={stageName} />
      )}
    </ThemeProvider>
  );
};
