import React from "react";
import {
  Button,
  Container,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined";
import HexagonOutlinedIcon from "@mui/icons-material/HexagonOutlined";

import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import FlowConstants from "../FlowConstant";

/**
 * @description React Flow Tool Bar Component which can be used as global toolbar or specific node toolbar
 * @param {*} props
 * @returns
 */
export default function FlowToolBar(props) {
  const { selectedElement } = props;

  const onDragStart = (event, nodeType, subCategoryIcon) => {
    event.dataTransfer.setData(
      "application/reactflow",
      FlowConstants.ADD_NEW_NODE
    );
    event.dataTransfer.setData("application/reactflow/node/type", nodeType);
    event.dataTransfer.setData("application/subcategory-icon", subCategoryIcon);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <Grid
      container
      spacing={1}
      style={{
        background: "white",
        boxShadow: "0px 0px 15px -5px",
        borderRadius: "5px",
      }}
    >
      <Grid item={true} xs={2}>
        <IconButton
          aria-label="Add Node"
          draggable
          onDragStart={(e) => onDragStart(e, FlowConstants.PROCESS_NODE)}
        >
          <AddCircleOutlineOutlinedIcon />
        </IconButton>
      </Grid>
      <Grid item={true} xs={2}>
        <IconButton aria-label="Comment">
          <ModeCommentOutlinedIcon />
        </IconButton>
      </Grid>
      <Grid item={true} xs={2}>
        <IconButton aria-label="Action">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#000000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M12 2l9 4.9V17L12 22l-9-4.9V7z" />
          </svg>
        </IconButton>
      </Grid>
      {selectedElement && (
        <Grid item={true} xs={2}>
          <IconButton aria-label="Delete">
            <DeleteOutlineIcon />
          </IconButton>
        </Grid>
      )}
      <Grid item={true} xs={2}>
        <IconButton aria-label="More Option">
          <MoreVertIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
