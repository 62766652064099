/* eslint-disable no-unused-vars */
import { makeStyles } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useReducer, useState } from 'react';
import { getProductsById } from '../../services/catalogue/productlist';
import { APP_KEYS } from '../../utils/constants/AppKeyConstants';
import SwipeableViews from 'react-swipeable-views';

const useStyles = makeStyles((theme) => ({
  activeTab: {
    borderBottom: '2px solid',
    borderColor: '#149BA1',
    color: 'black', // This makes the text color black
    fontWeight: 'bold',
  },
  backButton: {
    alignItems: 'start',
  },
  backIcon: {
    marginRight: '0.5rem',
  },
  buttonGroup: {
    width: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '10px 0',
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#f6f8fe',
  },
  inputLabel: {
    color: '#000',
    fontWeight: '500',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: 'normal',
  },
  helperLabel: {
    marginTop: '5px',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontStyle: 'normal',
    lineHeight: 'normal',
    textDecoration: 'underline',
  },
}));

const ResetButton = styled(Button)({
  textTransform: 'none',
  boxShadow: 'none',
  fontSize: 16,
  fontWeight: 'bolder',
  width: '15%',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: '#fff',
  borderColor: '#FA3F3F',
  color: '#FA3F3F',
  fontFamily: 'Poppins',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  '&:hover': {
    backgroundColor: '#fff',
    borderColor: '#FA3F3F',
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.24)',
  },
});

const SaveButton = styled(Button)({
  textTransform: 'none',
  boxShadow: 'none',
  fontSize: 16,
  fontWeight: 'bolder',
  width: '15%',
  border: '1px solid #149BA1',
  lineHeight: 1.5,
  backgroundColor: '#E2E2E2',
  color: '#030229',
  fontFamily: 'Poppins',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  '&:hover': {
    backgroundColor: '#E2E2E2',
    borderColor: '#149BA1',
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.24)',
  },
});

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


const initialState = {
  stageId: [],
  nodeData: [],
  templateData: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_STAGE_ID':
      return { ...state, stageId: action.payload };
    case 'SET_NODE_DATA':
      return { ...state, nodeData: action.payload };
    case 'SET_TEMPLATE_DATA':
      return { ...state, templateData: action.payload };
    case 'UPDATE_TEMPLATE_DATA':
      const { stageId, index, value } = action.payload;
      const updatedTemplateData = state.templateData.map(stage => {
        if (stage.id === stageId) {
          const updatedData = stage.data.map((item, idx) => {
            if (idx === index) {
              return {
                ...item,
                ctrlData: [{
                  ...item.ctrlData[0],
                  name: value
                }]
              };
            }
            return item;
          });
          return {
            ...stage,
            data: updatedData
          };
        }
        return stage;
      });
      return { ...state, templateData: updatedTemplateData };
      case 'RESET_TEMPLATE_DATA':
      const resetTemplateData = state.templateData.map((stage) => ({
        ...stage,
        data: stage.data.map((item) => ({
          ...item,
          ctrlData: [{ ...item.ctrlData[0], name: '' }],
        })),
      }));
      return { ...state, templateData: resetTemplateData };
    default:
      return state;
  }
};

export default function NewStageEntryMain() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const userInfo = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO) || '{}');
  const selectedProductId = new URLSearchParams(location.search).get('product');
  const [state, dispatch] = useReducer(reducer, initialState);
  const { stageId, nodeData, templateData } = state;
  const [currentTab, setTab] = useState(0);

  useEffect(() => {
    async function fetchProductData() {
      try {
        const response = await getProductsById(selectedProductId);
       
        if (response.success) {
          let productData = response.data[0];
          let filteredStageIds = [];

          productData.stageProp = (productData?.stageProp || []).filter(
            (stage) => stage.supplierId === userInfo?.userId
          );

          if (productData.stageProp.length > 0) {
            filteredStageIds = productData.stageProp.map((el) => el.stageID);
          }

          dispatch({ type: 'SET_STAGE_ID', payload: filteredStageIds });

          if (
            filteredStageIds.length > 0 &&
            productData.supplyChainConfig &&
            productData.supplyChainConfig.nodes.length > 0
          ) {
            const filteredNodes = productData.supplyChainConfig.nodes.filter(
              (node) => filteredStageIds.includes(node.id)
            );
            dispatch({ type: 'SET_NODE_DATA', payload: filteredNodes });
          }
        }
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    }

    fetchProductData();
  }, [navigate, selectedProductId, userInfo?.userId]);

  useEffect(() => {
    if (nodeData.length > 0) {
      let filteredTemplateData = [];

      nodeData.forEach((node) => {
        const filteredProps = node.data.templateSchemaProp.filter(
          (item) => item.category === 'factor' || item.category === 'attribute'
        );
        filteredTemplateData.push({
          id: node.id,
          stageName: node.data.stageName,
          data: filteredProps,
        });
      });
      dispatch({ type: 'SET_TEMPLATE_DATA', payload: filteredTemplateData });
    }
  }, [nodeData]);

  const handleChangeIndex = (index) => {
    setTab(index);
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleInputChange = (stageId, index, event) => {
    const value = event.target.value;
    dispatch({
      type: 'UPDATE_TEMPLATE_DATA',
      payload: { stageId, index, value }
    });
  };

  const handleReset=()=>{
    dispatch({type:'RESET_TEMPLATE_DATA'})
  }

  const renderTabContent = (stage) => {
    return (
      <Grid paddingBottom={4} marginLeft={5}>
        <Box key={stage.id} sx={{ paddingBottom: 4, paddingX: 3, paddingTop: 3 }}>
          <Grid container spacing={4} alignItems="stretch" paddingBottom={3} paddingX={2}>
            {stage &&
              stage.data.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  spacing={3}
                  key={index}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Box sx={{ flexGrow: 1, mb: 1 }} >

                    <Typography

                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        fontWeight: "400",
                        fontSize: "14px",
                      }}
                    >
                      {item.ctrlDisplayName}
                    </Typography>
                  </Box>
                  <Box sx={{ flexGrow: 1 }}
                  >
                    <TextField
                      value={item.ctrlData[0].name}
                      onChange={(event) => handleInputChange(stage.id, index, event)}
                      fullWidth
                      variant="outlined"
                      sx={{
                        boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.15)' ,
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "inherit"
                          },

                        },
                      }}
                      InputProps={{
                        sx: {
                          height: '40px',
                          fontSize: '14px',
                          padding: '8px',
                          borderRadius: "8px"
                        },
                      }} />
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Grid>
    );
  };


  
  return (
    <div style={{ margin: '0 50px' }}>
      
        <Grid md={1}>
          <Button
            className={classes.backButton}
            onClick={() =>
              navigate(
                `/carboncompete/supplychainconfig/productloghistory?product=${selectedProductId}`
              )
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <mask
                id="mask0_171_1034"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_171_1034)">
                <path
                  d="M7.825 13L13.425 18.6L12 20L4 12L12 4L13.425 5.4L7.825 11H20V13H7.825Z"
                  fill="#1C1B1F"
                />
              </g>
            </svg>
          </Button>
        </Grid>
        <Grid container sx={{ alignItems: 'center', }}>
        <Grid item md={11}>
          <Tabs
            value={currentTab}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
            TabIndicatorProps={{ style: { background: 'grey' } }}
          >
            {(templateData || []).map((stage, i) => (
              <Tab key={stage.id} label={stage.stageName} {...a11yProps(i)} />
            ))}
          </Tabs>
          <SwipeableViews
            axis="x"
            index={currentTab}
            onChangeIndex={handleChangeIndex}
          >
            {(templateData || []).map((stage, index) => (
              <div
                role="tabpanel"
                hidden={currentTab !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                key={stage.id}
              >
                {currentTab === index && renderTabContent(stage)}
              </div>
            ))}
          </SwipeableViews>
        </Grid>
      </Grid>
      <div className={classes.buttonGroup}>
      <ResetButton
            variant="outlined"
            size="large"
            onClick={()=>handleReset()}
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 24"
              fill="none"
            >
              <path
                d="M9.09375 23.125C6.53125 22.9167 4.375 21.8646 2.625 19.9688C0.875 18.0729 0 15.8229 0 13.2188C0 11.6146 0.369792 10.1042 1.10938 8.6875C1.84896 7.27083 2.88542 6.11458 4.21875 5.21875L5.5625 6.5625C4.39583 7.25 3.48958 8.19271 2.84375 9.39062C2.19792 10.5885 1.875 11.8646 1.875 13.2188C1.875 15.3021 2.5625 17.1042 3.9375 18.625C5.3125 20.1458 7.03125 21.0208 9.09375 21.25V23.125ZM10.9688 23.125V21.25C13.0521 21 14.7708 20.1198 16.125 18.6094C17.4792 17.099 18.1562 15.3021 18.1562 13.2188C18.1562 10.9479 17.3698 9.02604 15.7969 7.45312C14.224 5.88021 12.3021 5.09375 10.0312 5.09375H9.40625L11.2812 6.96875L9.9375 8.3125L5.78125 4.15625L9.9375 0L11.2812 1.34375L9.40625 3.21875H10.0312C12.8229 3.21875 15.1875 4.19271 17.125 6.14062C19.0625 8.08854 20.0312 10.4479 20.0312 13.2188C20.0312 15.8229 19.1615 18.0729 17.4219 19.9688C15.6823 21.8646 13.5312 22.9167 10.9688 23.125Z"
                fill="#FA3F3F"
              />
            </svg>
            <Typography fontFamily="Raleway" fontWeight={600}>
              Reset
            </Typography>
          </ResetButton>
          <SaveButton type="submit" size="large" variant="contained">
            <Typography fontFamily="Raleway" fontWeight={600}>
              Submit
            </Typography>
          </SaveButton>
      </div>
    </div>
  );
}
