import React, { useState } from "react";
import { Backdrop, Box, Button, Chip, CircularProgress, FormControl, Grid, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { APP_KEYS } from "../../utils/constants/AppKeyConstants";
import * as XLSX from 'xlsx';
import { postEsgData } from "../../services/esgdata";
import { enqueueSnackbar } from "notistack";
import { MuiFileInput } from "mui-file-input";
import CloseIcon from "@mui/icons-material/Close";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS, NOTIFICATION_WARN } from "../../utils/constants/NotificationConstants";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import StripDataGrid from "../../components/data-grid/StripedDataGrid";
import { financialYearConversion } from "../../utils/helperFunctions";

const columns = [
  { field: 'material', headerName: 'Material Name', flex: 1 },
  {
    field: 'status',
    headerName: 'Status',
    description: 'Not Found i.e cannot find emissoin factors for these materials',
    sortable: false,
    flex: 1,
    renderCell: (params) => {
      return <Chip variant="outlined" label="Not Found" color="error" icon={<ErrorOutlineIcon />}/>
    }
  },
  {
    field: 'row',
    headerName: 'File Row No.',
    type: 'number',
    flex: 1
  },
];

function ExcelToJsonConverter() {
  const userData = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO)); //Esg User Info
  const compId = userData?.compId;
  const [value, setValue] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("")
  const [confirmSubmission, setConfirmSubmission] = useState(false);
  const [report, setReport] = useState([])
  const [loader, setLoader] = useState(false)

  const handleDateChange = (newValue) => {
    setValue(newValue);
  };

  const handleConfirmation = () => {
    setReport([])
    setConfirmSubmission(true);
  };

  const handleSubmit = async () => {
    if (file) {
      setLoader(true)
      let formData = new FormData()
      formData.append("file", file);
      formData.append("compId", parseInt(compId));
      formData.append("companyName", userData.userName);
      formData.append("userId", parseInt(userData.compId));
      formData.append("userEmail", userData.userEmail);
      formData.append("stageId", 19);
      formData.append("stageName", "Goods_&_Services");
      formData.append("month", `${value["$d"].toString().split(" ")[1]}`);
      formData.append("year", financialYearConversion(value)); // passing complete datepicker obj to get FY 
      // formData.append("year", `FY ${value["$d"].toString().split(" ")[3].slice(2)}-${parseInt(value["$d"].toString().split(" ")[3].slice(2))+1}`);

      try {
        // console.log(formData, "here is the form data")
        const response = await postEsgData(formData);

        if (
          response &&
          response.success === true &&
          response.statusCode === "135101"
        ) {
          setReport(response.data[0]?.report || [])
          enqueueSnackbar(response.msg, {
            autoHideDuration: 5000,
            variant: NOTIFICATION_SUCCESS,
          });
          setTimeout(() => {
            setConfirmSubmission(false);
            setValue(null);
            setFile(null);
          }, 1000);
        } else {
          setReport(response.data[0]?.report || [])
          enqueueSnackbar(response.msg, {
            autoHideDuration: 5000,
            variant: NOTIFICATION_WARN,
          });
          setConfirmSubmission(false);
        }
        setLoader(false)
      } catch (error) {
        setLoader(false)
        console.error("Error:", error);
        setConfirmSubmission(false);
        setFile(null);
        setValue(null);
      }
    } else {
      console.log("File is not present");
    }
  };

  const handleCancel = () => {
    setConfirmSubmission(false);
    setFile(null);
    setValue(null);
  };

  const handleFileChange = (event) => {
    if (event) {
      setReport([])
      setFile(event);
      setFileName(event.name)
      const reader = new FileReader();
      reader.onload = (event) => {
        const binaryString = event.target.result;
        const workbook = XLSX.read(binaryString, { type: 'binary' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const metaData = data[0]
        let requiredMetaData = ['material', 'measures', 'purchase weight']
        let error = { column: [], lenght: data.length - 1 }

        metaData.forEach((colName)=>{
          if(!requiredMetaData.includes(colName.toLowerCase())){
            error.column.push(colName)
          }
        })

        if (error.column.length && !error.lenght) {
          setFile(null)
          enqueueSnackbar(`File has no data and ${error.column.join(", ")} columns are missing`, {
            autoHideDuration: 3000,
            variant: NOTIFICATION_ERROR
          });
        } else if (!error.lenght) {
          setFile(null)
          enqueueSnackbar(`File has no data`, {
            autoHideDuration: 3000,
            variant: NOTIFICATION_ERROR
          });
        } else if (error.column.length) {
          setFile(null)
          enqueueSnackbar(`${error.column.join(", ")} columns are missing`, {
            autoHideDuration: 3000,
            variant: NOTIFICATION_ERROR
          });
        } else {
          enqueueSnackbar(`File can be uploaded`, {
            autoHideDuration: 3000,
            variant: NOTIFICATION_SUCCESS
          });
        }
      }

      reader.readAsArrayBuffer(event)
    }
  };

  // const onUploadExcel = async () => {
  //   try {
  //     if (!isNaN(date) && file && date !== null) {
  //       const newSchema = await schemaDataGeneration(
  //         file,
  //         compId,
  //         userData,
  //         date
  //       );

  //       console.log(newSchema);

  //       if (newSchema) {
  //         setSchema(newSchema);
  //       }

  //       enqueueSnackbar("Are you sure you want to upload the file", {
  //         autoHideDuration: 3000,
  //         variant: NOTIFICATION_SUCCESS,
  //       });

  //       setConfirmSubmission(true);
  //     } else {
  //       enqueueSnackbar("Please select Year and Month", {
  //         autoHideDuration: 3000,
  //         variant: NOTIFICATION_ERROR,
  //       });
  //     }
  //   } catch (err) {
  //     console.error("Error:", err);
  //   }
  // };


  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "30px",
          marginBottom: "30px",
        }}
      >
        {/* <DialogTitle variant="h4">Upload Excel Value</DialogTitle> */}
        <Typography sx={{ fontFamily: "poppins" }}>
          In-order to import catalog from the file. please download the catalog
          upload template file, add the data and upload the file.
          <a
            href={"/assets/data/Activity_Input.xlsx"}
            download={"Activity_Input.xlsx"}
            _target="blank"
          >
            Download Here
          </a>
        </Typography>
      </div>

      <Grid container spacing={5} alignItems="center">
        <Grid item xs={12} md={4}>
          <FormControl variant="outlined" size="small" fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Select Year and Month"
                value={value}
                onChange={handleDateChange}
                views={["year", "month"]}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>

        </Grid>
        <Grid item xs={12} md={4}>
          <MuiFileInput
            fullWidth
            value={file}
            placeholder="Click or Drag and Drop a file"
            onChange={handleFileChange}
            clearIconButtonProps={{
              onClick: () => setFile(null),
              title: "Remove",
              children: <CloseIcon fontSize="small" />,
            }}
            InputProps={{
              sx: {
                display: "flex",
                alignItems: "center",
                gap: 3,
              },
              inputProps: {
                accept:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              },
              startAdornment: <UploadFileIcon />,
            }}
            sx={{ cursor: "pointer" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          {confirmSubmission ? (
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Button
                variant="contained"
                onClick={handleCancel}
                sx={{
                  width: "100%",
                  padding: "15px",
                  backgroundColor: "red",

                  "&:hover": {
                    backgroundColor: "red",
                  },
                }}
              >
                No
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{ width: "100%" }}
              >
                Yes
              </Button>
            </Box>
          ) : (
            <Button
              fullWidth
              variant="contained"
              onClick={handleConfirmation}
              disabled={!(file && value)}
              style={{ padding: "15px" }}
            >
              <Typography fontFamily="Raleway" fontWeight={600}>
                Upload Files
              </Typography>
            </Button>
          )}
        </Grid>
      </Grid>

      {
        report.length ? (
          <div style={{ height: 450, width: '80%', marginTop: 50, gap: 10, marginLeft: 'auto', marginRight: 'auto '}}>
            <Typography sx={{ fontFamily: "poppins" }} variant="body2" >{fileName} file report, export the table data</Typography>
            <StripDataGrid 
              columnObj={columns}
              rowObj={report}
              enableExport={true}
            />
          </div>
        ) : ""
      }
    </>
  );
}

export default ExcelToJsonConverter;
