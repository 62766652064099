import React, { memo } from 'react';
import { Handle } from 'reactflow';
import styled from 'styled-components';
import { Box } from '@mui/material';


import { ICONLIB } from './IconLib';

import "./Custom_Nodes.css";

const Node = styled.div`
    height: 110px;
    width: 110px;
    border-radius: 10px;
    // border: 1px solid black;

    .react-flow__handle {
        width: 8px;
        height: 8px;
        border: none;
        background-color: transparent;
    }
`;

export default memo(({ id, data, isConnectable, selected }) => {

    return (
        <Node>
            <Handle
                id='target-left'
                position="left"
                type="target"
                style={{ backgroundColor: selected ? 'transparent' : 'rgba(0,0,0, 0.1)' }}
                onConnect={(params) => console.log("handle onConnect", params)}
                isConnectable={isConnectable}
            />
            <Handle
                id='target-right'
                position="right"
                type="target"
                style={{ backgroundColor: selected ? 'transparent' : 'rgba(0,0,0, 0.1)' }}
                onConnect={(params) => console.log("handle onConnect", params)}
                isConnectable={isConnectable}
            />
            <Handle
                id='target-top'
                position="top"
                type="target"
                style={{ backgroundColor: selected ? 'transparent' : 'rgba(0,0,0, 0.1)' }}
                onConnect={(params) => console.log("handle onConnect", params)}
                isConnectable={isConnectable}
            />
            <Handle
                id='target-bottom'
                position="bottom"
                type="target"
                style={{ backgroundColor: selected ? 'transparent' : 'rgba(0,0,0, 0.1)' }}
                onConnect={(params) => console.log("handle onConnect", params)}
                isConnectable={isConnectable}
            />
            {/* 
            <Stack spacing={1} pt={3} pr={2}>

                    <span style={{ borderRadius: '50px', padding: '20px', boxShadow: '0 0 15px -5px' }}>
                        <LocalShippingOutlinedIcon style={{ color: 'black' }} />
                    </span>

            </Stack> */}


            <Box className="transport-node-icon" style={{ border: '4px solid #159ba1', borderRadius: '50px', padding: '20px', boxShadow: '0 0 15px -5px', height: 100, width: 100 }}>
                { ICONLIB[data?.metaData?.icon || 'shipping']}
            </Box>


            <Handle
                type="source"
                id='source-top'
                position="top"
                isConnectable={isConnectable}
            />
            <Handle
                type="source"
                id='source-bottom'
                position="bottom"
                isConnectable={isConnectable}
            />
            <Handle
                type="source"
                id='source-right'
                position="right"
                isConnectable={isConnectable}
            />
            <Handle
                type="source"
                id='source-left'
                position="left"
                isConnectable={isConnectable}
            />
        </Node>
    );
});
