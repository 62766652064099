import React, { useEffect, useState } from 'react';
import {
    Dialog,
    Typography,
    Container,
    Grid,
    IconButton,
    Button,
    Alert,
    Paper,
} from '@mui/material';
import { useFormik } from 'formik';
import Iconify from '../../../components/iconify';
import { ChangeSubscription } from '../../../services/subscription';

const Premium = ({ open, setOpen, roles, userDetails, handleFetchUser }) => {
    const [confirmSubscription, setConfirmSubscription] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showAlert, setShowAlert] = useState('');

    const [selectedSubscription, setSelectedSubscription] = useState(userDetails.subsName);

    const formik = useFormik({
        initialValues: {
            userId: userDetails.id,
            userName: userDetails.name,
            userEmail: userDetails.email,
            userMobile: userDetails.mobile,
        },
        onSubmit: () => {
            setConfirmSubscription(true);
        },
    });

    const handleSubscriptionChange = (subscription) => {
        setSelectedSubscription(subscription);
    };

    const handleFormSubmission = async () => {
        try {
            setShowLoader(true);
            let subsId, selectedSubscriptionName;

            if (selectedSubscription === 'Basic') {
                subsId = 1;
                selectedSubscriptionName = 'Basic';
            } else if (selectedSubscription === 'Premium') {
                subsId = 2;
                selectedSubscriptionName = 'Premium';
            }

            const response = await ChangeSubscription({
                userId: formik.values.userId,
                userName: formik.values.userName,
                userEmail: formik.values.userEmail,
                userMobile: formik.values.userMobile,
                subsId,
                selectedSubscriptionName,
            });

            setShowLoader(false);
            if (response.success) {
                setShowAlert('user_updated');
                handleFetchUser();
            } else {
                setShowAlert('failed_to_update');
            }
        } catch (err) {
            console.error(err);
            setShowLoader(false);
            setShowAlert('failed_to_update');
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setShowAlert('');
        }, 1500);
    }, [showAlert]);

    return (
        <Dialog open={open} keepMounted maxWidth="md" fullWidth>
            <Container sx={{ p: 1 }}>
                <Container sx={{ pb: 1 }}>
                    <Grid container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Grid item>
                            <IconButton onClick={() => setOpen((s) => !s)}>
                                <Iconify icon="material-symbols:close" />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container py={1}>
                        <Typography sx={{ m: 'auto', fontSize: '20px', textAlign: 'center' }} fontFamily="Raleway" fontWeight={600}>
                            Change Subscription for <b>{formik.values.userName}</b>
                        </Typography>
                    </Grid>
                </Container>

                <Container spacing={2}>
                    {showAlert === 'user_updated' ? (
                        <Alert severity="success" sx={{ width: '100%' }}>
                            <strong>User updated successfully</strong>
                        </Alert>
                    ) : (
                        <>
                            {showAlert === 'failed_to_update' ? (
                                <Alert severity="error" sx={{ width: '100%' }}>
                                    <strong>Failed to update user</strong>
                                </Alert>
                            ) : null}
                        </>
                    )}

                    <form style={{ paddingBottom: '30px' }} onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2} mt={2}>
                            <Grid item xs={6}>
                                <Paper
                                    elevation={selectedSubscription === 'Basic' ? 3 : 0}
                                    sx={{
                                        borderRadius: '1px solid grey',
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                        p: 2,
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                        backgroundColor: selectedSubscription === 'Basic' ? 'primary.main' : 'transparent',
                                    }}
                                    onClick={() => handleSubscriptionChange('Basic')}
                                >
                                    <Typography
                                        variant="h6"
                                        fontFamily="Raleway"
                                        style={{ color: selectedSubscription === 'Basic' ? '#fff' : '#000' }}
                                    >
                                        Basic Plan
                                    </Typography>
                                    <Typography
                                        fontFamily="Raleway"
                                        fontWeight={600}
                                        style={{ color: selectedSubscription === 'Basic' ? '#fff' : '#000' }}
                                    >
                                        Default
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        fontFamily="Raleway"
                                        style={{ color: selectedSubscription === 'Basic' ? '#fff' : '#000' }}
                                    >
                                        :                                    </Typography>
                                    <Button
                                        sx={{ backgroundColor: selectedSubscription === 'Premium' ? 'primary.main' : '#000' }}
                                        variant="contained" onClick={handleFormSubmission}>
                                        Default Plan
                                    </Button>
                                </Paper>

                            </Grid>
                            <Grid item xs={6}>
                                <Paper
                                    elevation={selectedSubscription === 'Premium' ? 3 : 0}
                                    sx={{
                                        borderRadius: '1px solid grey',
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                        p: 2,
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                        backgroundColor: selectedSubscription === 'Premium' ? 'primary.main' : 'transparent',
                                    }}
                                    onClick={() => handleSubscriptionChange('Premium')}
                                >
                                    <Typography
                                        variant="h6"
                                        fontFamily="Raleway"
                                        style={{ color: selectedSubscription === 'Premium' ? '#fff' : '#000' }}
                                    >
                                        Premium Plan
                                    </Typography>
                                    <Typography
                                        fontFamily="Raleway"
                                        fontWeight={600}
                                        style={{ color: selectedSubscription === 'Premium' ? '#fff' : '#000' }}
                                    >
                                        $20/month
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        fontFamily="Raleway"
                                        style={{ color: selectedSubscription === 'Premium' ? '#fff' : '#000' }}
                                    >
                                        :
                                    </Typography>
                                    <Button
                                        sx={{ backgroundColor: selectedSubscription === 'Premium' ? '#000' : 'primary.main' }}
                                        variant="contained" onClick={handleFormSubmission}>
                                        Subscribe
                                    </Button>
                                </Paper>
                            </Grid>
                        </Grid>
                    </form>
                </Container>
            </Container>
        </Dialog>
    );
};

export default Premium;
