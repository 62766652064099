import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Tooltip, Switch, Container, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import ReactECharts from 'echarts-for-react';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 20px',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  emissionContainer: {
    width: '100%'
  },
  confidenceScore: {
    width: 'fit-content',
    color: '#149BA1',
    fontFamily: 'Poppins',
    fontSize: '20px',
    border: '1px solid #149BA1',
    padding: '14px 23px',
    borderRadius: '5px',
    marginTop: '5px',
  },
  title: {
    fontFamily: 'Poppins !important',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  message: {
    fontFamily: 'Poppins !important',
    fontSize: '20px',
    marginTop: theme.spacing(2),
    opacity: 0.8,
  },
  select: {
    width: '100%',
  },
  noteIcon: {
    fontFamily: 'Poppins !important',
    fontSize: '16px',
    verticalAlign: 'middle',
    marginLeft: '5px',
    color: '#149BA1',
  },
  carbonScoreIndicator: {
    fontFamily: 'Poppins !important',
    fontSize: '16px',
    fontWeight: 'bold',
    verticalAlign: 'middle',
    color: '#ff923f',
    writingMode: 'vertical-lr',
  },
  confidenceScoreIndicator: {
    fontFamily: 'Poppins !important',
    fontSize: '16px',
    fontWeight: 'bold',
    verticalAlign: 'middle',
    color: '#159ba1',
    writingMode: 'vertical-lr',
  }
}));

const Scores = ({ productData }) => {
  const classes = useStyles();
  const [selectedProduct, setSelectedProduct] = useState('');
  const [isCarbonScore, setIsCarbonScore] = useState(true);
  const [selectedScore, setSelectedScore] = useState('');

  const [emissionData, setEmissionData] = useState([])

  useEffect(() => {
    if (productData && productData.length > 0) {
      const productsArray = productData[0]?.products || [];
      setEmissionData(productsArray);
    }
  }, [productData]);

  useEffect(() => {
    if (!selectedProduct || !productData || productData.length === 0) {
      return;
    }

    const scoreField = isCarbonScore ? 'stage_carbon_score' : 'stage_confidence_score';

    const selectedProductData = productData[0]?.products.find(
      (product) => product.productName === selectedProduct
    );

    if (selectedProductData) {
      const selectedProductScore = selectedProductData[scoreField];
      setSelectedScore(selectedProductScore);
    }
  }, [isCarbonScore, selectedProduct, productData]);

  const sortedEmissionData = emissionData
    .slice()
    .sort((a, b) => {
      const scoreField = isCarbonScore ? 'stage_carbon_score' : 'stage_confidence_score';
      return b[scoreField] - a[scoreField];
    })
    .slice(0, 10);

  const chartOptions = {
    color: ['#FF9240', '#149BA1'],
    legend: {
      show: false,
      orient: 'horizontal',
      data: ['Carbon Score - kg Co2e', 'Confidence Score'],
    },
    tooltip: {},
    dataset: {
      source: [
        ['product', 'Carbon Score - Kg Co2e', 'Confidence Score'],
        ...sortedEmissionData.map((data) => [
          data.productName,
          data.stage_carbon_score,
          data.stage_confidence_score,
        ]),
      ],
    },
    itemStyle: {
      borderRadius: [5, 5, 0, 0],
    },
    xAxis: {
      type: 'category', axisLabel: {
        color: '#030229',
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontSize: 12,
      },
    },
    yAxis: {
      axisLabel: {
        color: '#030229',
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontSize: 16,
      },
    },
    series: [{ type: 'bar' }, { type: 'bar' }],
  };
  return (
    <div>
      <Typography variant='h6' sx={{ textAlign: 'center', fontFamily: 'Poppins' }}>
        Your top 10 Highest Emission Contributors
      </Typography>
      <div className={classes.container}>
        <Typography className={classes.carbonScoreIndicator}>
          Carbon Score - Kg CO<sub>2</sub>e
        </Typography>
        <div className={classes.emissionContainer}>
          <ReactECharts option={chartOptions} style={{ height: '400px', width: '100%' }} />
        </div>
        <Typography className={classes.confidenceScoreIndicator}>
          Confidence Score
        </Typography>
      </div>
    </div>

    // <div className={classes.emissionContainer}>
    //   <Typography className={classes.title}>
    //     {isCarbonScore ? 'Carbon Score' : 'Confidence Score'} for {selectedProduct}
    //   </Typography>
    //   <FormControl className={classes.select}>
    //     <InputLabel>Select Product</InputLabel>
    //     <Select value={selectedProduct} onChange={handleChange}>
    //       {productData[0]?.products.map((product) => (
    //         <MenuItem key={product.productId} value={product.productName}>
    //           {product.productName}
    //         </MenuItem>
    //       ))}
    //     </Select>
    //   </FormControl>
    //   <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //     <Tooltip title="Toggle between Carbon Score and Confidence Score">
    //       <InfoIcon className={classes.noteIcon} onClick={() => setIsCarbonScore(false)} />
    //     </Tooltip>
    //     <Switch checked={isCarbonScore} onChange={handleToggle} />
    //   </Container>
    //   <Typography className={classes.confidenceScore}>
    //     {isCarbonScore
    //       ? `${selectedScore} kg CO2e`
    //       : `${selectedScore}%`}
    //   </Typography>
    //   <Typography className={classes.message}>
    //     Reduce emissions for a greener future!
    //   </Typography>
    // </div>
  );
};

export default Scores;