import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getScenarioById } from "../../../services/scenario/scenario";
import ReactECharts from 'echarts-for-react';
import Paper from '@mui/material/Paper';
import { makeStyles } from "@material-ui/core";
import { Button, Typography } from "@mui/material";
import Iconify from "../../../components/iconify";

const monthNames = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const scopeMap = {
  "Scope 1": "Scope_1_Emissions",
  "Scope 2": "Scope_2_Emissions",
  "Scope 3": "Scope_3_Emissions"
};

const transformTargetData = (data) => {
  console.log(data);
  const transformed = {};

  for (const scope in data) {
    const newScopeName = scopeMap[scope];
    transformed[newScopeName] = {
      currentYear: data[scope].currentYear,
      yearlyTargets: {}
    };

    for (const year in data[scope].yearlyTargets) {
      const yearlyValue = parseFloat(data[scope].yearlyTargets[year]);
      const monthlyValue = (yearlyValue / 12).toFixed(4);

      transformed[newScopeName].yearlyTargets[year] = {};

      monthNames.forEach(month => {
        transformed[newScopeName].yearlyTargets[year][month] = monthlyValue;
      });
    }
  }

  return transformed;
};

const prepareChartData = (transformedTargetData, scopeHistoricalData, scopeForecastData, scope) => {
  let allDates = new Set();

  // Collect all unique dates from all data sources
  const collectDates = (data) => {
    if (data && data[scope]) {
      Object.keys(data[scope]).forEach(year => {
        Object.keys(data[scope][year]).forEach(month => {
          allDates.add(`${year}-${month}`);
        });
      });
    }
  };

  collectDates(transformedTargetData);
  collectDates(scopeHistoricalData);
  collectDates(scopeForecastData);

  // Sort dates chronologically
  const xAxisData = Array.from(allDates).sort();

  // Initialize data arrays with null values
  const targetData = new Array(xAxisData.length).fill(null);
  const historicalData = new Array(xAxisData.length).fill(null);
  const forecastData = new Array(xAxisData.length).fill(null);
  const lowerBoundData = new Array(xAxisData.length).fill(null);
  const upperBoundData = new Array(xAxisData.length).fill(null);

  // Helper function to check if a value is valid
  const isValidValue = (value) => value !== null && value !== undefined && value !== '' && !isNaN(value);

  // Fill in the data arrays
  xAxisData.forEach((date, index) => {
    const [year, month] = date.split('-');

    if (transformedTargetData[scope] && transformedTargetData[scope].yearlyTargets[year] && transformedTargetData[scope].yearlyTargets[year][month]) {
      targetData[index] = parseFloat(transformedTargetData[scope].yearlyTargets[year][month]);
    }

    if (scopeHistoricalData[scope] && scopeHistoricalData[scope][year] && scopeHistoricalData[scope][year][month]) {
      const value = parseFloat(scopeHistoricalData[scope][year][month]);
      historicalData[index] = isValidValue(value) ? value.toFixed(2) : null;
    }

    if (scopeForecastData[scope] && scopeForecastData[scope][year] && scopeForecastData[scope][year][month]) {
      const forecast = scopeForecastData[scope][year][month];
      forecastData[index] = isValidValue(forecast.value) ? parseFloat(forecast.value).toFixed(2) : null;
      lowerBoundData[index] = isValidValue(forecast.lowerbound) ? parseFloat(forecast.lowerbound).toFixed(2) : null;
      upperBoundData[index] = isValidValue(forecast.upperbound) ? parseFloat(forecast.upperbound).toFixed(2) : null;
    }
  });

  return {
    xAxis: {
      type: 'category',
      data: xAxisData,
      name: 'Year-Month'
    },
    yAxis: {
      type: 'value',
      name: 'Value'
    },
    series: [
      { name: 'Target', type: 'line', data: targetData, smooth: true },
      { name: 'Historical', type: 'line', data: historicalData, smooth: true },
      { name: 'Forecast', type: 'line', data: forecastData, smooth: true },
      { name: 'Lower Bound', type: 'line', data: lowerBoundData, smooth: true },
      { name: 'Upper Bound', type: 'line', data: upperBoundData, smooth: true },
    ],
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: ['Target', 'Historical', 'Forecast', 'Lower Bound', 'Upper Bound']
    },
    smooth: true, // Add smooth lines
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "'Poppins', sans-serif",
    padding: "20px",
    backgroundColor: "#f5f5f5"
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    borderRadius: "15px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#ffffff",
    border: "1px solid #e0e0e0"
  },
  heading: {
    color: "#00C9D2",
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    fontSize: "24px"
  },
  subheading: {
    color: "#333",
    fontFamily: 'poppins', fontSize: '18px', fontWeight: 600, marginBottom: '25px', textAlign: 'center'
  }
}));

export default function ScopeScenarioGraph() {
  const { scenarioId } = useParams();
  const navigate = useNavigate();
  const [scenarioData, setScenarioData] = useState(null);
  const [targetData, setTargetData] = useState(null);
  const [transformedTargetData, setTransformedTargetData] = useState(null);
  const [scopeHistoricalData, setScopeHistoricalData] = useState(null);
  const [scopeForecastData, setScopeForecastData] = useState(null);
  const [chartOptions, setChartOptions] = useState({});
  const classes = useStyles();

  const getAllScenarios = async () => {
    try {
      const options = { scenarioId };
      const response = await getScenarioById(options);

      if (response) {
        setScenarioData(response?.data[0]?.data);
        const rawTargetData = response?.data[0]?.data?.scenarios[0]?.targets.emissions;
        setTargetData(rawTargetData);
        setTransformedTargetData(transformTargetData(rawTargetData));
        setScopeHistoricalData(response?.data[0]?.data?.data.emissions.historical || {});
        setScopeForecastData(response?.data[0]?.data?.data?.emissions.forecast || {});
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllScenarios();
  }, [scenarioId]);

  useEffect(() => {
    if (transformedTargetData && scopeHistoricalData && scopeForecastData) {
      const scopeChartOptions = {};
      Object.keys(scopeMap).forEach(scope => {
        const newScopeName = scopeMap[scope];
        scopeChartOptions[newScopeName] = prepareChartData(transformedTargetData, scopeHistoricalData, scopeForecastData, newScopeName);
      });
      setChartOptions(scopeChartOptions);
    }
  }, [transformedTargetData, scopeHistoricalData, scopeForecastData]);

  return (
    <div className={classes.root}>
      <Button
        sx={{
          backgroundColor: "#222",
          "&:hover": { backgroundColor: "#149BA1" },
          color: "#fff",
          marginRight: "20px",
        }}
        onClick={() => navigate(-1)}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Iconify icon="icon-park-outline:back" color="white" />
          <Typography
            fontFamily="Poppins"
            style={{ textTransform: "capitalize", marginLeft: "0.5rem" }}
          >
            Back
          </Typography>
        </div>
      </Button>
      <Typography
        sx={{ fontFamily: 'poppins', fontSize: '25px', fontWeight: 600, marginBottom: '25px', textAlign: 'center' }}
      >
        Scope Scenario Graphs for {scenarioData?.location || 'Loading...'}
      </Typography>
      {Object.keys(chartOptions).map(scope => (
        <Paper key={scope} className={classes.paper}>
          <h2 className={classes.subheading}>{scope.replace(/_/g, ' ')}</h2>
          <ReactECharts option={chartOptions[scope]} style={{ height: '400px' }} />
        </Paper>
      ))}
    </div>
  );
}
