import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MultipleSelectWithCheckBox = ({
  label,
  id,
  value,
  options,
  onChange,
  menuProps,
  style,
  isAllSelected,
}) => {
  const [valueState, setValueState] = useState([]);
  const [optionsState, setOptionsState] = useState([]);
  const [idState, setIdState] = useState("");
  const [labelState, setLabelState] = useState("");
  const [styleState, setStyleState] = useState({
    width: "100%",
    height: "42px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    fontFamily: "Poppins !important",
    fontWeight: 500,
    border: "none !important",
    "&:focus": {
      border: "none",
    },
  });
  const [menuPropsState, setMenuPropsState] = useState({
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    variant: "menu",
  });

  useEffect(() => {
    if (value) {
      setValueState(value);
    }
    if (options) {
      setOptionsState(options);
    }
    if (id) {
      setIdState(id);
    }
    if (label) {
      setLabelState(label);
    }
    if (style) {
      setStyleState(style);
    }
    if (menuProps) {
      setMenuPropsState(menuProps);
    }
  }, [value, options, id, label, style, menuProps, styleState]);

  return (
    <FormControl fullWidth>
      <InputLabel id={idState}>{labelState}</InputLabel>
      <Select
        id={idState}
        multiple
        value={valueState}
        onChange={onChange}
        MenuProps={menuPropsState}
        style={styleState}
        input={<OutlinedInput label={labelState} />}
        renderValue={(selected) => selected.join(", ")}
      >
        <MenuItem value="Select All">
          <ListItemIcon>
            <Checkbox
              checked={isAllSelected}
              indeterminate={
                valueState.length > 0 && valueState.length < optionsState.length
              }
            />
          </ListItemIcon>
          <ListItemText primary="Select All" />
        </MenuItem>
        {optionsState.map((option) => (
          <MenuItem key={option} value={option}>
            <ListItemIcon>
              <Checkbox checked={value.indexOf(option) > -1} />
            </ListItemIcon>
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
