import React, { memo, useState } from "react";
import { Handle } from "reactflow";
import styled from "styled-components";
import {
  Container,
  Typography,
  Stack,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import { ICONLIB } from "./IconLib";

const Node = styled.div`
padding: 0px 5px;
  height: 110px;
  width: 110px;
  border-radius: 10px;
  border: none;
  box-shadow: 0 0 20px -5px;
  border: 1px solid
    ${(props) =>
      props.selected ? props.theme.primary : props.theme.nodeBorder};
  background-color: #159ba1;

  .react-flow__handle {
    width: 8px;
    height: 8px;
    border: none;
    background-color: transparent;
  }
`;

const ActionTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    top: -50,
    color: "black",
  },
}));

export default memo(({ id, data, isConnectable, selected }) => {
  const [clicked, unclicked] = useState(false);
  const [showAddIcon, setShowAddIcon] = useState(false);

  const toggleAddButton = (e) => {
    setShowAddIcon(!showAddIcon);
    e.preventDefault();
  };

  return (
    <Node
      onClick={() => unclicked(!clicked)}
      onMouseEnter={toggleAddButton}
      onMouseLeave={toggleAddButton}
    >
      <Handle
        style={{
          backgroundColor: clicked ? "transparent" : "rgba(0,0,0, 0.1)",
        }}
        type="target"
        position="left"
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />

      <Stack spacing={1} pt={3} pr={2}>
        <Container>
          <span style={{ background: "#c9e5e9", boxShadow: "0 0 15px -5px" }}>
            {ICONLIB[data?.metaData?.icon] ||  ICONLIB['default']}
          </span>
        </Container>

        <Container>
          <Typography mt={4}>{data?.metaData?.name}</Typography>
        </Container>
      </Stack>
      {/* <ActionTooltip
        open={showAddIcon}
        title={
          <React.Fragment>
            <IconButton
              aria-label="Add Node"
              onClick={(e) => data.addNodeAction(id, data)}
              size="large"
            >
              <AddIcon fontSize="30px" />
            </IconButton>
            <IconButton
              aria-label="Add Node"
              onClick={(e) => data.nodeEditAction(id, data)}
              size="large"
            >
              <EditIcon fontSize="30px" />
            </IconButton>
          </React.Fragment>
        }
        placement="bottom"
        placeholder="Add New Node"
      >
        <Button style={{ visibility: "hidden" }}></Button>
      </ActionTooltip> */}
    </Node>
  );
});
