import { Navigate, createBrowserRouter } from "react-router-dom";

// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";

// 404 not found
import Page404 from "./pages/Page404";
import Canvas from "./pages/Canvas";
import { CarbonCatalogue } from "./pages/CarbonCatalogue/index";
import Dashboard from "./pages/Dashboard";
import { ProductInfo } from "./pages/CarbonCatalogue/ProductInfo";
import { HelpAndSupport } from "./pages/HelpAndSupport/HelpAndSupport";
// import SelectStages from "./pages/CarbonCatalogue/AddStages/SelectStages";
import AddProduct from "./pages/CarbonCatalogue/AddProduct/AddProduct";
import Configure from "./pages/Configure";
import Modules from "./pages/Configure/Modules";
import Permissions from "./pages/Configure/Permissions";
import Roles from "./pages/Configure/Roles";
import SubscriptionPlans from "./pages/Configure/Plans/Plans";
import Suppliers from "./pages/Suppliers/Suppliers";
import AuthWrapper from "./sections/auth/AuthWrapper";
import Clients from "./pages/Clients";
import SuperAdminDashboard from "./pages/SuperAdminDashboard";
// route guard
import { roleGuard } from "./guards/roleGuard";
// import { Typography } from "@material-ui/core";
import { APP_MODULES } from "./utils/constants/AppConstants";

// Ashish - 6th Sep'23
import SupplyChainConfig from "./pages/SupplyChainConfig";
import ProductLogHistory from "./pages/SupplyChainConfig/ProductLogHistory";
import { SupplierCanvas } from "./pages/SupplierCanvas";
import PremiumDashboard from "./pages/SupplierDashboard/Premium";
import { SupplierConfigure } from "./pages/SupplierConfigure";
import { ResetPassword } from "./sections/auth/resetPass/ResetPassword";
import { APP_KEYS } from "./utils/constants/AppKeyConstants";
// import { StageEntryMain } from "./pages/SupplyChainConfig/StageEntryMain";
import { EsgDashboard } from "./pages/ESG/EsgDashboard";
import { EsgData } from "./pages/ESG/EsgData";
import { EsgInfo } from "./pages/ESG/EsgInfo";
import { ActivityHistory } from "./pages/ESG/ActivityHistory";
import EsgBulkUpload from "./pages/ESG/EsgBulkUpload";
import ScopeInfo from "./pages/ESG/ScopeInfo";
import NewStageEntryMain from "./pages/SupplyChainConfig/NewStageEntryMain";
import { Forecasting } from "./pages/CarbonAdvice/CarbonAdvice";
import { CorporateCanvas } from "./pages/CorporateCanvas/CorporateCanvas";
import { ScenarioPlanning } from "./pages/CarbonAdvice/ScenarioPlanning";
import ScopeScenarioGraph from "./pages/CarbonAdvice/Scenario/ScopeScenarioGraph";
import ActivityScenarioGraph from "./pages/CarbonAdvice/Scenario/ActivityScenarioGraph";
import ScenarioAnalysis from "./pages/CarbonAdvice/Scenario/ScenarioCards";
import { CorporateSuppliers } from "./pages/CorporateSupplier/CorporateSuppliers";
import { SupplierCorporateCanvas } from "./pages/CorporateCanvasSupplier/SupplierCorporateCanvas";
import { ActivityDialog } from "./pages/CorporateCanvasSupplier/ActivityDialog";
import { ConfigPage } from "./pages/CorporateCanvas/ConfigPage";
import { CorporateDashboard } from "./pages/CorporateCanvas/CorporateDashboard";
import { ScopeWiseInfo } from "./pages/CorporateCanvas/ScopeWiseInfo";

// ----------------------------------------------------------------------

export function Router() {
  const user = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO));

  const routes = createBrowserRouter([
    {
      path: "/login",
      element: <AuthWrapper />,
      loader: roleGuard.unProtectedPageLoaded,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "/carboncompete",
      element: <DashboardLayout />,
      children: [
        {
          element: <Navigate to="/carboncompete/dashboard" />,
          index: true,
          loader: roleGuard.protectedLoader(APP_MODULES.DASHBOARD),
        },
        {
          path: "dashboard",
          element: <Dashboard />,
          loader: roleGuard.protectedLoader(APP_MODULES.DASHBOARD),
        },
        {
          path: "premiumdash",
          element: <PremiumDashboard />,
          loader: roleGuard.protectedLoader(
            APP_MODULES.PREMIUM_SUPPLIERS_DASHBOARD
          ),
        },
        {
          path: "sp-dashboard",
          element: <SuperAdminDashboard />,
          loader: roleGuard.protectedLoader(APP_MODULES.SUPER_ADMIN_DASHBOARD),
        },
        {
          path: "supplychainconfig",
          element: <SupplyChainConfig />,
          loader: roleGuard.protectedLoader(APP_MODULES.ACTIVITY_INPUTS),
        },
        {
          path: "supplychainconfig/productloghistory",
          element: <ProductLogHistory />,
          // loader: roleGuard.protectedLoader(APP_MODULES.ACTIVITY_INPUTS),
        },
        {
          path: "supplychainconfig/product/stageentry",
          element: <NewStageEntryMain />,
          loader: roleGuard.protectedLoader(APP_MODULES.ACTIVITY_INPUTS),
        },
        {
          path: "canvas",
          element: <Canvas />,
          loader: roleGuard.protectedLoader(APP_MODULES.CARBON_CANVAS),
        },
        {
          path: "scanvas",
          element: <SupplierCanvas />,
          loader: roleGuard.protectedLoader(APP_MODULES.SUPPLIER_CANVAS),
        },
        {
          path: "clients",
          element: <Clients />,
          loader: roleGuard.protectedLoader(APP_MODULES.CLIENT_MANAGEMENT),
        },
        {
          path: "carboncatalogue",
          element: <CarbonCatalogue />,
          loader: roleGuard.protectedLoader(APP_MODULES.CARBON_CATALOGUE),
        },
        {
          path: "carboncatalogue/addproduct",
          element: <AddProduct />,
          loader: roleGuard.protectedLoader(APP_MODULES.CARBON_CATALOGUE),
        },
        {
          path:"scenarioplanning/analysis/:scenarioId",
          element:<ScenarioAnalysis />
        },
        {
          path:"scenarioplanning/analysis/scope-graph/:scenarioId",
          element:<ScopeScenarioGraph />
        },
        {
          path:"scenarioplanning/analysis/activity-graph/:scenarioId",
          element:<ActivityScenarioGraph />
        },
        {
          path: "carboncatalogue/product/:productId",
          element: <ProductInfo />,
          loader: roleGuard.protectedLoader(APP_MODULES.CARBON_CATALOGUE),
        },
        {
          path: "carboncatalogue/product/:productId/edit",
          element: <AddProduct />,
          loader: roleGuard.protectedLoader(APP_MODULES.CARBON_CATALOGUE),
        },
        {
          path: "carboncatalogue/product",
          element: <ProductInfo />,
          loader: roleGuard.protectedLoader(APP_MODULES.CARBON_CATALOGUE),
        },
        {
          path: "help",
          element: <HelpAndSupport />,
          loader: roleGuard.protectedLoader(APP_MODULES.HELP_AND_SUPPORT),
        },
        {
          path: "suppliers",
          element: <Suppliers />,
          loader: roleGuard.protectedLoader(APP_MODULES.SUPPLIERS),
        },
        {
          path: "configure",
          Component: Configure,
          loader: roleGuard.protectedLoader(APP_MODULES.CONFIGURE),
        },
        {
          path: "supplierconfig",
          Component: SupplierConfigure,
          loader: roleGuard.protectedLoader(APP_MODULES.SUPPLIER_CONFIGURE),
        },
        {
          path: "esgdashboard",
          Component: EsgDashboard,
          loader: roleGuard.protectedLoader(APP_MODULES.ESG_DASHBOARD),
        },
        {
          path: "corporatedashboard",
          Component: CorporateDashboard,
          loader: roleGuard.protectedLoader(APP_MODULES.CORPORATE_DASHBOARD),
        },
        {
          path: "corporatedashboard/scopewiseinfo/:scopeName",
          Component: ScopeWiseInfo,
          loader: roleGuard.protectedLoader(APP_MODULES.CORPORATE_DASHBOARD),
        },
        {
          path: "corporatecanva",
          Component: CorporateCanvas,
          loader: roleGuard.protectedLoader(APP_MODULES.CORPORATE_CANVA),
        },
        {
          path: "corporatecanva/configpage/:id",
          Component: ConfigPage,
          loader: roleGuard.protectedLoader(APP_MODULES.CORPORATE_CANVA),
        },        
        {
          path: "corporatesuppliers",
          Component: CorporateSuppliers,
          loader: roleGuard.protectedLoader(APP_MODULES.CORPORATE_SUPPLIERS),
        },
        {
          path: "forecasting",
          Component: Forecasting,
          loader: roleGuard.protectedLoader(APP_MODULES.CARBON_ADVICE),
        },
        {
          path: "scenarioplanning",
          Component: ScenarioPlanning,
          loader: roleGuard.protectedLoader(APP_MODULES.SCENARIO_PLANNING),
        },
        {
          path: "suppliercorporatecanvas",
          Component: SupplierCorporateCanvas,
          loader: roleGuard.protectedLoader(APP_MODULES.SUPPLIER_CORPORATE_CANVAS),
        },
        {
          path: "suppliercorporatecanvas/activity/:id",
          Component: ActivityDialog,
          loader: roleGuard.protectedLoader(APP_MODULES.SUPPLIER_CORPORATE_CANVAS),
        },
        {
          path: "esgdata",
          Component: EsgData,
          loader: roleGuard.protectedLoader(APP_MODULES.ESG_DATA),
        },
        {
          path: "esgdashboard/stageinfo/:stageId/:stageName",
          element: <EsgInfo />,
          loader: roleGuard.protectedLoader(APP_MODULES.ESG_DASHBOARD),
        },
        {
          path: "esgdashboard/scopeinfo/:scopeName",
          element: <ScopeInfo />,
          loader: roleGuard.protectedLoader(APP_MODULES.ESG_DASHBOARD),
        },
        {
          path: "activityhistory",
          Component: ActivityHistory,
          loader: roleGuard.protectedLoader(APP_MODULES.ACTIVITY_HISTORY),
        },
        {
          path: "esgbulkupload",
          Component: EsgBulkUpload,
          loader: roleGuard.protectedLoader(APP_MODULES.BULK_UPLOAD),
        },
        {
          path: "configure",
          children: [
            {
              path: "roles",
              index: true,
              element: <Roles />,
              loader: roleGuard.protectedLoader(APP_MODULES.CONFIGURE),
            },
            {
              path: "modules",
              element: <Modules />,
              loader: roleGuard.protectedLoader(APP_MODULES.CONFIGURE),
            },
            {
              path: "permissions",
              element: <Permissions />,
              loader: roleGuard.protectedLoader(APP_MODULES.CONFIGURE),
            },
            {
              path: "plans",
              element: <SubscriptionPlans />,
              loader: roleGuard.protectedLoader(APP_MODULES.CONFIGURE),
            },
          ],
        },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        {
          element: (
            <Navigate to={user ? "/carboncompete/dashboard" : "/login"} />
          ),
          index: true,
        },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}

export const Routes = Router();
