// Add user component
import React, { useState } from "react";
import {
  Dialog,
  Typography,
  Container,
  Grid,
  Slide,
  Button,
  DialogTitle,
  DialogContent,
  Divider,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import { DropzoneArea } from 'mui-file-dropzone';
import { uploadProduct } from "../../services/product";
import { enqueueSnackbar } from "notistack";
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS, NOTIFICATION_WARN } from "../../utils/constants/NotificationConstants";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const accectableFilesList = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

const UploadProductDialog = ({ open, setOpen, setShowLoader }) => {
  const [showAlert, setShowAlert] = useState("");

  const validationSchema = yup.object({
    fileName: yup.mixed().required("Please select a file")
  });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      fileName: "",
    },
    onSubmit: async () => {
      await onUploadProduct();
    },
  });

  const onUploadProduct = async () => {
    try {
      if (!formik.isValid) {
        alert('Please select the file');
        return;
      }
      setShowLoader(true);

      const result = await uploadProduct(formik.values.fileName);

      if (result?.success?.length === 0) {
        enqueueSnackbar('Error in uploading products', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_ERROR
        });
      } else if (result?.error?.length) {
        // show success message to user
        enqueueSnackbar(`Products uploaded partically, \n ${result?.error.join(", ")} `, {
          autoHideDuration: 3000,
          variant: NOTIFICATION_WARN
        });
      } else {
        enqueueSnackbar(`File uploaded successfully \n Total Product Count: ${result?.success.length} `, {
          autoHideDuration: 3000,
          variant: NOTIFICATION_SUCCESS
        });
      }

      setShowLoader(false);
      formik.resetForm();
      setOpen(false);
    } catch (err) {
      setShowLoader(false);
      console.error("Error:", err);
      setShowAlert("unknown_error");
    }
  };

  const onFileSelection = (event) => {
    console.log('file selection event - ', event);
    formik.setFieldValue('fileName', event[0]);
  };

  /**
   * @description Close dialog component
   */
  const onUploadCancel = () => {
    formik.resetForm();
    setOpen(false);
  }

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        fullWidth
      >
        <Container sx={{ p: 1 }}>
          <DialogTitle variant="h4">
            Upload Products
          </DialogTitle>

          <Divider />

          <DialogContent sx={{ fontFamily: 'poppins' }}>
            In-order to import catalog from the file. please download the catalog upload template file, add the data and upload the file.
            <a href={'/assets/data/Catalog_Template.xlsx'} download={"Catalog_Template.xlsx"} _target="blank">Download Here</a>
          </DialogContent>

          <Container spacing={2}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  <DropzoneArea
                    filesLimit={1}
                    onChange={onFileSelection}
                    maxFileSize={20000}
                    acceptedFiles={accectableFilesList}
                  // Icon={FileUploadOutlinedIcon}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1} mt={1}>
                <Grid sx={{ mb: 2 }} item xs={6}>
                  <Button
                    sx={{
                      width: "100%",
                      backgroundColor: "red",
                      mt: 1,
                      "&:hover": {
                        backgroundColor: "red",
                      },
                    }}
                    variant="contained"
                    onClick={onUploadCancel}
                  >
                    <Typography fontFamily="Raleway" fontWeight={600}>
                      Cancel
                    </Typography>
                  </Button>
                </Grid>
                <Grid sx={{ mb: 2 }} item xs={6}>
                  <Button
                    // type="submit"
                    onClick={onUploadProduct}
                    sx={{ width: "100%", mt: 1 }}
                    variant="contained"
                  >
                    <Typography fontFamily="Raleway" fontWeight={600}>
                      Upload Files
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Container>
      </Dialog>
    </>
  );
};

export default UploadProductDialog;
