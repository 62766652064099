import React, { useEffect, useState } from "react";

import { css } from "@emotion/react";

import styled from "@emotion/styled";
import leaf from "../Assets2/leaf.png";
import { Icon } from "@iconify/react";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { Typography } from "@mui/material";

const Wrapper = styled.div`
  position: relative;
  height: 240px;
  width: 300px;
  display: flex;
  justify-center: "center";
  align-items: center;
`;

const Progress = styled.svg`
  width: 280px;
  height: 260px;
`;

const TrackAndFill = css`
  fill: rgba(0, 0, 0, 0);
  stroke-width: 30;
  transform: translate(290px, 800px) rotate(-120deg);
`;

const Track = styled.path`
  ${TrackAndFill}
  stroke: #EEEEEE;
`;

const Fill = styled.path`
  ${TrackAndFill}
  stroke: #149BA1;
  stroke-linecap: round;
  stroke-dasharray: 2160;
  stroke-dashoffset: 2160;
  transition: stroke-dashoffset 1s;
  &.blue {
    stroke: rgb(104, 214, 198);
  }
  &.green {
    stroke: rgb(186, 223, 172);
  }
`;
//small track
const SmallTrack = styled.path`
  fill: rgba(0, 0, 0, 0);
  stroke-width: 30;
  transform: translate(200px, 800px) rotate(-120deg);
  stroke: #eeeeee;
  width: 100px;
  height: 140px;
`;

const SmallTrack2 = styled.path`
  fill: rgba(0, 0, 0, 0);
  stroke-width: 30;
  transform: translate(200px, 800px) rotate(-120deg);
  stroke: #eeeeee;
  width: 100px;
  height: 140px;
`;

const Line = styled.div`
  width: 2cm;
  height: 0.038cm; 
  background-color: black; 
`;

const TextAbove = styled.div`
  margin-bottom: 0cm;
`;

const toolTipFont = { fontFamily: 'poppins', fontSize: '12px', lineHeight: '25px', fontWeight: 'bold' }
const toolTipFontMain = { fontFamily: 'poppins', fontSize: '12px', lineHeight: '28px', fontWeight: '800' }

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.1)',
    border: '0.5px solid #D8D8D8',
    boxShadow: '0px 30px 60px -30px rgba(0, 0, 0, 0.30), 0px 50px 100px -20px rgba(50, 50, 93, 0.25)'
  },
}));

export const ProgressBar = ({ progress, carbonEmission }) => {
  const strokeDashoffset = (1440 * (100 - progress)) / 100;
  return (
    <Wrapper>
      <div>
        <Progress
          className={`progress blue noselect`}
          data-progress={progress}
          x="0px"
          y="0px"
          viewBox="0 0 785 628"
          style={{ transform: "rotate(180deg)", marginLeft: "0x" }}
        >
          <Track
            className="track"
            d="M723 314L543 625.77 183 625.77 3 314 183 2.23 543"
            style={{ strokeWidth: "85px" }}
          />

          <SmallTrack
            d="M723 314L543 625.77 183 625.77 3 314 183 2.23 543"
            style={{
              strokeWidth: "7px",
              stroke: "#51CBFF",
              strokeDasharray: "40,20",
              transform: "translateX(300px) translateY(800px) rotate(-120deg)",
            }}
          />

          <Fill
            className="fill"
            d="M723 314L543 625.77 183 625.77 3 314 183 2.23 543 "
            style={{
              strokeDasharray: "1440",
              strokeDashoffset: strokeDashoffset,
              strokeWidth: "85px",
            }}
          ></Fill>
        </Progress>
      </div>
      <div style={{ marginLeft: "-265px" }}>
        <Progress
          viewBox="0 0 785 628"
          style={{
            transform: "rotate(180deg)",
            marginLeft: "10px",
            height: "200px",
            width: "180px",
          }}
        >
          <SmallTrack2
            d="M723 314L543 625.77 183 625.77 3 314 183 2.23 543"
            style={{
              strokeWidth: "25px",
              stroke: "#FF9240",
            }}
          />
        </Progress>
      </div>

      <div style={{ marginLeft: "20px", marginTop: "10px" }}>
        <p
          style={{
            fontSize: "16px",
            fontWeight: "900",
            marginTop: "-10px",
            marginBottom: "5px",
            marginLeft: "-110px",
            fontFamily: 'poppins'
          }}
        >
          {(carbonEmission).toFixed(1)} Kg CO<sub>2</sub>e
        </p>
        <Line style={{ marginLeft: "-93px", marginTop: '7px' }} />
        <p
          style={{
            fontSize: "15px",
            fontWeight: '900',
            marginTop: "7px",
            marginBottom: "5px",
            marginLeft: "-120px",
            fontFamily: 'poppins'
          }}
        >
          CarbonCompete
        </p>

      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', marginLeft: "30px" }}>
        <div
          style={{
            fontFamily: "poppins",
            textAlign: "center",
            width: '150px'
          }}
        >
          <div style={{ fontSize: "16px", fontWeight: "bolder" }}>
            CONFIDENCE SCORE
          </div>
          <TextAbove style={{ fontSize: "1.3rem", fontWeight: "bolder", color: '#149BA1' }}>
             25%
          </TextAbove>
        </div>
        <img style={{ marginLeft: "67px", height: '30px', width: '30px',justifyItems:"center" }} src={leaf} alt="leaf" />
        <div
          style={{
            fontFamily: "poppins",
            textAlign: "center",
          }}
        >
          {/* <div style={{ display: 'flex', justifyContent: 'center', gap: '0 !important', alignItems: 'center', fontSize: "16px", fontWeight: "bolder", }}>
            WACI
            <div>
              <LightTooltip
                placement="bottom"
                title={
                  <div>
                    <Typography style={{ fontFamily: 'poppins', textAlign: 'center', lineHeight: '25px', fontSize: '15px', color: '#05D772' }}>
                      Financed Emissions
                    </Typography>
                    <Typography sx={toolTipFont}>
                      Absolute Emission - <b style={{color: '#149BA1'}}>{(carbonEmission / 1000).toFixed(2)} tCO<sub>2</sub>ee</b>
                    </Typography>
                    <Typography sx={toolTipFontMain}>
                      Average Carbon Intensity - <b style={{color: '#149BA1'}}>{(carbonEmission / 900).toFixed(2)} tCO<sub>2</sub>e/€m</b>
                    </Typography>
                    <Typography sx={toolTipFont}>
                      Economic Emission Intensity - <b style={{color: '#149BA1'}}>{(carbonEmission / 700).toFixed(2)} tCO<sub>2</sub>e/€m</b>
                    </Typography>
                    <Typography sx={toolTipFont}>
                      Physical Emission Intensity - <b style={{color: '#149BA1'}}>{(carbonEmission / 800).toFixed(2)} tCO<sub>2</sub>e/tkm</b>
                    </Typography>
                  </div>
                }
                arrow
              >
                <IconButton
                  style={{
                    left: '10px',
                  }}
                >
                  <InfoIcon style={{ fontSize: '20px', color: '#149BA1' }} />
                </IconButton>
              </LightTooltip>
            </div>
          </div> */}
          {/* <TextAbove style={{ fontSize: "1.3rem", fontWeight: "bolder", color: '#149BA1' }}>
            {(carbonEmission / 90).toFixed(1)} Kg CO<sub>2</sub>e
          </TextAbove> */}
        </div>
      </div>

      <div>
        <Icon
          style={{
            fontSize: "53px",
            marginBottom: "235px",
            marginLeft: "-215px",
            color: "#149BA1",
          }}
          icon="bxs:right-arrow"
        />
      </div>

    </Wrapper>
  );
}