import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
// import EChartsReact from 'echarts-for-react';
import {
  FormControl,
  Select,
  MenuItem,
  Typography,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { makeStyles } from '@material-ui/core';
// import { getProductBatchEmissionData } from '../../../services/analytics';

const useStyles = makeStyles((theme) => ({
  customLegend: {
    position: 'absolute',
    bottom: 10,
    left: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
}))


const CarbonScoreChart = (props) => {
  const classes = useStyles();
  const [productData, setProductData] = useState({});
  const [selectedProduct, setSelectedProduct] = useState('');
  const [top5Products, setTop5Products] = useState([]);
  const [chartOptions, setChartOptions] = useState({
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)',
    },
    legend: {
      show: false,
      top: '5%',
      left: 'center',
    },
    color: ['#149BA1', '#FFC950', '#FFA058', '#006799', '#DAF7A6', '#CCCCFF'],
    series: [
      {
        name: 'Stage Carbon Scores',
        type: 'pie',
        radius: ['50%', '90%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 5,
          borderColor: '#fff',
          borderWidth: 3,
        },
        label: {
          show: false,
          position: 'center',
          formatter: '{b}',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 14,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: [],
      },
    ],
  });

  useEffect(()=>{
    setProductData(props?.productData || {}) 
    setTop5Products(props?.top5Products || [])
    if (props?.top5Products?.length > 0) {
      setSelectedProduct(props?.top5Products[0]);
    }
  },[props])

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await getProductBatchEmissionData();
  //       const data = response.data[0].products || {};
  //       setProductData(data);
  //       const productsArray = Object.entries(data);
  //       productsArray.sort(([, productA], [, productB]) => {
  //         return productB.products_carbon_score - productA.products_carbon_score;
  //       });
  //       const top5 = productsArray.slice(0, 5).map(([productName]) => productName);

  //       setTop5Products(top5);
  //       if (top5.length > 0) {
  //         setSelectedProduct(top5[0]);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching product data:', error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  useEffect(() => {
    if (selectedProduct && productData[selectedProduct]) {
      const selectedProductData = productData[selectedProduct];
      const stages = Object.keys(selectedProductData.stages);
      // const stageCarbonScores = stages.map(
      //   (stage) => selectedProductData.stages[stage].batches[0].stage_carbon_score
      // );

      setChartOptions((prevOptions) => ({
        ...prevOptions,
        series: [
          {
            ...prevOptions.series[0],
            data: stages.map((stage) => ({
              value: parseInt( // Change parseFloat to parseInt
                selectedProductData.stages[stage].batches.reduce(
                  (accumulator, batch) => accumulator + batch.stage_carbon_score,
                  0
                )
              ),
              name: stage,
            })),
          },
        ],
      }));

    }
  }, [selectedProduct, productData]);


  const handleProductChange = (event) => {
    setSelectedProduct(event.target.value);
  };

  const theme = createTheme({
    typography: {
      fontFamily: 'Poppins',
    },
  });

  const legendItems = Object.keys(productData[0]?.products || {}).map((key) => (
    <div key={key} className={classes.legendItem}>
      <div className={classes.dot}
        style={{
          backgroundColor:
            chartOptions.series[0].data.find((item) => item.name === key)?.itemStyle?.color || 'gray',
        }}
      >
        {key}
      </div>
    </div>
  ));

  return (
    <ThemeProvider theme={theme} >
      <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'fit-content', padding: '20px 0', }}>
        <div style={{width: '100%', display: "flex", flexDirection: 'column', gap: 10 }}>
          <Typography
            sx={{ fontFamily: 'poppins', fontSize: '15px', fontWeight: 600, textAlign: 'center' }}
          >
            Stage wise Carbon Emissions
          </Typography>
          <FormControl fullWidth>
            {/* <InputLabel>Select a Product</InputLabel> */}

            <Select
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 180,
                    overflowY: 'scroll',
                  },
                },
              }}
              size='small'
              style={{
                width: '100%',
                backgroundColor: '#F1F1F1',
                borderRadius: '8px',
                fontWeight: 900,
                border: 'none !important',
                '&:focus': {
                  border: 'none',
                },
              }}
              value={selectedProduct} onChange={handleProductChange}>
              {top5Products.map((productName) => (
                <MenuItem sx={{ fontSize: '12px', }} key={productName} value={productName}>
                  {productName.length > 13
                    ? `${productName.slice(0, 27)}`
                    : productName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
          <ReactECharts option={chartOptions} style={{ height: '250px', width: '300px', marginTop: '10px' }} />
          <div className={classes.customLegend}>{legendItems}</div>
      </div>
    </ThemeProvider>
  );
};

export default CarbonScoreChart;