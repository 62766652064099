import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';

const ConfirmationModal = ({modal, setModal, heading, body, acceptFunction, buttonName="next", fullscreen=false}) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState("")
    const [caption, setCaption] = useState("")
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(()=>{
        if(modal){
            setOpen(modal);
        }
        if(heading){
            setTitle(heading)
        }
        if(body){
            setCaption(body)
        }
    },[modal, heading, body])

    const handleClose = () => {
        setOpen(false);
        setModal(false)
    };

    return (
        <React.Fragment>
            <Dialog
                fullScreen={fullscreen && fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {caption}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={acceptFunction} autoFocus>
                        {buttonName}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default ConfirmationModal;