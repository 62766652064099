import axios from "./axiosInstance";

const API_URL = process.env.REACT_APP_API_URL;

/**
 * @description Get stages. following are optional params
 * @param {string} schemaId
 * @param {String} schemaName
 * @param {Boolean} isActive
 * @returns
 */
export const getStages = async (stageIds = [], stageName, isActive = true) => {
  try {

    const stageId = stageIds.length > 0 ? stageIds.join(',') : undefined;

    const responseData = await axios({
      url: `${API_URL}/api/v1/stage`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        isActive,
        stageId: stageId,
        stageName,
      },
    });
    return responseData.data || [];
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getAllStages = async () => {
  try {
    const response = await axios({
      url: `${API_URL}/api/v1/stage`,
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};