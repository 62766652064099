import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Drawer, Typography, Grid, IconButton, Link, Container, Button } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import cc_logo from '../../../pages/Assets2/carbon_intel_logo.png'
import cc_small_logo from '../../../pages/Assets2/cc-small-logo-main.png'
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
import Iconify from '../../../components/iconify/Iconify';
//
import { navConfig } from './config';
import { makeStyles } from '@material-ui/core';
import Typewriter from 'typewriter-effect';

// ----------------------------------------------------------------------

const NAV_WIDTH = 129;

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '21px',
    height: '34px',
    marginBottom: '15px'
  },
  wordAnimation: {
    animation: '$fadeInOut 2s linear infinite',
  },
  '@keyframes fadeInOut': {
    '0%, 100%': {
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
  },
  compete: {
    opacity: 1,
  },
  intel: {
    opacity: 0,
  },
  logo_font1: {
    fontSize: '16px',
    color: '#fff',
    fontWeight: 'bold',
    fontFamily: 'poppins',
    letterSpacing: '1.5px'
  },
  logo_font2: {
    fontSize: '16px',
    color: '#fff',
    marginTop: '-7px',
    fontWeight: 'bold',
    fontFamily: 'poppins',
    letterSpacing: '1.5px'
  },
  logo_font_container: {
    marginTop: '-15px'
  },
  supportButton: {
    bottom: '-60px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    padding: '8px',
    backgroundColor: 'rgba(94, 99, 102, 0.1)',
    color: 'rgba(28, 29, 34, 1)',
    '&:hover': {
      backgroundColor: 'rgba(0, 201, 210, 0.2)',
      color: '#000'
    },
  },
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [typewriterCount, setTypewriterCount] = useState(0);
  const { pathname } = useLocation();
  const localNavWidth = JSON.parse(localStorage.getItem('nav_width'));
  const [navWidth, setNavWidth] = useState(localNavWidth || 218);

  const isDesktop = useResponsive('up', 'lg');

  const handleSidebarCollapse = () => {
    if (navWidth === 280) {
      localStorage.setItem('nav_width', 129);
      setNavWidth(129);
    } else {
      localStorage.setItem('nav_width', 280);
      setNavWidth(280);
    }
  };

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleTypewriterComplete = () => {
    if (typewriterCount < 1) {
      setTypewriterCount(typewriterCount + 1);
    }
  };

  const handleSupportClick = () => {
    navigate('/carboncompete/help');
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        color: (theme) => theme.palette.primary.dark,
        backgroundColor: 'rgba(0, 201, 210, 0.05)',
        color: '#000',
        borderRight: '0.5px solid rgba(224, 224, 224, 1)',
      }}
    >
      <Box
        sx={{
          margin: '17px 0 0 11px',
          display: { xs: 'none', lg: 'flex' }, // Hide on mobile
          alignItems: 'center',
          justifyContent: 'center',
          gap: '5px',
          height: '50px', // Adjust this value as needed
        }}
      >
        <img
          className={classes.logo}
          src={cc_small_logo}
          alt='cc_logo'
          style={{ height: '100%', width: 'auto', display: 'block', marginRight: '4px' }}
        />
        <Typography
          className={classes.logo_font2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            lineHeight: 1, // Adjust line height
            fontSize: '1rem', // Adjust font size as needed
            margin: 0,
            padding: 0,
          }}
        >
          Carbon
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Typography
            className={classes.logo_font2}
            sx={{
              lineHeight: 1, // Adjust line height
              fontSize: '1rem', // Adjust font size as needed
              margin: 0,
              padding: 0,
            }}
          >
            {typewriterCount < 2 && (
              <Typewriter
                options={{
                  strings: ["Compete", "Intel"],
                  autoStart: true,
                  deleteSpeed: 200,
                  cursor: ''
                }}
                onInit={(typewriter) => {
                  typewriter
                    .typeString("Compete")
                    .pauseFor(1000)
                    .deleteAll()
                    .typeString("Intel")
                    .start()
                    .callFunction(() => handleTypewriterComplete());
                }}
              />
            )}
            {typewriterCount === 2 && (
              <span>Compete</span>
            )}
          </Typography>
        </Box>
      </Box>
      <NavSection isDesktop={isDesktop} showText={navWidth === 280} data={navConfig} />

      <Button
        className={classes.supportButton}
        onClick={handleSupportClick}
        startIcon={<Iconify icon="mdi:headphones" />}
      >
        {navWidth === 218 ? 'Contact Support' : ''}
      </Button>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: navWidth },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: navWidth,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}