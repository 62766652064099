import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Grid,
    Button,
    ThemeProvider,
    createTheme,
} from '@mui/material';
import EChart from 'echarts-for-react';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Poppins, sans-serif',
    },
    chartContainer: {
        width: '100%',
    },
    selectFormControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    switchButton: {
        display: 'flex',
        width: '126px',
        height: '26px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        textTransform: 'capitalize',
        flexShrink: 0,
        textAlign: 'center',
        borderRadius: '36px',
        '&:hover': {
            background: 'linear-gradient(45deg, #149BA1 30%, #FFC950 95%)',
        },
    }
}));

export const ComparisonChart21 = ({ allProducts }) => {
    const [clickedProduct, setClickedProduct] = useState(null);
    const classes = useStyles();
    const [checkedProducts, setCheckedProducts] = useState({});
    const [chartData, setChartData] = useState(null);
    const [chartType, setChartType] = useState('bar');

    useEffect(() => {
        if (allProducts) {
            // Sort products by CarbonScore in descending order
            const sortedProducts = allProducts.sort((a, b) => b.analytics.CarbonScore - a.analytics.CarbonScore);
            
            // Select top 4 products
            const topProducts = sortedProducts.slice(0, 4);

            // Initialize checked state with top products
            const initialCheckedState = topProducts.reduce((state, product) => {
                state[product.productName] = true;
                return state;
            }, {});

            setCheckedProducts(initialCheckedState);
        }
    }, [allProducts]);

    useEffect(() => {
        setChartData(getChartData());
    }, [checkedProducts, allProducts, chartType]);

    const handleCheckboxChange = (productName) => {
        setCheckedProducts((prevChecked) => ({
            ...prevChecked,
            [productName]: !prevChecked[productName],
        }));
    };

    const handleToggle = () => {
        setChartType((prevType) => (prevType === 'bar' ? 'bar' : 'bar'));
    };

    const trimProductName = (productName) => {
        const words = productName.split(' ');
        return words.slice(0, 1).join(' ');
    };

    const getChartData = () => {
        const selectedProducts = Object.keys(checkedProducts).filter(
            (productName) => checkedProducts[productName]
        );

        const trimmedProducts = selectedProducts.map(trimProductName);

        const colors = ['#FFC950', '#FFA058', '#149BA1', '#006799', '#DAF7A6', '#CCCCFF'];

        return {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
                formatter: (params) => {
                    const originalProductName = params[0]?.name || '';
                    return `${originalProductName}: ${params[0]?.value || 0}`;
                },
            },
            xAxis: {
                type: 'category',
                data: trimmedProducts,
                axisLabel: {
                    color: '#030229',
                    textAlign: 'center',
                    fontFamily: 'Poppins',
                    fontSize: 12,
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: '#030229',
                    textAlign: 'center',
                    fontFamily: 'Poppins',
                    fontSize: 16,
                },
            },
            legend: {
                data: selectedProducts,
            },

            series: [
                {
                    data: selectedProducts.map((productName, index) => {
                        const product = allProducts.find(
                            (p) => p.productName === productName
                        );
                        return product ? parseFloat(product.analytics.CarbonScore).toFixed(2) : 0;
                    }),
                    barWidth: 40,
                    type: chartType,
                    itemStyle: {
                        borderRadius: [8, 8, 0, 0],
                        color: (params) => {
                            return colors[params.dataIndex % colors.length];
                        },
                    },
                    lineStyle: {
                        color: 'silver',
                    },
                    emphasis: {
                        focus: 'series',
                    },
                    markPoint: {
                        data: selectedProducts.map((productName, index) => {
                            const product = allProducts.find(
                                (p) => p.productName === productName
                            );
                            return product
                                ? {
                                    name: productName,
                                    value: parseFloat(product.analytics.CarbonScore).toFixed(0),
                                }
                                : null;
                        }),
                    },
                },
            ],
        };
    };

    const theme = createTheme({
        typography: {
            fontFamily: 'Poppins',
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <>
                <Typography
                    sx={{ fontFamily: 'poppins', fontSize: '15px', fontWeight: 600, marginBottom: '37px', textAlign: 'center', paddingTop: '26px' }}
                >
                    Comparison of Carbon Score of your top products
                </Typography>
                <Grid container spacing={2}>
                    {allProducts.slice(0, 4).map((product) => (  // Only render top 4 products
                        <Grid item xs={3} key={product.productName}>
                            <Button
                                sx={{
                                    background: checkedProducts[product.productName] ? '#149BA1' : '#D9D9D9',
                                    color: checkedProducts[product.productName] ? '#fff' : '#000',
                                }}
                                variant="contained"
                                className={classes.switchButton}
                                onClick={() => {
                                    handleCheckboxChange(product.productName);
                                    setClickedProduct(clickedProduct === product.productName ? null : product.productName);
                                }}
                            >
                                <Typography style={{ textAlign: 'center' }}>
                                    {trimProductName(product.productName)}
                                </Typography>
                            </Button>
                        </Grid>
                    ))}
                </Grid>
                <div className={classes.root}>
                    <div className={classes.chartContainer}>
                        <EChart style={{ height: '400px' }} option={getChartData()} />
                    </div>
                </div>
            </>
        </ThemeProvider>
    );
};

