import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { Typography } from '@mui/material';

const RadarGraph = ({ productById }) => {
  if (!productById || productById.length === 0) {
    return <div>Loading...</div>; // Placeholder while data is loading
  }

  
  const data = {
    productID: productById[0]?.productId,
    productName: productById[0]?.productName,
    CarbonScore: productById[0]?.analytics?.CarbonScore ?? 0,
    LifeCycleStages: productById[0]?.analytics?.LifeCycleStages ?? [],
  };

  


  if (!Array.isArray(data.LifeCycleStages) || data.LifeCycleStages.length === 0) {
    return <div style={{padding:"15px",marginTop:"-10px"}}>No lifecycle stages data available</div>;
  }

  // Prepare data for radar chart
  const { productName, LifeCycleStages } = data;

  // Extracting stage names and contributions
  const indicatorData = LifeCycleStages.map((stage) => ({
    name: stage.name || 'Unknown Stage',
    max: 100, // Max value for normalization to 100%
  }));

  // Series data for radar chart
  const seriesData = [
    {
      value: LifeCycleStages.map((stage) => (stage.sumContribution || 0) * 100),
    },
  ];

  const getOption = () => ({
    color: ['#67F9D8', '#FFE434', '#56A3F1', '#FF917C'],
    legend: {},
    radar: [
      {
        indicator: indicatorData,
        center: ['50%', '50%'],
        radius: 120,
        startAngle: 90,
        splitNumber: 4,
        shape: 'circle',
        axisName: {
          formatter: '【{value}】',
          color: '#428BD4',
        },
        splitArea: {
          areaStyle: {
            color: ['#77EADF', '#26C3BE', '#64AFE9', '#428BD4'],
            shadowColor: 'rgba(0, 0, 0, 0.2)',
            shadowBlur: 10,
          },
        },
        axisLine: {
          lineStyle: {
            color: 'rgba(211, 253, 250, 0.8)',
          },
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(211, 253, 250, 0.8)',
          },
        },
      },
    ],
    tooltip: {
      trigger: 'item',
      position: function (point, params, dom, rect, size) {
        // Calculate the position to show the tooltip on the right side
        return [size.viewSize[0] - size.contentSize[0], point[1]];
      },
      formatter: function (params) {
        const tooltipText = params.value.map((v, index) => {
          return `${indicatorData[index].name}: ${v.toFixed(2)}%`;
        }).join('<br />');
        return `${tooltipText}`;
      },
    },
    series: [
      {
        type: 'radar',
        emphasis: {
          lineStyle: {
            width: 4,
          },
        },
        data: seriesData,
      },
    ],
  });

  return (
    <>
      <Typography
        sx={{ fontFamily: 'poppins', fontSize: '15px', fontWeight: 600, textAlign: 'center', paddingTop: '26px' }}
      >
        Life Cycle Stages contributions of <span style={{fontFamily: 'poppins', textTransform: 'capitalize'}}>{productName}</span>
      </Typography>
      <ReactEcharts
        option={getOption()}
        style={{ width: '100%', height: '450px' }}
      />
    </>
  );
};

export default RadarGraph;