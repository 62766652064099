import React, { useState } from "react";
import { Box, Button, Typography, Paper } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export const PurchasedGoods = ({ onFileUpload, uploadedFile }) => {
  const [file, setFile] = useState(uploadedFile || null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      if (onFileUpload) {
        onFileUpload(selectedFile);
      }
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    if (onFileUpload) {
      onFileUpload(null);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 4,
        gap: 2,
        borderRadius: 2,
        border: "1px solid #ddd",
        maxWidth: 500,
        margin: "auto",
      }}
    >
      <Typography fontFamily={'poppins'} variant="h5" gutterBottom>
        Upload your purchase register file
      </Typography>
      <Button
        variant="outlined"
        component="label"
        sx={{
          width: "100%",
          height: 50,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 2,
          cursor: "pointer",
          fontFamily: 'poppins'
        }}
      >
        {file ? `Replace File: ${file.name}` : "Click to select a CSV file"}
        <input
          type="file"
          accept=".csv"
          hidden
          onChange={handleFileChange}
        />
      </Button>

      {file && (
        <Paper
          sx={{
            width: "100%",
            mt: 2,
            p: 2,
            backgroundColor: "#f9f9f9",
            borderRadius: 2,
            overflowX: "auto",
          }}
        >
          <Typography fontFamily={'poppins'} variant="h6">File Selected:</Typography>
          <Typography fontFamily={'poppins'}>{file.name}</Typography>
          <Button
            startIcon={<DeleteIcon />}
            color="error"
            variant="contained"
            onClick={handleRemoveFile}
            sx={{ mt: 2, fontFamily: 'poppins' }}
          >
            Remove File
          </Button>
        </Paper>
      )}
    </Box>
  );
};
