import React from 'react';
import { Typography, Grid, Paper, IconButton } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { makeStyles } from '@material-ui/core';
import { APP_KEYS } from '../../utils/constants/AppKeyConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: 15,
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    fontSize: 48,
    marginBottom: theme.spacing(1),
  },
  videoContainer: {
    position: 'relative',
    paddingTop: '56.25%',
    width: '100%',
    height: 0,
  },
  videoIframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 'none',
  },
  anchor: {
    textDecoration: 'none', color: '#149BA1'
  }
}));

export const HelpAndSupport = () => {

  const userInfo = JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO) || '{}');
  const roleId = userInfo?.roles?.[0]?.roleId;

  const classes = useStyles();

  return (
    <div className={classes.root}>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <PhoneIcon className={classes.icon} color="primary" />
            <Typography variant="h6">Phone Support</Typography>
            <Typography><a target='_blank' className={classes.anchor} href="tel:+11234567890">+91 9486084342</a></Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <EmailIcon className={classes.icon} color="primary" />
            <Typography variant="h6">Email Support</Typography>
            <Typography><a target='_blank' className={classes.anchor} href="mailto:support@carboncompete.com">support@carboncompete.com</a></Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper className={classes.paper}>
            <HelpOutlineIcon className={classes.icon} color="primary" />
            <Typography variant="h6"> Website</Typography>
            <Typography><a className={classes.anchor} href='https://www.carboncompete.com' target='_blank'>Explore our Website</a></Typography>
          </Paper>
        </Grid>

        {roleId === 2 && (
          <Grid item xs={12} md={4}>
            <Paper className={classes.paper}>
              <div className={classes.videoContainer}>
                <iframe
                  src="https://carboncompetetech-my.sharepoint.com/personal/shailesh_carboncompete_com/_layouts/15/embed.aspx?UniqueId=57b77ff6-1aee-47b6-bbea-2dc42d8a4bd7&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create"
                  className={classes.videoIframe}
                  frameBorder="0"
                  allowFullScreen
                  title="Demo Video">
                </iframe>
              </div>
            </Paper>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
