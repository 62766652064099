import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Grid } from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Page404() {

  let navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title> 404 Page Not Found </title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Sorry, page not found!
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your
            spelling.
          </Typography>

          <Box
            component="img"
            src="/assets/illustrations/illustration_404.svg"
            sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
          />

          <Grid container spacing={3}>
            <Grid px={6}>
              <Button item xs={6} to="/" size="large" variant="contained" sx={{ backgroundColor: '#256D85' }} component={RouterLink}>
                <HomeIcon />  Go to Home
              </Button>
            </Grid>
            <Grid px={6}>
              <Button item xs={6} onClick={() => navigate(-2)} size="large" variant="contained" sx={{ backgroundColor: '#256D85' }} component={RouterLink}>
                <ArrowBackIcon /> Back
              </Button>
            </Grid>
          </Grid>
        </StyledContent>
      </Container>
    </>
  );
}
