import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";

import Iconify from "../../../../components/iconify";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Scope1And2Cards from "./Scope1And2Cards";
import Scope3Cards from "./Scope3Cards";
import EmissionChart from "../../ExternalCharts/ScopeActivity";
import ScopeIntensityChart from "./ScopeIntensityChart";

// Define sortYearsDescending function
const sortYearsDescending = (a, b) => {
  // Extract the fiscal years if they are strings
  const yearA = typeof a === "string" ? parseInt(a.slice(3, 5)) : a;
  const yearB = typeof b === "string" ? parseInt(b.slice(3, 5)) : b;

  // Sort in descending order based on fiscal years
  return yearB - yearA;
};


export default function ScopeCards({ data, scopeName, esgData }) {
  const [selectedLocation, setSelectedLocation] = useState("All");
  const [selectedYear, setSelectedYear] = useState("All");

  const [commonYears, setCommonYears] = useState([]);

  useEffect(() => {
    if (esgData) {
      const allYears = esgData
        .map((location) => Object.keys(location.years))
        .flat();
      const commonYearsSet = new Set(
        allYears.filter((year) =>
          allYears.every((y) =>
            esgData.some((location) => Object.keys(location.years).includes(y))
          )
        )
      );

      setCommonYears(Array.from(commonYearsSet).sort(sortYearsDescending));
    }
  }, [esgData]);

  const locations = ["All", ...data.map((location) => location.location)];
  const charLocations = [...data.map((location) => location.location)];
  const yearsSet = new Set();
  data.forEach((item) => {
    if (item && item.years) {
      Object.keys(item.years).forEach((year) => {
        yearsSet.add(year);
      });
    }
  });

  const time = ["All", ...yearsSet];

  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value);
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const navigate = useNavigate();

  const selectStyles = {
    width: "100%",
    height: "42px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    fontFamily: "Poppins !important",
    fontWeight: 900,
    border: "none !important",
    "&:focus": {
      border: "none",
    },
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    variant: "menu",
  };

  const theme = createTheme({
    typography: {
      fontFamily: "Poppins !important",
      fontWeight: "bolder !important",
    },
  });
  const useStyles = makeStyles((theme) => ({
    backButton: {
      marginLeft: "20px",
      marginBottom: "10px",
      backgroundColor: "#222",
      "&:hover": { backgroundColor: "#149BA1" },
      color: "#fff",
      display: "flex",
      width: "100px",
      alignItems: "center",
      padding: theme.spacing(1),
      borderRadius: "10px",
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ThemeProvider theme={theme}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Button
              className={classes.backButton}
              onClick={() => navigate("/carboncompete/esgdashboard")}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Iconify
                  icon="icon-park-outline:back"
                  color="white"
                  className={classes.backIcon}
                />
                <Typography
                  fontFamily="Poppins"
                  style={{ textTransform: "capitalize" }}
                >
                  Back
                </Typography>
              </div>
            </Button>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="location-select-label">
                Select Location
              </InputLabel>
              <Select
                labelId="location-select-label"
                id="location-select"
                value={selectedLocation}
                label="Select Location"
                onChange={handleLocationChange}
                MenuProps={menuProps}
                style={selectStyles}
              >
                {locations.map((location) => (
                  <MenuItem key={location} value={location}>
                    {location}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="year-select-label">Select Year</InputLabel>
              <Select
                labelId="year-select-label"
                id="year-select"
                value={selectedYear}
                label="Select Year"
                onChange={handleYearChange}
                MenuProps={menuProps}
                style={selectStyles}
              >
                {time
                  .sort(sortYearsDescending) // Sort the fiscal years in descending order
                  .map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

          </Grid>
        </Grid>
        {scopeName === "scope1And2" && data && esgData && (
          <>
            <Scope1And2Cards
              data={data}
              selectedLocation={selectedLocation}
              selectedYear={selectedYear}
              esgData={esgData}
            />
            <Grid container spacing={2} padding={2}>
              <Grid item xs={12}>
                {/* Add EmissionChart for scope1 */}
                <ScopeIntensityChart
                  data={data}
                  defaultLocations={charLocations}
                  defaultYears={commonYears}
                  scope="scope1"
                />
              </Grid>
              <Grid item xs={12}>
                {/* Add EmissionChart for scope1 */}
                <EmissionChart
                  selectStyles={selectStyles}
                  menuProps={menuProps}
                  data={data}
                  defaultLocations={charLocations}
                  defaultYears={commonYears}
                  scope="scope1"
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: "20px" }}>
                {/* Add EmissionChart for scope1 */}
                <ScopeIntensityChart
                  data={data}
                  defaultLocations={charLocations}
                  defaultYears={commonYears}
                  scope="scope2"
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: "20px" }}>
                {/* Add EmissionChart for scope2 */}
                <EmissionChart
                  selectStyles={selectStyles}
                  menuProps={menuProps}
                  data={data}
                  defaultLocations={charLocations}
                  defaultYears={commonYears}
                  scope="scope2"
                />
              </Grid>
            </Grid>
          </>
        )}
        {scopeName === "scope3" && data && esgData && (
          <>
            <Scope3Cards
              data={data}
              selectedLocation={selectedLocation}
              selectedYear={selectedYear}
              esgData={esgData}
            />
            <Grid container spacing={2} padding={2}>
              <Grid item xs={12} style={{ marginTop: "20px" }}>
                {/* Add EmissionChart for scope1 */}
                <ScopeIntensityChart
                  data={data}
                  defaultLocations={charLocations}
                  defaultYears={commonYears}
                  scope="scope3"
                />
              </Grid>
              <Grid item xs={12}>
                {/* Add EmissionChart for scope1 */}
                <EmissionChart
                  selectStyles={selectStyles}
                  menuProps={menuProps}
                  data={data}
                  defaultLocations={charLocations}
                  defaultYears={commonYears}
                  scope="scope3"
                />
              </Grid>
            </Grid>
          </>
        )}
      </ThemeProvider>
    </div>
  );
}
