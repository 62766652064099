// Flow
import React, { useState, useRef, useCallback } from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Panel,
  ConnectionMode,
} from "reactflow";
import "reactflow/dist/style.css";

import "./Flow.css";
import DefaultNode from "./FlowElements/DefaultNode";
import StartNode from "./FlowElements/StartNode";
import EndNode from "./FlowElements/EndNode";
import InfoNode from "./FlowElements/InfoNode";
import FlowToolBar from "./FlowElements/FlowToolBar";
import FlowConstants from "./FlowConstant";
import TransportNode from "./FlowElements/TransportNode";
import { getCanvasDetailsByProductId } from "../../services/canvas";
import AddNewNodeDialog from "./FlowElements/AddNewNodeDialog";

const nodeTypes = {
  customdefault: DefaultNode,
  custominput: StartNode,
  customoutput: EndNode,
  infoNode: InfoNode,
  transportNode: TransportNode,
};

const DnDFlow = ({ subCategoryName, subCategoryIcon }) => {
  const proOptions = { hideAttribution: true };

  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [showDropNote, setShowDropNote] = useState(true); // note for dropping the product

  const [selectedNodeData, setSelectedNodeData] = useState(null);

  const [addNewNodeDialog, toggleAddNodeDialog] = useState(false);

  const onConnect = useCallback(
    (params) => {
      const sourceNode = nodes.find((e) => e.id === params.source);
      const targetNode = nodes.find((e) => e.id === params.target);

      let edgeStyle = {};

      if (
        sourceNode.type === FlowConstants.TRANSPORT_NODE ||
        targetNode.type === FlowConstants.TRANSPORT_NODE
      ) {
        edgeStyle = {
          style: { stroke: "black" },
          type: "smoothstep",
          animated: true,
        };
      } else {
        edgeStyle = {
          type: "smoothstep",
          style: { strokeWidth: 20 },
        };
      }

      setEdges((eds) =>
        addEdge(
          {
            id: `dnd_edge_${edges.length}`,
            source: params.source,
            target: params.target,
            sourceHandle: params.sourceHandle,
            targetHandle: params.targetHandle,
            ...edgeStyle,
          },
          eds
        )
      );
    },
    [nodes, edges, setEdges]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const calculateNodesAndEdges = ({ stages = {} }, { productName = "" }) => {
    // Calculate Nodes
    const productNodes = Object.keys(stages).map((stage, index) => {
      const stageName = stage.toLowerCase();
      const type = stageName.includes("farm")
        ? "farmer"
        : stageName.includes("transp")
        ? "transport"
        : "production";

      return {
        width: 125,
        height: 125,
        sourceHandle: "source-right",
        targetHandle: "target-left",
        id: "dndnode_" + index,
        type: type === "transport" ? "transportNode" : type === "farmer" ? 'custominput' : "customdefault",
        position: {
          x: 350 * index,
          y: 500,
        },
        data: {
          coe2: "2.3co2e",
          factors: stages[stage]?.batches[0]?.emission_breakdown || {},
          metaData: {
            icon: type,
            name: stage,
          },
        },
      };
    });

    // Add End Node - to show the proudct name on it
    if (productName) {
      productNodes.push({
        width: 110,
        height: 100,
        id: "dndnode_" + productNodes.length,
        type: "customoutput",
        position: {
          x: 350 * productNodes.length,
          y: 500,
        },
        animated: true,
        data: {
          coe2: "2.3co2e",
          metaData: {
            name: productName,
            icon: productName
          },
        },
      });
    }

    // Calculate Edges
    const productEdges = [];

    for (let productIndex = 0; productIndex < productNodes.length - 1; productIndex++) {
      const edge = {
        id: "dnd_edge_" + productIndex,
        source: "dndnode_" + productIndex,
        target: "dndnode_" + (productIndex + 1),
        sourceHandle: "source-right",
        targetHandle: "target-left",
        type: "smoothstep",
        style: {
          strokeWidth: 20,
        },
      };
      if (productNodes[productIndex].type === "transportNode" || productNodes[productIndex + 1]?.type === "transportNode") {
        edge.animated = true;

        delete edge.style;
      }
      productEdges.push(edge);
    }

    return { productNodes, productEdges };
  };

  const onDrop = useCallback(
    async (event) => {
      setShowDropNote(false);
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");

      if (type === FlowConstants.ADD_NEW_NODE) {
        // const nodeType = event.dataTransfer.getData('application/reactflow/node/type');

        // const nodeName = "Temp" || prompt('Enter New Node Name');

        const position = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: event.clientY - reactFlowBounds.top,
        });

        setSelectedNodeData({
          nodeId: "dndnode_" + nodes.length,
          position,
        });

        toggleAddNodeDialog(true);
      } else {
        const selectedProductObj = JSON.parse(
          event.dataTransfer.getData("application/productObj")
        );

        const { productId: selectedProductId } = selectedProductObj;

        // check if the dropped element is valid
        if (typeof type === "undefined" || !type) {
          return;
        }

        // Check if the dropped node already exists in the nodes state
        const nodeExists = nodes.some((node) => node.type === type);
        if (nodeExists) {
          return;
        }

        setNodes([]);
        setEdges([]);

        let response;
        // if (selectedProductObj?.productName.toLowerCase() === "yogurt") {
        //   // Get Product Detail by productId
        //   const productDetail = getCanvasDetailsByProductIdMockData("yogurt_id");

        //   // Remove previous nodes and edges
        //   setNodes(productDetail.flow_info.nodes || []);
        //   setEdges(productDetail.flow_info.edges || []);
        //   return;
        // } else {
        // }
        // Get Product Detail by productId
        response = await getCanvasDetailsByProductId(selectedProductId);

        const productDetail = Object.values(response)[0] || {};

        const { productNodes, productEdges } = calculateNodesAndEdges(
          productDetail,
          selectedProductObj
        );

        // Remove previous nodes and edges
        setNodes(productNodes || []);
        setEdges(productEdges || []);

        // setNodes((prevNodes) => [...prevNodes, ...productDetail.flow_info.nodes]);
        // setEdges((prevEdges) => [...prevEdges, ...productDetail.flow_info.edges]);
      }
    },
    [reactFlowInstance, nodes, setNodes, setEdges]
  );

  const onSelectionDragStart = useCallback((event, node) => {
    console.log(node);
  });

  const onSelectionEnd = useCallback((event, node) => {
    console.log(node);
  });

  const onSave = useCallback(() => {
    if (reactFlowInstance) {
      const flow = reactFlowInstance.toObject();
      localStorage.setItem("flow_data", JSON.stringify(flow));
    }
  }, [reactFlowInstance]);

  // Adds new node with corresponding data
  const onAddNodeSubmit = (nodeData) => {
    if (nodeData === null) {
      // clear the selected node data;
      setSelectedNodeData(null);
      return;
    }

    const newNode = {
      id: selectedNodeData.nodeId,
      type: "customdefault",
      position: selectedNodeData.position,
      animated: true,
      data: {
        metaData: {
          name: nodeData.nodeName,
          icon: nodeData.nodeIcon,
        },
      },
    };

    if (nodeData.nodeType === FlowConstants.TRANSPORT_NODE) {
      newNode.type = "transportNode";
    } else if (nodeData.nodeType === FlowConstants.INFO_NODE) {
      newNode.type = "infoNode";
    } else {
      newNode.type = "customdefault";
    }

    // clear the selected node data;
    setSelectedNodeData(null);

    toggleAddNodeDialog(false);

    setNodes((prevNodes) => [
      ...prevNodes,
      { ...newNode, id: `dndnode_${nodes.length}` },
    ]);
  };

  return (
    <div className="dndflow">
      <ReactFlowProvider>
        <div
          style={{ backgroundColor: "#fff" }}
          className="reactflow-wrapper"
          ref={reactFlowWrapper}
        >
          <ReactFlow
            connectionMode={ConnectionMode.Loose}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            proOptions={proOptions}
            onDragOver={onDragOver}
            nodeTypes={nodeTypes}
            onSelectionDragStart={onSelectionDragStart}
            onSelectionEnd={onSelectionEnd}
            // nodesDraggable={false}
            fitView
          >
            {showDropNote && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 999,
                }}
              >
                <p>Drag and drop any product here</p>
              </div>
            )}

            <Panel position="top-right">
              {/* <button onClick={onSave}>save</button> */}
              <FlowToolBar />
            </Panel>
            <Controls />
          </ReactFlow>
        </div>
      </ReactFlowProvider>

      <AddNewNodeDialog
        openDialog={addNewNodeDialog}
        toggleDialog={toggleAddNodeDialog}
        dialogConfig={{
          title: "Add New Node",
        }}
        emitSubmitEvent={(e) => onAddNodeSubmit(e)}
        nodeData={selectedNodeData}
      />
    </div>
  );
};

export default DnDFlow;
