//simple float
import React, { memo } from "react";
import { Handle, Position } from "reactflow";
import "./Flow.css";
import { Box, styled, Tooltip, tooltipClasses, Typography } from "@mui/material";
import { Icon } from '@iconify/react';

// .nodeLabel {
//     text-overflow: ellipsis;
//     overflow: hidden;
//     white-space: nowrap;
//   }

export default memo(({ data }) => {
   
  const LightTooltipLabel = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'gray',
      color: '#fff',
      boxShadow: theme.shadows[1],
      fontSize: 15,
      fontWeight: 'bold'
    },
  }));

  return (
    <>
      <LightTooltipLabel title={data?.stageName} placement="top">
        <Box sx={{ gap: 1, display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
          <Icon icon={data?.metaData?.icon} width="30" height="30" />
          <Typography variant="body1" sx={{ fontSize: '22px', fontWeight: 'bold', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: "max-content", maxWidth: "100px" }}>{data?.stageName}</Typography>
        </Box>

        {/* <Handle type="source" position={Position.Top} id="a" /> */}
        <Handle type="source" position={Position.Right} id="b" />
        {/* <Handle type="source" position={Position.Bottom} id="c" />  */}
        <Handle type="source" position={Position.Left} id="d" />

        {/* <Handle type="target" position={Position.Right} id="f" /> */}
      </LightTooltipLabel>
    </>
  );
});

