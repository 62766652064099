import React, { useState } from 'react';
import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';
import AddLocation from './AddLocation';
import Configuration from './Configuration';
import Iconify from '../../components/iconify';
import { ActivityDialog } from '../CorporateCanvasSupplier/ActivityDialog';
// import ActivityInput from './ActivityInput';

const StyledTabs = styled(Tabs)({
    position: 'relative',
    '& .MuiTabs-flexContainer': {
        justifyContent: 'center',
    },
    '& .MuiTab-root': {
        color: '#000',
        '&.Mui-selected': {
            color: '#00C9D2',
            fontWeight: 'bold',
        },
    },
    '& .MuiTabs-indicator': {
        backgroundColor: '#00C9D2',
        height: '7px',
        width: '164px !important',
        bottom: '-2px',
        borderRadius: '3.5px',
        zIndex: 10,
    },
    paddingTop: 2,
});

const StyledTab = styled(Tab)({
    textTransform: 'none',
    minWidth: 164,
    marginInline: 28,
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
});

const TabContainer = styled(Box)({
    borderBottom: '1px solid black',
    position: 'relative',
    zIndex: 1,
});

export const ConfigPage = () => {
    const location = useLocation();
    const { selectedLocation } = location.state || {};
    const [activeTab, setActiveTab] = useState(0);
    const navigate = useNavigate();

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Box sx={{ width: '100%', padding: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', mb: 2 }}>
                <Button
                    sx={{
                        backgroundColor: "#222",
                        "&:hover": { backgroundColor: "#149BA1" },
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "10px",
                        marginRight: 'auto',
                    }}
                    onClick={() => navigate("/carboncompete/corporatecanva")}
                >
                    <Iconify
                        icon="icon-park-outline:back"
                        color="white"
                        sx={{ marginRight: 1 }}
                    />
                    <Typography
                        fontFamily="Poppins"
                        style={{ textTransform: "capitalize" }}
                    >
                        Back
                    </Typography>
                </Button>
                <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: '29px',
                        fontWeight: 600,
                        textAlign: 'center',
                        borderRadius: '10px',
                        flexGrow: 1,
                        color: '#149BA1'
                    }}
                >
                    Category and Activity config for {selectedLocation?.name}
                </span>
            </Box>
            <TabContainer>
                <StyledTabs
                    value={activeTab}
                    onChange={handleTabChange}
                    aria-label="scope configuration tabs"
                >
                    <StyledTab label="Location" />
                    <StyledTab label="Configuration" />
                    <StyledTab label="Activity Inputs" />
                </StyledTabs>
            </TabContainer>
            <Box sx={{ padding: 2 }}>
                {activeTab === 0 && <AddLocation location={selectedLocation} setActiveTab={setActiveTab}/>}
                {activeTab === 1 && <Configuration location={selectedLocation} />}
                {activeTab === 2 && <ActivityDialog location={selectedLocation} />}
            </Box>
        </Box>
    );
};
