import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  Typography,
  CircularProgress,
  Box,
  Container,
  useTheme
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import { useFormik } from 'formik';
import * as yup from 'yup';
import Iconify from '../../../components/iconify';
import { userLogin } from '../../../services/auth';
import { useSnackbar } from 'notistack';
import { NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from '../../../utils/constants/NotificationConstants'
import { makeStyles } from '@material-ui/core';
import TypewriterComponent from 'typewriter-effect';
import Typewriter from 'typewriter-effect';
import microsoft from '../../../pages/Assets2/microsoft.png'
import cc_small_logo from '../../../pages/Assets2/cc-small-logo-main.png'
import { APP_KEYS } from '../../../utils/constants/AppKeyConstants';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '40.229px',
    height: '60.879px',
  },
  wordAnimation: {
    animation: '$fadeInOut 2s linear infinite',
  },
  '@keyframes fadeInOut': {
    '0%, 100%': {
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
  },
  compete: {
    opacity: 1,
  },
  intel: {
    opacity: 0,
  },
  logo_font1: {
    fontSize: '30px',
    color: '#000',
    marginBottom: '-17px',
    fontWeight: 'bold',
    fontFamily: 'poppins',
    letterSpacing: '1.5px'
  },
  logo_font2: {
    fontSize: '30px',
    color: '#000',
    marginTop: '-17px',
    fontWeight: 'bold',
    fontFamily: 'poppins',
    letterSpacing: '1.5px'
  },
  logo_font_container: {
    marginLeft: '-15px !important',
    width: '51.229px',
    height: '70.879px',
  }
}));

export const LoginForm = ({ setAuthNavigation }) => {
  const theme = useTheme();
  theme.breakpoints.custom = {
    down: (width) => `@media (max-width:${width}px)`,
  };
  const classes = useStyles();
  const navigate = useNavigate();

  // redirect to dashboard page if user is logged in already
  useEffect(() => {
    if (localStorage.getItem(APP_KEYS.USER_INFO)) {
      navigate('/carboncompete/dashboard');
    }
  }, [navigate]);

  const [showPassword, setShowPassword] = useState(false);
  const [showLoginLoader, setShowLoginLoader] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = yup.object({
    email: yup.string().required('Please enter email'),
    password: yup.string().required('Please enter password'),
  });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async () => {
      setShowLoginLoader(true);
      const loginObject = {
        email: formik.values.email,
        password: formik.values.password
      };
      try {
        const loginResult = await userLogin(loginObject);
        setShowLoginLoader(false);

        // show success message to user
        enqueueSnackbar('Login Successfull', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_SUCCESS
        });
        // navigate('/carboncompete/dashboard');

        let toURL = '/carboncompete/';

        if ((loginResult?.roles).find(e => e.roleName === 'Super Admin'))
          toURL = '/carboncompete/sp-dashboard';
        else if ((loginResult?.roles).find(e => e.roleName === 'Admin'))
          toURL = '/carboncompete/dashboard';
        else if ((loginResult?.roles).find(e => e.roleName === 'ESG'))
          toURL = '/carboncompete/esgdashboard';
        else if ((loginResult?.roles).find(e => e.roleName === 'Corporate Supplier'))
          toURL = '/carboncompete/suppliercorporatecanvas';
        else if ((loginResult?.roles).find(e => e.roleName === 'Corporate Ghg'))
          toURL = '/carboncompete/corporatedashboard';
        window.location = toURL;

      } catch (err) {
        setShowLoginLoader(false);

        // Add Error to notify user
        enqueueSnackbar(err.message || err.msg || 'Error occured while logging', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_ERROR
        });
      }
    },
  });
  const [typewriterCount, setTypewriterCount] = useState(0);

  const handleTypewriterComplete = () => {
    if (typewriterCount < 1) {
      setTypewriterCount(typewriterCount + 1);
    }
  };
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <img className={classes.logo} src={cc_small_logo} alt='cc_logo' />
        <Container sx={classes.logo_font_container}>
          <Typography className={classes.logo_font1}>Carbon <br></br></Typography>
          <Typography className={classes.logo_font2}>
            {typewriterCount < 2 && (
              <Typewriter
                options={{
                  strings: ["Compete", "Intel"],
                  autoStart: true,
                  cursor: ''
                }}
                onInit={(typewriter) => {
                  typewriter
                    .typeString("Compete")
                    .pauseFor(1000)
                    .deleteAll()
                    .typeString("Intel")
                    .start()
                    .callFunction(() => handleTypewriterComplete());
                }}
              />
            )}
            {typewriterCount === 2 && (
              <span>Compete</span>
            )}
          </Typography>


        </Container>
      </Box>
      <Typography sx={{ mt: 3 }} fontFamily="Poppins" fontSize="20px" gutterBottom>
        <b>Sign in <br></br>
          to access CarbonIntel</b>
      </Typography>
      <Stack sx={{ mt: 3 }} spacing={3}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            label={
              <Typography fontFamily="Raleway" fontWeight={600}>
                Enter Email
              </Typography>
            }
            sx={{ width: '100%', '& .MuiTextField-root': { fontFamily: 'Poppins' } }}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={
              formik.touched.email && formik.errors.email
            }
          />

          <TextField
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            label={
              <Typography fontFamily="Raleway" fontWeight={600}>
                Password
              </Typography>
            }
            type={showPassword ? 'text' : 'password'}
            sx={{
              width: '100%',
              '& .MuiTextField-root': { fontFamily: 'Poppins' },
              color: 'white',
              mt: 1,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={
              formik.touched.password && formik.errors.password
            }
          />

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Checkbox name="remember" />{' '}
              <Typography fontFamily="Raleway" fontWeight={600}>
                Remember me
              </Typography>
            </Stack>
            <Typography fontFamily="Poppins" style={{ marginTop: '1.5rem' }}>
              <span
                onClick={() => setAuthNavigation(2)}
                style={{ fontWeight: '600', cursor: 'pointer' }}
              >
                Forgot Password?
              </span>
            </Typography>
          </Stack>

          <>
            <LoadingButton
              startIcon={showLoginLoader && <CircularProgress size={15} style={{ color: 'white' }} />}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{ fontFamily: 'Poppins', backgroundColor: '#149BA1', '&:hover': { bgcolor: '#303030' } }}>
              Sign in
            </LoadingButton>
            <Typography sx={{ textAlign: 'center', color: '#8D8D8D;', mt: 1 }}>____________ or ____________ </Typography>
            <Container sx={{ display: 'flex', justifyContent: 'center', borderRadius: '30px' }}>
              {/* Don't show the loader for the microsft login when user is trying to sgnup */}
              <LoadingButton
                startIcon={false && showLoginLoader && <CircularProgress size={15} style={{ color: 'white' }} />}
                fullWidth
                size="large"
                // onClick=''
                variant="contained"
                sx={{
                  fontFamily: 'Poppins',
                  backgroundColor: '#303030',
                  gap: '20px',
                  width: '70%',
                  mt: 3,
                  [theme.breakpoints.custom.down(1200)]: {
                    width: '100%',
                    gap: '5px'
                  },
                  '&:hover': { bgcolor: '#303030' },
                }}
              >
                <img src={microsoft} alt='microsoft' />
                Sign in with Microsoft
              </LoadingButton>
            </Container>
            <Typography fontFamily="Poppins" style={{ marginTop: '1.5rem' }}>
              Don't have an account?{' '}
              <span
                onClick={() => setAuthNavigation(1)}
                style={{ fontWeight: '600', textDecoration: 'underline', cursor: 'pointer' }}
              >
                Sign Up
              </span>
            </Typography>
          </>
        </form>
      </Stack>
    </>
  );
};
