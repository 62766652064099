import { useNavigate } from 'react-router-dom';
// @mui
import {
  Stack,
  TextField,
  Typography,
  CircularProgress,
  Box,
  Container
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useFormik } from 'formik';
import * as yup from 'yup';
import { userRegister } from '../../../services/auth';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import Typewriter from 'typewriter-effect';
import { NOTIFICATION_SUCCESS, NOTIFICATION_ERROR, NOTIFICATION_INFO } from '../../../utils/constants/NotificationConstants'
import { makeStyles } from '@material-ui/core';
import TypewriterComponent from 'typewriter-effect';
import microsoft from '../../../pages/Assets2/microsoft.png'
import cc_small_logo from '../../../pages/Assets2/cc-small-logo-main.png'
import { APP_KEYS } from '../../../utils/constants/AppKeyConstants';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '40.229px',
    height: '60.879px',
  },
  wordAnimation: {
    animation: '$fadeInOut 2s linear infinite',
  },
  '@keyframes fadeInOut': {
    '0%, 100%': {
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
  },
  compete: {
    opacity: 1,
  },
  intel: {
    opacity: 0,
  },
  logo_font1: {
    fontSize: '30px',
    color: '#000',
    marginBottom: '-17px',
    fontWeight: 'bold',
    fontFamily: 'poppins',
    letterSpacing: '1.5px'
  },
  logo_font2: {
    fontSize: '30px',
    color: '#000',
    marginTop: '-17px',
    fontWeight: 'bold',
    fontFamily: 'poppins',
    letterSpacing: '1.5px'
  },
  logo_font_container: {
    marginLeft: '-15px !important',
    width: '51.229px',
    height: '70.879px',
  }
}));

export const RegisterForm = ({ useCase, setAuthNavigation }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [showLoginLoader, setShowLoginLoader] = useState(false);

  // redirect to dashboard page if user is logged in already
  if (localStorage.getItem(APP_KEYS.USER_INFO)) {
    navigate('/carboncompete/dashboard');
  }

  const validationSchema = yup.object({
    name: yup.string().required('Please enter your name'),
    email: yup.string().required('Please enter your email'),
    password: yup.string().required('Please enter your password'),
    mobile: yup.string().required('Please enter your mobile number'),
  });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: '',
      email: '',
      password: '',
      mobile: '',
    },
    onSubmit: async () => {
      setShowLoginLoader(true);
      try {

        await userRegister(formik.values);

        // show success message to user
        enqueueSnackbar('Account Registered Successfully', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_SUCCESS
        });
        // show info message
        enqueueSnackbar('Please login to proceed further', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_INFO
        });

        navigate('/login');

        setShowLoginLoader(false);
      } catch (err) {

        // Add Error to notify user
        enqueueSnackbar(err.message || err.msg || 'Error occured while trying to create account', {
          autoHideDuration: 3000,
          variant: NOTIFICATION_ERROR
        });

        setShowLoginLoader(false);
      }
    },
  });

  const [typewriterCount, setTypewriterCount] = useState(0);

  const handleTypewriterComplete = () => {
    if (typewriterCount < 1) {
      setTypewriterCount(typewriterCount + 1);
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <img className={classes.logo} src={cc_small_logo} alt='cc_logo' />
        <Container sx={classes.logo_font_container}>
          <Typography className={classes.logo_font1}>Carbon <br></br></Typography>
          <Typography className={classes.logo_font2}>
            {typewriterCount < 2 && (
              <Typewriter
                options={{
                  strings: ["Compete", "Intel"],
                  autoStart: true,
                  cursor: ''
                }}
                onInit={(typewriter) => {
                  typewriter
                    .typeString("Compete")
                    .pauseFor(1000)
                    .deleteAll()
                    .typeString("Intel")
                    .start()
                    .callFunction(() => handleTypewriterComplete());
                }}
              />
            )}
            {typewriterCount === 2 && (
              <span>Compete</span>
            )}
          </Typography>


        </Container>
      </Box>
      <Typography sx={{ mt: 3 }} fontFamily="Poppins" fontSize="20px" gutterBottom>
        <b>Sign up <br></br>
          to get started with CarbonIntel</b>
      </Typography>
      <Stack sx={{ mt: 3 }} spacing={3}>
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
          <TextField
            label={
              <Typography fontFamily="Poppins" fontWeight={500}>
                Your name
              </Typography>
            }
            sx={{ width: '100%' }}
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={
              formik.touched.name && formik.errors.name
            }
          />

          <TextField
            label={
              <Typography fontFamily="Poppins" fontWeight={500}>
                Email address
              </Typography>
            }
            sx={{ width: '100%', mt: 1 }}
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={
              formik.touched.email && formik.errors.email
            }
          />

          <TextField
            label={
              <Typography fontFamily="Poppins" fontWeight={500}>
                Password
              </Typography>
            }
            sx={{ width: '100%', mt: 1 }}
            name="password"
            type='password'
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={
              formik.touched.password && formik.errors.password
            }
          />

          <TextField
            label={
              <Typography fontFamily="Poppins" fontWeight={500}>
                Mobile Number
              </Typography>
            }
            sx={{ width: '100%', mt: 1 }}
            name="mobile"
            value={formik.values.mobile}
            onChange={formik.handleChange}
            error={formik.touched.mobile && Boolean(formik.errors.mobile)}
            helperText={
              formik.touched.mobile && formik.errors.mobile
            }
          />

          <>
            <LoadingButton
              sx={{ mt: 2, fontFamily: 'Poppins', backgroundColor: '#149BA1', '&:hover': { bgcolor: '#303030' } }}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              startIcon={showLoginLoader && <CircularProgress size={15} style={{ color: 'white' }} />}
            >
              Register
            </LoadingButton>
            <Typography fontFamily="Poppins" style={{ marginTop: '1.5rem' }}>
              Have an account?{' '}
              <span
                onClick={() => setAuthNavigation(0)}
                style={{ fontWeight: '600', textDecoration: 'underline', cursor: 'pointer', }}
              >
                Sign In
              </span>
            </Typography>
          </>
        </form>
      </Stack>
    </>
  );
};
