import Iconify from "../../../components/iconify/Iconify";

import SettingsIcon from "@mui/icons-material/Settings";
import { APP_MODULES, SUB_MODULES } from "../../../utils/constants/AppConstants";
import { APP_KEYS } from "../../../utils/constants/AppKeyConstants";

// Modules component
const modules = [
  {
    title: "Super Admin Dashboard",
    meta: {
      moduleName: APP_MODULES.SUPER_ADMIN_DASHBOARD,
    },
    path: "/carboncompete/sp-dashboard",
    icon: <Iconify icon="akar-icons:dashboard" />,
    highlightedIcon: '',
    component: "dashboard",
    subModules: []
  },
  {
    title: "Dashboard",
    meta: {
      moduleName: APP_MODULES.DASHBOARD,
    },
    path: "/carboncompete/dashboard",
    icon: <Iconify icon="akar-icons:dashboard" />,
    component: "dashboard",
    subModules: []
  },
  {
    title: "Dashboard", // Premium Supplier Dashboard
    meta: {
      moduleName: APP_MODULES.PREMIUM_SUPPLIERS_DASHBOARD,
    },
    path: "/carboncompete/premiumdash",
    icon: <Iconify icon="akar-icons:dashboard" />,
    component: "premiumdash",
    subModules: []
  },
  {
    title: "Carbon Catalogue",
    meta: {
      moduleName: APP_MODULES.CARBON_CATALOGUE,
    },
    path: "/carboncompete/carboncatalogue",
    icon: <Iconify icon="mingcute:grid-line" />,
    component: "carboncatalogue",
    subModules: []
  },
  {
    title: "Carbon Canvas",
    meta: {
      moduleName: APP_MODULES.CARBON_CANVAS,
    },
    path: "/carboncompete/canvas",
    icon: <Iconify icon="pixelarticons:drop-area" />,
    component: "canvas",
    subModules: []
  },
  {
    title: "Dashboard",
    path: "/carboncompete/corporatedashboard",
    meta: {
      moduleName: APP_MODULES.CORPORATE_DASHBOARD,
    },
    icon: <Iconify icon="akar-icons:dashboard" />,
    component: "corporatedashboard",
    subModules: []
  },
  {
    title: "Corporate Canvas",
    meta: {
      moduleName: APP_MODULES.CORPORATE_CANVA,
    },
    path: "/carboncompete/corporatecanva",
    icon: <Iconify icon="pixelarticons:drop-area" />,
    component: "corporatecanva",
    subModules: []
  },
  {
    title: "Carbon Advice",
    meta: {
      moduleName: APP_MODULES.CARBON_ADVICE,
    },
    // path: "/carboncompete/carbonadvice",
    icon: <Iconify icon="streamline:ai-technology-spark" />,
    component: "carbonadvice",
    subModules: [{
      title: "Forecasting",
      meta: {
        moduleName: "Forecasting",
      },
      path: "/carboncompete/forecasting",
      icon: <Iconify icon="streamline:ai-technology-spark" />,
      component: "forecasting",
      subModules: []
    },
    {
      title: "Scenario Planning",
      meta: {
        moduleName: "Scenario Planner",
      },
      path: "/carboncompete/scenarioplanning",
      icon: <Iconify icon="pajamas:planning" />,
      component: "scenarioplanning",
      subModules: []
    }
    ]
  },
  {
    title: "Corporate Canvas",
    path: "/carboncompete/suppliercorporatecanvas",
    meta: {
      moduleName: APP_MODULES.SUPPLIER_CORPORATE_CANVAS,
    },
    icon: <Iconify icon="pixelarticons:drop-area" />,
    component: "suppliercorporatecanvas",
    subModules: []
  },
  {
    title: "Suppliers",
    meta: {
      moduleName: APP_MODULES.CORPORATE_SUPPLIERS,
    },
    path: "/carboncompete/corporatesuppliers",
    icon: <Iconify icon="hugeicons:corporate" />,
    component: "corporatesuppliers",
    subModules: []
  },
  {
    title: "Activity Inputs",
    meta: {
      moduleName: APP_MODULES.LOCATION_ACTIVITY_INPUT,
    },
    path: "/carboncompete/locationactivityinputs",
    icon: <Iconify icon="hugeicons:corporate" />,
    component: "locationactivityinputs",
    subModules: []
  },
  {
    title: "Activity Inputs",
    path: "/carboncompete/supplychainconfig",
    meta: {
      moduleName: APP_MODULES.ACTIVITY_INPUTS,
    },
    icon: <Iconify icon="lucide:form-input" />,
    component: "supplychainconfig",
    subModules: []
  },
  {
    title: "Client Management",
    path: "/carboncompete/clients",
    meta: {
      moduleName: APP_MODULES.CLIENT_MANAGEMENT,
    },
    icon: <Iconify icon="lucide:form-input" />,
    component: "clients",
    subModules: []
  },
  // {
  //   title: "Supplier Dashboard",
  //   meta: {
  //     moduleName: APP_MODULES.SDASHBOARD,
  //   },
  //   path: "/carboncompete/sdashboard",
  //   icon: <Iconify icon="akar-icons:dashboard" />,
  //   component: "sdashboard",
  // },
  {
    title: "Suppliers",
    meta: {
      moduleName: APP_MODULES.SUPPLIERS,
    },
    path: "/carboncompete/suppliers",
    icon: <Iconify icon="ic:outline-people" />,
    component: "suppliers",
    subModules: []
  },
  {
    title: "Configure",
    meta: {
      moduleName: APP_MODULES.CONFIGURE,
    },
    path: "/carboncompete/configure",
    icon: <SettingsIcon />,
    component: "configure",
    subModules: []
  },
  {
    title: "Dashboard",
    meta: {
      moduleName: APP_MODULES.ESG_DASHBOARD,
    },
    path: "/carboncompete/esgdashboard",
    icon: <Iconify icon="akar-icons:dashboard" />,
    component: "esgdashboard",
    subModules: []
  },
  {
    title: "Activity Inputs",
    meta: {
      moduleName: APP_MODULES.ESG_DATA,
    },
    path: "/carboncompete/esgdata",
    icon: <Iconify icon="lucide:form-input" />,
    component: "esgdata",
    subModules: []
  },
  {
    title: "Bulk Upload",
    meta: {
      moduleName: APP_MODULES.BULK_UPLOAD,
    },
    path: "/carboncompete/esgbulkupload",
    icon: <Iconify icon="mage:upload" width="1.5em" height="1.5em" />,
    component: "esgbulkupload",
    subModules: []
  },
  {
    title: "Batch History",
    path: "/carboncompete/activityhistory",
    meta: {
      moduleName: APP_MODULES.ACTIVITY_HISTORY,
    },
    icon: <Iconify icon="ion:time-outline" />,
    component: "activityhistory",
    subModules: []
  },
  // {
  //   title: "Help and Support",
  //   meta: {
  //     moduleName: APP_MODULES.HELP_AND_SUPPORT,
  //   },
  //   path: "/carboncompete/help",
  //   icon: <Iconify icon="bx:support" />,
  //   component: "help",
  //   subModules:[]
  // },
  {
    title: "Configure",
    meta: {
      moduleName: APP_MODULES.SUPPLIER_CONFIGURE,
    },
    path: "/carboncompete/supplierconfig",
    icon: <SettingsIcon />,
    component: "supplierconfig",
    subModules: []
  },
];

const userModules =
  (JSON.parse(localStorage.getItem(APP_KEYS.USER_INFO) || "{}") || {})
    ?.modules || [];

export const navConfig = modules.filter(module =>
  userModules.some(userModule =>
    userModule.moduleName === module.meta.moduleName
  )
).map(module => {
  const matchingUserModule = userModules.find(userModule =>
    userModule.moduleName === module.meta.moduleName
  ).subModules;

  return {
    ...module,
    subModules: module.subModules.filter((item) => {
      return matchingUserModule?.some(matchingUserModule => matchingUserModule.subModuleName === item?.meta?.moduleName)
    }) || []
  };
});

