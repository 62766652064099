import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, Typography, Grid, styled } from '@mui/material';
import SubCategoryEmissions from './SubCategoryEmissions';

const CustomCard = styled(Card)(({ theme }) => ({
    borderRadius: '6px',
    boxShadow: '0 6px 10px 0 rgba(0, 0, 0, 0.06)',
    transition: 'transform 0.2s',
    border: '1px solid #306E91',
    '&:hover': {
        boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.3, 0.5)',
        transform: 'scale(1.02)',
    },
    height: '180px',
    cursor: 'pointer',
}));

const cardHeading = { color: '#306E91', fontSize: '15px', fontWeight: 600 };
const cardYear = { color: 'rgba(0, 0, 0, 0.6)', fontSize: '16px' };
const cardValue = { color: '#000', fontSize: '14px' };

export const ScopeInfoCards = ({ aggregatedEmissions, selectedYears }) => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [subCategoryData, setSubCategoryData] = useState({});
    const subCategoryRef = useRef(null);

    useEffect(() => {
        const categories = Object.keys(aggregatedEmissions);
        if (categories.length > 0) {
            setSelectedCategory(categories[0]);
        }
    }, [aggregatedEmissions]);

    useEffect(() => {
        if (selectedCategory) {
            const data = {};
            selectedYears.forEach(year => {
                if (aggregatedEmissions[selectedCategory] && aggregatedEmissions[selectedCategory][year]) {
                    data[year] = aggregatedEmissions[selectedCategory][year].subcategory_breakdown_emissions;
                }
            });
            setSubCategoryData(data);
        }
    }, [selectedCategory, aggregatedEmissions, selectedYears]);

    const handleCardClick = (category) => {
        setSelectedCategory(category);
        if (subCategoryRef.current) {
            subCategoryRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    const hasData = Object.keys(aggregatedEmissions).length > 0;
    const categories = Object.keys(aggregatedEmissions);
    const gridSize = categories.length > 3 ? 3 : 4;
    
    return (
        <>
            <Grid container spacing={3}>
                {hasData ? (
                    categories.map(category => (
                        <Grid item xs={12} sm={6} md={gridSize} key={category}>
                            <CustomCard onClick={() => handleCardClick(category)}>
                                <CardContent
                                    sx={{
                                        height: '100%',
                                        overflowY: 'scroll',
                                        padding: 2,
                                    }}
                                >
                                    <Typography sx={cardHeading}>{category}</Typography>
                                    {selectedYears.map(year => (
                                        <div key={year} style={{ marginBottom: '8px' }}>
                                            {aggregatedEmissions[category][year] && (
                                                <>
                                                    <Typography sx={cardValue}>
                                                        {aggregatedEmissions[category][year].totalEmission ? aggregatedEmissions[category][year].totalEmission.toFixed(2) : 0}
                                                    </Typography>
                                                    <Typography sx={cardYear}>
                                                        {year}
                                                    </Typography>
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </CardContent>
                            </CustomCard>
                        </Grid>
                    ))
                ) : (
                    <Grid item xs={12}>
                        <Typography variant="body1" align="center" color="textSecondary">
                            No data available. Please select options to display information.
                        </Typography>
                    </Grid>
                )}
            </Grid>
            {selectedCategory && (
                <div ref={subCategoryRef}>
                    <SubCategoryEmissions
                        subCategoryData={subCategoryData}
                        categoryName={selectedCategory}
                    />
                </div>
            )}
        </>
    );
};
